export class serviceProxy {
    private static proxies = [];

    public static activateCasingProxy(objectToProxy) {
        var proxy = this.createCasingProxy(objectToProxy);
        this.proxies.push(proxy);
        return proxy;
    }

    private static createCasingProxy(obj) {
        return new Proxy(obj, {
            get(target, prop) {
                if (typeof target[prop] === 'function') {
                    return new Proxy(target[prop], {
                        apply: (target, thisArg, argumentsList) => {
                            if (argumentsList.length == 1 && (typeof argumentsList[0] === 'string' || argumentsList[0]  instanceof String)) {
                                let parsedArgs;
                                try {
                                    parsedArgs = JSON.parse(<string>argumentsList[0]); //DO PROMISE.ALL
                                }
                                catch (e) {
                                    //it's a regular string, not JSON
                                    parsedArgs = argumentsList[0];
                                }

                                let retval;
                                //if function called from .net <-> js interop (and therefore parameters serialized by JSRuntimeWrapper.cs)
                                if (parsedArgs instanceof Array) {
                                    retval = Reflect.apply(target, thisArg, parsedArgs);
                                } else { //if function called from JS
                                    retval = Reflect.apply(target, thisArg, [parsedArgs]);

                                }

                                //Blazor can't handle array of promises, only a single promised being resolved.
                                if (retval instanceof Array && retval[0] instanceof Promise) {
                                    return Promise.all(retval);
                                }

                                return retval;

                            }

                            return Reflect.apply(target, thisArg, argumentsList);
                        }
                    });
                } else {
                    return Reflect.get(target, prop);
                }
            }
        });
    }

}