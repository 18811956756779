import { config } from ".";

export class sessionService {
    static create = function (anglianToken, dashboardToken, repId, username, forename, surname, products, region, salesTeam, myproducts, allowedproducts, useadaptlsprices, vatrates, adaptlsapplyvatrate, RegionMarkups, ExpiryMessage, DivisionCode, DivisionName, RelationShips, RestrictedMaterials, LivingSpacesRegionalMarkupsXML, EnvisageVersionsXML, UsingAD, EmailRegularExpression, addLocationTracking, ADAPTZoomLevelJSON, ADAPTLocationOKText, ADAPTLocationWarnText, ADAPTLocationErrorText, IsASL, ADAPTESignOnly, StopDiscountLogging, AllowDiaryLink) {
   window.localStorage.anglianToken = anglianToken;
   window.localStorage.dashboardToken = dashboardToken;
   window.localStorage.username = username;
   window.localStorage.repId = repId;
    var authExpiry = new Date();
    authExpiry.setDate(authExpiry.getDate() + parseInt(config.maxDaysWithoutAuth, 10));
   window.localStorage.tokenExpiration = authExpiry.setHours(23, 59, 59);

   window.localStorage.name = forename + ' ' + surname;
   window.localStorage.products = products;
   window.localStorage.region = region;
   window.localStorage.salesTeam = salesTeam;
   window.localStorage.expirymessage = ExpiryMessage;
   window.localStorage.DivisionCode = DivisionCode;
   window.localStorage.DivisionName = DivisionName;
    //AW
   window.localStorage.myproducts = '';
    if (myproducts !== undefined) {
        if (myproducts.length > 0) {
           window.localStorage.myproducts = JSON.stringify(myproducts); // AW

        }
    }

    //AW
   window.localStorage.allowedproducts = '';
    if (allowedproducts !== undefined) {
        if (allowedproducts.length > 0) {
           window.localStorage.allowedproducts = JSON.stringify(allowedproducts); // AW

        }
    }

    //AW 
   window.localStorage.useadaptlsprices = useadaptlsprices;

    //AW
   window.localStorage.vatrates = '';
    if (vatrates !== undefined) {
        if (vatrates.length > 0) {
           window.localStorage.vatrates = JSON.stringify(vatrates); // AW

        }
    }


    //AW 
   window.localStorage.adaptlsapplyvatrate = adaptlsapplyvatrate;

    // Added for JIRA 7012
   window.localStorage.regionmarkups = '';// '[{ "Group": "ALUMINIUM", "MarkUp": 1.21 } , { "Group": "BUILDINGWORKS", "MarkUp": 1.08 } , { "Group": "GARAGEDOORS", "MarkUp": 1.09 } , { "Group": "PVCU", "MarkUp": 1.12 },{ "Group": "ROOFTRIM", "MarkUp": 1.13 },{ "Group": "TIMBER", "MarkUp": 1.18 }]';
    if (RegionMarkups !== undefined) {
        if (RegionMarkups.length > 0) {
           window.localStorage.regionmarkups = JSON.stringify(RegionMarkups); // AW

        }
    }

    //Added for AHIADAPT - 163
   window.localStorage.relationships = '';
    if (RelationShips !== undefined && RelationShips !== null) {
        if (RelationShips.length > 0) {
           window.localStorage.relationships = JSON.stringify(RelationShips); // AW

        }
    }

    //Added for timber restriction.
   window.localStorage.RestrictedMaterials = '';
    if (RestrictedMaterials !== undefined && RestrictedMaterials !== null) {
       window.localStorage.RestrictedMaterials = JSON.stringify(RestrictedMaterials);

    }
    //Added for Living Spaces regional markup XML
   window.localStorage.LivingSpacesRegionalMarkupsXML = LivingSpacesRegionalMarkupsXML;

    //Added for Living Spaces Envisage versions XML
   window.localStorage.EnvisageVersionsXML = EnvisageVersionsXML;

    //Flag to indicate that ADA details are being used to logon.
   window.localStorage.UsingAD = UsingAD;

    //Email Regular Expression
   window.localStorage.EmailRegularExpression = EmailRegularExpression;

    //Add location tracking to audit.
   window.localStorage.addLocationTracking = addLocationTracking;

    //ADAPT Zoom level.
   window.localStorage.ADAPTZoomLevelJSON = ADAPTZoomLevelJSON;

    //ADAPT Location message text
   window.localStorage.ADAPTLocationOKText = ADAPTLocationOKText;
   window.localStorage.ADAPTLocationWarnText = ADAPTLocationWarnText;
   window.localStorage.ADAPTLocationErrorText = ADAPTLocationErrorText;

   window.localStorage.IsASL = IsASL;

   window.localStorage.ADAPTESignOnly = ADAPTESignOnly;

   window.localStorage.StopDiscountLogging = StopDiscountLogging;

   window.localStorage.AllowDiaryLink = AllowDiaryLink;

};


    static setADAPTSettings(ADAPTSettings) {
        window.localStorage.ADAPTSettings = ADAPTSettings;
    }

    static getDocVersions = function () {
        return window.localStorage.DocVersions;
    };

    static setDocVersions = function (DocVersions) {
        window.localStorage.DocVersions = DocVersions;
    };

    static setForcedOptInSettings = function (forcedsettings) {
        window.localStorage.ForcedOptInSettings = forcedsettings;
    };

    static getForcedOptInSettings = function (forcedsettings) {
        return window.localStorage.ForcedOptInSettings;
    };

    static getLogisticsCharges = function () {
        return window.localStorage.LogisticsCharges;
    };

    static setLogisticsCharges = function (LogisticsCharges) {
        window.localStorage.LogisticsCharges = LogisticsCharges;
    };

    static getVideoVersions = function () {
        return window.localStorage.VideoVersions;
    };
    static setVideoVersions = function (VideoVersions) {
        window.localStorage.VideoVersions = VideoVersions;
    };

    static getVideoMenu = function () {
        return window.localStorage.VideoMenu;
    };
    static setVideoMenu = function (VideoMenu) {
        window.localStorage.VideoMenu = VideoMenu;
    };

    static getImageVersions = function () {
        return window.localStorage.ImageVersions;
    };
    static setImageVersions = function (ImageVersions) {
        window.localStorage.ImageVersions = ImageVersions;
    };

    static getImageMenu = function () {
        return window.localStorage.ImageMenu;
    };
    static setImageMenu = function (ImageMenu) {
        window.localStorage.ImageMenu = ImageMenu;
    };

    static setCPMarkUps = function (data) {
        window.localStorage.CPMarkUps = data;
    };

    static getCPMarkUps = function () {
        return window.localStorage.CPMarkUps;
    };

}