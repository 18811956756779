export class queueManager {

    public ItemProcessingFn: (item: any) => Promise<any>;
    public OnItemProcessSuccess: (item: any) => Promise<any>;
    public OnItemProcessError: (error: Error) => Promise<any>;
    public OnAllRetriesExhausted: (item: any) => Promise<any>;
    public OnAllComplete: () => Promise<any>;

    //config 
    private readonly _howOftenCheckQueueMS = 1000;
    private readonly _maxAllowedRetries = 3;
    private readonly _loggingActive = true;

    private _queue = [];
    private _killQueue = false;

    //  ---------- PUBLIC ----------- //

    public start() {
        this.log("starting queue");

        this._killQueue = false;

        if (this.queueEmpty) {
            this.log("Nothing to process");
            this._killQueue = true;
            return;
        }

        this.log("queue not empty, number of entries:",this.queueCount);
        this.processQueue();
    }

    public stop() {
        this._killQueue = true;
        //queue loop will now stop after its current iteration if is not empty OR its currentl interval if watching for entries.
        this.log("Stopping the queue...");

    }

    public add(itemOrItems) {
        var isArray = Array.isArray(itemOrItems);

        //must go to back of queue
        if (isArray) {
            this._queue.push(...itemOrItems);
        }
        else {
            if (isArray) {
                this._queue.push(itemOrItems);
            }
        }
    }

    public getItems() {
        return this._queue;
    }

    // public getters //
    public get queueEmpty() {
        return this.queueCount === 0;
    }

    public get queueCount() {
        return !this._queue ? 0 : this._queue.length;
    }

    //   ---------- PRIVATE  ----------   //
    private async processQueue() {
        if (this.queueEmpty) {
            throw Error("Cannot process an empty queue.");
        }

        var retryCounter = 0;

        while (this.queueCount > 0) {
            var itemToProcess = this._queue[0];
            this.log("Processing entry: ", itemToProcess);

            try {
                await this.ItemProcessingFn(itemToProcess);
                this.log("Entry successfully processed!",);

            }
            catch (e) {
                retryCounter++;

                this.log("Entry processing FAILED. Retry number incremented to: ",retryCounter);
                await this.OnItemProcessError(e);

                if (retryCounter >= this._maxAllowedRetries) {
                    this.log("Number of retries exhausted. Removing entry from queue. Attemped # of times: ", retryCounter);


                    //run the handler for this and remove from queue
                    if (this.OnAllRetriesExhausted) {
                        await this.OnAllRetriesExhausted(itemToProcess);
                    }

                    retryCounter = 0;
                    this._queue.shift();
                }

                continue; //don't remove entry at the start, this will reprocess the first function
            }

            //if successful, remove entry
            retryCounter = 0;
            this._queue.shift();
            if (this.OnItemProcessSuccess) {
                await this.OnItemProcessSuccess(itemToProcess);
            }
        }

        if (this.OnAllComplete) {
            await this.OnAllComplete();
        }
    }

    private log(message, entity = null) {
        if (!this._loggingActive) {
            return;
        }

        var msg = message;
        if (entity != null) {
            console.log(msg, entity);
        }
        else {
            console.log(msg);
        }
    }
}