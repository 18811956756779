import _ from 'underscore';

export class depositsService {

    public calcDepositVals(repaymentPeriod, provider, OrderValue) {
        return this.calcDepositValues(repaymentPeriod, provider, OrderValue);
    }


    public checkDepositVal(deposit, depositVals, setVal) {
        return this.checkDeposit(deposit, depositVals, setVal);
    }

    private checkDeposit(deposit, depositVals, setVal) {

        if (!deposit || setVal === true) {
            return depositVals.minVal;
        }
        if (depositVals.enforce === true) {
            if (depositVals.minVal > 0) {
                if (deposit < depositVals.minVal) {
                    return depositVals.minVal;
                }
            }
            if (depositVals.maxVal > 0) {
                if (deposit > depositVals.maxVal) {
                    return depositVals.minVal;
                }
            }
        }

        return deposit;

    }

    private calcDepositValues(repaymentPeriod, provider, OrderValue) {

        var repayment = null;

        if (provider) {
            repayment = _.find(provider.Repayments, function (r) { return r.RepaymentPeriod == repaymentPeriod; });
        }

        if (repayment) {
            if (repayment.MinimumDeposit > 0) {
                if (repayment.DepositType && repayment.DepositType.length > 0) {
                    return this.calcDeposits(repayment, OrderValue);
                }
            }
        }

        return this.calcDeposits(provider.APRRate, OrderValue);

    }

    private calcDeposits(depItem, OrderValue) {

        var depositVals: any = {};
        depositVals.enforce = depItem.EnforceDepositValues;

        if (depItem.DepositType == 'FIXED') {
            depositVals.minVal = parseFloat(depItem.MinimumDeposit.toFixed(2));
            depositVals.maxVal = parseFloat(depItem.MaximumDeposit.toFixed(2));
        }
        else if (depItem.DepositType == 'PERCENT') {
            depositVals.minVal = parseFloat(((OrderValue / 100) * depItem.MinimumDeposit).toFixed(2));
            depositVals.maxVal = parseFloat(((OrderValue / 100) * depItem.MaximumDeposit).toFixed(2));
        }

        return depositVals;

    }
}