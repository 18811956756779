import { config, tokendata } from ".";
import { dataAccessService, defer } from "./dataAccessService";
import { encryptionService } from "./encryptionService";
import { mapFromAdaptService } from "./mapFromAdaptService";
import { queueManager } from "./queueManager";


export class queueService {
    _queueCommon = new queueCommon();
    _mapFromAdapt = new mapFromAdaptService();
    _encryptionService = new encryptionService();
    _dataAccessService = new dataAccessService();
    _config = config;
    _authID;
    _onCompleteCallback;


    defaultConfig = {
        delay: -1
    };

    setDefaultDelay = function(delay) {
         this.defaultConfig.delay = delay;
      };

    queues = {};

    public async startProcessingOpportunityQueue(authID, callback): Promise<any> {
        this._authID = authID;
        this._onCompleteCallback = callback;
        await this.open(this._queueCommon.opportunityQueueName);
    }

    public async addVersionToQueue(versionMessage, title, opportunityId): Promise<any> {
        var urlToSend = this._config.SaveOrder;

        //attach audits
        var audits = await this._dataAccessService.getAuditsForVersion(opportunityId,versionMessage.Id)
        versionMessage.AuditTrail = audits;

        await this.addToQueue(versionMessage, title, this._queueCommon.opportunityQueueName, urlToSend);
    }

    public async addToQueue(data, title, itemType, url) {
        var queueItem = {
            Type: itemType,
            Item: {
                endPoint: url,
                data: data
            },
            Title: title,
            Date: new Date()
        }

        await this._queueCommon.addItemToQueue(queueItem);
    }

    public async putItem(item) {
        await this._queueCommon.putDBQueueItem(item);
    }

    public viewOpportunityQueue(): Promise<any[]> {
        return this.viewQueue(this._queueCommon.opportunityQueueName);
    }



    // ======== PRIVATE =======//

    private async viewQueue(type) {
        var queueItems: any[] = await this._queueCommon.getDBQueueItemByType(type);
        var iteminfo = [];
        iteminfo.push(
            ...queueItems.map(qi => ({
                Id: qi.Id,
                Title: qi.Title,
                Date: qi.Date,
                Done: false
            }))
        );

        return iteminfo;
    }


    private async open(itemType) {
        var openingQueue = defer();

        if (this.queues[itemType]) {
            return this.queues[itemType];
        } else {
            this.queues[itemType] = openingQueue.promise;

            var queueService = new queueServiceInternal(null, itemType, this.uploadToServer.bind(this), null, this._onCompleteCallback);
            queueService._loading
                .then(openingQueue.resolve, openingQueue.reject);
        }

        return this.queues[itemType][itemType];
    }

    private async uploadToServer(qItem) {
        var deferred = defer();
        var user = await this._dataAccessService.getLocal('UserAccount',null, null, true);

        qItem.data.AuthID = this._authID;
        qItem.data.Token = user.AnglianToken;
        qItem.data.clientDateTime = new Date();
        qItem.data.clientDateTimeOffset = qItem.data.clientDateTime.getTimezoneOffset();

        qItem = await this._encryptionService.decryptQueueItem(qItem);
        var data = await fetch(qItem.endPoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(qItem.data)
        });

        if (!data.ok) {
            throw new Error("UPLOAD WAS UNSUCCESSFUL.")
        }

        var dataAsAny: any = data;

        if (dataAsAny && dataAsAny.OrderNumber && dataAsAny.OrderNumber.length) {
            try {
                var id = qItem.data.OrderDetails.Id;

                var opp = await this._dataAccessService.getLocal('opportunities', id);
                if (opp) {
                    opp.lead.OrderNumber = dataAsAny.OrderNumber;
                    await this._dataAccessService.saveLocal(opp, 'opportunities').then(deferred.resolve);
                } else {
                    deferred.resolve();
                }
            } catch (exception) { // if this goes wrong, it's not important code so just let it go
                deferred.resolve();
            }
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

}

class queueServiceInternal {
    public _loading: Promise<void>;
    _queueCommon = new queueCommon();
    _queue = new queueManager();
    processingFn: (any) => Promise<any>;
    onCompleteCallback: () => Promise<any>;

    connectionTest;
    _loggerService;
    _queueProcessingPromise = null;
    _type;
    _itemInfo = [];


    public constructor(connectionTest, itemType, processingFn, loggerService, onCompleteCallback) {
        this._type = itemType;
        this._loggerService = loggerService;
        this.processingFn = processingFn;
        this.connectionTest = connectionTest;
        this.onCompleteCallback = onCompleteCallback;
        this.initializeQueueService(processingFn);
    }

    public getInfo() {
        this._itemInfo;
    }


    private initializeQueueService(processingFn: (any) => Promise<any>) {
        this._queue.stop();
        var attempts = 0;

        //set what we want done to each item
        this._queue.ItemProcessingFn = async (qData) => {

            //1) ERROR HANDLING SETUP
            var errorHandler = function (error) {
                //var continueProcessing = true;

                //// We won't repeat attempts for errors anymore - we'll just spit them into the log and move on.
                //if (qData.Title !== 'Error') {
                //   attempts++;
                //   self._queue.add(qData, true);
                //}

                //if (attempts > 2 || qData.Title === 'Error') {
                //   attempts = 0;  // prepare for next time
                //   self._loggerService.logError(qData.Title + ' | Endpoint: ' + qData.Item.endPoint + ' | Error : ' + JSON.stringify(error), 'QueueService error');
                //   self._loggerService.logError(JSON.stringify(qData.Item.data), 'QueueService error');

                //   if (qData.Title !== 'Error') {
                //      continueProcessing = false;
                //   }

                //}

                //if (continueProcessing) {
                //   self._queue.next();
                //} else {
                //   self._queueProcessingPromise.reject();
                //   self._loggerService.logError('Stopping Queue', 'Repeated QueueService error');
                //}

                console.error(error);
                throw error;
            };

            //2) LOGGING
            //this._loggerService.logInfo('Processing Q Item: ' + qData.Title);


            try {
                //3) PROCESSING
                await processingFn(qData.Item)

                var deletedItemInfo = this._itemInfo.find(item => item.Id === qData.Id);
                deletedItemInfo.Done = true;
                attempts = 0;

            } catch (error) {
                errorHandler(error);
            }

        };

        //set what we want to happen after item processed
        this._queue.OnItemProcessSuccess = async (qData) => {
            console.log("sucessfully processed and now deleting: ", qData);
            await this._queueCommon.deleteDBQueueItem(qData);
        }

        this._queue.OnItemProcessError = async (error) => console.error(error);
        this._queue.OnAllRetriesExhausted = async (error) => console.error(error);
        this._queue.OnAllComplete = this.onCompleteCallback;

        //load it up
        this._loading = this.loadQueue();
    }

    private async loadQueue() {
        this._itemInfo = this._itemInfo || [];

        var queueItemCount = 0;

        var queueItems: any[] = await this._queueCommon.getDBQueueItemByType(this._type);
        this._queue.add(queueItems);

        if (!queueItems || !queueItems.length) {
            console.log("Queue is empty, stopping queue.");
            await this.onCompleteCallback();
            return;
        }

        this._itemInfo.push(
            ...queueItems.map(qi => ({
                Id: qi.Id,
                Title: qi.Title,
                Date: qi.Date,
                Done: false
            }))
        );

        this._queue.start();
    };

    

}

class queueCommon{
    public readonly queueItemTableName = "QueueItem";
    public readonly opportunityQueueName = "pushQueue";
    _dataAccessService = new dataAccessService();

    public async addItemToQueue(queueItem) {
        return this.addDBQueueItem(queueItem)
    }

    public async getDBQueueItemByType(type) {
        return this._dataAccessService.getLocal(this.queueItemTableName, "Type", IDBKeyRange.only(type))
    }

    private async addDBQueueItem(item) {
        return this._dataAccessService.saveLocal(item, this.queueItemTableName);
    }

    public async putDBQueueItem(item) {
        return this._dataAccessService.saveLocal(item, this.queueItemTableName);
    }

    public async deleteDBQueueItem(item) {
        return this._dataAccessService.delete("QueueItem", item.Id);
    }

}
