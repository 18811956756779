export class progressReportingService {
    private dotnetClassRef;
    private readonly SUCCESS = 'done';
    private readonly FAIL = 'could not complete';
    private readonly PENDING ='pending';

    public setDotNetRef(dotnetClassRef) {
        this.dotnetClassRef = dotnetClassRef;
    }

    //
    public async report(forEntity, text, status, loadingPromise: Promise<any> = null, onSuccessText = "", onFailText = "") {
        if (!this.dotnetClassRef) {
            console.error("dotnet class reference has not been set. Aborting updating sync progress");
            return;
        }

        var newState = {
            [forEntity]: {
                Text: text,
                Status: status
            }
        };

        await this.dotnetClassRef.invokeMethodAsync("UpdateSyncDownloadProgress", newState);

        if (loadingPromise) {
            this.onComplete(forEntity, loadingPromise, onSuccessText, onFailText);
        }
    }

    //update the status of entity download
    public updateStatus(forEntity, status): Promise<void> {
        return this.report(forEntity, null, status, null);
    }

    //handler for when entitydownload is complete
    public onComplete(forEntity, promiseToAttachCallback: Promise<any>, onSuccessText = "", onFailText = "", onSuccessTextFn: Function = null, disableOnFailCallback = false): Promise<void> {
        var newState = {
            [forEntity]: {
                Status: "",
                //we don't need to include text (aka title) as if we leave it null, it will not be updated in the state
            }
        };

        return promiseToAttachCallback
            .then(
                async successVal => {
                    onSuccessText = (onSuccessTextFn ? onSuccessTextFn() : null)
                        || onSuccessText
                        || this.SUCCESS;

                    newState[forEntity].Status = onSuccessText;
                    await this.dotnetClassRef.invokeMethodAsync("UpdateSyncDownloadProgress", newState);
                },
                async rejectedVal => {
                    if (disableOnFailCallback) {
                        return;
                    }
                    onFailText = onFailText || this.FAIL;
                    newState[forEntity].Status = onFailText;
                    await this.dotnetClassRef.invokeMethodAsync("UpdateSyncDownloadProgress", newState);
                }
            )
    }

    //event listener for percentage based front-end reporting
    public updateProgress(evt, forEntity) {
        if (evt.lengthComputable) { //evt.loaded the bytes browser receive
            //evt.total the total bytes seted by the header
            var percentComplete = Math.round((evt.loaded / evt.total) * 100);
                if (percentComplete < 100) {
                    this.updateStatus(forEntity, percentComplete + "%");
                } else {
                    this.updateStatus(forEntity, 'processing');
                }
        }
    }

    public reportError(errorMessage): Promise<any> {
        return this.dotnetClassRef.invokeMethodAsync("ReportError", errorMessage);
    }
}

