import { entityInfoService } from "./entityInfoService";
import { sessionService } from './sessionService';
import { config, tokendata } from '.';
import _, { map } from 'underscore';
import { AnglianData, dataAccessService, defer } from "./dataAccessService";
import { mapFromAdaptService } from './mapFromAdaptService';
import { mapToAdaptService } from './mapToAdaptService';
import { mappingHelperService } from './mappingHelperService';
import { PriceListService } from "./priceListService";
import { encryptionService } from "./encryptionService";
import { queueService } from "./queueService";
import { progressReportingService } from "./progressReportingService";
import { referenceDataService } from "./refferenceDataService";
import { BeforeSaveMode } from "./enums";
//import { SecurityService } from './securityService';
//import { winStorage } from './winStorage';

export class opportunityService {
    private _dataAccessService: dataAccessService = new dataAccessService();
    private _entityInfoService: entityInfoService = new entityInfoService();
    private _sessionService: sessionService = new sessionService();
    private _mapToAdaptService: mapToAdaptService = new mapToAdaptService();
    private _mapFromAdaptService = new mapFromAdaptService();
    private _encryptionService = new encryptionService();
    private _priceListService = new PriceListService();
    private _queueService = new queueService();
    private _referenceDataService = new referenceDataService();

    private dotnetRef;

    private _mappingHelperService: mappingHelperService = new mappingHelperService();
    public get progressReporting(): progressReportingService {
        return window["progressReportingService"];
    }
    constructor() { };
    public setDotNetRef(dotnetRef) {
        this.dotnetRef = dotnetRef;
    }

    //STUFF FOR TO AND FROM SERVER
 
    public async getLatestOpportunities(repNumber, getAll) {
        var batchID = "";
        var processedCount = 0;
        var affiliates = [];
        var contactmethods = [];

        var mapToDb = async (data) => {

            var opportunitiesArray = [];

            // The opportunities we want are in a named Array called Opportunities on an object called Opportunities within the body of the message.. and any level could be null.
            if (data.Opportunities && data.Opportunities.Opportunity) {
                opportunitiesArray = data.Opportunities.Opportunity;
            }

            batchID = data.Opportunities.BatchID;
            processedCount += opportunitiesArray.length;
            //var logger = loggerService.open('Getting latest opportunities  - batch id ' + batchID + ' ' + processedCount + ' in batch');

            var promises = [];

            //AW - Get user details so we have the valid products list.
            var oUser = await this._dataAccessService.getCurrentUser();

            for (var oppIdx in opportunitiesArray) {
                var remoteOpportunity = opportunitiesArray[oppIdx];
                var loadingPromise = defer();
                promises.push(loadingPromise.promise);

                //Check that we only process opportunities for products the user is setup for.
                if (!_.any(oUser.Products, (p) => { return p == remoteOpportunity.Product; })) {
                    loadingPromise.resolve(false);
                    return;
                }

                //Fetch a local copy of the opportunity if it exists.
                var query = IDBKeyRange.only(remoteOpportunity.OpportunityNumber);
                var ds = this._dataAccessService;
                var matchingLocalOpportunities = await ds.getLocal('opportunities', 'OpportunityNumber', query);

                //If opportunity is marked for deletion then remove.
                if (remoteOpportunity.Delete === 'Y') {
                    if (matchingLocalOpportunities.length) {
                        var opportunityIdToDelete = matchingLocalOpportunities[0].Id;
                        await ds.clear('opportunities', opportunityIdToDelete);
                        loadingPromise.resolve(true);
                    } else {
                        loadingPromise.resolve(true);
                    }

                    return;
                }

                var hasMatchingLocalOpportunity = matchingLocalOpportunities.length > 0;

                //If no matching opportunity found locally then create a bare bones opportunity object.
                var opportunity = hasMatchingLocalOpportunity ? matchingLocalOpportunities[0] : { lead: {}, order: { items: [] }, contract: {} };

                if (!hasMatchingLocalOpportunity) {
                    opportunity.IsFirstTimeViewingPresentation = true;
                }

                var _orderNumber = opportunity.lead.OrderNumber;

                if ((!remoteOpportunity.OrderNumber || remoteOpportunity.OrderNumber === "") && _orderNumber) {
                    // If we have an order number locally, keep hold of this.
                    remoteOpportunity.OrderNumber = _orderNumber;
                }

                opportunity.lead = remoteOpportunity;
                opportunity.unsoldVersions = opportunity.unsoldVersions || [];

                // Fetch the sold or unsold previous presentations.
                var soldOpportunity;


                // Please note FailedSales apparently contains all opportunities from server
                if (remoteOpportunity.FailedSales && remoteOpportunity.FailedSales.length > 0) {
                    opportunity = await this._mapToAdaptService.mapToUnsoldVersion(opportunity, remoteOpportunity);


                    for (var idx in opportunity.unsoldVersions) {
                        var uv = opportunity.unsoldVersions[idx];
                        if (uv.Id) {
                            continue;
                        }

                        uv.Id = await this._dataAccessService.getNextUnsoldVersionId(opportunity.unsoldVersions);

                    }

                }
                else {
                    opportunity.unsoldVersions = [];
                }


                delete opportunity.lead.FailedSales; // We don't want to save these in the app against the lead

                opportunity.lead.AppointmentDate = this._mappingHelperService.convertYYYYMMDDToDate(opportunity.lead.AppointmentDate);
                opportunity.lead.AppointmentTime = this._mappingHelperService.convertTimetoDate(opportunity.lead.AppointmentTime);

                if (opportunity.lead.SurveyDate || opportunity.lead.SurveyDate === 0) {
                    opportunity.lead.SurveyDate = this._mappingHelperService.convertYYYYMMDDToDate(opportunity.lead.SurveyDate);
                }
                if (opportunity.lead.SurveyTime || opportunity.lead.SurveyTime === 0) {
                    opportunity.lead.SurveyTime = this._mappingHelperService.convertTimetoDate(opportunity.lead.SurveyTime);
                }

                if (opportunity.lead.ProposedInstall) {
                    opportunity.lead.ProposedInstall = this._mappingHelperService.convertYYYYMMDDToDate(opportunity.lead.ProposedInstall);
                } else if (opportunity.lead.ProposedInstall == 0) {
                    opportunity.lead.ProposedInstall = null;
                }

                if (opportunity.lead.InstallationDate) {
                    opportunity.lead.InstallationDate = this._mappingHelperService.convertYYYYMMDDToDate(opportunity.lead.InstallationDate, true);
                } else if (opportunity.lead.InstallationDate == 0) {
                    opportunity.lead.InstallationDate = null;
                }

                // If we've got an order number, make sure the job is marked as sold
                if (opportunity.lead.OrderNumber) {
                    opportunity.contract.isSold = 1;
                }

                _.forEach(opportunity.lead.PreviousOrder, (o) => {
                    o.SoldDate = this._mappingHelperService.convertYYYYMMDDToDate(o.SoldDate);
                    o.InstalledDate = this._mappingHelperService.convertYYYYMMDDToDate(o.InstalledDate);
                });

                _.forEach(opportunity.lead.PreviousUnsoldOpportunity, (o) => {
                    o.AppointmentDate = this._mappingHelperService.convertYYYYMMDDToDate(o.AppointmentDate);
                });

                _.forEach(opportunity.lead.ActiveOpportunity, (o) => {
                    o.AppointmentDate = this._mappingHelperService.convertYYYYMMDDToDate(o.AppointmentDate);
                    o.AppointmentTime = this._mappingHelperService.convertTimetoDate(o.AppointmentTime);
                });

                // Append Rep No to opp to allow ASL viewing
                opportunity.lead.CanBeViewedById = repNumber;


                //Keep a copy of the Promar OptIns for opportunity.
                opportunity.promarOptIns = remoteOpportunity.OptIns;

                var cmObj = null;
                var iYesQty = 0;
                var iNoQty = 0;
                var iBlankQty = 0;
                var iOptIn = 0;

                //Only setup if not already.
                if (!opportunity.AnglianOptIns) {

                    //Setup the Anglian OptIn
                    opportunity.AnglianOptIns = {};
                    opportunity.AnglianOptIns.contactMethods = [];

                    for (iMethod = 0; iMethod < contactmethods.length; iMethod++) {

                        cmObj = {};
                        cmObj.id = contactmethods[iMethod].Id;

                        for (iOptIn = 0; iOptIn < remoteOpportunity.OptIns.length; iOptIn++) {

                            if (remoteOpportunity.OptIns[iOptIn].MethodId == contactmethods[iMethod].Id) {
                                cmObj.optIn = remoteOpportunity.OptIns[iOptIn].optIn;
                                if (cmObj.optIn.length === 0) {
                                    cmObj.optIn = ' ';
                                }

                                if (cmObj.optIn == 'Y') {
                                    iYesQty++;
                                }
                                if (cmObj.optIn == 'N') {
                                    iNoQty++;
                                }
                                if (cmObj.optIn == ' ') {
                                    iBlankQty++;
                                }
                                break;
                            }

                        }

                        opportunity.AnglianOptIns.contactMethods.push(cmObj);

                    }
                }

                //Only setup if not already.
                if (!opportunity.AffiliateOptIns) {
                    opportunity.AffiliateOptIns = [];
                    var afObj = null;
                    for (var iAff = 0; iAff < affiliates.length; iAff++) {

                        afObj = {};
                        afObj.id = affiliates[iAff].Id;
                        afObj.contactMethods = [];

                        iYesQty = 0;
                        iNoQty = 0;
                        iBlankQty = 0;

                        for (var iMethod = 0; iMethod < affiliates[iAff].ContactMethods.length; iMethod++) {

                            cmObj = {};
                            cmObj.id = affiliates[iAff].ContactMethods[iMethod].Id;

                            for (iOptIn = 0; iOptIn < remoteOpportunity.OptIns.length; iOptIn++) {

                                if (remoteOpportunity.OptIns[iOptIn].MethodId == affiliates[iAff].ContactMethods[iMethod].Id) {
                                    cmObj.optIn = remoteOpportunity.OptIns[iOptIn].optIn;
                                    if (cmObj.optIn.length === 0) {
                                        cmObj.optIn = ' ';
                                    }

                                    if (cmObj.optIn == 'Y') {
                                        iYesQty++;
                                    }
                                    if (cmObj.optIn == 'N') {
                                        iNoQty++;
                                    }
                                    if (cmObj.optIn == ' ') {
                                        iBlankQty++;
                                    }
                                    break;
                                }

                            }

                            afObj.contactMethods.push(cmObj);
                        }

                        opportunity.AffiliateOptIns.push(afObj);
                    }

                }

                //We want to clear out any previous discount trails as they are not needed. And also add ids
                if (opportunity.unsoldVersions) {
                    for (var iUnSold = 0; iUnSold < opportunity.unsoldVersions.length; iUnSold++) {
                        var msg = opportunity.unsoldVersions[iUnSold];
                        if (msg.OrderDetails) {
                            if (msg.OrderDetails.Contract) {
                                msg.OrderDetails.Contract.stream2DiscountTrail = [];
                            }
                        }

                    }

                }

                //flag for needing to be setup
                opportunity.OrderNeedsSettingUp = true;

                opportunity.contract.customerDocs = remoteOpportunity.CustomerDocs;

                var beforeSaveMode = hasMatchingLocalOpportunity ? null : BeforeSaveMode.Disabled;
                var id = await this._dataAccessService.saveOpportunity(opportunity, [beforeSaveMode]);
                if (soldOpportunity !== undefined) {
                    try {

                        opportunity.id = id;

                        var mapped = await this._mapToAdaptService.mapFromMessageBackToOpportunity(opportunity, soldOpportunity.OrderDetails);
                        opportunity.order = mapped.order;
                        opportunity.contract = mapped.contract;

                        // If we've got an order number, make sure the job is marked as sold
                        if (opportunity.lead.OrderNumber) {
                            opportunity.contract.isSold = 1;
                        }
                        opportunity.lead = mapped.lead;
                        await this._dataAccessService.saveOpportunity(opportunity);

                        console.log("Done mapping the opps");
                        loadingPromise.resolve();
                    } catch (err) {// If we have trouble saving it, at least let the user get back in.
                        console.log(err.Message);
                        loadingPromise.resolve();
                    }
                }
                else {
                    loadingPromise.resolve();
                }
            };

            //logger.watchPromise($q.all(promises));

            return promises;
        };

        var loading = defer();

        var rqData = { RepNumber: repNumber, IncludeAllActive: this._mappingHelperService.mapBoolToYN(getAll) };

        var sendConfirmation = (resultCode) => {
            this._dataAccessService.getRemote(config.SendOpportunitiesDataConfirm, {
                RepNo: repNumber,
                BatchID: batchID,
                RequestResult: resultCode
            }, () => {
            }, null, false);
        };

        var failure = function (reason) {
            console.log("failure reason", reason);
            sendConfirmation(1);
            loading.reject(reason);
        };

        var success = async (mapResult) => {
            await this._entityInfoService.updateEntityInformation('Opportunities');
            await this.progressReporting.updateStatus('LeadsProgress', 'processed ' + processedCount)

            await sendConfirmation(0);



            if (batchID != '-1') {
                //give the server a minute to update itself / have next batch ready to grab
                await this.asyncWait(2000);
                rqData.IncludeAllActive = "N";

                //then start poll for the next batch
                await this._dataAccessService.getRemote(config.GetOpportunities, rqData, mapToDb, null, false).then(success, failure);
            }
            else {
                loading.resolve();
            }
        };

        this._dataAccessService.getLocal('optinsettings').then(async (optinsettings) => {

            if (optinsettings) {
                if (optinsettings.length == 2) {
                    for (var iOptIn = 0; iOptIn < optinsettings.length; iOptIn++) {
                        if (optinsettings[iOptIn].length > 0) {

                            if (optinsettings[iOptIn][0].Affilate === true) {
                                affiliates = optinsettings[iOptIn];
                            }
                            else {
                                contactmethods = optinsettings[iOptIn];
                            }
                        }
                    }
                }
            }

            await this._dataAccessService.getRemote(config.GetOpportunities, rqData, mapToDb, null, false).then(success, failure);
        });

        return loading.promise;
    }

    public uploadQueue(callback): Promise<any> {
        return this._queueService.startProcessingOpportunityQueue(tokendata.authid, callback);
    }

    public async createUnsoldVersion(opp, thenAddToQueue = false) {

        //first, get the userActions
        var userActions = await this._dataAccessService.getLocal("userActions", "opportunityId", IDBKeyRange.only(opp.id));
        opp.userActions = userActions;

        var msg = await this._mapFromAdaptService.mapOpportunityToMessage(opp);

        msg = await this.generateIdForNewVersion(opp.id, msg);

        await this._dataAccessService.assignInProgressAuditsVersionId(opp.id, msg.Id);

        //attach unsoldversions to the opportunity, unsoldversions are NEVER brought into the Blazor/C# side of things for perfomance reasons. 
        var savedOpp = await this._dataAccessService.getOpportunity(opp.id);
        opp.unsoldVersions = savedOpp.unsoldVersions;

        if (!opp.unsoldVersions) {
            opp.unsoldVersions = [];
        }

        var encryptedCustDetails = JSON.stringify(msg.OrderDetails.Opportunity.CustomerDetails);
        var encryptedString = await this._encryptionService.encrypt(encryptedCustDetails);
        
        msg.OrderDetails.Opportunity.CustomerDetailsE = encryptedString;

        msg.OrderDetails.Opportunity.CustomerDetails = {};

        var promises = [];
        var inst = null;
        var sell = null;

        //Encrypt selling address
        if (msg.OrderDetails.Contract.SellingAddress) {

            var sellingAddress = JSON.stringify(msg.OrderDetails.Contract.SellingAddress);

            sell = this._encryptionService.encrypt(sellingAddress).then(function (encryptedString) {

                msg.OrderDetails.Contract.SellingAddressE = encryptedString;
                msg.OrderDetails.Contract.SellingAddress = null;
                //sell.resolve();

            });
            promises.push(sell);
        }


        //Encrypt Installation address
        if (msg.OrderDetails.Contract.InstallationAddress) {
            var installAddress = JSON.stringify(msg.OrderDetails.Contract.InstallationAddress);

            inst = this._encryptionService.encrypt(installAddress).then(function (encryptedString) {

                msg.OrderDetails.Contract.InstallationAddressE = encryptedString;
                msg.OrderDetails.Contract.InstallationAddress = null;
                //inst.resolve();
            });
            promises.push(inst);
        }

        //complete processing after all promises have been resolved
        await Promise.all(promises);

        opp.unsoldVersions.unshift(msg);
        opp.HasInProgressVersion = false;

        var title = this.getVersionMessageTitle(opp); //grab title before lead is encrypted

        //these are saved in a different table, don't save to the opp table as well
        delete opp.userActions;

        await this._dataAccessService.saveOpportunity(opp);

        if (thenAddToQueue) {
            await this._queueService.addVersionToQueue(msg, title, opp.id);
        }

        return msg;

    }


    public async getUnsoldVersions(opportunityId) {
        var opportunity = await this._dataAccessService.getOpportunity(opportunityId);
        var unsoldVersionData = await this._dataAccessService.getUnsoldVersionData(opportunityId);
        var unsoldVersionDataToReturn = unsoldVersionData.unsoldVersions.map(unsoldVersion => ({
            Id: unsoldVersion.Id,
            VersionMessageDate: new Date(unsoldVersion.OrderDetails.MessageDate),
            VersionRepNumber: unsoldVersion.OrderDetails.Opportunity.RepNumber,
            RepName: unsoldVersion.OrderDetails.Opportunity.RepName,
            NoOfUnits: unsoldVersion.OrderDetails.Order.Items.length,
            QuoteNo: ((unsoldVersion.OrderDetails.Opportunity.quote) ? unsoldVersion.OrderDetails.Opportunity.quote.QuoteNo : null),
            CashPrice: ((unsoldVersion.OrderDetails.Opportunity.quote && unsoldVersion.OrderDetails.Opportunity.quote) ? parseFloat(unsoldVersion.OrderDetails.Opportunity.quote.CashPrice) : null),
        }));

        if (opportunity.HasInProgressVersion) {
            unsoldVersionDataToReturn.push({
                VersionMessageDate: null,
                VersionRepNumber: opportunity.lead.RepNumber,
                RepName: opportunity.lead.RepName,
                NoOfUnits: opportunity.order.items.length,
                QuoteNo: (opportunity.quote ? opportunity.quote.QuoteNo : null),
                CashPrice: (opportunity.quote ? parseFloat(opportunity.quote.CashPrice) : null),
                IsInProgressVersion: true
            });          
        }

        var fullAddress = unsoldVersionData.lead.Address1 + ", "
            + ((unsoldVersionData.lead.Address2 && unsoldVersionData.lead.Address2.length)
                ? unsoldVersionData.lead.Address2 + ", "
                : "")
            + unsoldVersionData.lead.PostCode;
        return {
            OpportunityId: opportunityId.toString(),
            UnsoldVersionData: unsoldVersionDataToReturn,
            AppointmentDateTime: unsoldVersionData.lead.AppointmentDate,
            Product: unsoldVersionData.lead.Product,
            FullName: unsoldVersionData.lead.Title + unsoldVersionData.lead.Forename + unsoldVersionData.lead.Surname,
            FullAddress: fullAddress

        };
    }

    public async restoreUnsoldVersion(opportunityId, versionRepNumber, versionMessageDate): Promise<any> {
        var opportunity = await this._dataAccessService.getLocal('opportunities', opportunityId, null, true);
        //if (matches.length > 0) {
        //    var opportunity = matches[0];

        let version;

        if (versionRepNumber && versionMessageDate) {
            version = _.find(opportunity.unsoldVersions, function (unsoldVersion) {
                var isSame =
                    unsoldVersion.OrderDetails.Opportunity.RepNumber === versionRepNumber &&
                    unsoldVersion.OrderDetails.MessageDate.toString() === versionMessageDate.toString();

                return isSame;
            });
        }
        else {
            //user has selected in-progress version, don't map version data onto opp (its already there)
            version = null;
        }

        if (version) {
            //decrypt the version for mapping to the opportunity object
            version = await this._encryptionService.decryptUnsoldVersion(version);

            var mapped = await this._mapToAdaptService.mapFromMessageBackToOpportunity(opportunity, version.OrderDetails);
            opportunity.order = mapped.order;
            opportunity.contract = mapped.contract;
            opportunity.lead = mapped.lead;



            //Update 
            if (version.OrderDetails.Contract) {
                opportunity.AffiliateOptIns = mapped.AffiliateOptIns;
                opportunity.AnglianOptIns = mapped.AnglianOptIns;
                opportunity.SuppressedAnglianOptIns = mapped.SuppressedAnglianOptIns;
            }
        }

        opportunity.TreatingCustomersFairlyValid = false;
        opportunity.CustomerDetailsValid = false;
        opportunity.CustomerPrioritiesValid = false;
        opportunity.DesignValid = false;

        //flag for preloading, this will mean data will be preloaded if opp.order.items are present
        this.dotnetRef.invokeMethodAsync("SetNeedToPreload", opportunity.id);

        return this._dataAccessService.saveOpportunity(
            opportunity,
            [
                BeforeSaveMode.DontAttachItems,
                BeforeSaveMode.DontAttachUnsoldVersions
            ]
        );
        
    //}

    }

    //MY CUSTOMER TABLE DATA
    public getSalesAwaitingInstallation(opportunities) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return _.filter(opportunities, function (o) { return o.lead.SurveyDate && o.lead.SurveyDate < today && (!o.lead.ProposedInstall || o.lead.ProposedInstall > today) && !o.lead.InstallationDate; });
    }
    public getSalesInstallInProgress(opportunities) {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return _.filter(opportunities, function (o) { return o.lead.SurveyDate && o.lead.SurveyDate < today && !((!o.lead.ProposedInstall || o.lead.ProposedInstall > today) && !o.lead.InstallationDate); });
    }

    public async getOpportunity(opportunityId, trimPropertiesForFrontEnd = true) {
        return this._dataAccessService.getOpportunity(opportunityId, trimPropertiesForFrontEnd);
    }

    public async getContractNotesForOpp(oppId) {
        var numberOppId: number;
        if (typeof oppId === 'string') {
            numberOppId = parseInt(oppId);
        }
        else {
            numberOppId = oppId;
        }
        var opp = await this._dataAccessService.getOpportunity(numberOppId);
        return opp.order.notes;
    }

    public async setContractNotesForOpp(oppId, contractNotes) {
        var numberOppId: number;
        if (typeof oppId === 'string') {
            numberOppId = parseInt(oppId);
        }
        else {
            numberOppId = oppId;
        }
        var opp = await this._dataAccessService.getOpportunity(numberOppId);
        opp.order.notes = contractNotes;
        await this._dataAccessService.saveOpportunity(opp);
    }



    public async viewCustomerFile(document, opportunityId): Promise<any> {
        var loading = defer();

        try {
            var docName = document.DocumentName;
            //1) check if the file exists,
            var file = await this._dataAccessService.getCustomerFile(opportunityId, docName);

            if (file) {
                var dataurl = URL.createObjectURL(file.blob);
                loading.resolve(dataurl);
                return loading.promise;
            }


            //2) else, we need to download it before viewing it
            var processList = async (data) => {
                var dataFile = data.Files;
                var contentType = this.determineContentType(dataFile.FileType);

                //blob it for storage
                var blob = this._referenceDataService.blobIt(dataFile.FileBytes, contentType);
                var file = {
                    fileName: docName,
                    fileType: dataFile.FileType,
                    blob: blob,
                    opportunityId: opportunityId
                };

                await this._dataAccessService.saveCustomerFile(file);

                //now create dataurl to be viewed and return
                var dataUrl = URL.createObjectURL(blob);

                loading.resolve(dataUrl);
            };

            var url = config.GetDocuments;
            await this._dataAccessService.getRemote(url, document, processList, null, false, true);

        } catch (Ex) {
            var error = Ex.Message;
            loading.reject(Ex);
        }




        return loading.promise;
    }

    public getDocumentIfExists(document, opportunityId) {
        return this._dataAccessService.saveLocal(document, 'customer-docs');
    }

    public async getDesignedItemForOpportunity(opportunityId: number, designItemUniqueId: number) {
        var opp = await this.getOpportunity(opportunityId, false);
        var item = opp.order.items.filter(i => i.uniqueId == designItemUniqueId);

        return item.length ? item[0] : null;
    }

    public async deleteDesignItem(opportunityId: number, designItemUniqueId: number) {
        var opp = await this.getOpportunity(opportunityId, false);
        opp.order.items = opp.order.items.filter(i => i.uniqueId != designItemUniqueId);

        await this._dataAccessService.saveOpportunity(opp, [BeforeSaveMode.DontAttachItems]);

    }



    public async getDesignedItemsForOpportunity(opportunityId: number) {
        var opp = await this.getOpportunity(opportunityId, false);
        return opp.order.items;

    }

    public async addUpdateDesignItem(opportunityId: number, itemToSave) {
        var opp = await this.getOpportunity(opportunityId, false);

        if (!opp.order.items) {
            opp.order.items = [];
        }
        else if (!itemToSave.uniqueId) {
            itemToSave.uniqueId = Math.max(opp.order.items.map(i => i.uniqueId)) + 1;
        }

        var savedItem = opp.order.items.filter(i => i.uniqueId == itemToSave.uniqueId)[0];
        if (!savedItem) {
            opp.order.items.push(itemToSave);
        }
        else {
            var itemIdx = opp.order.items.findIndex(element => element.uniqueId === itemToSave.uniqueId);
            opp.order.items[itemIdx] = itemToSave;
        }

        await this._dataAccessService.saveOpportunity(opp, [BeforeSaveMode.DontAttachItems]);
    }



    //------- private methods --------//

    private determineContentType(fileType: string) {
        if (fileType.toLowerCase().includes("pdf")) {
            return "application/pdf";
        }
        else if (fileType.toLowerCase().includes("jpeg") || fileType.toLowerCase().includes("jpg")) {
            return "image/jpeg";
        }
        else if (fileType.toLowerCase().includes("png")) {
            return "image/png";
        }
        else if (fileType.toLowerCase().includes("txt")) {
            return "text/plain";
        }
    }

    private findExtra(extrasGroups, property) {
        var found = false;
        _.forEach(extrasGroups, function (group, i) {
            if (!found) {
                _.forEach(group.data, function (extra, j) {
                    if (!found) {
                        if (extra.Id == property.Id) {
                            found = true;
                            extrasGroups[i].data[j] = property;
                        } else if (extra.Attributes && extra.Attributes.length) {
                            var attr = _.find(extra.Attributes, function (a) { return a.Id == property.Id; });
                            if (attr) {
                                found = true;
                                var k = _.indexOf(extra.Attributes, attr);
                                extrasGroups[i].data[j].Attributes[k] = property;

                                if (group.specialSelections == null) {
                                    group.specialSelections = [];
                                }

                                group.specialSelections.push(property);
                            }
                        }
                    }
                });
            }
        });
    }

    private getVersionMessageTitle(opp) {
        return opp.lead.Title + " " + opp.lead.Forename + " " + opp.lead.Surname;
    }

    private asyncWait(millisecondsToWait: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, millisecondsToWait));
    }

    private async generateIdForNewVersion(oppId, version) {
        var opp = await this._dataAccessService.getLocal("opportunities", oppId, null, true);
        version.Id = await this._dataAccessService.getNextUnsoldVersionId(opp.unsoldVersions);

        return version;
    }
}

