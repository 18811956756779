
export class mappingHelperService {

    public convertDateToYYYYMMDD(date) {
            var NO_DATE = 'To be arranged';
            if (!date || date === NO_DATE) {
                return null;
            }
            return date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
            }

    public convertTimeToHHMMSS(date) {
        var NO_DATE = 'To be arranged';
                if (!date || date === NO_DATE) {
                    return null;
                }
                return ("0" + (date.getHours())).slice(-2) + ("0" + (date.getMinutes())).slice(-2) + ("0" + date.getSeconds()).slice(-2);
            }

    public convertTimeToHHMM(date) {
        var NO_DATE = 'To be arranged';
                if (!date || date === NO_DATE) {
                    return null;
                }
                return ("0" + (date.getHours())).slice(-2) + ("0" + (date.getMinutes())).slice(-2);
            }

    public convertYYYYMMDDToDate(yyyymmdd, breturnnulloninvalid=false) {

        var NULL_DATE_FROM_SERVICE = '0';
                if (!yyyymmdd || yyyymmdd === NULL_DATE_FROM_SERVICE) {

                    if (breturnnulloninvalid) {
                        return null;
                    }
                    else {
                        return null;
                    }


                }
                yyyymmdd = yyyymmdd.toString();

                var date = new Date(yyyymmdd.substring(0, 4), yyyymmdd.substring(4, 6) - 1, yyyymmdd.substring(6, 8));
                date.setHours(0, 0, 0, 0);
                
                return date;
            }

    public convertDateToTime(date) {
                if (!date) {
                    return null;
                }

                var time = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
                time = time.replace(/:/g, '');
                return time;
            }

    public convertTimetoDate(time) {
        var NULL_DATE_FROM_SERVICE = '0';

                if (!time || time === NULL_DATE_FROM_SERVICE) {
                    return null;
                }

                time = time.toString();

                // Defensive check so that if the time string isn't 6 chars, it definitely will be.
                var pad = "000000";
                time = pad.substring(0, pad.length - time.length) + time;

                return new Date(0, 0, 0, time.substring(0, 2), time.substring(2, 4), time.substring(4, 6));
            }

    public mapBoolToYN(bool) {
                return bool ? 'Y' : 'N';
            }

    public mapYNToBool(YN) {
                return YN === 'Y';
            }

    public mapNumberToBool(number) {
                return number == 1;
            }

    public mapBoolToNumber(bool) {
                return bool ? 1 : 0;
            }

    public mapNumberToYN(number) {
                return number > 0 ? 'Y' : 'N';
            }

    public mapYNToNumber(YN) {
                return YN == 'Y' ? 1 : 0;
            }
}


