import { config } from ".";
import { AnglianData, dataAccessService, defer } from "./dataAccessService";
import { progressReportingService } from "./progressReportingService";
import { referenceDataService } from "./refferenceDataService";


export class entityInfoService {
    private _dataAccessService: dataAccessService = new dataAccessService;
    private _referenceDataService: referenceDataService = new referenceDataService;

    public get progressReportingService(): progressReportingService {
        return window["progressReportingService"];
    }

    /**
    * Syncronises EnityInfo with the back end and local cache
    * @param forceUpdate if true will update  all data in the local indexdb database, false will only sync data that needs to be updated.
    * @param data Anglian Dashboard Token, with Rep Number and AuthID
    * @returns a Promise that will wreolve
    */
    public async Sync(forceUpdate): Promise<any> {
        try {
            var syncing = defer();

            var rd = this._referenceDataService;
            var caller = this;

            var entities = await this.getLatestEntityInfo(forceUpdate);
            var promises = [];

            var ei = new entityInfoService();
            entities.forEach(function (e) {
                if (e.needsUpdating) {
                    promises.push(ei.updateEntity(e.entity, e.currentUpdate));
                }
            });

            var adminCharges = rd.updateAdminCharges();
            //loggerService.forPromise(adminCharges, 'Get Admin Charges');
            promises.push(adminCharges);

            var RelevantDays = rd.updateRelevantDays();
            //loggerService.forPromise(RelevantDays, 'Get Relevant Days');
            promises.push(RelevantDays);

            var commissions = rd.updateCommissions();
            //loggerService.forPromise(commissions, 'Get Commissions');
            promises.push(commissions);

            var optinsettings = rd.updateOptInSettings();
            //loggerService.forPromise(optinsettings, 'Get OptIn Settings');
            promises.push(optinsettings);

            var privacydoc = rd.getPrivacyDocument();
            //loggerService.forPromise(privacydoc, 'Get Privacy Document');
            promises.push(privacydoc);

            var forcedoptinsettings = rd.updateForcedOptInSettings();
            //loggerService.forPromise(forcedoptinsettings, 'Get Forced Opt In Settings');
            promises.push(forcedoptinsettings);

            var logisticCharges = rd.updateLogisticCharges();
            //loggerService.forPromise(logisticCharges, 'Get Forced Opt In Settings');
            promises.push(logisticCharges);

            var financeSetup = rd.updateFinanceSetup();
            //loggerService.forPromise(financeSetup, 'Get Finance Setup');
            promises.push(financeSetup);


            var videosDownload = rd.getVideos();
            ////loggerService.forPromise(videosDownload, 'Get Videos');
            promises.push(videosDownload);

            var imagesDownload = rd.getImages();
            //loggerService.forPromise(imagesDownload, 'Get Images');
            promises.push(imagesDownload);

            var eSyncCheck = rd.checkESignStatus();
            //loggerService.forPromise(eSyncCheck, 'Check Esign Statuses');
            promises.push(eSyncCheck);

            var vocCheck = rd.checkVOCStatus();
            //loggerService.forPromise(vocCheck, 'Check VOC Statuses');
            promises.push(vocCheck);

            var leadTimes = rd.updateLeadTimes();
            //loggerService.forPromise(leadTimes, 'Updating Lead Times');
            promises.push(leadTimes);

            var adaptSettings = rd.getADAPTSettings();
            //loggerService.forPromise(adaptSettings, 'Updating ADAPT Settings');
            promises.push(adaptSettings);

            var cpMarkUps = rd.updateCPMarkUps();
            promises.push(cpMarkUps);

            await Promise.allSettled(promises);
            await caller.confirmSyncComplete();


            syncing.resolve();
            return syncing.promise;
        }
        catch (e) {
            console.error("Error in entityInfoService.Sync:");
            console.error(e);
            this.progressReportingService.reportError(e.message);
        }
    };

    private updateEntity(entityName, currentUpdate): Promise<any> {
        var updating = defer();

        //loggerService.forPromise(updating.promise, 'Updating ' + entityName);

        var successFunction = function (entityType) {
            var ei = new entityInfoService();
            ei.updateEntityInformation(entityType).then(updating.resolve, updating.reject);
        };

        var errorFunction = function () {
            updating.reject();
        };


        switch (entityName) {
            case 'Discounts':
                this._referenceDataService.updateDiscounts().then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'PriceList':
                this._referenceDataService.getPriceList(currentUpdate).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'CustomerPriorities':
                this._referenceDataService.updateReferenceData('customerPriorities',
                    'Id',
                    config.GetCustomerPriorities).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'BudgetOptions':
                this._referenceDataService.updateReferenceData('budgetOptions', 'Id',
                    config.GetBudgetOptions).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'RoomList':
                this._referenceDataService.updateReferenceData('rooms', 'Id',
                    config.GetRoomList).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'FundingOptions':
                this._referenceDataService.updateReferenceData('fundingOptions', 'Id',
                    config.GetFundingOptions).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'TimescaleOptions':
                this._referenceDataService.updateReferenceData('timescaleOptions', 'Id',
                    config.GetTimescaleOptions).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'Marketing':
                this._referenceDataService.updateReferenceData('marketing', 'Id',
                    config.GetMarketingOptions).then(function () { successFunction(entityName); }, errorFunction);

                break;
            //case 'FinanceProvider':
            //    referenceDataService.updateFinanceProviders().then( function () { successFunction( entityName ); }, errorFunction );
            //    break;
            case 'FinanceNotEligibleOptions':
                this._referenceDataService.updateReferenceData('financeNotEligibleOptions', 'Id',
                    config.GetFinanceNotEligibleOptions).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'SlidingScaleRegionalPricings':
                this._referenceDataService.updateReferenceData('slidingscaleregionalpricings', 'Id',
                    config.GetSlidingScaleRegionalPricings).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'MagicEyeVariables':
                this._referenceDataService.updateReferenceData('magiceyevariables', 'Id',
                    config.GetMagicEyeVariables).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'EnvisageSetup':
                this._referenceDataService.updateReferenceData('envisagesetup', 'Id',
                    config.GetEnvisageSetup).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'ScrappageScheme':
                this._referenceDataService.updateReferenceData('scrappagescheme', 'Id',
                    config.GetScrappageScheme).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'NoEmailCharge':
                this._referenceDataService.updateReferenceData('noemailcharge', 'Id',
                    config.NoEmailCharge).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'RegionCost':
                this._referenceDataService.updateReferenceData('regioncost', 'Id',
                    config.RegionCost).then(function () { successFunction(entityName); }, errorFunction);
                break;
            case 'DefaultError':
                this._referenceDataService.updateReferenceData('defaulterror', 'Id',
                    config.GetDefaultError).then(function () { successFunction(entityName); }, errorFunction);
                break;
            default:
                updating.resolve();
        }

        return updating.promise;
    };

    public updateEntityInformation(entityName): Promise<any> {
        var d = defer();
        var query = IDBKeyRange.only(entityName);
        var ds = this._dataAccessService;
        ds.getLocal('entityInfo', 'EntityName', query).then(function (entityInfo) {
            var result;
            if (!entityInfo.length) {
                result =
                    { EntityName: entityName, LastUpdated: new Date() };
            } else {
                result = entityInfo[0];
                result.LastUpdated = new Date();
            }
            ds.saveLocal(result, 'entityInfo').then(d.resolve, d.reject);
        });

        return d.promise;
    };

    private async getLatestEntityInfo(forceUpdate): Promise<any> {

        var loading = defer();
        //loggerService.forPromise(loading.promise, 'Getting latest entity information');

        var ds = this._dataAccessService;
        var mapToDb = function (entityInfo) {
            try {
                var infoPromises = [];
                entityInfo.forEach((e) => {
                    var d = defer();
                    infoPromises.push(d.promise);

                    var query = IDBKeyRange.only(e.EntityName);

                    ds.getLocal('entityInfo', 'EntityName', query).then(function (currentInfo) {
                        var needsUpdating = forceUpdate;
                        var lastUpdated = new Date(e.LastUpdated);
                        lastUpdated = new Date(Date.UTC(lastUpdated.getFullYear(), lastUpdated.getMonth(), lastUpdated.getDate(), lastUpdated.getHours(), lastUpdated.getMinutes(), lastUpdated.getSeconds()));
                        if (!currentInfo.length || currentInfo[0].LastUpdated < lastUpdated) {
                            // We need to update this one
                            needsUpdating = true;
                        }
                        // If force update is true, always send Null so we get everything.
                        var currentUpdate = currentInfo.length && !forceUpdate ? currentInfo[0].LastUpdated : null;
                        d.resolve({ entity: e.EntityName, needsUpdating: needsUpdating, currentUpdate: currentUpdate, lastUpdated: new Date(e.LastUpdated) });
                    });
                });
            } catch (e) {
                console.log("error in eneityInformap" + e);
            }

            return infoPromises;
        };

        ds.getRemote(config.GetEntityInfo, {}, mapToDb, null)
            .then(
                (result) => {
                    console.log("entities have retunred");
                    loading.resolve(result);
                }
            )
            .catch((e) => {
                console.error(e);
                loading.reject(e);
            });

        return loading.promise;
    };    

    public async confirmSyncComplete(): Promise<any> {
        try {

            var ds = this._dataAccessService;
            var mapToDb = function (data) {
                var promises = [];
                var fakePromise = defer();
                fakePromise.resolve();
                promises.push(fakePromise.promise);
                return promises;
            };
            await ds.getRemote(config.ConfirmSyncComplete, {}, mapToDb, null);
        }
        catch (e) {
            await this.progressReportingService.updateStatus("Confirm Sync Download", "FAILED");
        }
    }

}