import _ from 'underscore';
import * as moment from 'moment';
import { defer, dataAccessService } from './dataAccessService';

export class DiscountService {
    dataAccess = new dataAccessService();

    public removeInvalidDiscounts(appliedDiscounts) {
        _.forEach(appliedDiscounts, function (discount) {
            discount.appliedOptions = _.filter(discount.appliedOptions, function (opt) { return opt.applicableBand != null; });
        });
    }

    public getExpiredDiscounts(appliedDiscounts) {

        var flattened = _.flatten(_.pluck(appliedDiscounts, 'appliedOptions'));

        var now = moment();

        return _.filter(flattened, (option) => {

            this.adjustValidDateRanges(option);

            if (option.ValidFrom && option.ValidTo) {
                return !now.isBetween(option.ValidFrom, moment(option.ValidTo).endOf("day"));
            }
            else if (option.ValidFrom) {
                return now.isBefore(option.ValidFrom);
            }
            else if (option.ValidTo) {
                return now.isAfter(moment(option.ValidTo).endOf("day"));
            }
            else {
                return false;
            }

        });
    }

    public calculateSlidingScalePercent(orderValue, chargedAdminFee, bottomPrice, slidingScaleEntry, slidingScaleBottomPercent, allAdminFees, auditLog, regionCost, logisticCharge) {

        if (isNaN(logisticCharge) === false) {
            chargedAdminFee += logisticCharge;
        }

        //auditLog.paramOrderValue = orderValue;
        //auditLog.paramChargedAdminFee = chargedAdminFee;
        //auditLog.paramBottomPrice = bottomPrice;
        //auditLog.paramSlidingScaleEntry = slidingScaleEntry;
        //auditLog.paramSlidingScaleBottomPercent = slidingScaleBottomPercent;
        //auditLog.paramAllAdminFees = allAdminFees;
        //auditLog.paramRegionCost = regionCost;
        //auditLog.paramLogisticCharge = logisticCharge;

        var orderValueWithoutAdminFee = orderValue - chargedAdminFee;
        //auditLog.orderValueWithoutAdminFee = orderValueWithoutAdminFee;

        bottomPrice = this.removeAdminFeeFromPrice(bottomPrice, allAdminFees) - regionCost - logisticCharge;
        //auditLog.bottomPrice = bottomPrice;

        slidingScaleEntry = this.removeAdminFeeFromPrice(slidingScaleEntry, allAdminFees) - regionCost - logisticCharge;
        //auditLog.slidingScaleEntry = slidingScaleEntry;

        var retval = Math.round((1 - (orderValueWithoutAdminFee - bottomPrice) / (slidingScaleEntry - bottomPrice)) * slidingScaleBottomPercent);
        if (Number.isNaN(retval)){
            retval = null;
        }

        return { percentage: retval }
    }

    public findAndRemoveSlidingScaleDiscount(appliedDiscounts) {
        var slidingScale = this.findSlidingScaleDiscount(appliedDiscounts);

        if (slidingScale) {
            _.forEach(appliedDiscounts, function (discount) {
                var idx = discount.appliedOptions.indexOf(slidingScale);

                if (idx > -1) {
                    discount.appliedOptions.splice(idx, 1);

                    // Remove the parent discount too.
                    var discountIdx = appliedDiscounts.indexOf(discount);
                    appliedDiscounts.splice(discountIdx, 1);
                    return;
                }
            });
        }
    }

    public findAndRemoveSlidingScaleDiscountbyType(appliedDiscounts, Type) { //1 = Additional 2 = extendend options.
        var slidingScale = this.findSlidingScaleDiscount(appliedDiscounts);

        if (slidingScale) {
            _.forEach(appliedDiscounts, function (discount) {
                var idx = discount.appliedOptions.indexOf(slidingScale);

                if (idx > -1) {
                    //var AdditionaOrExtendScale = findSlidingScaleDiscountByType(discount.appliedOptions, Type);
                    if (Type == 2) {
                        discount.appliedOptions[0].ExtendedEovDiscount = undefined;
                    }
                    if (Type == 1) {
                        discount.appliedOptions[0].AdditionalEovDiscount = undefined;
                    }
                    if (discount.appliedOptions[0].ExtendedEovDiscount === undefined && discount.appliedOptions[0].AdditionalEovDiscount === undefined) {
                        discount.appliedOptions.splice(idx, 1);

                        // Remove the parent discount too.
                        var discountIdx = appliedDiscounts.indexOf(discount);
                        appliedDiscounts.splice(discountIdx, 1);
                    }

                    return;
                }
            });
        }
    }

    public recalculateDiscounts(viewDiscounts, product, materialCodes, regionNumber, totalPrice, appliedDiscounts, totalBuildingWorksPrice, totalADGPrice, units, leadSource, mediaCode) {
        var recalculating = defer();
        this.dataAccess.getLocal('discounts').then( (discounts) => {
            var discountOptions = this.setupDiscountOptions(discounts, product, materialCodes, regionNumber, totalPrice, totalBuildingWorksPrice, totalADGPrice, units, leadSource, mediaCode);

            _.forEach(appliedDiscounts, (discount) => {
                _.forEach(discount.appliedOptions, (opt) => {
                    if (opt.Id === 'slidingScale' || opt.Id === 'finance') {
                        return;
                    }

                    var match = _.find(discountOptions, function (o) { return o.Id === opt.Id; });
                    opt.applicableBand = match.applicableBand;
                    opt.amountToNextLevel = match.amountToNextLevel;
                    opt.percentThroughBand = match.percentThroughBand;

                    // If we have drip fed, and the discount is still valid, but the drip feed is bigger than we are now allowed....
                    if (opt.amountAdded && opt.applicableBand && opt.amountAdded > opt.applicableBand.DiscountApplied) {
                        // Set to the max we are now allowed.
                        opt.amountAdded = opt.applicableBand.DiscountApplied;
                    }

                    if (opt.ShowExchangeOfValue) {
                        // set the selected items
                        _.forEach(opt.ReferenceData, function (ref) {
                            _.forEach(match.ReferenceData, function (mr) {
                                if (mr.Id === ref.Id) {
                                    mr.selected = ref.selected;
                                }
                            });
                        });
                    }

                    match.isApplied = true;

                });
            });

            this.removeInvalidDiscounts(appliedDiscounts);

            _.forEach(discountOptions, function (opt) {
                var viewMatch = _.find(viewDiscounts, function (d) { return d.Id === opt.Id; });
                viewMatch.applicableBand = opt.applicableBand;
                viewMatch.amountToNextLevel = opt.amountToNextLevel;
                viewMatch.percentThroughBand = opt.percentThroughBand;
            });


            recalculating.resolve();
        });

        return recalculating.promise;
    }

    public  findSlidingScaleDiscount(appliedDiscounts) {
        var result = null;
        _.forEach(appliedDiscounts, function (discount) {
            if (!result) {
                result = _.find(discount.appliedOptions, function (opt) { return opt.Id == 'slidingScale'; });
            }
        });

        return result;
    }

    public deleteSlidingScaleDiscount(appliedDiscounts, order) {
        var slidingScaleDiscount = this.findSlidingScaleDiscount(appliedDiscounts);

        _.forEach(order.appliedDiscounts, function (discount) {
            var idx = discount.appliedOptions.indexOf(slidingScaleDiscount);

            if (idx > -1) {
                discount.appliedOptions.splice(idx, 1);
                return;
            }
        });
    }

    public validateSlidingScaleValueIsOk(contractPrice, bottomPrice, bottomFinancePrice) {
        return contractPrice > bottomPrice && contractPrice > bottomFinancePrice;
    }

    public getSlidingScale(name, value, isAgainstCash, discountType) {

        //If no discount type specified then use 1 - Value
        if (!discountType && isNaN(discountType)) {
            discountType = '1';
        }

        return {
            Id: 'slidingScale',
            Name: name,
            DiscountType: discountType,
            applicableBand: {
                DiscountApplied: Number(value)
            },
            parent: {
                Id: 'slidingScale',
                Priority: isAgainstCash ? 1000 : 15
            }
        };
    }

    //for some reason Blazor has a tough time deserializing this when handing off to C#
    public getAdditionalEovScale(additionalEovOptions, value, isAgainstCash): string {
        return JSON.stringify({
            Id: 'slidingScale',
            DiscountType: 1, // Value
            AdditionalEovDiscount:
                additionalEovOptions,
            applicableBand: {
                DiscountApplied: Number(value)
            },
            parent: {
                Id: 'slidingScale',
                Priority: isAgainstCash ? 1000 : 15
            }
        });
    }

    public calculateBottomPercentageForSlidingScale(items, totalPrice) {
        // 1. Get all the product codes. 
        var productCodes = _.uniq(_.map(items, function (i) { return i.product.Code; }));

        // 2. Work out how much of the order is made up of each product

        var priceForProduct = [];
        var that = this;
        _.forEach(productCodes, function (code) {
            var itemsForProduct = _.filter(items, function (i) { return i.product.Code === code; });

            // The discountable total
            var productTotal = that.getTotalPrice(itemsForProduct, null) - that.getTotalNonDiscountables(itemsForProduct, null);

            // Get the max sliding scale for this product whilst we're in the loop.
            var product = itemsForProduct[0].product;
            // Note: SlidingScaleHouseAccount was previously named MaxSlidingScaleAmount - look up both values just in case the user has not synced with the updated dashboard to get the newly named property.
            // Going forwards the SlidingScaleHouseAccount property will always be populated so the reference to MaxSlidingScaleAmount can be removed.

            var maxSlide = product.SlidingScaleHouseAccount || product.MaxSlidingScaleAmount || 0;

            priceForProduct.push({ code: code, total: productTotal, maxSlide: maxSlide });
            
        });

        // Bottom value is the max slide for each product subtracted from the total
        var bottomValue = _.reduce(priceForProduct, function (memo, p) {

            var slideForProduct = p.total * (p.maxSlide / 100);

            return memo - slideForProduct;
        }, totalPrice);

        // Bottom percentage is: bottom value / max
        var totalSlidingScalePercentage = 1 - (bottomValue / totalPrice);

        return { totalSlidingScalePercentage: totalSlidingScalePercentage, totalPrice: totalPrice };
    }

    public getExtendedEovScale(extendedEovOptions, value, isAgainstCash) {
        return {
            Id: 'slidingScale',
            DiscountType: 1, // Value
            ExtendedEovDiscount:
                extendedEovOptions,
            applicableBand: {
                DiscountApplied: Number(value)
            },
            parent: {
                Id: 'slidingScale',
                Priority: isAgainstCash ? 1000 : 15
            }
        };
    }

    public getFinanceDiscount(id, salesGroup, amount) {
        if (salesGroup === "CP") {
            return {
                Id: id,
                Name: 'Anglian Gold Account discount',
                IsFinance: true,
                DiscountType: 0, // Percent
                applicableBand: {
                    DiscountApplied: 5
                },
                parent: {
                    Id: id,
                    Priority: 0
                },
                hide: false
            };
        }
        else {
            return {
                Id: id,
                Name: 'Anglian Gold Account discount',
                IsFinance: true,
                DiscountType: 0, // Percent
                applicableBand: {
                    DiscountApplied: 10
                },
                parent: {
                    Id: id,
                    Priority: 0
                },
                hide: false
            };
        }
    }

    public getRemoveFinanceDiscount(id, removeFinanceId) {
        return {
            Id: id,
            Name: '(Anglian Gold Account discount)',
            DiscountType: 1, // Amount
            IsRemoveFinance: true,
            applicableBand: {
                DiscountApplied: null
            },
            parent: {
                Id: removeFinanceId,
                Priority: 999
            },
            hide: false
        };
    }

    public isDiscountApplied(discount, appliedDiscounts) {
        return _.any(_.flatten(_.pluck(appliedDiscounts, 'appliedOptions')), function (d) { return d.Id === discount.Id; });
    }

    public getDiscountAmountForPercentDiscount(listPrice, discountBand, amountAdded, isEOV, ssDisc) {
        var percent = 0;

        //If sliding scale discount, fetch the value entered.
        if (ssDisc) {
            percent = (100 - parseFloat(ssDisc.enteredDiscountAmount)) / 100;

        } else {
            //For EOV (HUHY) the amoundAdded could be 0, this was causing the discountBand to be used instead
            //which calculates an incorrect discount.
            if (amountAdded || isEOV === true) {
                if (!amountAdded) {
                    amountAdded = 0; // null value would cause exception in C# calling code
                }
                percent = (100 - parseFloat(amountAdded)) / 100;
            } else {
                percent = (100 - parseFloat(discountBand)) / 100;
            }
        }

        var discountApplied = (listPrice - (listPrice * percent)).toFixed(2);
        return discountApplied;
    }

    public removeDiscount(appliedDiscounts, discountOption) {
        var parentDiscount = _.find(appliedDiscounts, function (d) { return (d.Id == discountOption.parent.Id); });

        var iLen = parentDiscount.appliedOptions.length;
        for (var iIndex = 0; iIndex < iLen; iIndex++) {

            if (parentDiscount.appliedOptions[iIndex].Id === discountOption.Id) {
                parentDiscount.appliedOptions.splice(iIndex, 1);
                break;
            }

        }

        return appliedDiscounts;
    }

    public applyDiscount(appliedDiscounts, discountOption) {

        ////
        //if (discountOption.ShowExchangeOfValue) {
        //    for (var iRef = 0; iRef < discountOption.ReferenceData.length; iRef++) {

        //        if (discountOption.ReferenceData[iRef].selected === true) {

        //            scope.getAdditionalDiscountPercentage(discountOption, discountOption.ReferenceData[iRef], true);
        //        }

        //    }
        //}


        // check if this parent discount has been added already - if not add
        var parentDiscount = _.find(appliedDiscounts, function (d) { return (d.Id == discountOption.parent.Id); });
        if (parentDiscount == null) {
            parentDiscount = {
                Id: discountOption.parent.Id,
                Priority: discountOption.parent.Priority,
                appliedOptions: []
            };

            // Check if there is already a parent discount of same priority
            var samePriorityParent = _.find(appliedDiscounts, function (d) { return d.Priority === discountOption.parent.Priority; });
            if (samePriorityParent) {
                // one exists, replace it.
                var parentIdx = appliedDiscounts.indexOf(samePriorityParent);
                appliedDiscounts[parentIdx] = discountOption.parent;
            } else {
                // Simply push parent discount
                appliedDiscounts.push(parentDiscount);
            }

            // Keep them in order.
            appliedDiscounts = _.sortBy(appliedDiscounts, function (d) { return d.Priority; });
        }

        // Parent priority might have changed (sliding scale)
        if (parentDiscount.Priorty != discountOption.parent.Priority) {
            parentDiscount.Priority = discountOption.parent.Priority;
            appliedDiscounts = _.sortBy(appliedDiscounts, function (d) { return d.Priority; });
        }

        // Add this option to the parent.
        var option = _.find(parentDiscount.appliedOptions, function (opt) { return opt.Id === discountOption.Id; });
        if (option) {
            // Already exists, overwrite it instead.
            if (option.Id === "slidingScale") {
                if (discountOption.eovInfo) {
                    option.eovInfo = discountOption.eovInfo;
                }

                if (discountOption.AdditionalEovDiscount !== undefined) {
                    option.AdditionalEovDiscount = discountOption.AdditionalEovDiscount;
                }
                var idx1 = parentDiscount.appliedOptions.indexOf(option);
                parentDiscount.appliedOptions[idx1] = option;
            }
            else {
                var idx = parentDiscount.appliedOptions.indexOf(option);
                parentDiscount.appliedOptions[idx] = discountOption;
            }
        } else {
            var samePriorityDiscounts = _.filter(parentDiscount.appliedOptions, function (opt) { return opt.Priority === discountOption.Priority; });
            if (!this.isDiscountAllowedWithoutAlteringCurrentTrail(samePriorityDiscounts, discountOption)) {
                _.forEach(samePriorityDiscounts, function (d) {
                    var optIdx = parentDiscount.appliedOptions.indexOf(d);
                    parentDiscount.appliedOptions.splice(optIdx, 1);
                });
            }
            parentDiscount.appliedOptions.push(discountOption);
        }

        // Re-sort - _.sortBy is stably sorted. So we can order it by Group Priority, then Priority to get the exact order. 
        // See: http://blog.falafel.com/nifty-underscore-tricks-sorting-by-multiple-properties-with-underscore/
        var reorderedOptions = _.sortBy(parentDiscount.appliedOptions, function (d) { return d.PriorityInGroup; });
        parentDiscount.appliedOptions = _.sortBy(reorderedOptions, function (d) { return d.Priority; });

        return appliedDiscounts;
    }


    public async isHUHYDiscountAvailable(product) {
        const discounts = await this.dataAccess.getLocal("discounts");

        var oDisc = null;
        var oDiscOpt = null;
        var oDiscOptBand = null;

        for (var iDiscIdx = 0; iDiscIdx < discounts.length; iDiscIdx++) {
            oDisc = discounts[iDiscIdx];
            if (oDisc.SalesGroup === product) {
                if (oDisc.DiscountOptions) {
                    for (var iDiscOptIdx = 0; iDiscOptIdx < oDisc.DiscountOptions.length; iDiscOptIdx++) {
                        oDiscOpt = oDisc.DiscountOptions[iDiscOptIdx];

                        var today = new Date();
                        var validFrom = new Date(oDiscOpt.ValidFrom);
                        var validTo = new Date(oDiscOpt.ValidTo);

                        if (validFrom <= today && validTo >= today) {
                            if (oDiscOpt.DiscountBands) {
                                for (var iDiscOptBandIdx = 0; iDiscOptBandIdx < oDiscOpt.DiscountBands.length; iDiscOptBandIdx++) {

                                    oDiscOptBand = oDiscOpt.DiscountBands[iDiscOptBandIdx];
                                    if (oDiscOptBand.EnableExchangeofValue === true) {
                                        return true;
                                    }

                                }
                            }
                        }
                    }
                }
            }

        }

        return false;

    }


    public setupDiscountOptions(discounts, product, materialCodes, regionNumber, totalPrice, totalBuildingWorksPrice, totalADGPrice, units, leadSource, mediaCode) {
        // Need to set up the discount options here in a structure we understand so ....
        var that = this;
        var discountOptions: any[] =
            _.chain(discounts)
                .filter(function (discount) {
                    return discount.SalesGroup === product;
                })
                .map(function (discount) {
                    return that.getApplicableDiscountOptions(discount, materialCodes, regionNumber, totalPrice, totalBuildingWorksPrice, totalADGPrice, units, leadSource, mediaCode);
                })
                .sortBy(discounts, "priority")
                .flatten()
                .value();


        discountOptions = this.formatEOVText(discountOptions);

        //remove entity reference so we don't cause an infinite loop 
        discountOptions.forEach(entry => {
            entry.parent.DiscountOptions = entry.parent.DiscountOptions
                .filter(parentEntry => entry.Id != parentEntry.Id);
        })

        return discountOptions;
    }


    public cartesianProductOf(arr) {
        return _.reduce(arr, function (a, b) {
            return _.flatten(_.map(a, function (x) {
                return _.map(b, function (y) {
                    return x.concat([y]);
                });
            }), true);
        }, [[]])
    }

    public setupHUHY(opportunity) {
        var appliedDiscounts = opportunity.order.appliedDiscounts;
        for (var appliedDiscountIdx in appliedDiscounts) {
            for (var appliedOptionIdx in appliedDiscounts[appliedDiscountIdx].appliedOptions) {
                var appliedOption = appliedDiscounts[appliedDiscountIdx].appliedOptions[appliedOptionIdx];
                var dTotal = 0;

                if (appliedOption.selectedHUHYOptions) {

                    for (var iRef = 0; iRef < appliedOption.ReferenceData.length; iRef++) {

                        for (var iSel = 0; iSel < appliedOption.selectedHUHYOptions.length; iSel++) {

                            if (appliedOption.selectedHUHYOptions[iSel].value) {
                                if (appliedOption.selectedHUHYOptions[iSel].value[0].Id == appliedOption.ReferenceData[iRef].Id) {
                                    appliedOption.ReferenceData[iRef].selected = true;
                                    appliedOption.ReferenceData[iRef].SalesGroupDiscount = this.returnHUHYDiscountValue(appliedOption.ReferenceData[iRef], appliedOption.selectedHUHYOptions[iSel].value[0], opportunity);
                                    if (isNaN(appliedOption.ReferenceData[iRef].SalesGroupDiscount) === true) {
                                        appliedOption.ReferenceData[iRef].selectedButtonText = appliedOption.ReferenceData[iRef].SalesGroupDiscount.OptionName;
                                        appliedOption.ReferenceData[iRef].selectedDiscountOption = [];
                                        appliedOption.ReferenceData[iRef].selectedDiscountOption.push(appliedOption.ReferenceData[iRef].SalesGroupDiscount);
                                        appliedOption.ReferenceData[iRef].DiscountPercentage = appliedOption.ReferenceData[iRef].SalesGroupDiscount.DiscountPercentage.toString();
                                        appliedOption.ReferenceData[iRef].SalesGroupDiscount = appliedOption.ReferenceData[iRef].SalesGroupDiscount.DiscountPercentage;
                                    }
                                    dTotal += appliedOption.ReferenceData[iRef].SalesGroupDiscount;
                                }

                            }

                        }

                    }

                    appliedOption.amountAdded = dTotal;

                }
            }
        }

        return opportunity;
    }









    // ------------------------------------ PRIVATE METHODS ----------------------------------------    //

    private returnHUHYDiscountValue(oRefData, value, opportunity) {
        var dDisc = 0;

        if (oRefData.SalesGroupDiscounts) {
            for (var iSaleGroup = 0; oRefData.SalesGroupDiscounts.length; iSaleGroup++) {

                if (oRefData.SalesGroupDiscounts[iSaleGroup].Product == opportunity.lead.Product) {

                    if (oRefData.DiscountTiedToContactMethods === true) {
                        var iSelQty = 0;

                        for (var iAMethod = 0; iAMethod < opportunity.AnglianOptIns.contactMethods.length; iAMethod++) {
                            if (opportunity.AnglianOptIns.contactMethods[iAMethod].optIn == 'Y') {
                                iSelQty += 1;
                            }
                        }

                        switch (iSelQty) {
                            case 1:
                                dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].DiscountAfter1ContactMethod;
                                break;
                            case 2:
                                dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].DiscountAfter2ContactMethod;
                                break;
                            case 3:
                                dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].DiscountAfter3ContactMethod;
                                break;
                            case 4:
                                dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].DiscountAfter4ContactMethod;
                                break;
                            case 5:
                                dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].DiscountAfter5ContactMethod;
                                break;
                        }


                    }
                    else {
                        var bApplied = false;
                        if (value && oRefData.PromotionalDiscountOption && oRefData.PromotionalDiscountOption.length > 1) {

                            var oMktOpt = null;
                            var oPromOpt = null;
                            for (var iMktIdx = 0; iMktIdx < opportunity.order.marketingOptions.length; iMktIdx++) {
                                oMktOpt = opportunity.order.marketingOptions[iMktIdx];
                                if (oMktOpt.Id === value.Id) {
                                    for (var iPromIdx = 0; iPromIdx < oMktOpt.PromotionalDiscountOption.length; iPromIdx++) {
                                        oPromOpt = oMktOpt.PromotionalDiscountOption[iPromIdx];
                                        if (value.OptId == oPromOpt.Id || (value.IsDefault === oPromOpt.IsDefault && oPromOpt.IsDefault === true)) {
                                            if (oPromOpt.DiscountPercentage > 0) {
                                                return oPromOpt;
                                            }

                                            break;
                                        }

                                    }
                                }
                            }

                        }

                        if (oRefData.selectedDiscountOption && oRefData.selectedDiscountOption.length > 0) {
                            if (isNaN(oRefData.selectedDiscountOption[0].DiscountPercentage) === false) {
                                dDisc = oRefData.selectedDiscountOption[0].DiscountPercentage;
                                bApplied = true;
                            }
                        }

                        if (bApplied === false) {
                            dDisc = oRefData.SalesGroupDiscounts[iSaleGroup].Discount;
                        }

                    }

                    break;
                }

            }
        }

        return dDisc;

    }



     private areDiscountsInSameGroup(discount1, discount2) {
        return discount1.DiscountGroup &&
            discount1.DiscountGroup.length &&
            discount2.DiscountGroup &&
            discount2.DiscountGroup.length &&
            discount1.DiscountGroup === discount2.DiscountGroup;
    }

     private isDiscountAllowedWithoutAlteringCurrentTrail(samePriorityDiscounts, discountOption) {
        return !samePriorityDiscounts.length || this.areDiscountsInSameGroup(samePriorityDiscounts[0], discountOption);
    }

    //This is used to check if the lead source and media code match what is on the discount.
     private doesLeadSourceMatch(sourceQuery, sourceId, mediaCode) {

        var aElemParts = [];
        var aElems = sourceQuery.split(")"); //Split query into separate queries.
        for (var iIdx = 0; iIdx < aElems.length; iIdx++) { //Loop through each query
            if (aElems[iIdx].length > 0) { //Ignore blanks.

                aElems[iIdx] = aElems[iIdx].substring(1); //Remove first bracket.
                aElemParts = aElems[iIdx].split('='); //Split query into lead source and media codes.
                var leadSource = aElemParts[0]; //Extract lead source
                var aMediaCodes = aElemParts[1].split(';'); //We can have multiple media codes per query so split them.

                //If media codes is empty then it generates a blank element, we want this removed.
                var iBlankIdx = aMediaCodes.indexOf("");
                if (iBlankIdx > -1) {
                    aMediaCodes.splice(iBlankIdx, 1);
                }

                //Checking lead source is valid.
                var bLeadSourceOK = false;
                if (!sourceId && leadSource.length === 0) { //If query has blank lead source and discount does then it's OK.
                    bLeadSourceOK = true;
                }
                else {
                    if (sourceId && leadSource.toString() === sourceId.toString()) {
                        bLeadSourceOK = true;
                    }
                }


                //Check media code is valid
                var bMediaCodeOK = false;
                if (!mediaCode && aMediaCodes.length === 0) { //If query has no media codes and neither does discount then it's OK.
                    bMediaCodeOK = true;
                }
                else {

                    //If media code passed then see if it's in array.
                    if (mediaCode && mediaCode.length > 0) {
                        var iMediaIdx = aMediaCodes.indexOf(mediaCode);
                        if (iMediaIdx > -1) {
                            bMediaCodeOK = true;
                        }
                    }

                }

                //If lead source and media code are OK then return OK.
                if (bLeadSourceOK === true && bMediaCodeOK === true) {
                    return true;
                }

            }
        }

        //Nothing matched.
        return false;
    }

     private getApplicableDiscountOptions(discount, materialCodes, regionNumber, totalPrice, totalBuildingWorksPrice, totalADGPrice, units, leadSource, mediaCode) {

        var sDefBaseDiscName = '';
        var iDefBaseDiscId = -1;
        var iDiscIdx = 0;
        for (iDiscIdx = 0; iDiscIdx < discount.DiscountOptions.length; iDiscIdx++) {
            if (discount.DiscountOptions[iDiscIdx].IsDefaultBase === true) {
                sDefBaseDiscName = discount.DiscountOptions[iDiscIdx].Name;
                iDefBaseDiscId = discount.DiscountOptions[iDiscIdx].Id;
                break;
            }
        }
        var iDefBaseMatchingDiscountId = -1;
        if (sDefBaseDiscName.length > 0) {
            for (iDiscIdx = 0; iDiscIdx < discount.DiscountOptions.length; iDiscIdx++) {
                if (discount.DiscountOptions[iDiscIdx].Name === sDefBaseDiscName) {
                    if (isNaN(regionNumber) === false && discount.DiscountOptions[iDiscIdx].Region == regionNumber) {
                        iDefBaseMatchingDiscountId = discount.DiscountOptions[iDiscIdx].Id;
                        break;
                    }
                }
            }
        }

        var applicableOptions =
            _.chain(discount.DiscountOptions)
                .filter( (opt) => {
                    //added to differentation Show Band table and Enable Exchange of value ShowExchangeOfValue this property used in discountpanel.tpl.html
                    var optEnableExchangeOfValue = _.some(opt.DiscountBands, function (e) {
                        return e.EnableExchangeofValue;
                    });
                    if (optEnableExchangeOfValue === true) {
                        opt.ShowExchangeOfValue = true;
                    }

                    if (discount.ValidFrom && !opt.ValidFrom) {
                        opt.ValidFrom = discount.ValidFrom;
                    }

                    if (discount.ValidTo && !opt.ValidTo) {
                        opt.ValidTo = discount.ValidTo;
                    }


                    var today = new Date();
                    var validFrom = new Date(opt.ValidFrom);
                    var validTo = new Date(opt.ValidTo);


                    if (opt.Property !== undefined) {
                        opt.Property.forEach(function (propertycode) {
                            if (_.contains(materialCodes, propertycode)) {
                                opt.MaterialCode = propertycode;
                            }
                        });
                    }

                    //Date check, is it in the valid period.
                    var isApplicableOption =
                        //((validFrom <= today && validFrom.getHours() <= today.getHours() && validFrom.getMinutes() <= today.getMinutes()) &&
                        //(opt.ValidTo == null || (new Date(opt.ValidTo) >= moment(today).startOf('day').toDate() && validTo >= moment(today).startOf('hour').toDate() && validTo >= moment(today).startOf('minute').toDate()))) &&
                        (validFrom <= today && validTo >= today) &&
                        opt.DiscountBands.length &&
                        (opt.MaterialCode === undefined || opt.MaterialCode === null || _.contains(materialCodes, opt.MaterialCode));

                    if (isApplicableOption === true) {
                        var bRegionMatch = false;

                        if (opt.Name === sDefBaseDiscName) {
                            if (iDefBaseMatchingDiscountId > -1 && iDefBaseMatchingDiscountId === opt.Id) {
                                bRegionMatch = true;
                            }
                            else if (iDefBaseMatchingDiscountId == -1 && iDefBaseDiscId === opt.Id) {
                                bRegionMatch = true;
                            }
                        }
                        else if (opt.Region === undefined || opt.Region === '0' || opt.Region === regionNumber) {
                            bRegionMatch = true;
                        }

                        isApplicableOption = bRegionMatch;
                    }

                    //Lead Source / Media code check.
                    if (isApplicableOption === true) {
                        if (opt.LeadSourceQuery && opt.LeadSourceQuery.length > 0) {

                            isApplicableOption = this.doesLeadSourceMatch(opt.LeadSourceQuery, leadSource, mediaCode);

                        }
                    }

                    //We now want to check the discount band properties.
                    if (isApplicableOption === true) {

                        var iPassQty = 0;
                        for (var iBand = 0; iBand < opt.DiscountBands.length; ++iBand) {

                            if (opt.DiscountBands[iBand].DiscountBandProperties !== undefined && opt.DiscountBands[iBand].DiscountBandProperties.length > 0 && units !== undefined) {

                                var bPass = this.bandPropertiesMatch(units, opt.DiscountBands[iBand].DiscountBandProperties);
                                if (bPass === true) {
                                    iPassQty++;

                                }
                                else {

                                    //Remove band
                                    opt.DiscountBands.splice(iBand, 1);
                                    iBand--;

                                }

                            }
                            else {
                                iPassQty++;

                            }

                        }

                        //If none passed then it's no longer applicable.
                        if (iPassQty === 0) {
                            isApplicableOption = false;
                        }

                    }
                    return isApplicableOption;
                })
                .map(function (opt) {
                    // Set the parent so that we can hook back in later to work out parent priorities
                    opt.parent = discount;

                    var _price = 0.00;
                    switch (opt.PriceCategory) {
                        case 0:
                            _price = totalPrice;
                            break;
                        case 1:
                            _price = totalBuildingWorksPrice;
                            break;
                        case 2:
                            _price = totalADGPrice;
                            break;

                    }

                    // Now we need to get the Discount Bands set up so that they know about each other..
                    for (var i = 0; i < opt.DiscountBands.length; i++) {
                        if (i === opt.DiscountBands.length - 1) {
                            // We are at the max band. No upper limit
                        } else {
                            opt.DiscountBands[i].upperLimit = opt.DiscountBands[i + 1].EntryLevel - 0.01;
                        }
                    }

                    var metBands = _.filter(opt.DiscountBands, function (band) { return (band.EntryLevel || 0 ) <= _price; });
                    if (metBands.length) {
                        opt.applicableBand = _.max(metBands, function (band) { return (band.EntryLevel || 0) ; });

                        if (opt.applicableBand.upperLimit) {
                            opt.amountToNextLevel = opt.applicableBand.upperLimit - _price + 0.01;
                            opt.percentThroughBand = Math.round(((_price - opt.applicableBand.EntryLevel) / (opt.applicableBand.upperLimit - opt.applicableBand.EntryLevel)) * 100);
                        }
                    } else {
                        opt.applicableBand = null;
                        opt.amountToNextLevel = opt.DiscountBands[0].EntryLevel - _price;
                        opt.percentThroughBand = Math.round((_price / opt.DiscountBands[0].EntryLevel) * 100);
                    }

                    return opt;
                })
                .value();
        var sortedOptions = _.sortBy(applicableOptions, "Priority");
        return sortedOptions;
    }

    //
     private bandPropertiesMatch(units, properties) {


        properties.sort(function (a, b) {
            return a.ProcessOrder - b.ProcessOrder;
        });

        var bMatch = false;
        var iMatches = 0;
        for (var iProp = 0; iProp < properties.length; ++iProp) {

            properties[iProp].match = false;
            iMatches = 0;
            bMatch = false;

            for (var iUnit = 0; iUnit < units.length; ++iUnit) {

                bMatch = false;

                for (var iUProp = 0; iUProp < units[iUnit].properties.length; ++iUProp) {

                    if (properties[iProp].PropertyId === units[iUnit].properties[iUProp].Id) {

                        //We only want to increment once per unit.
                        if (bMatch === false) {
                            bMatch = true;
                            iMatches++;

                        }

                    }
                    else if (bMatch === false) {

                        if (units[iUnit].properties[iUProp].parents) {

                            for (var iParent = 0; iParent < units[iUnit].properties[iUProp].parents.length; ++iParent) {

                                if (units[iUnit].properties[iUProp].parents[iParent] === properties[iProp].PropertyId) {

                                    //We only want to increment once per unit.
                                    if (bMatch === false) {
                                        bMatch = true;
                                        iMatches++;

                                    }


                                }

                            }

                        }

                    }

                }

            }

            if (properties[iProp].AppliesToAllUnits === true) {

                if (properties[iProp].HasToBeTrue === true) {

                    if (units.length === iMatches) {
                        properties[iProp].match = true;

                    }

                }
                else {

                    if (units.length !== iMatches) {
                        properties[iProp].match = true;

                    }

                }

            }
            else {

                if (properties[iProp].HasToBeTrue === true) {

                    if (iMatches > 0) {
                        properties[iProp].match = true;

                    }

                }
                else {

                    if (units.length !== iMatches) {
                        properties[iProp].match = true;

                    }

                }

            }

        }

        //Check what the outcome will be.
        for (iProp = 0; iProp < properties.length; ++iProp) {

            if (properties[iProp].match === true) {

                if (properties[iProp].ConditionAndOr === 'OR' || properties[iProp].ConditionAndOr === '' || properties[iProp].ConditionAndOr == null) {
                    return true;

                }

            }
            else {

                if (properties[iProp].ConditionAndOr === 'AND' || properties[iProp].ConditionAndOr === '' || properties[iProp].ConditionAndOr == null) {
                    return false;

                }

            }

        }

        return true;

    }

    //
    private formatEOVText(discOptions) {

        if (discOptions) {

            var sTextLines = [];
            var iDiscQty = discOptions.length;
            var iHUHYQty = 0;
            for (var iDisc = 0; iDisc < iDiscQty; iDisc++) {

                if ((discOptions[iDisc].ShowExchangeOfValue) && (discOptions[iDisc].ShowExchangeOfValue === true)) {

                    if (discOptions[iDisc].ReferenceData) {

                        iHUHYQty = discOptions[iDisc].ReferenceData.length;
                        for (var iHUHY = 0; iHUHY < iHUHYQty; iHUHY++) {

                            sTextLines = discOptions[iDisc].ReferenceData[iHUHY].Description.split("\n");
                            discOptions[iDisc].ReferenceData[iHUHY].descLine1 = sTextLines[0];
                            if (sTextLines.length > 1) {
                                discOptions[iDisc].ReferenceData[iHUHY].descLine2 = sTextLines[1];
                            }

                        }

                    }

                }

            }
        }

        return discOptions;

    }

    private removeAdminFeeFromPrice(price, allAdminFees) {
        var adminFee = +(_.max(_.filter(allAdminFees, function (fee) { return price > fee.OrderValue; }), function (fee) { return fee.OrderValue; }).AdminCharge);

        return Number(price) - Number(adminFee);
    }



    // Adjusts old style discounts so that the valid from/to dates are on the 
    // discount options rather than the parent discount
    private adjustValidDateRanges(option) {

        if (option.parent.ValidFrom && !option.ValidFrom) {
            option.ValidFrom = option.parent.ValidFrom;
        }

        if (option.parent.ValidTo && !option.ValidTo) {
            option.ValidTo = option.parent.ValidTo;
        }

    }

    private getTotalPrice(items, charges) {
        items = _.filter(items, function (i) { return i.included; });

        var cost = _.reduce(items, function (price, item) { return price + item.itemPrice; }, 0);

        cost += _.reduce(charges, function (counter, charge) { return counter + charge.Charge; }, 0);
        return cost;
    }


    private getTotalNonDiscountables(items, charges) {
        items = _.filter(items, function (i) { return i.included; });
        var total = 0;
        if (charges && charges.length) {
            total = _.reduce(charges, function (price, charge) { return price + charge.Charge; }, total);
        }

        return _.reduce(items, function (price, item) { return price + item.nonDiscountables; }, total);
    }

};
