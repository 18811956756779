

export class DataPruningService {
        public stripMaterial (material) {
            return {
                Id: material.Id,
                Code: material.Code,
                Style: material.Style,
                price: material.price,
                quantity: material.quantity,
                Name: material.Name,
                CommissionCode: material.CommissionCode,
                ExplodedImageFilename: material.ExplodedImageFilename,
                ExplodedRichText: material.ExplodedRichText
            };
        }
        public stripDesign (design) {
            return {
                Id: design.Id,
                Code: design.Code,
                isAvailable: design.isAvailable,
                isSelected: design.isSelected,
                Name: design.Name,
                TypeOfPrice: design.TypeOfPrice,
                Amount: design.Amount,
                Colours: design.Colours,
                Product: design.Product,
                IsDiscountable: design.IsDiscountable
            };
        }
}
