
import { config, tokendata } from '.';
import { AnglianData, defer, dataAccessService } from './dataAccessService';
import { entityInfoService } from './entityInfoService';
import _, { map } from 'underscore';
import async from 'async';
import { sessionService } from './sessionService';
import { progressReportingService } from './progressReportingService';

export class referenceDataService {
    public get progressReporting(): progressReportingService {
        return window["progressReportingService"];
    }


    private _dataAccessService: dataAccessService = new dataAccessService;
    private _sessionService: sessionService = new sessionService;

    public updateReferenceData(entity, id, endPoint): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var mapToDb = function (data) {

            var promises = [];
            ds.clear(entity).then(function () {
                if (typeof data.length !== "undefined" && data.length > 0) {
                    data.forEach((refData) => {
                        promises.push(ds.saveLocal(refData, entity));
                    });
                } else {
                    promises.push(ds.saveLocal(data, entity));
                }

                return promises;
            });
        };

        var rqData = {};
        ds.getRemote(endPoint, rqData, mapToDb, null)
            .then(function () {
                loading.resolve(entity);
            }, function () {
                loading.reject();
            });
        return loading.promise;
    }

    public updateDiscounts() {
        var loading = defer();
        var msgdata = {};
        var ds = this._dataAccessService;

        var mapToDb = function (result) {
            var promises = [];
            var dummyPromise = defer();
            promises.push(dummyPromise.promise);

            ds.clear('discounts').then(function () {
                result.forEach((discount) => {
                    var loadingPromise = defer();
                    promises.push(loadingPromise.promise);

                    ds.saveLocal(discount, 'discounts').then(function () {
                        loadingPromise.resolve();
                        dummyPromise.resolve();
                    }, loadingPromise.reject);
                });
            });

            return Promise.all(promises);
        };

        ds.getRemote(config.GetDiscounts, msgdata, mapToDb, null).then(loading.resolve, loading.reject);

        return loading.promise;
    }

    // #region "GetPriceList"

    public getPriceList(currentUpdate) {
        var deferred = defer();

        var p4 = this._dataAccessService.clear('salesGroups');
        var p1 = this._dataAccessService.clear('prices');
        var p2 = this._dataAccessService.clear('designs');
        var p3 = this._dataAccessService.clear('properties');

        var caller = this;

        Promise.all([p1, p2, p3, p4]).then(function () {
            var prices: Promise<any> = caller.getPrices();
            var designs: Promise<any> = caller.getDesigns();
            var properties: Promise<any> = caller.getProperties();
            var productGroups: Promise<any> = caller.getProductGroups();
            var all = Promise.all([prices, designs, properties, productGroups]);
            all.then(function () {
                caller.confirmPriceListDownload();
                caller.getImageInformation(currentUpdate).then(deferred.resolve, deferred.reject);
            }, deferred.reject);
        });

        return deferred.promise;


    }

    private confirmPriceListDownload() {
        var ds = this._dataAccessService;
        var mapToDb = function (data) {
            var promises = [];
            var fakePromise = defer();
            fakePromise.resolve();
            promises.push(fakePromise.promise);
            return promises;
        };

        ds.getRemote(config.ConfirmPriceListDownload, {}, mapToDb, null);
    };

    private async getImageInformation(currentUpdate): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var forEntity = 'UpdateImagesProgress';
        //loggerService.forPromise(loading.promise, 'Getting image information');
        await this.progressReporting.report(forEntity, 'Checking for updated images', 'pending');

        var Map = function (data) {
            var images = data;
            return [Promise.resolve(images)];
        }

        var data = { uploadedSince: currentUpdate };
        var downloadedCount = 0;

        ds.getRemote(config.GetImageInformation, data, Map, null).then(async (images) => {
            images = images[0];
            if (!images || !images.length) {
                await this.progressReporting.report(forEntity, null, `No new images to download.`);
                loading.resolve();
                return;
            }

            var chunkSize = 10;
            await this.progressReporting.report(forEntity, null, `Retrieved image list. Now downloading ${images.length} images...`);
            var successTextFn = () => {
                downloadedCount += chunkSize;

                //download count will overshoot unless the total number of images perfectly divides by chunk size
                if (images.length < downloadedCount) {
                    downloadedCount = images.length;
                }

                return `Successsfully downloaded ${downloadedCount} out of ${images.length} images.`
            };

            var chunks = chunkImages(images, chunkSize);

            async function startImageDownload(img) {
                //return Promise.resolve(img);
                var sURL = img.Url;
                var filename = img.Filename + ".png";
                await ds.httpGetStoreInCache('https://dashtest.anglian-windows.com' + sURL, 'images', filename);
            }
            
            // Chunk functions. Chunktions innit
            var chunktions = _.map(chunks,  (c) => {
                return (callback) => {
                    var promises = [];
                    c.forEach((img) => {

                        var downloadPromise = startImageDownload(img);

                        promises.push(downloadPromise);

                        //var downloadPromise = caller.downloadImage(img);
                        //downloadPromise.then( async () => {
                        //    countDownloaded++;
                        //    var percent = Math.round(countDownloaded / images.length * 100);
                        //    await this.progressReporting.updateStatus(forEntity, percent + "%");
                        //});

                    });

                    var imagesDownloaded = Promise.all(promises);

                    this.progressReporting.onComplete(forEntity, imagesDownloaded, null, null, successTextFn);

                    imagesDownloaded.then(function () {
                        callback(null);
                    }, function (err) { callback(err); });

                };
            });

            async.series(chunktions, function (err, results) {
                if (err) {
                    loading.reject();
                } else {
                    loading.resolve();
                }
            });
            //loggerService.forPromise(loading.promise, "Download " + images.length + " images");
        }, loading.reject);

        return loading.promise;
    };


    private async getPrices(): Promise<any> {
        var forEntity = 'PricesProgress';
        var loading = defer();
        //loggerService.forPromise(loading.promise, 'Getting prices');
        var report = await this.progressReporting.report(forEntity, 'Updating prices:', 'pending', loading.promise);

        var ds = this._dataAccessService;
        var mapToDb = function (data) {
            var promises = [];

            try {
                data.forEach((design) => {
                    promises.push(ds.saveLocal(design, 'prices'));
                });
            } catch (e) {
                loading.reject(e);
            }

            return promises;
        };

        var msgData = {};
        ds.getRemote(config.GetAllPrices, msgData, mapToDb, null, true)
            .then(loading.resolve, loading.reject);

        return loading.promise;
    };

    private async getDesigns(): Promise<any> {
        var loading = defer();
        var forEntity = 'DesignsProgress';
        //loggerService.forPromise(loading.promise, 'Getting designs');
        var report = await this.progressReporting.report(forEntity, 'Updating designs:', 'pending', loading.promise);
        var ds = this._dataAccessService;
        var mapToDb = function (data) {
            var promises = [];
            try {
                data.forEach((design) => {
                    promises.push(ds.saveLocal(design, 'designs'));
                });
            } catch (e) {
                loading.reject(e);
            }

            return promises;
        };

        var msgData = {};
        ds.getRemote(config.GetAllDesigns, msgData, mapToDb, null)
            .then(loading.resolve, loading.reject);

        return loading.promise;
    };

    private getProperties(): Promise<any> {
        var loading = defer();
        //loggerService.forPromise(loading.promise, 'Getting properties');
        var ds = this._dataAccessService;
        var mapToDb = function (data) {

            var promises = [];

            data.forEach((property) => {
                promises.push(ds.saveLocal(property, 'properties'));
            });

            return promises;
        };

        var msgData = {};
        ds.getRemote(config.GetAllProperties, msgData, mapToDb, null).then(function () {
            loading.resolve(true);
        }, function () {
            loading.reject();
        });

        return loading.promise;
    };

    private async getProductGroups(): Promise<any> {
        var ds = this._dataAccessService;
        console.log("getproducts Called in promoise");
        var loading = defer();
        var report = await this.progressReporting.report('PricesProgress', 'Updating prices:', 'pending', loading.promise);

        var mapToDb = function (data: any[]) {
            var promises = [];
            try {
                data.forEach((group) => {
                    promises.push(ds.saveLocal(group, 'salesGroups'));
                });
            } catch (e) { }

            return promises;
        };

        var msgData = {};

        var ds = new dataAccessService();

        ds.getRemote(config.GetAllSalesGroups, msgData, mapToDb, null).then(function () {
            console.log("data method returned");
            loading.resolve(true);
        }, function () {
            loading.reject();
        });

        return loading.promise;

    }

    public getReferenceData (entity, product): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        ds.getLocal(entity).then( (data) => {
        if (product) {
            var products = _.filter(data,  (d) => { return _.some(d.Products, function (p) { return p == product; }); });
            loading.resolve(products);
        } else {
            loading.resolve(data);
        }
    });

    return loading.promise;
}

    // #endregion

    // #region "Adapt Settings"
    public getADAPTSettings(): Promise<any> {
        var loading = defer();
        var oDownloadPromise = defer();

        var ds = this._dataAccessService;
        function saveADAPTSettings(data) {
            sessionService.setADAPTSettings(JSON.stringify(data));
            oDownloadPromise.resolve();
            return oDownloadPromise;
        }

        ds.getRemote(config.FetchSettings, {}, saveADAPTSettings, null, false).then(function () {
            loading.resolve();

        }, function (e) {
            loading.reject();
        });

        return loading.promise;
    }
    // #endregion   

    public async updateAdminCharges(): Promise<any> {
        var loading = defer();
        var report = await this.progressReporting.report('AdminChargesProgress', 'Updating admin charges:', 'pending', loading.promise);
        
        var ds = this._dataAccessService;
        var mapToDb = function (data) {
            var promises = [];
            if (data && data.AdminCharges && data.AdminCharges.AdminCharge) {
                //Move the clearing here in case an error occurs and the db cannot be populated.
                ds.clear('adminCharge').then(function () {
                    _.forEach(data.AdminCharges.AdminCharge, function (charge) {
                        charge.EffectiveDate = Number(charge.EffectiveDate);
                        charge.AdminCharge = Number(charge.AdminCharge);
                        charge.OrderValue = Number(charge.OrderValue);
                        charge.NetOrderVal = Number(charge.NetOrderVal);
                        promises.push(ds.saveLocal(charge, 'adminCharge'));
                    });

                    return promises;
                });

            }

        };
       ds.getRemote(config.GetAdminCharges, {}, mapToDb, null, false)
            .then(loading.resolve, loading.reject);




        return loading.promise;
    }

    public async updateRelevantDays(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        await this.progressReporting.report('UpdateRelevantDaysProgress', 'Updating relevant days:', 'pending', loading.promise);
        //await this.progressReporting.report('FinanceSourcesProgress', 'Updating finance sources:', 'pending', loading.promise);

        var mapToDb = function (data) {
            var promises = [];
            if (data) {
                _.forEach(data, function (source) {
                    promises.push(ds.saveLocal(source, 'relevantdays'));
                });
            }

            return promises;
        };

        ds.clear('relevantdays').then(function () {
            ds.getRemote(config.GetRelevantDays, {}, mapToDb, null, false)
                .then(loading.resolve, loading.reject);
        });

        return loading.promise;
    }

    public async updateCommissions(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        await this.progressReporting.report('UpdateCommissionsProgress', 'Updating commissions:', 'pending', loading.promise);
        //await this.progressReporting.report('lsUpdate', 'Updating finance sources:', 'pending', loading.promise);

        var mapToDb = function (data) {
            var promises = [];
            if (data) {
                var ss :any[] = data.SlidingScales;
                var ref: any[] = data.Referrals;
                var min: any[] = data.Minimums;
                var valperc: any[] = data.ValuesPercentages;

                ss.forEach(ssEntry => {
                    promises.push(ds.saveLocal(ssEntry, 'commissionsSlidingScales'));
                });

                ref.forEach(refEntry => {
                    promises.push(ds.saveLocal(refEntry, 'commissionsReferrals'));
                });

                min.forEach(minEntry => {
                    promises.push(ds.saveLocal(minEntry, 'commissionsMinimums'));
                });

                valperc.forEach(valPercEntry => {
                    promises.push(ds.saveLocal(valPercEntry, 'commissionsValuePercentages'));
                });
            }

            return promises;
        };

        Promise.all([
            ds.clear('commissionsSlidingScales'),
            ds.clear('commissionsReferrals'),
            ds.clear('commissionsMinimums'),
            ds.clear('commissionsValuePercentages'),
        ])
            .then(function () {
            ds.getRemote(config.GetCommissions, {}, mapToDb, null, false)
                .then(loading.resolve, loading.reject);
        });

        return loading.promise;
    }

    public async updateOptInSettings(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var report = await this.progressReporting.report('OptInSettingsProgress', 'Updating Opt In Settings:', 'pending', loading.promise);

        var mapToDb = function (data) {
            var promises = [];
            if (data) {
                console.log(data);
                promises.push(ds.saveLocal(data, 'optinsettings'));
            }

            return promises;
        };

        ds.clear('optinsettings').then(function () {
            ds.getRemote(config.GetOptInSettings, {}, mapToDb, null, false)
                .then(loading.resolve, loading.reject);
        });

        return loading.promise;
    }

    // #region "get Documents and store in Cache"

    public async getPrivacyDocument(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var caller = this;

        var promises = [];
        var forEntity = 'UpdateDocumentsProgress';
        await this.progressReporting.report(forEntity, 'Checking for updated documents:', 'pending', null);

        var fileCodes = config.FilesToDownload.split(',');
        var fileVersions = sessionService.getDocVersions();
        if (fileVersions) {
            fileVersions = JSON.parse(fileVersions);
        }

        var filesToCheck = [];
        var oInfo = {};
        var oFilesDownloadedInfo = null;

        //Build the file list up with version numbers.
        for (var iFile = 0; iFile < fileCodes.length; iFile++) {
            let oInfo: any = {};
            oInfo.Code = fileCodes[iFile];
            oInfo.LastMod = '0';

            if (fileVersions) {
                for (var iVer = 0; iVer < fileVersions.length; iVer++) {

                    if (fileVersions[iVer].Code == oInfo.Code) {
                        oInfo.LastMod = fileVersions[iVer].LastMod;
                        break;
                    }
                }
            }

            filesToCheck.push(oInfo);

        }


        var processList = async (data) => {

            var mappromises = [];

            var fileCount = data.Files.length;
            var saveFile = async (data) => {
                processedCount++;
                var oProm = caller.saveDocument(data, "document-cache");
                var successText = `Successsfully downloaded ${processedCount} out of ${fileCount} documents.`;
                this.progressReporting.onComplete(forEntity, oProm, successText);
                oProm.catch(e => console.error(e));
                return oProm;

            };

            if (data.Files.length > 0) {
                var processedCount = 0;
                await this.progressReporting.report(forEntity, null, `Retrieved file list. Now downloading ${fileCount} documents...`);

                for (var iDownloadFile = 0; iDownloadFile < data.Files.length; iDownloadFile++) {

                    var oDownload = ds.getRemote(config.GetADAPTFile, { FileCode: data.Files[iDownloadFile].Code }, saveFile, null, false);
                    oDownload.catch(e => console.log(e));
                    mappromises.push(oDownload);

                }

                oFilesDownloadedInfo = data.Files;
            }
            else {

                await this.progressReporting.report(forEntity, null, `No new documents to retrieve.`);

            }

            return mappromises;

        };

        ds.getRemote(config.GetADAPTFileList, { Files: filesToCheck }, processList, null,false)
            .then(function () {

                if (oFilesDownloadedInfo) {
                    for (var iCheck = 0; iCheck < filesToCheck.length; iCheck++) {

                        for (var iDownload = 0; iDownload < oFilesDownloadedInfo.length; iDownload++) {
                            if (filesToCheck[iCheck].Code == oFilesDownloadedInfo[iDownload].Code) {
                                filesToCheck[iCheck].LastMod = oFilesDownloadedInfo[iDownload].LastMod;
                                break;
                            }
                        }
                    }

                    sessionService.setDocVersions(JSON.stringify(filesToCheck));

                }

                loading.resolve();
            }, function () {
                loading.reject();
            });

        return loading.promise;

    }

    public async saveDocument(data, cacheName) {


        var sName = '/' + cacheName + '/' + data.FileName + '.' + data.FileType;
       
          
        var d = defer();
        //referrer.toUpperCase().indexOf("RAL") === -1
        if (data.FileType.toLowerCase().includes("pdf")) {
            const cache = await caches.open(cacheName);

            const options = {
                headers: {
                    'Content-Type': 'application/pdf'
                }
            }


            const pdfBlob = b64toBlob(data.FileData, 'application/pdf');
            const blobUrl = URL.createObjectURL(pdfBlob);
      
            fetch(blobUrl).then((r) => {
                cache.put(sName, r).then((s) => {
                    d.resolve(true);
                    //console.log("file placeed in cache: " + sName);
                }).catch((e) => {
                    d.reject();
                })
            })
            ////const stream = pdfBlob.stream();
            ////const Body: BodyInit = stream;
            //const buffer = _base64ToArrayBuffer(data.FileData);
            //const blob = new Blob([buffer], { type: 'application/pdf; charset=utf-8' });

            //const pdfResponse = new Response(pdfBlob, options);

            


        } else {
            d.resolve({});
        }

        // TODO: implement file store mechanisim.
        
        //Get local folder
        //var appFolder = Windows.Storage.ApplicationData.current.localFolder;

        //appFolder.createFileAsync(sFileName, Windows.Storage.CreationCollisionOption.replaceExisting).then(function (file) {

        //    file.openTransactedWriteAsync().then(function (transaction) {
        //        var dataWriter = new Windows.Storage.Streams.DataWriter(transaction.stream);
        //        var decodedString = Windows.Security.Cryptography.CryptographicBuffer.decodeFromBase64String(data);
        //        var byteArray = Windows.Security.Cryptography.CryptographicBuffer.copyToByteArray(decodedString);

        //        dataWriter.writeBytes(byteArray);

        //        dataWriter.storeAsync().then(function (size) {
        //            transaction.stream.size = size; // reset stream size to override the file
        //            transaction.commitAsync().done(function () {
        //                // Close stream
        //                transaction.close();
        //                defer.resolve(file);
        //            });
        //        });
        //    }, function (error) {
        //        console.log(error);
        //        defer.reject('Error saving file');
        //    });
        //}, function () {
        //    defer.reject('Error creating file');
        //});

        // dummy resolve
        

        return d.promise;

    };    

    // #endregion

    public updateForcedOptInSettings(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;

        var mapToDb = function (data) {
            if (data) {
                data = JSON.stringify(data);
            }
            sessionService.setForcedOptInSettings(data);
            return Promise.resolve(data);
        };

        ds.getRemote(config.GetForcedOptInDate, {}, mapToDb, null, false)
            .then(loading.resolve, loading.reject);


        return loading.promise;
    }

    public updateLogisticCharges(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;

        var mapToDb = function (data) {
            if (data) {
                data = JSON.stringify(data);
            }
            sessionService.setLogisticsCharges(data);
            return Promise.resolve(data);
        };

        ds.getRemote(config.GetLogisticCharges, {}, mapToDb, null, false)
            .then(loading.resolve, loading.reject);

        return loading.promise;
    }

    public async updateFinanceSetup(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var report = await this.progressReporting.report('FinanceSourcesProgress', 'Updating finance sources:', 'pending', loading.promise);

        var mapToDb = function (data) {
            var promises = [];
            if (data && data.FinanceSources) {
                _.forEach(data.FinanceSources, function (source) {
                    promises.push(ds.saveLocal(source, 'financeSetup'));
                });
            }

            return promises;
        };

        ds.clear('financeSetup').then(function () {
            ds.getRemote(config.GetFinanceSetup, {}, mapToDb, null, false)
                .then(loading.resolve, loading.reject);
        });

        return loading.promise;
    }

    // #region "Get Videos"

    public async getVideos(): Promise<any> {
        var ds = this._dataAccessService;
        var loading = defer();
        var caller = this;

        var forEntity = 'UpdateVideosProgress';
        await this.progressReporting.report(forEntity, 'Checking for updated videos:', 'pending');

        var iLastPercent = 0;
        var oDownloadPromise = defer();
        var oVideosToDownload = null;
        var iDownloadIdx = 0;

        var oLocalVideos = sessionService.getVideoVersions();
        if (oLocalVideos) {
            oLocalVideos = JSON.parse(oLocalVideos);
        }
        else {
            oLocalVideos = [];
        }


        function updateLocalVersion(oVideo) {

            if (!oLocalVideos) {
                oLocalVideos = [];
            }

            var bMatch = false;
            for (var iLocal = 0; iLocal < oLocalVideos.length; iLocal++) {

                if (oLocalVideos[iLocal].IDKey === oVideo.IDKey) {
                    oLocalVideos[iLocal] = oVideo;
                    bMatch = true;
                    break;
                }

            }

            if (bMatch === false) {
                oLocalVideos.push(oVideo);
            }

            sessionService.setVideoVersions(JSON.stringify(oLocalVideos));

        }

        var processList = async (data) => {
            if (!data.Videos || !data.Videos.length) {
                await this.progressReporting.report(forEntity, null, `No new videos to download.`);
                loading.resolve();
                return;
            }
            else {
                await this.progressReporting.report(forEntity, null, `Retrieved video list. Now downloading ${data.Videos.length} videos...`);
            }


            sessionService.setVideoMenu(JSON.stringify(data.MenuItems));

            var downloadedCount = 0;
            if (data.Videos.length > 0) {

                var successTextFn = () => {
                    downloadedCount++;
                    return `Successsfully downloaded ${downloadedCount} out of ${data.Videos.length} videos.`
                };

                var retries = 0;
                var maxRetries = 3;
                var failedCalls = [];
                var successCount = 0;
                var that = this;

                async function handleDownloadWithRetry(videoList) {
                    if (retries >= maxRetries) {
                        await that.progressReporting.report(forEntity, null, `Successfully downloaded ${successCount} videos. Failed to download ${failedCalls.length} videos. Max retries elapsed. Aborting...`);
                        return;
                    }

                    if (failedCalls.length) {
                        failedCalls = [];
                    }

                    for (var iDownloadFile = 0; iDownloadFile < videoList.length; iDownloadFile++) {

                        var oDownload = ds.httpGetStoreInCache(videoList[iDownloadFile].DownloadURL, 'video');
                        oDownload
                            .then(e => {
                                updateLocalVersion(videoList[iDownloadFile]);
                                successCount++;
                            })


                        that.progressReporting.onComplete(forEntity, oDownload, null, null, successTextFn, true);

                        try {
                            await oDownload;
                        }
                        catch (e) {
                            failedCalls.push(videoList[iDownloadFile]);
                        }
                    }

                    //retry logic
                    if (failedCalls.length) {
                        retries++;
                        await that.progressReporting.report(forEntity, null, `Successfully downloaded ${successCount} videos. Failed to download ${failedCalls.length} videos. Retrying now (retry #${retries})`);
                        await handleDownloadWithRetry(failedCalls);
                    }
                }

                await handleDownloadWithRetry(data.Videos);


                //oFilesDownloadedInfo = data.Videos;
            }
            else {
                // Todo: not needed any more ?
                //var pTemp = $q.when('data');
                //mappromises.push(pTemp);
                var x = "";
            }

            


        };

        await this.progressReporting.updateStatus(forEntity, 'Processing');

        ds.getRemote(config.GetVideosList, { Videos: oLocalVideos }, processList, null, false)
            .then(() => {

                loading.resolve();

            }, function (e) {
                console.error(e);
                loading.reject(e);
            });

        return loading.promise;
    }


    // #endregion

    // #region "GetImages"

    public async getImages(): Promise<any> {

        var loading = defer();
        var ds = this._dataAccessService;
        var caller = this;


        var forEntity = 'UpdateSlideImagesProgress';
        await this.progressReporting.report(forEntity, 'Checking for updated slide images:', 'pending');

        var iLastPercent = 0;
        var oDownloadPromise = defer();
        var oImagesToDownload = null;
        var iDownloadIdx = 0;

        var oLocalImages = sessionService.getImageVersions();
        if (oLocalImages) {
            oLocalImages = JSON.parse(oLocalImages);
        }
        else {
            oLocalImages = [];
        }


        function updateLocalVersion(oImage) {
            if (!oLocalImages) {
                oLocalImages = [];
            }

            var bMatch = false;
            for (var iLocal = 0; iLocal < oLocalImages.length; iLocal++) {

                if (oLocalImages[iLocal].IDKey === oImage.IDKey) {
                    oLocalImages[iLocal] = oImage;
                    bMatch = true;
                    break;
                }

            }

            if (bMatch === false) {
                oLocalImages.push(oImage);
            }

            sessionService.setImageVersions(JSON.stringify(oLocalImages));

        }

        //var downloadCompleted = async (oEvent) => {

            
        //    await this.progressReporting.updateStatus(forEntity, sStatus);

        //    var arrayBuffer = oEvent.currentTarget.response; // Note: not oReq.responseText
        //    if (arrayBuffer) {

        //        caller.saveImageFile(oImagesToDownload[iDownloadIdx].Filename, arrayBuffer).then(function () {

        //            updateLocalVersion();

        //            if (iDownloadIdx < oImagesToDownload.length - 1) {
        //                iDownloadIdx += 1;
        //              //  startImageDownload();
        //            }
        //            else {
        //                oDownloadPromise.resolve('downloaded');
        //            }

        //            arrayBuffer = null;

        //        });
        //    }
        //    else {
        //        oDownloadPromise.reject('no content');
        //    }

        //};

        //var downloadError = function (oEvent) {

        //    oDownloadPromise.reject();

        //    if (iDownloadIdx < oImagesToDownload.length - 1) {
        //        iDownloadIdx += 1;
        //       // startImageDownload();
        //    }

        //    console.log(oEvent);
        //};

        var downloadProgress = async (receivedLength, totalLength) => {
            var calc = 100 / receivedLength * totalLength;
            var perc = parseInt(calc.toString(), 0);
            if (perc !== iLastPercent) {
                var sStatus = 'Downloading (' + (iDownloadIdx + 1) + ' of ' + oImagesToDownload.length + ') ' + perc + '%';
                await this.progressReporting.updateStatus(forEntity,sStatus);
                iLastPercent = perc;
            }

        };

        var downloadedCount = 0;
        var ds = this._dataAccessService;

        async function startImageDownload(img, progressTracker: (receivedLength: number, totalLength: number) => Promise<any> = null) {
            var deepCopy = JSON.parse(JSON.stringify(img));
            var sURL = img.DownloadURL;
            await ds.httpGetStoreInCache(sURL, 'images', img.Filename);
            return deepCopy;
        }

        var processList = async (data) => {
            oImagesToDownload = data.Images;
            sessionService.setImageMenu(JSON.stringify(data.Menu));
            var promises = [];

            if (!oImagesToDownload || !oImagesToDownload.length) {
                await this.progressReporting.report(forEntity, null, `No new images to download.`);
                return;
            }
            else {
                await this.progressReporting.report(forEntity, null, `Retrieved image list. Now downloading ${oImagesToDownload.length} images...`);

                for (var idx in oImagesToDownload) {
                    try {
                        var i = oImagesToDownload[idx];
                        var downloadImagePromise = startImageDownload(i);
                        
                        downloadImagePromise.then(i => updateLocalVersion(i));

                        var successTextFn = () => {
                            downloadedCount++;
                            return `Successsfully downloaded ${downloadedCount} out of ${oImagesToDownload.length} images.`
                        };
                        this.progressReporting.onComplete(forEntity, downloadImagePromise,null, null, successTextFn)

                        //downloadImagePromise.then(retVal => i)

                        promises.push(downloadImagePromise);

                    }
                    catch (e) {
                        console.error(e);
                        //continue
                    }
                }
            }

            await Promise.all(promises);
        };

        await this.progressReporting.updateStatus(forEntity, 'Processing');

        ds.getRemote(config.GetImagesList, { Images: oLocalImages }, processList ,null, false)
            .then(function () {
                loading.resolve();
            }, function () {
                loading.reject();
            });


        return loading.promise;

    }

    private saveImageFile(filename, binaryData) {

        var d = defer();


        // TODO: sort out image storage 
        //createImagesFolder().then(function (folder) {

        //    //Save Video
        //    folder.createFileAsync(filename, Windows.Storage.CreationCollisionOption.replaceExisting).then(function (file) {
        //        file.openTransactedWriteAsync().then(
        //            function (transaction) {
        //                var dataWriter = new Windows.Storage.Streams.DataWriter(transaction.stream);
        //                dataWriter.writeBytes(new Uint8Array(binaryData));
        //                dataWriter.storeAsync().then(function (size) {
        //                    transaction.stream.size = size; // reset stream size to override the file
        //                    transaction.commitAsync().done(function () {
        //                        // Close stream
        //                        transaction.close();
        //                    });
        //                });
        //            },
        //            // Handle errors with an error function
        //            function (error) {
        //                // Proccess errors
        //            });
        //        defer.resolve(true);
        //    }, function () {
        //        defer.reject();
        //    });

        //});
        d.resolve({});

        return d.promise;
    };

    private createImagesFolder() {

        var d = defer();

        // TODO: sort out image storage 
        //var sFolder = 'SlideImages';
        ////Get local folder
        //var appFolder = Windows.Storage.ApplicationData.current.localFolder;

        ////See if the envisage folder exists.
        //appFolder.tryGetItemAsync(sFolder).done(function (folder) {

        //    if (folder !== null) {

        //        d.resolve(folder);

        //    } else {

        //        //Create folder
        //        appFolder.createFolderAsync(sFolder).done(function (newfolder) {

        //            d.resolve(newfolder);

        //        });

        //    }

        //});

        d.resolve({});

        return d.promise;
    };

    // #endregion

    public async checkESignStatus(): Promise<any> {
        var ds = this._dataAccessService;
        var loading = defer();
        var oPromise = defer();
        var report = await this.progressReporting.report('UpdateEsignStatusesProgress', 'Checking Esign Statuses:', 'pending', loading.promise);

        //1. Fetch orders to check
        var query, index;
        index = 'WaitingESign';
        query = IDBKeyRange.only(1);
        ds.getLocal('opportunities', index, query).then(function (opportunities) {

            var ordersToCheck = [];
            //2. Check orders
            for (var iOppIdx = 0; iOppIdx < opportunities.length; iOppIdx++) {
                if (opportunities[iOppIdx].lead.OrderNumber && isNaN(opportunities[iOppIdx].lead.OrderNumber) === false) {
                    ordersToCheck.push(opportunities[iOppIdx].lead.OrderNumber);
                }
            }

            if (ordersToCheck.length > 0) {
                var processList = function (data) {

                    var promises = [];

                    //3. Apply status to database.
                    var bMatch = false;
                    for (var iOppIdx = 0; iOppIdx < opportunities.length; iOppIdx++) {
                        bMatch = false;

                        for (var iEIdx = 0; iEIdx < data.ESignOrders.length; iEIdx++) {
                            if (parseFloat(data.ESignOrders[iEIdx].OrderNo) === parseFloat(opportunities[iOppIdx].lead.OrderNumber)) {
                                if (data.ESignOrders[iEIdx].HasSigned === true) {
                                    opportunities[iOppIdx].contract.WaitingESign = 0;
                                }
                                opportunities[iOppIdx].contract.ESignStatus = data.ESignOrders[iEIdx].SignedStatus;
                                bMatch = true;
                                break;
                            }
                        }

                        if (bMatch === true) {
                            promises.push(ds.saveLocal(opportunities[iOppIdx], 'opportunities'));
                        }

                    }

                    var all = Promise.all(promises);
                    all.then(function () {
                        oPromise.resolve();
                    });

                    return oPromise;

                };

                ds.getRemote(config.GetESignSync, { Orders: ordersToCheck }, processList, null)
                    .then(function () {
                        loading.resolve();

                    }, function (e) {
                        loading.reject();
                    });
            }
            else {
                loading.resolve();
            }
        });

        return loading.promise;

    }

    public async checkVOCStatus(): Promise<any> {
        var ds = this._dataAccessService;
        var loading = defer();
        var oPromise = defer();
        var forEntity = 'UpdateVOCStatusesProgress';
        var report = await this.progressReporting.report(forEntity, 'Checking VOC Statuses:', 'pending', loading.promise);

        //1. Fetch orders to check
        var query, index;
        index = 'IsSold';
        query = IDBKeyRange.only(1);
        ds.getLocal('opportunities', index, query).then((opportunities) => {

            var ordersToCheck = [];
            //2. Check orders
            for (var iOppIdx = 0; iOppIdx < opportunities.length; iOppIdx++) {
                if (opportunities[iOppIdx].lead.OrderNumber && isNaN(opportunities[iOppIdx].lead.OrderNumber) === false) {
                    ordersToCheck.push(opportunities[iOppIdx].lead.OrderNumber);
                }
            }

            if (ordersToCheck.length > 0) {
                var processList =  (data) => {

                    //1. Process 100 - return promise
                    var iBatchItemCount = 0;

                    var processBatch = (iBatchStartIdx, iBatchEndIdx) => {

                        var pBatch = defer();

                        var promises = [];
                        var all;

                        //3. Apply status to database.
                        var bMatch = false;
                        var iResult = -1;
                        for (var iOppIdx = iBatchStartIdx; iOppIdx < iBatchEndIdx; iOppIdx++) {
                            bMatch = false;

                            for (var iEIdx = 0; iEIdx < data.VOCOrders.length; iEIdx++) {
                                if (parseFloat(data.VOCOrders[iEIdx].OrderNo) === parseFloat(opportunities[iOppIdx].lead.OrderNumber)) {
                                    if (data.VOCOrders[iEIdx].AllowVOC === true) {
                                        iResult = 1;
                                    }
                                    else {
                                        iResult = 0;
                                    }
                                    if (opportunities[iOppIdx].contract.allowVOC !== iResult) {
                                        opportunities[iOppIdx].contract.allowVOC = iResult;
                                        bMatch = true;
                                    }

                                    break;
                                }
                            }

                            if (bMatch === true) {
                                promises.push(ds.saveLocal(opportunities[iOppIdx], 'opportunities'));
                            }

                        }

                        all = Promise.all(promises);
                        all.then(function () {
                            pBatch.resolve();
                        });

                        return pBatch.promise;
                    }

                    //2. Add promise, call 1 of more to process.
                    var controlBatchProcessing = () => {

                        var iBatchSize = 100;

                        var iEndLength = 0;
                        var iRemain = (opportunities.length - iBatchItemCount);
                        if (iRemain >= iBatchSize) {
                            iEndLength = iBatchItemCount + iBatchSize;
                        }
                        else if (iRemain > 0) {
                            iEndLength = iBatchItemCount + iRemain;
                        }
                        else {
                            oPromise.resolve();
                            return;
                        }

                        var prom = processBatch(iBatchItemCount, iEndLength);
                        prom.then(async () => {
                            iBatchItemCount = iEndLength;
                            await this.progressReporting.updateStatus(forEntity,'Processed ' + iBatchItemCount.toString());
                            controlBatchProcessing();
                        });

                    }

                    controlBatchProcessing();

                    return oPromise;

                };

                ds.getRemote(config.GetVOCSync, { Orders: ordersToCheck }, processList, null)
                    .then(function () {
                        loading.resolve();

                    }, function (e) {
                        loading.reject();
                    });
            }
            else {
                loading.resolve();
            }
        });

        return loading.promise;

    }

    public updateCPMarkUps(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;


        var mapToDb = function (data) {
            if (data) {
                data = JSON.stringify(data);
            }
            sessionService.setCPMarkUps(data);
            return Promise.resolve(data);
        };

        ds.getRemote(config.CPMarkUps, {}, mapToDb, null, false)
            .then(loading.resolve, loading.reject);


        return loading.promise;
    }

    public async updateLeadTimes(): Promise<any> {
        var loading = defer();
        var ds = this._dataAccessService;
        var report = await this.progressReporting.report('UpdateLeadTimesProgress', 'Updating Lead Times:', 'pending', loading.promise);

        var mapToDb = function (data) {
            var promises = [];
            if (data) {
                promises.push(ds.saveLocal(data, 'leadtimes'));
            }

            return promises;
        };

        ds.clear('leadtimes').then(function () {
            ds.getRemote(config.LeadTimes, {}, mapToDb, null, false)
                .then(loading.resolve, loading.reject);
        });

        return loading.promise;
    }

    public blobIt(b64Data, contentType = '', sliceSize = 512) {
        return b64toBlob(b64Data, contentType, sliceSize);
    }

}

function chunkImages(images: any, chunkSize: number) {
    var chunks = [];
    for (var i = 0, j = images.length; i < j; i += chunkSize) {
        var chunk = images.slice(i, i + chunkSize);
        chunks.push(chunk);
    }
    return chunks;
}


function _base64ToArrayBuffer(base64) {
    var binary_string = atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
