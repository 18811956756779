import { dataAccessService, defer } from './dataAccessService'
import _ from 'underscore';

export class AdminFeeService {
    private cachedAdminFees = [];
    private dataAccess = new dataAccessService();

    public async getAdminFeeForContractCalculation (totalPrice, product) {
        if (!this.cachedAdminFees.length) {
            await this.getAllAdminFees(product);
        }

        // Contract price includes the admin charge in it so we need to ensure the orderValue + adminFee is calculated to compare.
        var feesForPrice = _.filter(this.cachedAdminFees, function (fee) { return fee.NetOrderVal < totalPrice; });

        // If the total price falls between admin fee boundaries, i.e it's not actually possible to sell it at this price. Always give the smaller admin fee.
        var findFee = _.max(feesForPrice, function (fee) {
            return totalPrice < Number(fee.NetOrderVal) + Number(fee.AdminCharge) ? 0 : fee.OrderValue;
        });
        //var findFee = _.max(feesForPrice, function (fee) { return totalPrice - fee.AdminCharge < fee.OrderValue ? 0 : fee.OrderValue; });
        //var findFee = _.max(feesForPrice, function (fee) { return fee.OrderValue; });
        return findFee.AdminCharge;
    }


    public getAllAdminFees(product) : Promise<any> {
        var loading = defer();

        this.dataAccess.getLocal('adminCharge', 'Product', IDBKeyRange.only(product)).then((adminFees) => {

            var applicableFees = _.filter(adminFees, function (fee) {

                fee.EffectiveDate = new Date(fee.EffectiveDate.toString().substring(0, 4), fee.EffectiveDate.toString().substring(4, 6) - 1, fee.EffectiveDate.toString().substring(6, 8));
                return new Date() >= fee.EffectiveDate;
            });

            var maxDateToUse = _.max(applicableFees, function (fee) { return fee.EffectiveDate; });
            var feesForThisDate = _.filter(applicableFees, function (fee) { return fee.EffectiveDate.toDateString() == maxDateToUse.EffectiveDate.toDateString(); });

            loading.resolve(feesForThisDate);
        });

        return loading.promise;
    }

    public getAdminFee(totalPrice, product, contractCalcAdminFee, regionCost, allAdminFEes) {
        var loading = defer();
        if (contractCalcAdminFee) {
            loading.resolve(contractCalcAdminFee);
            return loading.promise;
        }

        var applicableFees = _.filter(allAdminFEes, function (fee) {
            return fee.NetOrderVal < totalPrice;
        });

        // We add the admin fee here instead of subtract, because we are working with subtotal now.
        var feeToApply = _.max(applicableFees, function (fee) {
            return totalPrice + regionCost + fee.AdminCharge < fee.OrderValue ? 0 : fee.OrderValue;
        });

        // TODO - Is this a legacy block of code that's no longer needed? I think it is...
        //if (totalPrice - feeToApply.AdminCharge < feeToApply.OrderValue) { // Actually we dont want this one after all.
        //   applicableFees.splice(_.indexOf(applicableFees, feeToApply), 1);

        //   feeToApply = _.max(applicableFees, function (fee) { return totalPrice + fee.AdminCharge < fee.OrderValue ? 0 : fee.OrderValue; });
        //}

        loading.resolve(feeToApply.AdminCharge);
        return loading.promise;
    }
}