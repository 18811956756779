import { defer } from './dataAccessService';

// PLEASE NOTE THERE IS A DIFFERENT BETWEEN A URL
// AND A DATAURL. 

export class MediaService {
    private capturedPhotoCacheName = "camera-photos";
    private imagesCacheName = "images";
    private readonly videoCacheName = 'video';

    private frontFacingCamera = 'user';
    private backFacingCamera = 'environment';
    private unloadedImgHeightWidthPx = 20;

    //      public methods      //
    // ---- 1) TAKING PHOTOS
    public async openCameraAsync(src: string, useFrontCamera: boolean = false): Promise<any> {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            return navigator.mediaDevices.getUserMedia({
                video: {
                    width: 1280,
                    height: 720,
                    aspectRatio: 3 / 2,
                    facingMode: useFrontCamera ? this.frontFacingCamera : this.backFacingCamera
                }
            })
                .then(function (stream) {
                    let video = <HTMLMediaElement>document.getElementById(src);
                    if (video != null) {
                        //pass the video capturing media device's output to the video player with id 'src'
                        video.srcObject = stream;
                        video.onloadedmetadata = function (e) {
                            video.play();
                        };
                    }
                    ////mirror image
                    //video.style.webkitTransform = "scaleX(-1)";
                    //video.style.transform = "scaleX(-1)";
                });
        }
    }

    public async captureImage(src: string, dest: string) {
        let video = <CanvasImageSource>document.getElementById(src);
        let canvas = <HTMLCanvasElement>document.getElementById(dest);
        var cw = canvas.width;
        var ch = canvas.height;
        var vw = <number>video.width;
        var vh = <number>video.height;
        if ((cw / ch) < (vw / vh)) {
            var th = cw * vh / vw;
            canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, 0, (ch - th) / 2, cw, th);
        } else {
            var tw = ch * vw / vh;
            canvas.getContext('2d').drawImage(video, 0, 0, vw, vh, (cw - tw) / 2, 0, tw, ch);
        }
    }

    public async clearCapturedImage(canvasId: string) {
        let canvas = <HTMLCanvasElement>document.getElementById(canvasId);
        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    }

    public async saveCapturedImage(canvasId) {
        const canvas = <HTMLCanvasElement>document.getElementById(canvasId);
        const dataUrl = canvas.toDataURL("image/jpeg");
        const fileName = this.generateFileNameWithoutExt() + ".jpg";

        this.cacheFileAsync(dataUrl, fileName, this.capturedPhotoCacheName);
        return {
            ImagePath: fileName,
            Base64: dataUrl
        };
    }

    public getImageScaleFactorsXY(filePath: string, htmlID: string): ScaleFactor {
        //get actual image dimensions
        const img = <HTMLImageElement>document.getElementById(htmlID);
        let renderedImageHeight = 1, renderedImageWidth = 1;

        if (!img || img.width < this.unloadedImgHeightWidthPx || img.height < this.unloadedImgHeightWidthPx) {
            return null;
        }

        renderedImageHeight = img.height;
        renderedImageWidth = img.width;


        //get original image size from cache
        let originalImg = new Image();
        originalImg.src = filePath;

        if (!originalImg.height || !originalImg.width) {
            return null;
        }

        //prepare scale factors
        const xFactor = renderedImageWidth / originalImg.width;
        const yFactor = renderedImageHeight / originalImg.height;
        console.log("origimg width, height, rendered width", originalImg.width, originalImg.height, renderedImageWidth, renderedImageHeight);
        var a = new ScaleFactor(xFactor, yFactor);
        console.log(a);

        return a;
    }

    public hasImageLoadedById(htmlID: string): boolean {
        const img = <HTMLImageElement>document.getElementById(htmlID);

        if (!img || img.width < this.unloadedImgHeightWidthPx || img.height < this.unloadedImgHeightWidthPx) {
            return false;
        }

        return true;

    }

    public async deletePhoto(photoURL: string) {
        const cache = await caches.open(this.capturedPhotoCacheName);
        await cache.delete(photoURL);
    }


    // ---- 2) CACHING IMAGES
    public async cacheImageByDataUrl(imageDataUrl: string, fileName: string) {
        await this.cacheFileAsync(imageDataUrl, fileName, this.imagesCacheName)
    }



    public async cacheFileAsync(url: string, fileName: string, cacheName: string) {
        let emptyPromise = defer();
        const cache = await caches.open(cacheName);
        fetch(url).then((r) => {
            cache.put(fileName, r).then((s) => {
                emptyPromise.resolve(fileName);
                console.log("captured photo placed in cache: " + fileName);
            }).catch((e) => {
                emptyPromise.reject();
            })
        })
    }

    public async getCachedImageBase64(imageUrl: string) {
        const cache = await caches.open(this.capturedPhotoCacheName);
        var image = await cache.match(imageUrl);

        if (!image.ok) {
            console.error("Could not find image with url " + imageUrl);
        }

        var buffer = await image.arrayBuffer();
        var view = new Uint8Array(buffer);

        return this.base64FromArrayBuffer(view);
    }

    public async preloadMedia(itemName: string, mediaElementId: string) {
        //get the cached response
        var cachedResponse = await caches.match(itemName);

        //create dataurl for <video> or <audio>
        var cachedResponseBlob = await cachedResponse.blob();
        var dataUrl = URL.createObjectURL(cachedResponseBlob);

        //attach it
        var mediaElement = <HTMLMediaElement>(document.getElementById(mediaElementId));
        mediaElement.src = dataUrl;
    }





    //      private methods     //

    private async base64FromArrayBuffer(data: Uint8Array) {
        // Use a FileReader to generate a base64 data URI
        const base64url: string = await new Promise((r) => {
            const reader = new FileReader()
            reader.onload = () => r(<string>reader.result)
            reader.readAsDataURL(new Blob([data]))
        })

        return base64url.split(",", 2)[1]
    }


    async doesCachedFileExist(filename, cachename) {
        const cache = await caches.open(cachename);
        var match = await cache.match(filename);
        return match !== null;
    }

    //      private methods     //
    private generateFileNameWithoutExt(): string {
        const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
        return 'camera-photos/' + guid;
    }
}

class ScaleFactor {
    constructor(x: number, y: number) {
        this.X = x;
        this.Y = y;
    }


    X: number;
    Y: number;
}