export class Validation {

    public isPhoneNumberValid(telephoneNumber, phoneRegEx) {
            // Convert into a string and check that we were provided with something
            var telnum = telephoneNumber + " ";
            if (telnum.length == 1) {
                return false;
            }

            telnum = telnum.slice(0, -1);

            // Don't allow country codes to be included (assumes a leading "+")
            var exp = /^(\+)[\s]*(.*)$/;
            if (exp.test(telnum) === true) {
                return false;
            }

            // Remove spaces from the telephone number to help validation
            while (telnum.indexOf(" ") != -1) {
                telnum = telnum.slice(0, telnum.indexOf(" ")) + telnum.slice(telnum.indexOf(" ") + 1);
            }

            // Remove hyphens from the telephone number to help validation
            while (telnum.indexOf("-") != -1) {
                telnum = telnum.slice(0, telnum.indexOf("-")) + telnum.slice(telnum.indexOf("-") + 1);
            }

            // Now check that all the characters are digits
            exp = /^[0-9]{10,11}$/;
            if (exp.test(telnum) !== true) {
                return false;
            }

            // Now check that the first digit is 0
            exp = /^0[0-9]{9,10}$/;
            if (exp.test(telnum) !== true) {
                return false;
            }

            //Now check telnum contains 3 or more distinct numbers, to prevent use of 0101010101 
            var count = 0;
            for (var j = 0; j < 10; j++) {
                if (telnum.indexOf(j.toString()) != -1) {
                    count++;
                }
            }
            if (count < 3) {
                return false;
            }

            // Disallow numbers allocated for dramas.

            // Array holds the regular expressions for the drama telephone numbers
            var tnexp = [];
            tnexp.push(/^(0113|0114|0115|0116|0117|0118|0121|0131|0141|0151|0161)(4960)[0-9]{3}$/);
            tnexp.push(/^02079460[0-9]{3}$/);
            tnexp.push(/^01914980[0-9]{3}$/);
            tnexp.push(/^02890180[0-9]{3}$/);
            tnexp.push(/^02920180[0-9]{3}$/);
            tnexp.push(/^01632960[0-9]{3}$/);
            tnexp.push(/^07700900[0-9]{3}$/);
            tnexp.push(/^08081570[0-9]{3}$/);
            tnexp.push(/^09098790[0-9]{3}$/);
            tnexp.push(/^03069990[0-9]{3}$/);

            for (var i = 0; i < tnexp.length; i++) {
                if (tnexp[i].test(telnum)) {
                    return false;
                }
            }

            // Finally check that the telephone number is appropriate.
            if (!phoneRegEx) {
                phoneRegEx = (/^(01|02|05|070|071|072|073|074|075|07624|077|078|079)[0-9]+$/);
                //phoneRegEx = (/^(01|02|03|05|070|071|072|073|074|075|07624|077|078|079)[0-9]+$/); - Removed 03 from valid starting numbers list.

            }
            if (phoneRegEx.test(telnum) !== true) {
                return false;
            }

            // Telephone number seems to be valid - return the stripped telehone number  
            return true;
    }

    // Found at http://www.braemoor.co.uk/software/postcodes.shtml
    public validatePostcode_ReturnNullIfInvalid(toCheck: string): string {

        // Permitted letters depend upon their position in the postcode.
        var alpha1 = "[abcdefghijklmnoprstuwyz]";                       // Character 1
        var alpha2 = "[abcdefghklmnopqrstuvwxy]";                       // Character 2
        var alpha3 = "[abcdefghjkpmnrstuvwxy]";                         // Character 3
        var alpha4 = "[abehmnprvwxy]";                                  // Character 4
        var alpha5 = "[abdefghjlnpqrstuwxyz]";                          // Character 5
        var BFPOa5 = "[abdefghjlnpqrst]";                               // BFPO alpha5
        var BFPOa6 = "[abdefghjlnpqrstuwzyz]";                          // BFPO alpha6

        // Array holds the regular expressions for the valid postcodes
        var pcexp = [];

        // BFPO postcodes
        pcexp.push(new RegExp("^(bf1)(\\s)([0-6]{1}" +
            BFPOa5 +
            "{1}" +
            BFPOa6 +
            "{1})$",
            "i"));

        // Expression for postcodes: AN NAA, ANN NAA, AAN NAA, and AANN NAA
        pcexp.push(new RegExp("^(" +
            alpha1 +
            "{1}" +
            alpha2 +
            "?[0-9]{1,2})(\\s)([0-9]{1}" +
            alpha5 +
            "{2})$",
            "i"));

        // Expression for postcodes: ANA NAA
        pcexp.push(new RegExp("^(" +
            alpha1 +
            "{1}[0-9]{1}" +
            alpha3 +
            "{1})(\\s)([0-9]{1}" +
            alpha5 +
            "{2})$",
            "i"));

        // Expression for postcodes: AANA  NAA
        pcexp.push(new RegExp("^(" +
            alpha1 +
            "{1}" +
            alpha2 +
            "{1}" +
            "?[0-9]{1}" +
            alpha4 +
            "{1})(\\s)([0-9]{1}" +
            alpha5 +
            "{2})$",
            "i"));

        // Exception for the special postcode GIR 0AA
        pcexp.push(/^(GIR)(\s)(0AA)$/i);

        // Standard BFPO numbers
        pcexp.push(/^(bfpo)(\s)([0-9]{1,4})$/i);

        // c/o BFPO numbers
        pcexp.push(/^(bfpo)(\s)(c\/o\s[0-9]{1,3})$/i);

        // Overseas Territories
        pcexp.push(/^([A-Z]{4})(\s)(1ZZ)$/i);

        // Anguilla
        pcexp.push(/^(ai-2640)$/i);

        // Load up the string to check
        var postCode = toCheck;

        // Assume we're not going to find a valid postcode
        var valid = false;

        // Check the string against the types of post codes
        for (var i = 0; i < pcexp.length; i++) {

            if (pcexp[i].test(postCode)) {

                // The post code is valid - split the post code into component parts
                pcexp[i].exec(postCode);

                // Copy it back into the original string, converting it to uppercase and inserting a space
                // between the inward and outward codes
                postCode = RegExp.$1.toUpperCase() + " " + RegExp.$3.toUpperCase();

                // If it is a BFPO c/o type postcode, tidy up the "c/o" part
                postCode = postCode.replace(/C\/O\s/, "c/o ");

                // If it is the Anguilla overseas territory postcode, we need to treat it specially
                if (toCheck.toUpperCase() == 'AI-2640') {
                    postCode = 'AI-2640';
                }

                // Load new postcode back into the form element
                valid = true;

                // Remember that we have found that the code is valid and break from loop
                break;
            }
        }

        // Return with either the reformatted valid postcode or the original invalid postcode
        if (valid) {
            return postCode;
        } else {
            return null;
        }
    }

    public isPhoneValidForType(phoneNumber: string, phoneType: string) {
        let phoneRegEx = /^(01|02|05|070|071|072|073|074|075|07624|077|078|079)[0-9]+$/;

        if (phoneType) {
            if (phoneType.toLowerCase() == "landline") {
                phoneRegEx = /^(01|02|05)[0-9]+$/;
            }
            else if (phoneType.toLowerCase().includes("mobile")) {
                phoneRegEx = /^(070|071|072|073|074|075|07624|077|078|079)[0-9]+$/;
            }
        }

        // Convert into a string and check that we were provided with something
        var telnum = phoneNumber + " ";
        if (telnum.length == 1) {
            return false;
        }

        telnum = telnum.slice(0, -1);

        // Don't allow country codes to be included (assumes a leading "+")
        var exp = /^(\+)[\s]*(.*)$/;
        if (exp.test(telnum) === true) {
            return false;
        }

        // Remove spaces from the telephone number to help validation
        while (telnum.indexOf(" ") != -1) {
            telnum = telnum.slice(0, telnum.indexOf(" ")) + telnum.slice(telnum.indexOf(" ") + 1);
        }

        // Remove hyphens from the telephone number to help validation
        while (telnum.indexOf("-") != -1) {
            telnum = telnum.slice(0, telnum.indexOf("-")) + telnum.slice(telnum.indexOf("-") + 1);
        }

        // Now check that all the characters are digits
        exp = /^[0-9]{10,11}$/;
        if (exp.test(telnum) !== true) {
            return false;
        }

        // Now check that the first digit is 0
        exp = /^0[0-9]{9,10}$/;
        if (exp.test(telnum) !== true) {
            return false;
        }

        //Now check telnum contains 3 or more distinct numbers, to prevent use of 0101010101 
        var count = 0;
        for (var j = 0; j < 10; j++) {
            if (telnum.indexOf(j.toString()) != -1) {
                count++;
            }
        }
        if (count < 3) {
            return false;
        }

        // Disallow numbers allocated for dramas.

        // Array holds the regular expressions for the drama telephone numbers
        var tnexp = [];
        tnexp.push(/^(0113|0114|0115|0116|0117|0118|0121|0131|0141|0151|0161)(4960)[0-9]{3}$/);
        tnexp.push(/^02079460[0-9]{3}$/);
        tnexp.push(/^01914980[0-9]{3}$/);
        tnexp.push(/^02890180[0-9]{3}$/);
        tnexp.push(/^02920180[0-9]{3}$/);
        tnexp.push(/^01632960[0-9]{3}$/);
        tnexp.push(/^07700900[0-9]{3}$/);
        tnexp.push(/^08081570[0-9]{3}$/);
        tnexp.push(/^09098790[0-9]{3}$/);
        tnexp.push(/^03069990[0-9]{3}$/);

        for (var i = 0; i < tnexp.length; i++) {
            if (tnexp[i].test(telnum)) {
                return false;
            }
        }

        // Finally check that the telephone number is appropriate.
        if (phoneRegEx.test(telnum) !== true) {
            return false;
        }

        // Telephone number seems to be valid - return the stripped telehone number  
        return true;
    }

}