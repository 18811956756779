import { dataAccessService, defer } from './dataAccessService'
import { DiscountService } from './discountService';
import { sessionService } from './sessionService';
import { AdminFeeService } from './adminFeeService';
import { FinanceService } from './financeService';
import _ from 'underscore';
import { dataOptimisationService } from './dataOptimisationService';

export class PriceListService {
    financeService = new FinanceService();
    dataAccess = new dataAccessService();
    adminFeeService = new AdminFeeService();
    discountService = new DiscountService()
    seshService = new sessionService();
    optimisationService = new dataOptimisationService();

    public async priceSummarySetup(opportunity) {
        var items = opportunity.order.items;
        var charges = opportunity.order.charges;

        var getAllAdminFeesProm = this.adminFeeService.getAllAdminFees(opportunity.lead.Product);
        var rtCalcProm = this.getPropertyPricesForRooftrimRecalculation(items);

        var results = await Promise.all([
            getAllAdminFeesProm,
            rtCalcProm,
            this.getTotalPrice(items, charges),
            this.getTotalBuildingWorksPrice(items),
            this.getTotalADGPrice(items),
            this.getTotalNonDiscountables(items, charges),
        ]);

        return {
            allAdminFees: results[0],
            rooftrimPrices: results[1],
            totalPrice: results[2],
            totalBuildingWorkPrice: results[3],
            totalADGPrice: results[4],
            nonDiscountables: results[5]
        };

    }




    public getPriceOnApplicationProperty(isDiscountable) {
        return {
            id: 0,
            pid: isDiscountable ? 'poa' : 'poanondisc',
            title: isDiscountable ? 'Price on Application' : 'Price on Application (Non-Discountable)',
            order: isDiscountable ? 500 : 501,
            data: [
                {
                    Name: '',
                    Amount: 0,
                    TypeOfPrice: 3, // Fixed price
                    Style: { Name: 'Price on Application' },
                    isPriceOnApplication: true,
                    isDiscountable: isDiscountable,
                    Code: 'PRICEONAPPLICATION',
                    Id: 0,
                    pId: isDiscountable ? 'POA' : 'POAnonDiscountable'
                }
            ]
        };
    }

    public getProductsForSalesGroup(salesGroupId) {
        var query = defer();
        this.dataAccess.getLocal('salesGroups', 'Code', IDBKeyRange.only(salesGroupId)).then( (data) => {

            query.resolve(data[0].ProductGroups);
        });

        return query.promise;
    }

    public getMyDesign(id) {
        var query = defer();

        this.dataAccess.getLocal('designs', 'Id', IDBKeyRange.only(id)).then( (data) =>{
            query.resolve(data[0]);
        });

        return query.promise;
    }

    public getDesign(id) {
        return this.dataAccess.getLocal('designs', id.toString());
    }

    public getProperty(id) {
        return this.dataAccess.getLocal('properties', id);
    }

    public async getDesignByCode(code) {
        // We look for the design. If we can't find the design, we look through all templates as it'll be a template code somewhere.
        var ds = this.dataAccess;
        try{
            var design = await ds.getLocal('designs', 'Code', IDBKeyRange.only(code))
                if (design.length) {
                    return design[0];
                } else {
                    var allDesigns = await ds.getLocal('designs');
                        var found;
                        _.forEach(allDesigns, (d) => {
                            if (!found) {
                                _.forEach(d.Templates, (t) => {
                                    if (!found && t.Code == code) {
                                        found = d;
                                    }
                                });
                            }
                        });
                    return found;
                }
        } catch (e) {
            debugger;
            var error = e.Message;
        }

    }

    public getDesignsForGroup(productGroupId) {
        var query = defer();

        this.dataAccess.getLocal('designs', 'ProductGroup', IDBKeyRange.only(productGroupId)).then( (data) => {
            // Take stuff off the object that we don't want in scope just yet
            _.forEach(data, (d) => {
                d.Properties = null;
                d.PropertyPrices = null;

                d = this.optimisationService.beforeGetDesign(d);
            });

            query.resolve(data);
        });

        return query.promise;
    }

    public getMaterialsForProduct(productGroupId, designs) {

        var colours = _.flatten(_.pluck(designs, 'Colours'), true); // only flatten single level
        var materials = _.pluck(colours, 'Material');
        materials = _.uniq(materials, (m) => { return m.Id; });

        return materials;
    }

    public async getDesignsForSize(designs, width, height, material) {
        let designFromDb;
        let design;
        for (var idx in designs) {
            design = designs[idx];
            designFromDb = (await this.getDesign(design.Id))[0];
            design.Sizes = designFromDb.Sizes;
        }


        width = parseInt(width, 10);
        height = parseInt(height, 10);
        var that = this;
        var designsForSize = _.filter(designs, function (d) {
            // first let's make sure we are in bounds
            var min = _.find(d.Limits, function (l) { return l.TypeOfLimit === 0 && l.Property.Style.Code === "MATERIAL"; }); // Min allowed
            var max = _.find(d.Limits, function (l) { return l.TypeOfLimit === 1 && l.Property.Style.Code === "MATERIAL"; }); // Max allowed

            var isOk = true;
            if (min) {
                d.minWidth = min.Width;
                d.minHeight = min.Height;
                isOk = width >= min.Width && height >= min.Height;
            }

            if (max) {
                d.maxWidth = max.Width;
                d.maxHeight = max.Height;

                if (isOk) {
                    isOk = width <= max.Width && height <= max.Height;
                }
            }

            // If we didn't have limits, but do have sizes, get the lower/upper bounds..
            if ((!d.minHeight || !d.minWidth) && d.Sizes) {
                d.minHeight = _.min(d.Sizes, function (s) { return s.Height; }).Height;
                d.minWidth = _.min(d.Sizes, function (s) { return s.Width; }).Width;
            }

            if ((!d.maxHeight || !d.maxWidth) && d.Sizes) {
                d.maxHeight = _.max(d.Sizes, function (s) { return s.Height; }).Height;
                d.maxWidth = _.max(d.Sizes, function (s) { return s.Width; }).Width;
            }

            if (d.maxWidth < width || d.minWidth > width) {
                return false;
            }

            if (d.maxHeight < height || d.minHeight > height) {
                return false;
            }

            return isOk;
        });

        var promises: any = [];
        _.forEach(designsForSize, function (d) {
            var promise = defer();
            promises.push(promise.promise);
            if (d.TypeOfPrice === 3) { // fixed price. we've got a price.
                promise.resolve(d.Id);
            } else {
                // We need to make sure it's ok for this material..
                //if (pricesForDesign[d.Id]) {
                //   if (getPriceForSize(pricesForDesign[d.Id], width, height, material.Id, d.minWidth, d.minHeight)) {
                //      promise.resolve(d.Id);
                //   } else {
                //      promise.resolve(false);
                //   }
                //} else {

                that.dataAccess.getLocal('prices', d.Id, null, true).then(function (prices) {
                    // Cache it.
                    //pricesForDesign[d.Id] = prices;
                    var priceSize = that.getPriceForSize(prices, width, height, material.Id, d.minWidth, d.minHeight);
                    if (priceSize != null) {
                        promise.resolve(d.Id);
                    } else {
                        promise.resolve(null);
                    }
                });
                //}
            }
        });
        var designIds = await Promise.all(promises); // a list of design ids
        return designIds.filter(d => d);
    }


    public getExtrasGroupsForDesign(design, productType, properties) {
        var that = this;
        // These are groups that we don't want to show on the screen, they are handled differently.
        var HIDDEN_GROUPS = ['MATERIAL', 'TEMPLATE', 'BAY', 'MANDATORY'];
        var groups = [];
        _.forEach(properties, function (prop) {
            if (_.find(HIDDEN_GROUPS, function (g) { return g === prop.Style.Code; })) {
                return;
            } else {
                groups.push(prop.Code);
            }
        });

        var result = [];
        _.each(groups, (g) => {
            var extras = that.getExtrasGroupForDesign(properties, g);

            if (extras) {


                result.push({
                    id: extras.id,
                    order: extras.order,
                    title: extras.name,
                    data: that.addParentToExtras([extras.id], extras.extras),
                    AreAttributesMutex: extras.AreAttributesMutex,
                    IsAttributeRequired: extras.IsAttributeRequired
                });
            }
        });

        result = _.sortBy(result, function (o) { return o.order; });
        //if (productType === "RT" && result.length === 0) {
        //    debugger
        //    // Add Price on Application to the end.
        //    result.push(that.getPriceOnApplicationProperty(true));

        //    result.push(that.getPriceOnApplicationProperty(false));

        //}
        //else {
        //}

        result.push(that.getPriceOnApplicationProperty(true));

        result.push(that.getPriceOnApplicationProperty(false));

        return result;
    }

    public getColoursNotAllowedForSize(design, width, height) {

        if (design == null) {
            return null;
        }

        var colourLimits = _.filter(design.Limits, function (l) {
            return l.Property.Style.Name == 'Colour';
        });

        var limitForColours = _.filter(colourLimits, function (l) {
            return l.TypeOfLimit === 2 && width >= l.Width && height >= l.Height;
        });

        var iColorIdx = 0;
        var oLimit = null;
        var oRestrictReasons = [];
        var oReason: any = {};
        var minimumLimits = [];
        var maximumLimits = [];

        for (iColorIdx = 0; iColorIdx < colourLimits.length; iColorIdx++) {

            oLimit = colourLimits[iColorIdx];

            switch (oLimit.TypeOfLimit) {
                case 0: //Minimums

                    if (oLimit.Height > height || oLimit.Width > width) {
                        oReason = {};
                        oReason.reason = 'The unit sizes must be at least be a width of ' + oLimit.Width + 'mm and height of ' + oLimit.Height + 'mm';
                        oReason.PropertyId = oLimit.Property.Id;
                        oRestrictReasons.push(oReason);

                        minimumLimits.push(oLimit);
                    }

                    break;
                case 1: //Maximums

                    if (height > oLimit.Height || width > oLimit.Width) {
                        oReason = {};
                        oReason.reason = 'The unit sizes must not exceed a width of ' + oLimit.Width + 'mm and height of ' + oLimit.Height + 'mm';
                        oReason.PropertyId = oLimit.Property.Id;
                        oRestrictReasons.push(oReason);

                        maximumLimits.push(oLimit);
                    }

                    break;
            }
        }

        var limits = _.union(limitForColours, minimumLimits, maximumLimits);
        var notAllowedColours = _.map(_.uniq(limits, false, function (l) { return l.Property.Id; }), function (c) { return c.Property; });


        //var oReturn: any = {};
        //oReturn.notAllowedColours = notAllowedColours;
        //oReturn.restrictReasons = oRestrictReasons;

        return {
            notAllowedColours: notAllowedColours,
            restrictReasons: oRestrictReasons
        };
    }

    getDesignsForMaterial(material, designs) {
        var result = _.filter(designs, function (d) { return _.some(d.Colours, function (c) { return c.Material.Id === material.Id; }); });
        return result;
    }

    getColoursForMaterial(material, designs) {
        var colours = _.flatten(_.pluck(designs, 'Colours'), true); // only flatten single level

        var coloursForMaterial = _.filter(colours, function (c) { return c.Material.Id === material.Id; });
        var uniqueColourGroups = _.uniq(coloursForMaterial, function (c) { return c.Id; });
        var allColours = _.flatten(_.map(uniqueColourGroups, function (c) { return c.Colours.Attributes; }));

        // We need the colour group properties here..
        var result = _.uniq(allColours, function (c) { return c.Id; });

        // HACK this is horrid but the order that we do things on page means that no 'proper' way of doing it makes sense. Definitely needs reviewing though.
        result.isSplitAllowed = uniqueColourGroups[0].AllowSplit;
        result.isDualAllowed = uniqueColourGroups[0].AllowDual;
        result.isNonStandardAllowed = uniqueColourGroups[0].AllowNonStandard;
        result.NonStandardPercent = uniqueColourGroups[0].NonStandardPercent;
        result.IsDiscountable = uniqueColourGroups[0].IsDiscountable;

        if (uniqueColourGroups[0].AllowSplit) {
            result.splitColours = uniqueColourGroups[0].SplitColours.Attributes;
        }

        return result;
    }


    public getTotalPrice(items, charges) {
        items = _.filter(items, function (i) { return i.included; });

        var cost = _.reduce(items, function (price, item) { return price + item.itemPrice; }, 0);

        cost += _.reduce(charges, function (counter, charge) { return counter + charge.Charge; }, 0);
        return cost;
    }

    public getPropertyPriceTypes(propId, propertyPriceTrail, propertyPrices) {
        var result = [];

        if (propertyPriceTrail && propertyPriceTrail.length) {
            _.forEach(propertyPriceTrail, function (prop) {
                if (!result.length) {
                    result = _.uniq(_.pluck(_.filter(propertyPrices, function (price) { return price.PropertyId === prop; }), "TypeOfPrice"));
                }
            });
        } else {
            result = _.uniq(_.pluck(_.filter(propertyPrices, function (price) { return price.PropertyId === propId; }), "TypeOfPrice"));
        }

        return result;
    }

    public getTotalBuildingWorksPrice(items) {
        items = _.filter(items, function (i) { return i.included; });
        var total = 0;
        _.forEach(items, function (i) {
            _.forEach(i.properties, function (p) {
                if ((p.name || p.Name) === "Building Work Cost") {
                    total += p.price;
                }
            });
        });
        return total;
    }

    public getTotalADGPrice(items) {
        var total = 0;
        _.forEach(items, function (i) {
            _.forEach(i.properties, function (p) {
                if ((p.name || p.Name) === "ADG Cost") {
                    total += p.price;
                }
            });
        });
        return total;
    }


    public getTotalNonDiscountables(items, charges) {
        items = _.filter(items, function (i) { return i.included; });
        var total = 0;
        if (charges && charges.length) {
            total = _.reduce(charges, function (price, charge) { return price + charge.Charge; }, total);
        }

        return _.reduce(items, function (price, item) { return price + item.nonDiscountables; }, total);
    }

    public async getPriceForRooftrim(designs,
        size,
        properties,
        designPropertyPrices,
        isActuallyForRooftrim = false) {

        var prices = [];

        var updating = defer();
        var that = this;

        var p: any;
        for (p in designs) {
            //_.forEach(designs, async function (design) {
            var design = designs[p];

            if (isActuallyForRooftrim) {
                design = design.selected;
            }

            if (design.included === false) {
                return;
            }

            var selectedDesign = design;

            var price = { basePrice: 0, properties: [], isDiscountable: selectedDesign.IsDiscountable, ExcludeFromColourSurcharge: null };
            if (design) {
                price.ExcludeFromColourSurcharge = design.ExcludeFromColourSurcharge;
            }

            if (selectedDesign.TypeOfPrice === 7 || selectedDesign.TypeOfPrice === 9 || selectedDesign.TypeOfPrice === 10) {
                var SizemarksUpFactor = 1;
                if (selectedDesign.Product !== undefined && selectedDesign.Product !== null) {
                    if (selectedDesign.Product.ProductPriceGroup !== undefined && selectedDesign.Product.ProductPriceGroup !== null) {
                        SizemarksUpFactor = await that.getRegionMarkupFactor(selectedDesign.Product.ProductPriceGroup);
                    }
                }
                price.basePrice = Math.ceil(selectedDesign.Amount * size * SizemarksUpFactor);
            } else if (selectedDesign.TypeOfPrice === 3) { // Fixed
                var FixedmarksUpFactor = 1;
                if (selectedDesign.Product !== undefined && selectedDesign.Product !== null) {
                    if (selectedDesign.Product.ProductPriceGroup !== undefined && selectedDesign.Product.ProductPriceGroup !== null) {
                        FixedmarksUpFactor = await that.getRegionMarkupFactor(selectedDesign.Product.ProductPriceGroup);
                    }
                }
                price.basePrice = Math.ceil(selectedDesign.Amount * FixedmarksUpFactor);
            }

            if (properties.length) {
                // Filter properties on included in base price - price these ones
                var propertiesToIncludeInBase = _.filter(properties, function (p) { return p.IsAttributeIncludedInBasePrice && p.included && p.designId == design.Id; });
                _.forEach(propertiesToIncludeInBase, function (p) {
                    var includedInBase = that.getPropertyPrice(designPropertyPrices[design.Id], p, price.basePrice, null, null, properties);
                    price.basePrice += includedInBase.price;
                });
            }

            prices.push(price);
        }

        var basePrice = _.reduce(prices, function (memo, price) { return memo + price.basePrice; }, 0);

        var basePriceNoColour = _.reduce(prices, function (memo, price) {
            if (price.ExcludeFromColourSurcharge === true) {
                return memo;
            }
            else {
                return memo + price.basePrice;
            }

        }, 0);


        _.forEach(designs, function (design, index) {
            if (design.included === false) {
                return;
            }

            var designPrice = prices[index];
            if (design.colour) {
                design.colour.quantity = size;
                designPrice.properties.push(that.getPropertyPrice(designPropertyPrices[design.selected.Id], design.colour, basePriceNoColour, null, null, properties));
            }

            if (design.material) {
                design.material.quantity = size;
                designPrice.properties.push(that.getPropertyPrice(designPropertyPrices[design.selected.Id], design.material, basePrice, null, null, properties));
            }

            if (properties.length) {
                _.forEach(properties, function (p) {
                    if (p.included && p.designId == design.selected.Id && !p.IsAttributeIncludedInBasePrice) {
                        designPrice.properties.push(that.getPropertyPrice(designPropertyPrices[design.selected.Id], p, basePrice, null, null, properties));
                    }
                });
            }
        });
        //// Put price on application onto design 1
        //_.forEach(properties, function(p) {
        //   if (!p.designId) {
        //      prices[0].properties.push(getPropertyPrice(null, p, prices[0].basePrice));
        //   }
        //});

        var total = _.reduce(prices, function (sum, p) { return sum + p.basePrice; }, 0);
        var nonDiscountables = 0;
        _.forEach(prices, function (price) {
            if (!price.isDiscountable) {
                nonDiscountables += price.basePrice;
            }

            if (price.properties.length) {
                _.forEach(price.properties, function (p) {
                    total += p.price;

                    if (!p.discountable) {
                        nonDiscountables += p.price;
                    }
                });
            }
        });

        var obj = {
            itemPrice: total,
            nonDiscountables: nonDiscountables
        };
        updating.resolve(obj);
        return updating.promise;
    }

    public async getPriceForItem(design, FormatWidth, FormatHeight, area, materialId, color, colors, template, properties, allProperties, propertyPrices) {
        var updating = defer();
        var that = this;

        //debugger
        var price = await this.getPriceForWindow(
            design,
            FormatWidth,
            FormatHeight,
            area,
            materialId,
            color,
            colors,
            template,
            properties,
            allProperties,
            propertyPrices);

        var total = price.basePrice;

        var nonDiscountables = 0;

        if (!price.isDiscountable) {
            nonDiscountables += price.basePrice;
        }

        if (price.properties.length) {
            var p: any;
            for (p in price.properties) {
                var price_properties = price.properties[p];
                //added for jira 7012
                var markupfactor = 1;
                if (price_properties.property.ProductPriceGroup !== undefined && price_properties.property.ProductPriceGroup !== null) {
                    markupfactor = await that.getRegionMarkupFactor(price_properties.property.ProductPriceGroup);
                }

                price_properties.price = price_properties.price ? price_properties.price : 0;

                total = total + Math.ceil(price_properties.price * markupfactor);

                if (!price_properties.discountable) {
                    nonDiscountables = nonDiscountables + Math.ceil(price_properties.price * markupfactor);
                }

            }
        }

        //debugger
        //item.itemPrice = total;
        //item.nonDiscountables = nonDiscountables;
        var obj = {
            itemPrice: total,
            nonDiscountables: nonDiscountables,
            pricePropertiesId: price.properties.PropertyId
        };
        updating.resolve(obj);
        return updating.promise;
    }

    public getTotalPriceForExtrasClass(items, extrasClass) {
        var total = 0;
        _.forEach(items, function (i) {
            _.forEach(i.properties, function (p) {
                if (p.Style.Code === extrasClass) {
                    total += p.price;
                }
            });
        });

        return total;
    }

    public getProductRestrictions(restrictions, selectedProperties, colour, designProperties, selectedTemplate, customTemplate, mandatoryProperties, colours) {

        //todo: remove static
        //customTemplate = '[[{"PropertyTemplate":{"Id":71,"Order":0,"Code":"FIXEDPANE","Name":"Fixed pane","IsAttributeIncludedInBasePrice":true,"IsDiscountable":false,"IsGroup":false,"Image":"/Uploaded/Images/6591a80d-96cd-489f-9b66-66298b44384c.png","TypeOfPrice":0,"Amount":0,"AreAttributesMutex":false,"IsDefaultSelect":false,"IsAttributeRequired":false,"SOWCode":"","Style":{"Id":6,"Code":"TEMPLATE","Name":"Template"},"Attributes":null,"CommissionCode":null,"ExplodedImageFilename":null,"ExplodedUploaded":"0001-01-01T00:00:00","ExplodedRichText":null,"IsRestricted":false,"$$hashKey":"0HK","restricted":false,"restrictedBy":[]},"TopLeft":{"Column":1,"Row":1},"BottomRight":{"Column":1,"Row":1},"rowspan":1,"colspan":1,"width":"150px","$$hashKey":"0HH"}]]';
        //
        //customTemplate = JSON.parse(customTemplate);

        var notAllowed = [];
        // for each restriction we need to apply the reverse. i.e. if you choose Product A you cannot choose B,C or D
        // therefore if you choose B you cannot choose A 
        // therefore if you choose C you cannot choose A 
        // therefore if you choose D you cannot choose A 

        var that = this;
        var reverseRestrictions = [];
        _.forEach(restrictions, function (restriction) {
            // we need to create the reverse restrictions
            _.forEach(restriction.CantApply, function (notAllowed) {
                var _cantApply = [restriction.WhenApplied];
                var _whenApplied = [notAllowed];
                var newRestriction = {
                    CantApply: _cantApply,
                    WhenApplied: _whenApplied
                };
                reverseRestrictions.push(newRestriction);
            });
        });

        _.forEach(_.union(restrictions, reverseRestrictions), (r) => {
            // For each restriction, first we look if we have a match on the properties
            var restrictionMatched = true;

            //Check r is set, otherwise error generated.
            if (r) {

                _.forEach(r.WhenApplied, (search) => {
                    // Set it to false if we can't find a match for this restriction.
                    if (!_.find(selectedProperties, function (p) { return p.Id === search; })) {

                        if (colour != null) {
                            if (colour.Id != search) {
                                restrictionMatched = false;
                            }
                        }
                        else {
                            if (colours) {

                                //Some units can have multiple colors
                                //we need to check too see if any match restriction.
                                var iMax = colours.length;
                                var bMatch = false;
                                for (var iCount = 0; iCount < iMax; iCount++) {
                                    if ((colours[iCount]) && (colours[iCount].Id)) {
                                        if (colours[iCount].Id === search) {
                                            bMatch = true;
                                        }
                                    }
                                }
                                restrictionMatched = bMatch;
                            }

                        }

                        // Not in properties, not in colour.. is it a template?
                        if (!restrictionMatched && selectedTemplate) {
                            restrictionMatched = _.any(selectedTemplate.Slots, function (s) { return s.Id === search; });
                        }

                        // Here's a long shot.. is it in the custom template?
                        if (!restrictionMatched && customTemplate) {
                            restrictionMatched = _.any(customTemplate, function (t) { return _.any(t, function (slot) { return slot.PropertyTemplate && slot.PropertyTemplate.Id === search; }); });
                        }

                        // Is it a top level property?
                        if (!restrictionMatched) {
                            var prop = _.find(designProperties, function (p) { return p.id === search; });
                            if (prop) {
                                restrictionMatched = that.searchForChildSelection(prop.data, selectedProperties);
                            }

                            //searchForRestriction(p, search);
                            //// have we selected a child of this property?
                            //if (_.forEach(p.Attributes, function (a) {
                            //   if (!restrictionMatched) {
                            //      if (!_.find(selectedProperties, function(p) { return p.Id === a.Id; })) {
                            //         restrictionMatched = true;
                            //      }
                            //   }
                            //}));
                        }

                        // Is it a mandatory property?
                        if (!restrictionMatched) {
                            _.forEach(mandatoryProperties, function (p) {
                                if (!restrictionMatched) {
                                    if (_.find(p.Attributes, function (attr) { return attr.Id === search; })) {
                                        restrictionMatched = true;
                                    }
                                }
                            });
                        }

                        if (!restrictionMatched) {
                            // It's not a property, it's not a colour and it's not in the template ... last throw of the dice, is it a child of one of the properties?!
                            var restrictedProp = _.find(_.flatten(_.map(designProperties, function (p) { return p.data; })), function (p) { return p.Id === search; });

                            if (restrictedProp && restrictedProp.Attributes) {
                                _.forEach(restrictedProp.Attributes, function (a) {
                                    if (!restrictionMatched) {
                                        if (_.find(selectedProperties, function (p) { return a.Id === p.Id; })) {
                                            restrictionMatched = true;
                                        }
                                    }
                                });
                            }
                        }
                    }
                });
            }

            if (restrictionMatched) {
                _.forEach(r.CantApply, function (restricted) {

                    var res = [];
                    var isArray = Array.isArray(restricted);

                    if (isArray) {
                        res = restricted;
                    }
                    else {
                        res.push(restricted);
                    }

                    _.forEach(res, function (check_restricted) {

                        var restrictedAlready = _.find(notAllowed, function (already_exists) {
                            return already_exists.notAllowed.indexOf(check_restricted) > -1;
                        });

                        if (restrictedAlready) {
                            restrictedAlready.restrictedBy = _.union(restrictedAlready.restrictedBy, r.WhenApplied);
                        } else {
                            notAllowed.push({ notAllowed: res, restrictedBy: r.WhenApplied });
                        }

                    });


                });
                //notAllowed = _.union(notAllowed, r.CantApply);
            }
        });

        return notAllowed;
    }

    public async getDiscountAmountForProductDiscount(discountBand, items, rooftrimPrices) {
        var discountApplied = 0;
        var productsToDiscount = discountBand.split(';');

        _.forEach(productsToDiscount, async (discountedProduct) => {
            if (_.any(items, async (i) => { return i.designs; })) {
                // Rooftrim, handle slightly differently!
                _.forEach(items, async (i) => {
                    var chargedPrice = i.itemPrice;
                    // For rooftrim, there might be colours that need to be considered, so we will re-price and work out the difference.
                    var matched = _.filter(i.designs, function (d) { return d.selected.Code === discountedProduct; });

                    if (matched.length) {
                        _.forEach(matched, function (m) { m.included = false; });
                        var price = await this.getPriceForRooftrim(i.designs, i.size, i.properties, rooftrimPrices);
                        discountApplied += (chargedPrice - price.itemPrice);
                        _.forEach(matched, function (m) { m.included = true; });
                    }
                });

            } else {
                var freeProducts = _.filter(items, function (i) { return i.design.Code == discountedProduct; });

                if (freeProducts.length) {
                    discountApplied += _.reduce(freeProducts, function (memo, product) { return memo + product.itemPrice; }, 0);
                }
            }

            if (!discountApplied) {
                // If no discount applied, look for a free extra
                var freeExtras = _.filter(_.flatten(_.pluck(items, 'properties')), function (p) { return p.Code == discountedProduct; });
                discountApplied += _.reduce(freeExtras, function (memo, product) { return memo + product.price; }, 0);
            }
        });

        return discountApplied;
    }

    public getChargesForSalesGroup(productCode) {
        var loading = defer();
        this.dataAccess.getLocal('salesGroups', 'Code', IDBKeyRange.only(productCode)).then(function (product) {
            loading.resolve(product[0].Charges);
        });

        return loading.promise;
    }

    public async calculatePriceWithDiscounts(listPrice, discounts, nonDiscountables, items, rooftrimPrices, allowUpdate, salesGroup, totalBuildingWorksPrice, totalADGPrice, regionCost, logisticCharge, product) {
        var discountTrail: any = [];
        var contractCalcAdminFee = 0;
        var productPrice = 0;
        var flattenedDiscounts = _.flatten(_.pluck(discounts, 'appliedOptions'));

        var productDiscounts = _.filter(flattenedDiscounts, function (d) { return d.DiscountType == 2; });

        _.forEach(productDiscounts, function (d) {
            flattenedDiscounts.splice(flattenedDiscounts.indexOf(d), 1);
            flattenedDiscounts.unshift(d);
        });

        if (salesGroup === "CP" && allowUpdate) {
            var slidingScaleIndex = -1;
            var BuildingWorkIndex = -1;
            var slidingDiscounts = _.filter(flattenedDiscounts, function (d) { return d.Id === 'slidingScale'; });
            _.forEach(slidingDiscounts, function (d) {
                slidingScaleIndex = flattenedDiscounts.indexOf(d);
            });
            var builindworkDiscount = _.filter(flattenedDiscounts, function (d) { return d.PriceCategory === 1; });
            _.forEach(builindworkDiscount, function (d) {
                BuildingWorkIndex = flattenedDiscounts.indexOf(d);
            });
            if (slidingScaleIndex > 0 && BuildingWorkIndex > 0) {
                flattenedDiscounts[BuildingWorkIndex] = slidingDiscounts[0];
                flattenedDiscounts[slidingScaleIndex] = builindworkDiscount[0];
            }

            var removeFianceIndex = -1;
            var removeDiscounts = _.filter(flattenedDiscounts, function (d) { return d.IsRemoveFinance === true; });
            _.forEach(removeDiscounts, function (d) {
                removeFianceIndex = flattenedDiscounts.indexOf(d);
            });

            if (slidingDiscounts !== null && slidingDiscounts !== undefined) {
                if (slidingDiscounts.length > 0 && removeDiscounts.length > 0) {
                    var slidngPriority = slidingDiscounts[0].parent.Priority;
                    var removeFiancePrioirty = removeDiscounts[0].parent.Priority;
                    if (slidngPriority > removeFiancePrioirty) {
                        _.forEach(slidingDiscounts, function (d) {
                            slidingScaleIndex = flattenedDiscounts.indexOf(d);
                        });

                        if (slidingScaleIndex > 0 && removeFianceIndex > 0) {
                            flattenedDiscounts[removeFianceIndex] = slidingDiscounts[0];
                            flattenedDiscounts[slidingScaleIndex] = removeDiscounts[0];
                        }
                    }
                }

            }


        }


        var MainListPrice = listPrice;
        var financeDiscount = 0;

        //angular.forEach(discounts, function (discount) {
        _.forEach(flattenedDiscounts, async (option) => {
            var discountApplied = 0;

            switch (option.DiscountType) {
                case 1: // Value 
                    if (option.setContractPrice) { // AKA, overriding discounts and setting the 'Contract Amount' from Further Share and Save
                        // Store the admin fee that we used so that the price presentation can use it. This is the only way to stop the contract price falling into a hole that no admin fee can reach.
                        if (salesGroup === "CP") {
                            productPrice = listPrice - (totalBuildingWorksPrice + totalADGPrice);
                            contractCalcAdminFee = await this.adminFeeService.getAdminFeeForContractCalculation(option.setContractPrice, product) + regionCost;
                            discountApplied = (option.setContractPrice - listPrice - contractCalcAdminFee - nonDiscountables - logisticCharge) * -1;
                            if (discountApplied < 0) {
                                discountApplied = discountApplied * -1;
                            }
                            listPrice = listPrice - discountApplied;
                        }
                        else {
                            contractCalcAdminFee = await this.adminFeeService.getAdminFeeForContractCalculation(option.setContractPrice, product) + regionCost;
                            discountApplied = (option.setContractPrice - listPrice - contractCalcAdminFee - nonDiscountables - logisticCharge) * -1;
                            listPrice = listPrice - discountApplied;
                        }
                    } else {
                        if (option.IsRemoveFinance) {
                            option.applicableBand.DiscountApplied = financeDiscount * -1;
                        }

                        listPrice = (listPrice - parseFloat(option.applicableBand.DiscountApplied)).toFixed(2);
                        discountApplied = parseFloat(option.applicableBand.DiscountApplied);
                    }
                    break;
                case 0: // Percent
                    if (salesGroup === "CP") {
                        var _price = listPrice;
                        if (option.IsDiscountApplyToListPrice) {
                            _price = MainListPrice - financeDiscount;
                            productPrice = MainListPrice - financeDiscount;
                            switch (option.PriceCategory) {
                                case 0:
                                    _price = productPrice;
                                    break;
                                case 1:
                                    _price = totalBuildingWorksPrice;
                                    break;
                                case 2:
                                    _price = totalADGPrice;
                                    break;
                            }
                            if (option.IsFinance) {
                                // finance discount is applied across all 3 price categories
                                _price = MainListPrice - financeDiscount;
                            }
                            discountApplied = parseFloat(this.discountService.getDiscountAmountForPercentDiscount(_price, option.applicableBand.DiscountApplied, option.amountAdded, option.ShowExchangeOfValue, option.eovInfo));
                            listPrice = listPrice - discountApplied;

                        }
                        else {

                            productPrice = listPrice;
                            switch (option.PriceCategory) {
                                case 0:
                                    _price = productPrice;
                                    break;
                                case 1:
                                    _price = totalBuildingWorksPrice;
                                    break;
                                case 2:
                                    _price = totalADGPrice;
                                    break;
                            }
                            if (option.IsFinance) {
                                // finance discount is applied across all 3 price categories
                                _price = listPrice;
                                if (salesGroup === "CP") {
                                    _price = _price + totalBuildingWorksPrice;
                                }

                            }
                            discountApplied = parseFloat(this.discountService.getDiscountAmountForPercentDiscount(_price, option.applicableBand.DiscountApplied, option.amountAdded, option.ShowExchangeOfValue, option.eovInfo));
                            listPrice = listPrice - discountApplied;
                        }
                    }
                    else {
                        if (option.IsDiscountApplyToListPrice) {
                            var actualListPrice = MainListPrice - financeDiscount;
                            discountApplied = parseFloat(this.discountService.getDiscountAmountForPercentDiscount(actualListPrice, option.applicableBand.DiscountApplied, option.amountAdded, option.ShowExchangeOfValue, option.eovInfo));
                            listPrice = listPrice - discountApplied;
                        }
                        else {
                            discountApplied = parseFloat(this.discountService.getDiscountAmountForPercentDiscount(listPrice, option.applicableBand.DiscountApplied, option.amountAdded, option.ShowExchangeOfValue, option.eovInfo));
                            listPrice = listPrice - discountApplied;

                        }
                    }
                    break;
                case 2: // Product     
                    discountApplied = await this.getDiscountAmountForProductDiscount(option.applicableBand.DiscountApplied, items, rooftrimPrices);
                    listPrice = listPrice - discountApplied;
                    break;
            }

            if (option.IsFinance) {
                financeDiscount = discountApplied;
            }
            // Added condition for AHIADAPT - 82
            if (option.IsIncludeDiscountInPerCalculation) {
                MainListPrice = MainListPrice - discountApplied;
            }

            var selectedOptions = [];
            if (option.ShowExchangeOfValue !== undefined) {
                _.forEach(option.ReferenceData, function (opt) {
                    if (opt.selected) {
                        var value = [];
                        var bAdded = false;
                        var oOptIns = [];

                        //Record the optins selected.
                        if (opt.PromptForContactMethods === true) {
                            if (Array.isArray(opt.contactMethods) === true) {
                                for (var iConIdx = 0; iConIdx < opt.contactMethods.length; iConIdx++) {
                                    if (opt.contactMethods[iConIdx].selected === true) {
                                        oOptIns.push(opt.contactMethods[iConIdx].GroupType);
                                    }
                                }
                            }
                            else if (opt.requiresOptIn && opt.requiresOptIn.length > 0) {
                                oOptIns.push(opt.requiresOptIn);
                            }
                        }

                        if (opt.selectedDiscountOption) {
                            if (opt.selectedDiscountOption.length > 0) {
                                value.push({
                                    'Id': opt.Id,
                                    'OptId': opt.selectedDiscountOption[0].Id,
                                    'OptionName': opt.selectedDiscountOption[0].OptionName,
                                    'IsDefault': opt.selectedDiscountOption[0].IsDefault
                                });
                                bAdded = true;
                            }
                        }

                        if (bAdded === false) {
                            value.push({
                                'Id': opt.Id,
                                'OptionName': opt.PromotionDefault,
                                'IsDefault': true
                            });
                        }

                        selectedOptions.push({
                            'title': opt.Description,
                            'value': value,
                            'optIns': oOptIns
                        });
                    }
                });
            }


            var appDiscVal = option.applicableBand.DiscountApplied;
            if (option.ApplyFullAmount !== true) {
                if (option.amountAdded) {
                    appDiscVal = option.amountAdded;
                }
            }


            discountTrail.push({
                id: option.Id,
                value: discountApplied,
                isFinance: option.IsFinance,
                newPrice: parseFloat(listPrice),
                name: option.Name,
                hide: option.hide,
                priceCategory: option.PriceCategory,
                discountType: option.DiscountType,
                discountApplied: parseFloat(appDiscVal),
                AdditionalEovDiscount: option.AdditionalEovDiscount,
                ExtendedEovDiscount: option.ExtendedEovDiscount,
                selectedOptions: selectedOptions,
                eovInfo: option.eovInfo

            });

            option.lastDiscountApplied = discountApplied;

            //if (!option.hide) {
            //   $scope.showFinalPrice = true;
            //}

            option.isApplied = true;
        });


        var retval = {
            contractPrice: Number(listPrice),
            contractCalcAdminFee: contractCalcAdminFee,
            discountTrailEntries: discountTrail
        };

        return retval;
    }

    public getPropertyPricesForRooftrimRecalculation(allRooftrim): Promise<any> {
        var calculating = defer();
        var workingItOut = [];
        var properties = [];
        _.forEach(allRooftrim, (rooftrim) => {
            _.forEach(rooftrim.designs, (r) => {
                if (properties[r.selected.Id] == null) {
                    var getting = defer();
                    workingItOut.push(getting.promise);
                    this.getDesign(r.selected.Id).then(function (selectedDesign) {
                        properties[r.selected.Id] = selectedDesign.PropertyPrices;
                        getting.resolve();
                    });
                }
            });
        });

        Promise.all(workingItOut).then(function () {
            calculating.resolve(properties);
        });

        return calculating.promise;
    }

    public copyItem(item) {
        var copying = defer();
        var querying = defer();

        if (item.design) { // WD or GD
            this.dataAccess.getLocal('designs', 'Id', IDBKeyRange.only(item.design.Id)).then(function (data) {
                querying.resolve(data[0]);
            });
        } else if (item.designs) { // ROOFTRIM
            this.copyRooftrim(item).then(copying.resolve);
        } else { // WD or GD
            querying.resolve(null);
        }

        querying.promise.then(function (design) {
            // Clear out objects we don't want to store.
            design.Properties = null;
            design.PropertyPrices = null;

            copying.resolve({
                area: item.area,
                colour: item.colour,
                colours: item.colours,
                cols: item.cols,
                design: design,
                height: item.height,
                included: item.included,
                isDesignComplete: false,
                itemPrice: item.itemPrice,
                material: item.material,
                nonDiscountables: item.nonDiscountables,
                priceOnApplication: item.priceOnApplication,
                priceOnApplicationNonDisc: item.priceOnApplicationNonDisc,
                product: item.product,
                properties: { ...item.properties },
                rows: item.rows,
                selectedTemplate: item.selectedTemplate,
                superProduct: item.superProduct,
                template: { ...item.template },
                useWizardProcess: item.useWizardProcess,
                width: item.width
            });
        });

        return copying.promise;
    }

    //Anything marked with Product Price Group of Extra 
           //needs to be removed from comission calculations.
    getNonCommExtras(charges, items) {
        var dExtras = 0;
        var oCharge = null;
        for (var iChgIdx = 0; iChgIdx < charges.length; iChgIdx++) {
            oCharge = charges[iChgIdx];
            if (oCharge.ProductPriceGroup && oCharge.ProductPriceGroup.ShortCode == 'Extra') {
                dExtras += oCharge.Charge;
            }
        }

        var oItem = null;
        var oProp = null;
        for (var iItmIdx = 0; iItmIdx < items.length; iItmIdx++) {
            oItem = items[iItmIdx];
            for (var iPropIdx = 0; iPropIdx < oItem.properties.length; iPropIdx++) {
                oProp = oItem.properties[iPropIdx];
                if (oProp.ProductPriceGroup && oProp.ProductPriceGroup.ShortCode == 'Extra') {
                    dExtras += oProp.price;
                }
            }
        }
        return dExtras;
    }












    //-------------------------private methods------------------------------//
    private copyRooftrim(item) {
        var copying = defer();

        var rooftrimDefers = [];
        _.forEach(item.designs, (d) => {
            var rooftrimLoading = defer();
            rooftrimDefers.push(rooftrimLoading.promise);
            this.dataAccess.getLocal('designs', 'Id', IDBKeyRange.only(d.selected.Id)).then(function (data) {
                data[0].materials = d.selected.materials;
                data[0].extrasGroups = d.selected.extrasGroups;
                data[0].isSelected = d.selected.isSelected;
                rooftrimLoading.resolve({
                    colour: d.colour,
                    material: d.material,
                    selected: data[0]
                });
            });
        });

        Promise.all(rooftrimDefers).then(function (design) {
            copying.resolve({
                designs: design,
                included: item.included,
                isDesignComplete: item.isDesignComplete,
                itemPrice: item.itemPrice,
                nonDiscountables: item.nonDiscountables,
                priceOnApplication: item.priceOnApplication,
                priceOnApplicationNonDisc: item.priceOnApplicationNonDisc,
                product: item.product,
                properties: { ...item.properties },
                size: item.size,
                superProduct: item.superProduct,
                typeOfMeasurement: item.typeOfMeasurement
            });
        });

        return copying.promise;
    }

    private getPriceForSize = function (prices, width, height, material, minWidth, minHeight) {
        // No sizes? Get out of here.
        if (!prices?.Sizes?.length) {
            return null;
        }

        var matches = _.filter(prices.Sizes, function (p) {
            return p.Size.Width >= width && p.Size.Height >= height && p.MaterialId == material;
        });

        if (matches.length) {
            return _.min(matches, function (m) { return m.BaseAmount; });
        }

        // Not found, so we are below the boundaries on the price grid..  Let's try and find if we're off the bottom first
        var minInGrid = _.min(prices.Sizes, function (p) { return p.Size.Width; }).Size.Width;
        if (width < minInGrid) {
            return this.getPriceForSize(prices, minInGrid, height, material, minWidth, minHeight);
        }

        var minHeightInGrid = _.min(prices.Sizes, function (p) { return p.Size.Height; }).Size.Height;
        if (height < minHeightInGrid) {
            return this.getPriceForSize(prices, width, minHeightInGrid, material, minWidth, minHeight);
        }

        // Still not found? Let's see if we're off the top..
        var maxWidthInGrid = _.max(prices.Sizes, function (p) { return p.Size.Width; }).Size.Width;
        if (width > maxWidthInGrid) {
            return this.getPriceForSize(prices, maxWidthInGrid, height, material, minWidth, minHeight);
        }

        var maxHeightInGrid = _.max(prices.Sizes, function (p) { return p.Size.Height; }).Size.Height;
        if (height > maxHeightInGrid) {
            return this.getPriceForSize(prices, width, maxHeightInGrid, material, minWidth, minHeight);
        }

        // This design is not allowed for this size in this material
        return null;
    }

    private getExtrasGroupForDesign = function (properties, group) {
        var g = _.find(properties, function (attr) { return attr.Code == group; });

        if (g) {
            return { id: g.Id, name: g.Name, extras: g.Attributes, AreAttributesMutex: g.AreAttributesMutex, IsAttributeRequired: g.IsAttributeRequired, order: g.Order };
        } else {
            return null;
        }
    }


    private getPricedByProperty(propertyPrices, propertyId, selectedProperties) {
        var pricedByProperty = _.filter(propertyPrices, function (pp) {
            return pp.PropertyId === propertyId && pp.TypeOfPrice === 13;
        });

        return _.find(pricedByProperty, function (pbp) { return _.any(selectedProperties, function (s) { return pbp.PriceByPropertyId === s.Id; }); });
    }



    private findPropertyPriceObjectWithNullCondition(propId, propertyPriceTrail, propertyPrices) {
        var result = null;
        if (propertyPriceTrail && propertyPriceTrail.length) {
            _.forEach(propertyPriceTrail, function (prop) {
                if (result == null) {
                    result = _.find(propertyPrices, function (price) { return price.PropertyId === prop && (price.Condition == null || price.Condition === ''); });
                }
            });
        } else {
            result = _.find(propertyPrices, function (price) { return price.PropertyId === propId && (price.Condition == null || price.Condition === ''); });
        }

        return result;
    }

    private findPropertyPriceObjectWithCondition(propId, condition, propertyPriceTrail, propertyPrices) {
        var result = [];
        if (propertyPriceTrail && propertyPriceTrail.length) {
            _.forEach(propertyPriceTrail, function (prop) {
                if (!result.length) {
                    result = _.filter(propertyPrices, function (price) { return price.PropertyId === prop && condition <= price.Condition; });
                }
            });
        } else {
            result = _.filter(propertyPrices, function (price) { return price.PropertyId === propId && condition <= price.Condition; });
        }

        return result;
    }

    private findPropertyPriceObject(propertyPrices, propertyId, selectedProperties, typeOfPrice) {
        // If ther's a price by property match we'll always use it.
        var p = this.getPricedByProperty(propertyPrices, propertyId, selectedProperties);

        if (p == null) {
            if (typeOfPrice != null) {
                p = _.find(propertyPrices, function (pP) { return pP.PropertyId === propertyId && pP.TypeOfPrice === typeOfPrice; });
            } else {
                p = _.find(propertyPrices, function (pP) { return pP.PropertyId === propertyId; });
            }
        }

        return p;
    }

    private findPropertyPriceObjectFromTrail(propId, propertyPriceTrail, typeOfPrice, selectedProperties, propertyPrices) {
        // Get the price object off of the design property prices
        var p = null;

        if (propertyPriceTrail && propertyPriceTrail.length) {
            _.forEach(propertyPriceTrail, (propertyId) => {
                if (p != null) {
                    return;
                }

                // If ther's a price by property match we'll always use it.
                p = this.findPropertyPriceObject(propertyPrices, propertyId, selectedProperties, typeOfPrice);
            });
        } else {
            p = this.findPropertyPriceObject(propertyPrices, propId, selectedProperties, typeOfPrice);
        }

        return p;
    }

    private getPropertyPrice = (propertyPrices, prop, basePrice, optionalPercent, width, selectedProperties) => {

        var p = this.findPropertyPriceObjectFromTrail(prop.Id, prop.parents, prop.typeOfPrice, selectedProperties, propertyPrices);

        // Comment below code for task no 6830 required to put selection option
        ////PROT-6666 Improve mandatory field selection functionality for options and sub options
        //if (p && p.TypeOfPrice == 9) // Window Doors have issue to set Quantity 1
        //{

        //}
        //else {
        //    // Always ensure we have a quantity
        //    if (!prop.quantity) {
        //        prop.quantity = 1;
        //    }
        //}
        // Added ProductPriceGroup for 7012 Mandatory fields apply markup factor as discussed on 13-Jan-2016
        var _ProductPriceGroup;
        if (p != null || p !== undefined) {
            _ProductPriceGroup = p.ProductPriceGroup;
        }
        var result = {
            property: prop,
            price: 0,
            discountable: p != null ? p.IsDiscountable : true,
            ProductPriceGroup: _ProductPriceGroup
        };

        if (p != null) {
            switch (p.TypeOfPrice) {
                case 2: // Percentage
                    result.price = basePrice * (p.Amount / 100);
                    break;

                case 3: // Fixed
                    result.price = p.Amount;
                    break;
                case 4: // Per Pane
                case 12: // by Door leaf
                case 5: // Per Panel
                case 9: // By Metre
                case 10: // By Storey
                case 6: // AddQuantity
                    result.price = (prop.quantity ? prop.quantity : 0) * p.Amount;
                    break;
                case 7: // Area
                    var prices = this.findPropertyPriceObjectWithCondition(prop.Id, prop.quantity, prop.parents, propertyPrices);

                    if (prices.length) {
                        var areaPrice = _.min(prices, function (p) { return p.Condition; });
                        result.price = areaPrice.Amount;
                        prop.requiresPriceOnApplication = false;
                    } else {
                        result.price = 0;
                        prop.requiresPriceOnApplication = true;
                    }

                    break;
                case 8: // Size
                    break;
                case 11: // By width
                    var thesePrices = this.findPropertyPriceObjectWithCondition(prop.Id, width, prop.parents, propertyPrices);

                    var widthPrice;

                    if (thesePrices.length) {
                        widthPrice = _.min(thesePrices, function (p) { return p.Condition; });
                    } else {
                        widthPrice = this.findPropertyPriceObjectWithNullCondition(prop.Id, prop.parents, propertyPrices);
                    }

                    if (!widthPrice) {
                        result.price = 0;
                    } else {
                        result.price = widthPrice.Amount;
                    }
                    break;
                case 13: // By property
                    result.price = p.Amount; // We've already found the amount 
                    break;
                default:
                    break;
            }
        } else if (optionalPercent != null) {
            result.price = basePrice * (optionalPercent / 100);
        }
        else if (prop.Style && prop.Style.Name === "Price on Application") {
            result.price = prop.Amount > 0 ? prop.Amount:0;
            result.discountable = prop.isDiscountable;
        }

        // Put the cost on the property so that we can get at it later.
        prop.price = result.price;
        prop.isDiscountable = result.discountable;
        return result;
    };

    private getTotalDiscountables(items) {
        // only get included.
        items = _.filter(items, function (i) { return i.included; });
        return _.reduce(items, function (price, item) {
            return price + item.itemPrice - item.nonDiscountables;
        }, 0);
    }


    private addParentToExtras(parents, extras) {
        var that = this;
        _.forEach(extras, function (extra) {
            var clonedParents = parents.slice(0); // Slicing creates a copy so that any unshifting doesn't affect other attributes
            clonedParents.unshift(extra.Id);
            extra.parents = clonedParents; // Add itself to the trail. This means we only need to look at this object when pricing.

            if (extra.Attributes) {
                extra.Attributes = that.addParentToExtras(clonedParents, extra.Attributes);
            }
        });

        return extras;
    }

    private async priceMandatoryProperties(properties, propertyPrices, price, width) {
        var mandatoryProps = _.filter(properties, function (p) { return p.Style.Code === 'MANDATORY'; });
        for (var idx in mandatoryProps) {
            // Check if the top level mandatory property has a price
            var prop = mandatoryProps[idx];
            if (prop.IsAttributeIncludedInBasePrice) {
                // Added  for 7012 Mandatory fields apply markup factor as discussed on 13-Jan-2016
                var propprice = this.getPropertyPrice(propertyPrices, prop, price.basePrice, null, width, properties);
                var marksUpFactor = 1;

                if (propprice.ProductPriceGroup !== undefined && propprice.ProductPriceGroup !== null) {
                    marksUpFactor = await this.getRegionMarkupFactor(propprice.ProductPriceGroup);
                }

                price.basePrice += Math.ceil(propprice.price * marksUpFactor);
                //price.basePrice += getPropertyPrice(propertyPrices, prop, price.basePrice, null, width, properties).price;
            } else {
                price.properties.push(this.getPropertyPrice(propertyPrices, prop, price.basePrice, null, width, properties));
            }

            _.forEach(prop.Attributes, (p) => {
                // Add this to the base price.
                if (p.IsAttributeIncludedInBasePrice) {
                    var propPrice = this.getPropertyPrice(propertyPrices, p, price.basePrice, null, width, properties);
                    price.basePrice += propPrice.price;
                } else {
                    price.properties.push(this.getPropertyPrice(propertyPrices, p, price.basePrice, null, width, properties));
                }
            });
        }

        var otherProperties = _.filter(mandatoryProps, function (p) { return !p.IsAttributeIncludedInBasePrice; });
        // Filter properties on not included - price these
        _.forEach(otherProperties, (p) => {
            if (p.included) {
                price.properties.push(this.getPropertyPrice(propertyPrices, p, price.basePrice, null, width, properties));
            }
        });
    }

    private async getPriceForWindow(design,
        width,
        height,
        area,
        material,
        color,
        colors,
        template,
        selectedProperties,
        allProperties,
        propertyPrices) {

        //todo: added for testing only need to remove later
        colors = [];
        width = parseInt(width, 10);
        height = parseInt(height, 10);

        var querying = defer();

        var pricing = defer();

        var that = this;
        var baseprice = { properties: [], isDiscountable: design.IsDiscountable, basePrice: null };

        if (design.TypeOfPrice === 3) { // Fixed price
            var FixedmarksUpFactor = 1;
            if (design.Product !== undefined && design.Product !== null) {
                if (design.Product.ProductPriceGroup !== undefined && design.Product.ProductPriceGroup !== null) {
                    FixedmarksUpFactor = await that.getRegionMarkupFactor(design.Product.ProductPriceGroup);
                }
            }
            baseprice.basePrice = Math.ceil(design.Amount * FixedmarksUpFactor);
            pricing.resolve(baseprice);
        } else if (design.TypeOfPrice === 7) { // Priced by area
            var AreamarksUpFactor = 1;
            if (design.Product !== undefined && design.Product !== null) {
                if (design.Product.ProductPriceGroup !== undefined && design.Product.ProductPriceGroup !== null) {
                    AreamarksUpFactor = await that.getRegionMarkupFactor(design.Product.ProductPriceGroup);
                }
            }
            baseprice.basePrice = Math.ceil(design.Amount * area * AreamarksUpFactor);
            pricing.resolve(baseprice);
        } else {
            this.dataAccess.getLocal('prices', design.Id).then(async (designs) => {
                var minwidth;
                var minheight;
                var data = that.getPriceForSize(designs[0], width, height, material, minwidth, minheight);
                //Added for 7012
                var marksUpFactor = 1;
                if (design.Product !== undefined && design.Product !== null) {
                    if (design.Product.ProductPriceGroup !== undefined && design.Product.ProductPriceGroup !== null) {
                        marksUpFactor = await this.getRegionMarkupFactor(design.Product.ProductPriceGroup);
                    }
                }

                baseprice.basePrice = Math.ceil(data.BaseAmount * marksUpFactor);
                pricing.resolve(baseprice);
            });

        }

        pricing.promise.then(async (price) => {
            if (template && template.length) {
                // We need to add the cost of all the panes in here..

                _.forEach(template, (row) => {
                    _.forEach(row, (column) => {
                        if (!column.hide) {
                            if (column.PropertyTemplate != null) {
                                var templatePrice = this.getPropertyPrice(propertyPrices, column.PropertyTemplate, price.basePrice, null, width, selectedProperties);
                                price.basePrice += templatePrice.price;
                            }
                        }
                    });
                });
            }

            await this.priceMandatoryProperties(allProperties, propertyPrices, price, width);

            if (selectedProperties.length) {
                // Filter properties on included in base price - price these ones
                var propertiesToIncludeInBase = _.filter(selectedProperties, function (p) { return p.IsAttributeIncludedInBasePrice; });
                _.forEach(propertiesToIncludeInBase, (p) => {
                    if (p.included) {
                        var includedInBase = this.getPropertyPrice(propertyPrices, p, price.basePrice, null, width, selectedProperties);
                        price.basePrice += includedInBase.price;
                    }
                });
            }


            if (color && (!colors || !colors.length)) {
                price.properties.push(this.getPropertyPrice(propertyPrices, color, price.basePrice, null, width, selectedProperties));
                //Non standard colour garage doors.
                if (color == 'NONSTANDARD') {
                    var colProp: any = this.getPropertyPrice(propertyPrices, color, price.basePrice, design.Colours[0].NonStandardPercent, width, null);
                    colProp.Class = "COLOUR";
                    colProp.SOWClass = "COLOUR";
                    colProp.Description = "Non Standard Colour";
                    colProp.PropertyId = "NONSTDCOLOUR";
                    price.properties.push(colProp);
                }

            }

            if (colors && colors.length) {
                var allcolorsMatch = true;
                for (var i = 2; i < 5; i++) {
                    if (colors[i] != colors[1]) {
                        allcolorsMatch = false;
                    }
                }

                // colors..
                // 0 - All
                // 1 - Inside Frame
                // 2 - Inside Sash
                // 3 - Outside Frame
                // 4 - Outside Sash

                if (allcolorsMatch) {
                    // It's just a fixed colour
                    price.properties.push(this.getPropertyPrice(propertyPrices, colors[1], price.basePrice, 0, width, selectedProperties)); // Optional percent is zero if everything matches. Needed so that Non-standard passes through ok.
                } else if (colors[1].Id != colors[3].Id && (colors[1].Id != colors[2].Id || colors[3].Id != colors[4].Id)) {
                    // Dual, because 1 and 3 are different. Split because 1 is different to 2 or 3 is different to 4.
                    var splitAndDual = design.Colours[0].DualPercent + design.Colours[0].SplitPercent;
                    price.properties.push(this.getPropertyPrice(propertyPrices, colors, price.basePrice, splitAndDual, width, selectedProperties));
                } else if (colors[1].Id != colors[3].Id) {
                    // Because 1 and 3 are different, we are dual. 
                    price.properties.push(this.getPropertyPrice(propertyPrices, colors, price.basePrice, design.Colours[0].DualPercent, width, selectedProperties));
                } else if (colors[1].Id != colors[2].Id || colors[3].Id != colors[4].Id) {
                    // If the inside is different to the outside, we are split.
                    price.properties.push(this.getPropertyPrice(propertyPrices, colors, price.basePrice, design.Colours[0].SplitPercent, width, selectedProperties));
                }

                var nonStandard = _.find(colors, function (c) { return c === 'NONSTANDARD'; });

                if (nonStandard) {
                    price.properties.push(this.getPropertyPrice(propertyPrices, nonStandard, price.basePrice, design.Colours[0].NonStandardPercent, width, null));
                }
            }

            if (selectedProperties.length) {
                var otherProperties = _.filter(selectedProperties, function (p) { return !p.IsAttributeIncludedInBasePrice; });
                // Filter properties on not included - price these
                _.forEach(otherProperties, (p) => {
                    if (p.included) {
                        price.properties.push(this.getPropertyPrice(propertyPrices, p, price.basePrice, null, width, selectedProperties));
                    }
                });
            }
            _.forEach(price.properties, (p) => {
                var pricedByProperty = _.filter(propertyPrices, function (pp) {
                    return pp.PropertyId === p.property.Id;
                });
                if (pricedByProperty !== undefined && pricedByProperty !== null) {
                    if (pricedByProperty.length > 0) {
                        p.property.ProductPriceGroup = pricedByProperty[0].ProductPriceGroup;
                    }
                }

            });


            querying.resolve(price);

        });

        return querying.promise;
    }
    //Added for 7012
    private async getRegionMarkupFactor(ProductPriceGroup) {
        var marksUpFactor = 1;
        var user = await this.dataAccess.getLocal('UserAccount');
        if (user.regionmarkups !== undefined && user.regionmarkups !== null) {
            if (user.regionmarkups.length > 0) {
                var _regionmarkups = JSON.parse(user.regionmarkups);
                var Markup = _.find(_regionmarkups, function (m) { return m.Group === ProductPriceGroup.ShortCode; });
                if (Markup !== undefined && Markup !== null) {
                    marksUpFactor = Markup.MarkUp;
                }
            }
        }
        return marksUpFactor;
    }





    private searchForChildSelection(propertiesGroup, selectedProperties) {

        var that = this;
        var ids = _.map(propertiesGroup, function (p) { return p.Id; });

        var isMatch = _.find(selectedProperties, function (p) { return _.find(ids, function (id) { return p.Id === id; }); });

        if (isMatch) {
            return true;
        }

        var propsWithChildren = _.filter(propertiesGroup, function (p) { return p.Attributes && p.Attributes.length; });
        var attrs = _.flatten(_.map(propsWithChildren, function (p) { return p.Attributes; }));

        if (attrs.length) {
            return that.searchForChildSelection(attrs, selectedProperties);
        } else {
            return false;
        }
    }
}