import { sessionService } from './sessionService'

export class logisticChargeService {

    public getLogisticCharge(postcode, orderValue) {
        if (!postcode) {
            return 0;
        }

        var logisticArea = this.getLogisticArea(postcode);
        if (logisticArea == null) {
            return 0;
        }

        if (isNaN(orderValue) === true) {
            return 0;
        }

        for (var iCharge = 0; iCharge < logisticArea.charges.length; iCharge++) {

            if (orderValue <= logisticArea.charges[iCharge].OrderValue) {
                return logisticArea.charges[iCharge].Charge;
            }

        }

        return 0;

    }

    private getLogisticArea(postcode) {

        var logCharges = sessionService.getLogisticsCharges();
        if (logCharges) {
            logCharges = JSON.parse(logCharges);

            var sPostcode = postcode.toLowerCase();
            var sPattern = '';
            for (var iArea = 0; iArea < logCharges.logisticAreas.length; iArea++) {

                for (var iPattern = 0; iPattern < logCharges.logisticAreas[iArea].postcodePatterns.length; iPattern++) {

                    sPattern = logCharges.logisticAreas[iArea].postcodePatterns[iPattern].toLowerCase();

                    if (sPattern.endsWith("*") === true) {
                        sPattern = sPattern.replace("*", "");
                        if (sPostcode.startsWith(sPattern) === true) {
                            return logCharges.logisticAreas[iArea];
                        }
                    }
                    else {
                        if (sPattern == sPostcode) {
                            return logCharges.logisticAreas[iArea];
                        }
                    }

                }

            }

        }

        return null;

    }
}