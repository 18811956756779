import { BeforeSaveMode } from "./enums";
import { LocalDbStore } from "./localDbStore";

export class dataOptimisationService {
    private _localDbStore = new LocalDbStore();

    //------        OPP         -----//
    public beforeGetOpportunity(opportunity) {
        //remove big items to not clog up the redux store/UI thread with unused data
        delete opportunity.unsoldVersions;

        //items will be trimmed by lightItem model definition when deserializing

        return opportunity;

    }

    public async beforeSaveOpportunity(opportunity, modes: BeforeSaveMode[] = null): Promise<any>  {
        //return big items so the data is not lost
        var savedOpp = await this._localDbStore.get("opportunities", opportunity.id);

        if (!savedOpp) {
            return opportunity;
        }

        if (!modes || !modes.some(m => m === BeforeSaveMode.DontAttachUnsoldVersions)) {
            opportunity.unsoldVersions = savedOpp.unsoldVersions;
        }

        if (!modes || !modes.some(m => m === BeforeSaveMode.DontAttachItems)) {
            opportunity.order.items = savedOpp.order.items;
        }

        return opportunity;

    }

    //------        DESIGN         -----//
    public beforeGetDesign(design) {
        design.Sizes = null;
        return design;
    }
}