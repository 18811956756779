import { defer } from './dataAccessService';
import SignaturePad from "signature_pad";

export class signatureService {
    private capturedSignatureCacheName = "signature";
    constructor() { };

    public async setupSignature(){
        console.log("setting up canvas");
        let canvas: any = document.getElementById('signature-pad');
        let signaturePad = new SignaturePad(<HTMLCanvasElement>canvas, {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            penColor: 'rgb(0, 0, 0)'
        });
        signaturePad.addEventListener("beginStroke", () => {
                console.log("Signature started");
            }, { once: true });

    }

    public async clearSignature() {
        let canvas: any = document.getElementById('signature-pad');
        let signaturePad = new SignaturePad(<HTMLCanvasElement>canvas);
        console.log("clear signature");
        signaturePad.clear();
    }

    public async saveSignature() {
        const canvas: any = document.getElementById('signature-pad');
        const signaturePad = new SignaturePad(<HTMLCanvasElement>canvas);
        console.log("save signature");
        const dataUrl = signaturePad.toDataURL("image/jpeg");
        const fileName = this.generateFileNameWithoutExt() + ".jpg";

        this.cacheFileAsync(dataUrl, fileName, this.capturedSignatureCacheName);
        return dataUrl;
    }

    public async cacheFileAsync(url: string, fileName: string, cacheName: string) {
        let emptyPromise = defer();
        const cache = await caches.open(cacheName);
        fetch(url).then((r) => {
            cache.put(fileName, r).then((s) => {
                emptyPromise.resolve(fileName);
                console.log("captured signature placed in cache: " + fileName);
            }).catch((e) => {
                emptyPromise.reject();
            })
        })
    }

    private generateFileNameWithoutExt(): string {
        const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
        return 'signature-photos/' + guid;
    }

}

