import { PriceListService } from "./priceListService";

export class orderValueService {

    private priceListService = new PriceListService();
    public getGrossTotals (grossOrderValue, items, charges, adminFee, productCode, cpSelected, discounts, noEmailCharge, logisticCharge) {
        // Total of decorative product.
        var totalSGO = 0;
        var totalExtras = 0;
        var totalAdditionalWork = 0;
        var totalProductPrice = 0;

        // Total of decorative product.
        totalSGO = Number(this.priceListService.getTotalPriceForExtrasClass(items, 'DECORATIVE'));

        // Total extras = Scaffolding + Access Charges
        // var chargesAmount = _.reduce(charges, function (counter, charge) { return counter + charge.Charge; }, 0);
        totalExtras = Number(this.priceListService.getTotalPriceForExtrasClass(items, 'SCAFFOLDING'));

        //Fetch extras value for items/charges that should not be included in commission calculation.
        var dNonCommExtras = this.priceListService.getNonCommExtras(charges, items);
        totalExtras += dNonCommExtras;

        // Total additional work = Any other non discountables which don't fall into the above two categories
        totalAdditionalWork = Number(this.priceListService.getTotalNonDiscountables(items, charges)) - totalSGO - totalExtras;

        if (discounts) {

            var iDiscLen = discounts.length;
            var iAppliedOptions = 0;
            for (var iDisc = 0; iDisc < iDiscLen; iDisc++) {
                if (discounts[iDisc].appliedOptions) {
                    iAppliedOptions = discounts[iDisc].appliedOptions.length;
                    for (var iOption = 0; iOption < iAppliedOptions; iOption++) {
                        if (discounts[iDisc].appliedOptions[iOption].Name == 'Building Works Discount') {
                            totalAdditionalWork -= discounts[iDisc].appliedOptions[iOption].lastDiscountApplied;
                        }
                    }
                }
            }
        }

        return {
            totalProductPrice: totalProductPrice,
            totalExtrasPrice: totalExtras,
            totalAdditionalWork: totalAdditionalWork,
            totalSGO: totalSGO
        };
    }

}