import { AnglianData, dataAccessService, defer } from "./dataAccessService";
import _, { map } from 'underscore';

export class winStorage {

    public saveImage (filename, data, folder):Promise<any> {

        var createFile = (writeTo) =>  {
            if (!filename) {
                filename = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) =>  { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
            }

            writeTo.createFileAsync(filename + ".png", window.localStorage.CreationCollisionOption.replaceExisting).then((file) => {
                file.openTransactedWriteAsync().then(
                    function (transaction) {
                        var dataWriter = new window.localStorage.Streams.DataWriter(transaction.stream);
                        dataWriter.writeBytes(new Uint8Array(data));
                        dataWriter.storeAsync().then((size) => {
                            transaction.stream.size = size; // reset stream size to override the file
                            transaction.commitAsync().done(() =>  {
                                // Close stream
                                transaction.close();
                            });
                        });
                    },
                    // Handle errors with an error function
                    (error) =>  {
                        // Proccess errors
                    });

                saving.resolve('ms-appdata:///local/' + (folder ? folder + '/' : '') + file.name);
            }, function () {
                saving.reject();
            });
        };

        var saving = defer();

        window.localStorage.ApplicationData.current.localFolder
            .createFolderAsync(folder, window.localStorage.CreationCollisionOption.openIfExists).then(createFile);


        return saving.promise;
    }
    public file: file = new file;
    public folder: folder = new folder;

}
class file {
    
    public deleteAsync(fileName) {
        var dfd = defer();

        window.localStorage.ApplicationData.current
            .localFolder.getFileAsync(fileName).then((file) => {
                file.deleteAsync().then(dfd.resolve, dfd.reject);
            }).then(dfd.resolve, dfd.resolve);

        return dfd.promise;
    }
    public writeText(folderName, filename, text) {
        var writeTextQueues = {};
        var queue = writeTextQueues[folderName + '/' + filename];

        if (!queue) {
            queue = writeTextQueues[folderName + '/' + filename] = [];

            var startWritingInterval = (file) =>  {
                setInterval(() => {
                    queue = writeTextQueues[folderName + '/' + filename];

                    if (queue.length > 0) {
                        writeTextQueues[folderName + '/' + filename] = [];
                        var fullText = queue.join("\r\n") + "\r\n";
                        window.localStorage.FileIO.appendTextAsync(file,
                            fullText);
                    }
                }, 1000);
            };

            window.localStorage.ApplicationData.current.localFolder
                .createFolderAsync('Logs',
                    window.localStorage.CreationCollisionOption.openIfExists)
                .then((folder) => {
                    folder.createFileAsync(filename,
                        window.localStorage.CreationCollisionOption.openIfExists)
                        .done(startWritingInterval);
                });
        }

        queue.push(text);
    }
}
class folder {
        private deleteAsync(folderName) {
            var dfd = defer();

            window.localStorage.ApplicationData.current.localFolder
                .getFolderAsync(folderName).then((folder) =>  {
                    folder.deleteAsync().then(dfd.resolve, dfd.reject);
                }).then(dfd.resolve, dfd.resolve);

            return dfd.promise;
        }
        private purge(folderName, maxAgeHours) {
            window.localStorage.ApplicationData.current.localFolder
                .getFolderAsync(folderName).then( (folder) =>  {
                    folder.getFilesAsync().then( (files) =>  {
                        _.forEach(files, (file) => {
                            var hours = (Date.now() - file.dateCreated) / 3600000;

                            if (hours > maxAgeHours) {
                                file.deleteAsync();
                            }
                        });
                    });
                });
        }
        private compress(folderName, user) {
            var deferred = defer();

            function addFileToZipAsync(filePath, zip) {
                return window.localStorage.StorageFile.getFileFromPathAsync(filePath)
                    .then(function (file) {
                        return getFileAsUint8Array(file)
                            .then((fileContents) =>  {
                                zip.file(file.displayName, fileContents);
                            });
                    });
            }

            function getFileAsUint8Array(file) {
                return window.localStorage.FileIO.readBufferAsync(file)
                    .then((buffer) => {
                        //Read the file into a byte array
                        var fileContents = new Uint8Array(buffer.length);
                        var dataReader = window.localStorage.Streams.DataReader.fromBuffer(buffer);
                        dataReader.readBytes(fileContents);
                        dataReader.close();

                        return fileContents;
                    });
            }

            //window.localStorage.ApplicationData.current.localFolder
            //    .getFolderAsync(folderName).then( (folder) => {
            //        folder.getFilesAsync().then( (files) =>  {
            //            var filePaths = _.map(files,  (file)  => {
            //                return file.path;
            //            });

            //            var localFolder = window.localStorage.ApplicationData.current.localFolder;

            //            var date = dateFilter(new Date(), 'yyyy-MM-dd');
            //            var zipFileName = user + "_" + date + ".zip";

            //            localFolder.createFileAsync(zipFileName,
            //                window.localStorage.CreationCollisionOption.replaceExisting)
            //                .then( (file) =>{
            //                    var zip = new JSZip();

            //                    var promises = [];
            //                    _.each(filePaths,  (path)=>{
            //                        promises.push(addFileToZipAsync(path, zip));
            //                    });

            //                    _.all(promises).then( ()=> {
            //                        var contentBytes = zip.generate(
            //                            {
            //                                compression: 'DEFLATE',
            //                                type: 'uint8array'
            //                            });

            //                        window.localStorage.FileIO
            //                            .writeBytesAsync(file, contentBytes)
            //                            .then( ()=> {
            //                                deferred.resolve(file);
            //                            });
            //                    });
            //                });
            //        });
            //    });

            return deferred.promise;
        }
}
