import { PriceListService } from './priceListService'
import {  LocalDbStore } from './localDbStore'
import { defer, dataAccessService } from './dataAccessService';
import interact from 'interactjs';
import * as Bowser from 'bowser';
export class BrowserApi {
    private _dac = new dataAccessService();

    private readonly maxServiceWorkerRetryAttempts = 4;
    private retryRetries = 0;
    private maxRetryRetries = 1;
    private chromeBrowser = "chrome";
    private edgeBrowser = "edge";

    public showPrompt(message: string): string {
        return prompt(message, 'Type anything here');
    }

    public consoleError(message: string) {
        console.error(message);
    }

    public consoleLog(message: string) {
        console.log(message);
    }


    public async getQuotas(message: string) {
        // this access the allowed storeage space and currently used space, 
        // will help to give warnings for to much content.
        console.log(message);

        const quota = await navigator.storage.estimate();
        const totalSpace = quota.quota;
        const usedSpace = quota.usage;

        return { "quota": totalSpace, "usage": usedSpace };
    }

    public refreshPage() {
        location.reload();
    }

    public async preloadData(event) {
        var promise = defer();

        console.log("preloading using event: ", event);

        await navigator.serviceWorker.ready;
        if (navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage(event);
            promise.resolve(true);
        }
        else {
            var counter = 0;
            var interval = window.setInterval(async () => {
                if (counter > this.maxServiceWorkerRetryAttempts) {
                    console.log("Service worker failed to start");
                    window.clearInterval(interval);

                    await this.attemptToHandleServiceWorkerFailure(event);
                }

                counter++
                console.log("service worker not ready, retrying in 1s. Attempt #" + counter);
                if (navigator.serviceWorker.controller) {
                    navigator.serviceWorker.controller.postMessage(event);
                    window.clearInterval(interval);
                    promise.resolve(true);

                }
            });
        }


        await promise;
    }

    public async preloadData_byOppId(event) {
        var opp = await this._dac.getOpportunity(event.oppId, false);
        event.opp = opp;

        return this.preloadData(event);
    }

    public getVersion(): string {
        var build = 1;
        var major = 1;
        var minor = 0;
        var revision = 0;

        return major + '.' + minor + '.' + build + '.' + revision;
    }

    public async testServiceWorker(event) {
        console.log("browserAPI.testSErviceWorker hit");

        await navigator.serviceWorker.ready;
        navigator.serviceWorker.controller.postMessage(event);

    }

    private readonly allowedBrowsersLowercase = [
        "chrome",
        "safari",
        "edge"
    ];
    public isBrowserOk() {
        var browserInfo = Bowser.getParser(window.navigator.userAgent);
        var browserName = browserInfo.getBrowserName();
        return this.allowedBrowsersLowercase.some(ab => browserName.toLowerCase().includes(ab));
    }

    public isInstalledAsPWA() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }


    public setZoomLevel(zoomPercentage: number) {
        if (zoomPercentage === 0) {
            console.error("Cannot set zoom level of 0 - this would cause an infinite scale factor");
            return; 
        }

        var scaleFactor = zoomPercentage / 100;

        var bodyElement = document.getElementById("body"); // please note, this is grabbing <div id="body"...> NOT <body>
        var headerElement = document.getElementById("header");

        (<any>bodyElement.style).zoom = scaleFactor;
        (<any>headerElement.style).zoom = scaleFactor;
    }

    public getSessionStorageItem(key) {
        var item = sessionStorage.getItem(key);;
        return item ? JSON.parse(item) : null;
    }

    ///------- DRAG N DROP ----------///
    public async setupDropzone(forHtmlId, classesThatCanBeDroppedCsv, dotNetCallback_MethodName) {
        if (!this.activatedDropzones.includes(forHtmlId)) {
            this.activatedDropzones.push(forHtmlId);
        }

        interact(forHtmlId)
            .dropzone({
                ondrop: async (event) => {
                    if (this.dotNetRef && dotNetCallback_MethodName) {
                        var itemIndex = event.relatedTarget.getAttribute("priority-index");
                        await this.dotNetRef.invokeMethodAsync(dotNetCallback_MethodName, parseInt(itemIndex));
                    }
                },
                accept: classesThatCanBeDroppedCsv,
                overlap: 0.25
            })
            .on('dropactivate', function (event) {
                event.target.classList.add('drop-activated')
            })
    }

    public setupDraggable(className) {
        const position = { x: 0, y: 0 }

        interact(className)
            .draggable({
                listeners: {
                    start(event) {
                        let target = event.target;
                        target.style.position = "fixed";
                        target.style.left = event.clientX0.toString() + "px";
                        target.style.top = event.clientY0.toString() + "px";
                    },
                    move(event) {
                        position.x += event.dx
                        position.y += event.dy

                        event.target.style.transform =
                            `translate(${position.x}px, ${position.y}px)`
                    },
                    end(event) {
                        position.x = 0;
                        position.y = 0;
                        event.target.style.transform =
                            'translate(0px, 0px)'
                        event.target.style.position = "static";
                    }
                }
        })
    }

    public clearDropzones() {
        this.activatedDropzones.forEach(htmlId => interact(htmlId).unset());
        this.activatedDropzones = [];
    }


    dotNetRef;
    activatedDropzones: string[] = [];

    public setDotNetRef(dnr) {
        this.dotNetRef = dnr;
    }


    private async attemptToHandleServiceWorkerFailure(event) {
        this.retryRetries++;

        if (this.retryRetries >= this.maxRetryRetries) {
            //else, refresh will probs sort it out
            location.reload();
        }

        //try re-registering the service worker and trying again
        await navigator.serviceWorker.register('service-worker.js');
        await this.preloadData(event);
    }



}







