
import _ from 'underscore';

export class DesignService {

    public setupCustomWindow(panelsHigh, panelsWide, template, slotOptions) {

        if (!template) {
            template = [];
        }
        var Slot = function (row, col) {
            return {
                PropertyTemplate: slotOptions && slotOptions.length > 0 ? slotOptions[0] : null, // Fixed pane
                TopLeft: { Column: col + 1, Row: row + 1 },
                BottomRight: { Column: col + 1, Row: row + 1 },
                rowspan: 1,
                colspan: 1,
                width: '150px'
            };
        };

        var newTemplate = [];
        for (var row = 0; row < panelsHigh; row++) {
            var columns = [];
            for (var col = 0; col < panelsWide; col++) {
                if (template[row] && template[row][col]) {
                    columns.push(template[row][col]);
                } else {
                    columns.push(Slot(row, col));
                }
            }

            newTemplate.push(columns);
        }

        return newTemplate;
    }

    public getTemplateOptions(properties) {
        return _.find(properties, function (p) { return p.Style.Name === 'Template'; });
    }

    public Slot(row, col, slotOptions) {
        return {
            PropertyTemplate: slotOptions ? slotOptions[0] : null, // Fixed pane
            TopLeft: { Column: col + 1, Row: row + 1 },
            BottomRight: { Column: col + 1, Row: row + 1 },
            rowspan: 1,
            colspan: 1,
            width: '150px'
        };
    }

    public isSelectedPropertyInSameGroupAsProperty(selectedProperty, property) {

        if (property.parents && property.parents.length > 1) {
            if (selectedProperty.parents && selectedProperty.parents.length > 1) {
                if (property.parents[1] === selectedProperty.parents[1]) {
                    if (property.Id != selectedProperty.Id) {
                        return true;

                    }
                }
            }
        }

        return false;
    }

    public applyMutexRules(property, selectedProperties) {
        var that = this;
        if (!property.parents || property.parents.length <= 1) {
            return selectedProperties;
        }

        var properties = selectedProperties;
        _.forEach(selectedProperties, function (p) {
            if (that.isSelectedPropertyInSameGroupAsProperty(property, p)) {
                p.isViewing = false;
                p.isSelected = false;
                properties = _.without(selectedProperties, p);
                properties.forEach(function (item) {
                    if (item.showMetreInput === true) {
                        if (that.isSelectedPropertyInSameGroupAsProperty(property, item)) {
                            item.quantity = undefined;
                        }
                    }
                });

            }
        });
        return properties;
    }

    public markOtherPropertiesInThisGroupAsNotViewed(property, selectedProperties) {
        var that = this;
        _.forEach(selectedProperties, function (p) {
            if (that.isSelectedPropertyInSameGroupAsProperty(property, p)) {
                p.isViewing = false;
            }
        });
        return selectedProperties;
    }

    public configureProperties(property, prices, width, height, size) {
        property.included = true;
        property.isSelected = true;

        var propertyPrice;

        if (property.typeOfPrice != null) {
            propertyPrice = _.find(prices, function (prop) { return prop.PropertyId === property.Id && prop.TypeOfPrice === property.typeOfPrice; });
        } else {
            propertyPrice = _.find(prices, function (prop) { return prop.PropertyId === property.Id; });
        }

        if (propertyPrice && propertyPrice.TypeOfPrice == 4) { // Priced by Pane
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                //property.quantity = 1;
                property.quantity = "";
            }
            property.showPanesInput = true;
        } else if (propertyPrice && propertyPrice.TypeOfPrice == 5) { // Price by Panel
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                //property.quantity = 1;
                property.quantity = "";
            }
            property.showPanelsInput = true;
        } else if (propertyPrice && propertyPrice.TypeOfPrice == 10) { // Price by Storey
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                //property.quantity = 1;
                property.quantity = "";
            }
            property.showStoreysInput = true;
        }
        else if (propertyPrice && propertyPrice.TypeOfPrice == 7) { // Price by Area
            property.typeOfPrice = 7;
            if (!property.quantity) {
                property.quantity = +((width * height) / 1000000).toFixed(2); // Area in sq metres. (+ converts the string to a number)
            }
            property.showAreaInput = true;
        } else if (propertyPrice && propertyPrice.TypeOfPrice == 6) { // Price by Quantity
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                //property.quantity = 1;
                property.quantity = "";
            }
            property.showQuantityInput = true;
        } else if (propertyPrice && propertyPrice.TypeOfPrice == 12) { // Price by Door leaves
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                //property.quantity = 1;
                property.quantity = "";
            }
            property.showLeavesInput = true;
        } else if (propertyPrice && propertyPrice.TypeOfPrice == 9) { // Price by Metre
            property.typeOfPrice = propertyPrice.TypeOfPrice;
            if (!property.quantity) {
                if (size) {
                    property.quantity = size;
                } else {
                    property.quantity = "";
                }
            }
            property.showMetreInput = true;
        }

        return property;
    }

    public setUpLeadTimes(leadTimes, opportunity, ltMaterials, effDate) {
        var ltProduct = null;
        var ltRegion = null;
        var ltMaterial = null;
        var oAppTimes = [];
        var oAppTime = null;

        var sProduct = opportunity.lead.Product;
        if (opportunity.isConservatory) {
            sProduct = 'PC';
        }

        ltRegion = this.fetchRegion(opportunity.lead.InstallingRegion, leadTimes);
        if (!ltRegion) {
            ltRegion = this.fetchRegion(-1, leadTimes);
        }

        ltProduct = this.fetchProduct(sProduct, ltRegion);
        if (!ltProduct) {
            ltProduct = this.fetchProduct('ZZ', ltRegion);
        }

        //If no region or product found then use defaults for both.
        if (!ltProduct || !ltRegion) {
            ltRegion = this.fetchRegion(-1, leadTimes);
            ltProduct = this.fetchProduct('ZZ', ltRegion);
        }

        oAppTimes = this.fetchMaterials(ltMaterials, ltProduct, effDate);

        //Sort out which one to use, if more then 1 we use one with highest addition.
        if (oAppTimes.length > 0) {
            for (var iAppIdx = 0; iAppIdx < oAppTimes.length; iAppIdx++) {

                if (oAppTime === null) {
                    oAppTime = oAppTimes[iAppIdx];
                }
                else {
                    if (oAppTimes[iAppIdx].LeadTimeToWeeks > oAppTime.LeadTimeToWeeks) {
                        oAppTime = oAppTimes[iAppIdx];
                    }
                }
            }
        }

        return {
            leadtimesapplicable: oAppTimes,
            leadtimesapplied: oAppTime,
            leadtimefrom: oAppTime.LeadTimeFromWeeks,
            leadtimeto: oAppTime.LeadTimeToWeeks
        }

    }
    
        //
        public fetchRegion(iRegionNo, leadtimes) {

            for (var iRegIdx = 0; iRegIdx < leadtimes.Regions.length; iRegIdx++) {

                if (leadtimes.Regions[iRegIdx].InstallationRegion == iRegionNo) {
                    return leadtimes.Regions[iRegIdx];
                }

            }

            return null;

        }

        //
        public fetchProduct(productCode,oRegion) {

            for (var iProdIdx = 0; iProdIdx < oRegion.Products.length; iProdIdx++) {

                if (oRegion.Products[iProdIdx].ProductCategory == productCode) {
                    return oRegion.Products[iProdIdx];
                }            
            }

            return null;

        }

        //Fetch materials.
        public fetchMaterials(lsMaterials, oProduct, effDate) {

            var oAppTimes = [];
            var oMaterials = null;
            var iMatIdx = 0;

            if (lsMaterials.length === 0) {
                oMaterials = this.fetchApplicableMaterials(oProduct, effDate, 'DEFAULT');
                if (Array.isArray(oMaterials) === true) {

                    for (iMatIdx = 0; iMatIdx < oMaterials.length; iMatIdx++) {
                        oAppTimes.push(oMaterials[iMatIdx]);
                    }

                }
            }
            else {
                for (var iLsIdx = 0; iLsIdx < lsMaterials.length; iLsIdx++) {

                    oMaterials = this.fetchApplicableMaterials(oProduct, effDate, lsMaterials[iLsIdx]);

                    if (oMaterials.length === 0) {
                        oMaterials = this.fetchApplicableMaterials(oProduct, effDate, 'DEFAULT');
                    }

                    if (Array.isArray(oMaterials) === true) {

                        for (iMatIdx = 0; iMatIdx < oMaterials.length; iMatIdx++) {
                            oAppTimes.push(oMaterials[iMatIdx]);
                        }

                    }

                }

            }


            return oAppTimes;

        }

        private fetchApplicableMaterials(oProduct, effDate, sMaterial) {

            var oMaterials = null;
            oMaterials = _.filter(oProduct.Materials, function (mat) {
                if (mat.Material.toUpperCase() === sMaterial.toUpperCase()) {
                    if (mat.EffectiveDateTime_From <= effDate) {
                        if (mat.EffectiveDateTime_To >= effDate || mat.EffectiveDateTime_To === 0) {
                            return true;
                        }
                    }
                }
            });

            return oMaterials;

        }
}
