import { tokendata, config } from '.';
import { db, LocalDbStore } from './localDbStore'
import { Axios } from 'axios'
import { encryptionService } from "./encryptionService";
import { dataOptimisationService } from './dataOptimisationService';
import { BeforeSaveMode } from './enums';
const axios: Axios = require('axios').default;

export const defer = () => {
    var deferred: any = {};
    var promise = new Promise(function (resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    deferred.promise = promise;
    return deferred;
}

export class dataAccessService {

    constructor() { }

    localDbStore = new LocalDbStore();
    _dataOptimiseService = new dataOptimisationService();
    private _encryptionService = new encryptionService();

    public checkDate(sValue, parseToDate = true) {

        var NO_DATE = 'To be arranged';

        if (sValue == NO_DATE) {
            return sValue;
        }

        if (sValue) {
            if (sValue.toString().length > 1 && parseToDate) {
                return new Date(sValue);
            }
        }

        return sValue;

    }

    public async httpPostAsync(theurl, data, callback, addToken: boolean = true) {

        var headers: any = {
            "headers": {
                "accept": "*/*",
                "content-type": "application/json; charset=utf-8",
            },
            "body": JSON.stringify(data),
            "method": "POST",
            "mode": "cors",
            "credentials": "omit"
        }

        const headers2: any = {
            'Content-Type': 'application/json',
        }


        if (addToken) {
            headers.headers.bearer = tokendata.bearer;
            headers2.bearer = tokendata.bearer;
        } else {

        }

        var response = await axios.post(theurl, data, { headers: headers2 });
        callback(response.data);

    }

    public async httpPostAsyncProgress(theurl, data, callback, addToken: boolean = true) {
        let loading = false;

        let chunks = [];
        let results = null;
        let error = null;

        const json = async () => {
            loading = true

            var headers: any = {
                "headers": {
                    "accept": "*/*",
                    "content-type": "application/json; charset=utf-8",
                },
                "body": JSON.stringify(data),
                "method": "POST",
                "mode": "cors",
                "credentials": "omit"
            }

            if (addToken) {
                headers.headers.bearer = tokendata.bearer;
            }

            try {
                const response = await fetch(theurl, headers);

                if (response.status >= 200 && response.status < 300) {
                    results = await _readBody(response)

                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    if (isJson) { callback(JSON.parse(results)) } else { callback(results) };

                    return; // results;
                } else {
                    throw new Error(response.statusText)
                }
            } catch (err) {
                error = err
                results = null
                console.log(error);
                return;// error
            } finally {
                loading = false
            }
        }

        const _readBody = async (response) => {
            const reader = response.body.getReader();

            const length = +response.headers.get('content-length');

            // Declare received as 0 initially
            let received = 0;

            // Loop through the response stream and extract data chunks
            while (loading) {
                const { done, value } = await reader.read();
                const payload = { detail: { received, length, loading } }
                const onProgress = new CustomEvent('fetch-progress', payload);
                const onFinished = new CustomEvent('fetch-finished', payload);

                if (done) {
                    // Finish loading 
                    loading = false;
                    // Fired when reading the response body finishes
                    window.dispatchEvent(onFinished)
                } else {
                    // Push values to the chunk array                    
                    chunks.push(value);
                    received += value.length;

                    // Fired on each .read() - progress tick
                    window.dispatchEvent(onProgress);
                }
            }

            // Concat the chinks into a single array
            let body = new Uint8Array(received);
            let position = 0;

            // Order the chunks by their respective position
            for (let chunk of chunks) {
                body.set(chunk, position);
                position += chunk.length;
            }

            // Decode the response and return it
            return new TextDecoder('utf-8').decode(body);
        }

        var ret = await json();

        return ret;
    }

    public async httpGetVideoAsync(theurl, data, callback) {
        let loading = false;

        let chunks = [];
        let results = null;
        let error = null;

        const json = async () => {
            loading = true


            var headers: any = {
                "headers": {
                    "accept": "*/*",
                },
                mode: 'no-cors',
                "method": "GET",
            }

            try {
                const response = await fetch(theurl, headers);

                if (response.status >= 200 && response.status < 300) {
                    results = await _readBody(response)

                    callback(results);

                    return; // results;
                } else {
                    throw new Error(response.statusText)
                }
            } catch (err) {
                error = err
                results = null
                console.log(error);
                return;// error
            } finally {
                loading = false
            }
        }

        const _readBody = async (response) => {
            const reader = response.body.getReader();

            const length = +response.headers.get('content-length');

            // Declare received as 0 initially
            let received = 0;

            // Loop through the response stream and extract data chunks
            while (loading) {
                const { done, value } = await reader.read();
                const payload = { detail: { received, length, loading } }
                const onProgress = new CustomEvent('fetch-progress', payload);
                const onFinished = new CustomEvent('fetch-finished', payload);

                if (done) {
                    // Finish loading 
                    loading = false;
                    // Fired when reading the response body finishes
                    window.dispatchEvent(onFinished)
                } else {
                    // Push values to the chunk array                    
                    chunks.push(value);
                    received += value.length;

                    // Fired on each .read() - progress tick
                    window.dispatchEvent(onProgress);
                }
            }

            // Concat the chinks into a single array
            let body = new Uint8Array(received);
            let position = 0;

            // Order the chunks by their respective position
            for (let chunk of chunks) {
                body.set(chunk, position);
                position += chunk.length;
            }

            // Decode the response and return it
            return new TextDecoder('utf-8').decode(body);
        }

        var ret = await json();

        return ret;
    }

    public getRemote(url, msgdata, mapToDb: Function, progressTracker, addToken: boolean = true, notLoadFromSync: boolean = false): Promise<any> {

        console.log(url);
        var querying = defer();

        // set tokens and user
        if (!notLoadFromSync) {
            msgdata.AuthID = tokendata.authid;
            msgdata.RepNo = tokendata.repno;
            msgdata.Token = tokendata.payloadToken;
        }
        if (!progressTracker) {
            this.httpPostAsync(url, msgdata, async (data) => {

                if (data.Error && data.Error.toLowerCase().includes("your session has expired")) {
                    //send em back to the login screen
                    await this.clear("UserAccount");
                    localStorage.setItem("URLBeforeLogout", window.location.pathname);
                    localStorage.setItem("LogoutReason", "Your session has expired. Please log back in. You will be redirected to the page you just came from.");
                    location.reload();

                }
                let mapping = mapToDb(data);
                if (!isIterable(mapping)) {
                    let promiseArray = [];
                    promiseArray.push(mapping);
                    mapping = promiseArray
                }

                try {
                    Promise.all(mapping).then(function (mapResult) {
                        if (mapResult.length > 0) {
                            querying.resolve(mapResult);
                        }
                    },
                        function (err) {
                            querying.reject(err);
                        });

                } catch (e) {
                    console.log(e);
                    querying.reject(e);
                }
            }, addToken)
                .catch(e => querying.reject(e));

        } else {
            this.httpPostAsyncProgress(url, msgdata, function (data) {
                var mapping = mapToDb(data);

                if (!isIterable(mapping)) {
                    let promiseArray = [];
                    promiseArray.push(mapping);
                    mapping = promiseArray
                }

                try {
                    Promise.all(mapping).then(function (mapResult) {
                        if (mapResult.length > 0) {
                            querying.resolve(mapResult);
                        }
                    },
                        function (err) {
                            querying.reject(err);
                        });

                } catch (e) {
                    console.log(e);
                }
            }, addToken)
                .catch(e => querying.reject(e));;
        }

        return querying.promise;
    }



    public async httpGetStoreInCache(url: string, cacheName, fileName = "") {
        try {

            var d = defer();
            const cache = await caches.open(cacheName);

            var headers: any = {
                "method": "GET",
            };

            if (!fileName) {
                fileName = url.replace(/^.*[\\\/]/, '')
            }

            var sName = cacheName + '/' + fileName;

            var response = await fetch(url, headers);
            if (!response.ok) {
                throw new Error("Request returned a non-OK status code.")
            }


            var s = await cache.put(sName, response)
            d.resolve(true);
            //console.log("file placeed in cache: " + sName);




        }
        catch (e) {
            console.error(e);
            d.reject(e);
        }

        return d.promise;
    }

    public async ClearVideoCache(): Promise<any> {

        await caches.delete('video');

    }

    public async getPrices(designId): Promise<any> {
        return (await db).transaction("prices").store.getAll(designId);
    };

    //parameter index is the value of the primary key index when query is null.
    //If query is NOT null, index is used as the name of the index (and query provides the index value)
    public async getLocal(entity, indexValueOrName = null, query = null, returnSingle = false): Promise<any> {
        let results;

        var _this = this;
        try {
            if (indexValueOrName) {
                if (query) {
                    var indexName = indexValueOrName;
                    results = await (await db).transaction(entity).store.index(indexName).getAll(query);
                }
                else {
                    var indexValue = indexValueOrName;

                    results = await (await db).transaction(entity).store.getAll(indexValue);
                }
            }
            else {
                results = await (await db).transaction(entity).store.getAll();
            }

        }
        catch (e) {
            console.error(e);
            throw e;
        }

        if (entity === "opportunities") {
            var querying = defer();
            var returnOpps = [];

            _this._encryptionService.decryptOppsMain(results, 0, returnOpps, querying);
            results = await querying.promise;
        }


        if (returnSingle) {
            return results[0]
        }

        return results;
    };

    public async saveLocal(data, entity): Promise<any> {
        var d = defer();

        var oReport: any = {};
        var bLog = false;
        var oDataCopy = null;

        if (entity == 'opportunities') {
            bLog = true;
            oReport.entity = entity;
            oReport.product = data.lead.Product;
            oReport.surname = data.lead.Surname;
            oReport.unitqty = data.order.items.length;
            data.UnsoldVersionsCount = data.unsoldVersions.length;

            oDataCopy = Object.assign({}, data);
        }
        else {
            oDataCopy = data;
        }

        var that = this;
        var emptyPromise = defer();
        emptyPromise.resolve(data);
        //don't even ask
        emptyPromise.promise.then(async function (dataSave) {

            var transaction = (await db).transaction([entity], "readwrite");
            transaction.onerror = function (event) {
                //Log DB transaction error.             
                oReport.event = event;
                oReport.data = dataSave;
                //oReport.entity = entity;
                //oggerService.logError(JSON.stringify(oReport), "SAVING DATA ERROR");
                console.log('Transaction error: ' + event) // event.target.error.name + ' on ' + event.target.source.name);
                console.log(oReport);
            };

            let objectStore = transaction.objectStore(entity);

            if (dataSave.id) {

                if (bLog === true) {
                    oReport.dataid = data.id;
                }

                await that.localDbStore.putIdentityInlineKey(objectStore.name, dataSave).then(function (result: any) {
                    d.resolve(result);
                });

            } else {
                // Insert

                if (bLog === true) {

                    oReport.event = event;
                    //loggerService.logError(JSON.stringify(oReport), "SAVING DATA (INSERT)");

                }

                await that.localDbStore.addIdentityInlineKey(objectStore.name, dataSave).then(function (result: any) {
                    d.resolve(result);
                });
            }

        });

        return d.promise;
    };



    public async clear(entity, index = null) {
        var d = defer();
        var transaction = (await db).transaction([entity], "readwrite");
        var objectStore = transaction.objectStore(entity);
        objectStore.clear().then(() => {
            d.resolve(true);
        });
        return d.promise;
    }

    public async saveAudit(audit) {
        await this.saveLocal(audit, "audits");
    }

    public async getAuditsForOpportunity(opportunityId) {
        var audits = await this.getLocal("audits", "opportunityId", IDBKeyRange.only(opportunityId));
        return audits;
    }

    public async getAuditsForVersion(opportunityId, versionId) {
        var auditsForOpp = await this.getAuditsForOpportunity(opportunityId);
        var auditsForVersion = (auditsForOpp ?? []).filter(afo => afo.unsoldVersionId === versionId);
        return auditsForVersion;

    }

    public async assignInProgressAuditsVersionId(opportunityId, versionId) {
        var inprogAudits = await this.getAuditsForInProgressOpportunity(opportunityId);
        for (var auditIdx in inprogAudits) {
            var audit = inprogAudits[auditIdx];
            audit.unsoldVersionId = versionId;

            await this.saveAudit(audit);
        }
    }

    public async getUserActionsForOpportunity(oppId) {
        var userActions = await this.getLocal("userActions", "opportunityId", IDBKeyRange.only(oppId));
        return userActions;
    }

    public async createUserActionForOpportunity(userAction, oppId) {
        var toSave = {
            opportunityId: oppId,
            action: userAction,
            actionDate: new Date()
        };

        await this.saveLocal(toSave, "userActions");
    }

    public async getUnsoldVersionData(opportunityId) {
        var opp = await this.getLocal("opportunities", opportunityId, null, true);
        return {
            unsoldVersions: opp.unsoldVersions,
            lead: opp.lead
        };
    }

    public async getNextUnsoldVersionId(unsoldVersions) {
        if (!unsoldVersions || !unsoldVersions.length) {
            return 1;
        }

        unsoldVersions = unsoldVersions
            .map(uv => uv.Id)
            .filter(uv => uv);

        if (!unsoldVersions.length) {
            return 1;
        }

        return Math.max(...unsoldVersions) + 1;
    }

    public async getOpportunity(opportunityId, trimPropertiesForFrontEnd = true) {
        var opp = await this.getLocal("opportunities", opportunityId, null, true);

        this.doOpportunityTypeConversions(opp);

        if (trimPropertiesForFrontEnd) {
            opp = this._dataOptimiseService.beforeGetOpportunity(opp);
        }

        return opp;
    }

    public async getAllOpportunities() {
        var opps = await this.localDbStore.getAll("opportunities");

        var querying = defer();
        var returnOpps = [];

        this._encryptionService.decryptOppsMain(opps, 0, returnOpps, querying);

        for (var oppIdx in opps) {
            var opp = opps[oppIdx];
            this.doOpportunityTypeConversions(opp);
        }
        querying.promise.then(function (leads) {
            querying.resolve(leads);
        });

        return querying.promise;
    }

    public async getFilteredOpportunityCount(tableType: number, searchText: string) {

        const _this = await this;
        let unFilteredOpps = await this.localDbStore.getAll("opportunities");
        let count = 0;
        let searchLowerCase = null;

        let counting = defer();

        //console.log("getFilteredOpportunityCount()");

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (searchText && searchText.length > 0) {
            searchLowerCase = searchText.toLowerCase();
        }

        let decrypting = defer();
        let returnOpps = [];

        for (const oppIdx in unFilteredOpps) {
            const opp = unFilteredOpps[oppIdx];
            this.doOpportunityTypeConversions(opp);
        }

        this._encryptionService.decryptOppsMain(unFilteredOpps, 0, returnOpps, decrypting);

        decrypting.promise.then((decryptedOpps) => {
            for (const opp of decryptedOpps) {

                const matchesFilter = this.myCustomerTableFilterMatch(tableType, opp);

                if (matchesFilter) {
                    if (searchLowerCase) {
                        if (_this.opportunityMatchesSearch(opp, searchLowerCase)) {
                            count++;
                        }
                    } else {
                        count++;
                    }
                }
            }

            //console.log("count: " + count.toString());

            counting.resolve(count);
        });

        return counting.promise;
    }

    public async getFilteredOpportunities(tableType: number, pageNumber: number, pageSize: number, searchText: string) {

        let _this = await this;

        //let startDateTime = Date.now();
        //console.log("getFilteredOpportunities()");

        let unFilteredOpps = await this.localDbStore.getAll("opportunities");
        let searchLowerCase = null;
        

        let querying = defer();

        //console.log("unfiltered: " + unFilteredOpps.length.toString());

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (searchText && searchText.length > 0) {
            searchLowerCase = searchText.toLowerCase();
        }

        for (const oppIdx in unFilteredOpps) {
            const opp = unFilteredOpps[oppIdx];
            this.doOpportunityTypeConversions(opp);
        }

        let decrypting = defer();
        let returnOpps = [];

        this._encryptionService.decryptOppsMain(unFilteredOpps, 0, returnOpps, decrypting);

        decrypting.promise.then((decryptedOpps) => {
            let opps = [];
            for (const opp of decryptedOpps) {
                const matchesFilter = this.myCustomerTableFilterMatch(tableType, opp);
               
                if (matchesFilter) {
                    if (searchLowerCase) {
                        if (_this.opportunityMatchesSearch(opp, searchLowerCase)) {
                            opps.push(opp);
                        }
                    } else {
                        opps.push(opp);
                    }
                }
            }

            //console.log("filtered: " + opps.length.toString());

            _this.sortOpportunitiesByDate(opps);

            if (pageNumber > 0 && pageSize > 0) {
                const start = (pageNumber - 1) * pageSize;
                const end = pageNumber * pageSize;
                //console.log("start: " + start.toString() + ", end: " + end.toString());
                opps = opps.slice(start, end);
            }

            //console.log("-----querying.resolve---opps-" + JSON.stringify(opps));
            querying.resolve(opps);

            //let ms1: number = Date.now() - startDateTime;
            //console.log("getFilteredOpportunities resolved in " + ms1.toString() + " ms, " + opps.length.toString() + " records");
        });

        //let ms2: number = Date.now() - startDateTime;
        //console.log("getFilteredOpportunities returned in " + ms2.toString() + " ms"); 

        return querying.promise;
    }

    private async getAuditsForInProgressOpportunity(opportunityId) {
        var audits = (await this.getAuditsForOpportunity(opportunityId)) || [];
        return (audits || [])
            .filter(audit => !audit.unsoldVersionId);
    }

    private myCustomerTableFilterMatch(tableType, opp): boolean {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var matchesFilter = false;
        switch (tableType) {
            case 0:
                if (opp.lead && (!opp.lead.AppointmentDate || new Date(opp.lead.AppointmentDate) >= today)) {
                    matchesFilter = true;
                }
                break;
            case 1:
                if (opp.lead && opp.lead.AppointmentDate && new Date(opp.lead.AppointmentDate) <= today) {
                    matchesFilter = true;
                }
                break;
            case 2:
                if (opp.lead && (!opp.lead.SurveyDate || new Date(opp.lead.SurveyDate) >= today) && opp.contract && !opp.contract.WaitingESign) {
                    matchesFilter = true;
                }
                break;
            case 3:
                if (opp.lead && opp.lead.SurveyDate && new Date(opp.lead.SurveyDate) < today && (!opp.lead.ProposedInstall || new Date(opp.lead.ProposedInstall) > today) && !opp.lead.InstallationDate) {
                    matchesFilter = true;
                }
                break;
            case 4:
                if (opp.lead && opp.lead.SurveyDate && new Date(opp.lead.SurveyDate) < today && !((!opp.lead.ProposedInstall || new Date(opp.lead.ProposedInstall) > today) && !opp.lead.InstallationDate)) {
                    matchesFilter = true;
                }
                break;
        }

        return matchesFilter;
    }

    public sortOpportunitiesByDate(opps) {
        opps.sort((a, b) => {
            if (a.lead && a.lead.AppointmentDate) {
                if (b.lead && b.lead.AppointmentDate) {
                    if (a.lead.AppointmentDate > b.lead.AppointmentDate) {
                        return 1;
                    } else if (a.lead.AppointmentDate < b.lead.AppointmentDate) {
                        return -1;
                    } else {
                        if (a.lead.AppointmentTime) {
                            if (b.lead.AppointmentTime) {
                                if (a.lead.AppointmentTime > b.lead.AppointmentTime) {
                                    return 1;
                                } else if (a.lead.AppointmentTime < b.lead.AppointmentTime) {
                                    return -1;
                                }
                            } else {
                                return 1;
                            }
                        } else {
                            if (b.lead.AppointmentTime) {
                                return -1;
                            }
                        }
                    }
                } else {
                    return 1;
                }
            } else {
                if (b.lead && b.lead.AppointmentDate) {
                    return -1;
                }
            }
            return 0;
        });
    }

    public opportunityMatchesSearch(opp, searchLowerCase) {
        if (opp.lead) {
            const lead = opp.lead;
            if (lead.Address1) {
                if (lead.Address1.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Address2) {
                if (lead.Address2.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Address3) {
                if (lead.Address3.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Address4) {
                if (lead.Address4.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.PostCode) {
                if (lead.PostCode.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Title) {
                if (lead.Title.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Forename) {
                if (lead.Forename.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Surname) {
                if (lead.Surname.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
                if (lead.Forename) {
                    const fullName = lead.Forename + " " + lead.Surname;
                    if (fullName.toLowerCase().includes(searchLowerCase)) {
                        return true;
                    }
                }
            }
            if (lead.RepNumber) {
                if (lead.RepNumber.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
            if (lead.Product) {
                if (lead.Product.toLowerCase().includes(searchLowerCase)) {
                    return true;
                }
            }
        }

        return false;
    }

    public async saveOpportunity(opportunity, modes: BeforeSaveMode[] = null) {

        if (!modes || !modes.some(m => m === BeforeSaveMode.Disabled)) {
            //we do not bring the versions into the c#, they live purely in JS/IndexedDB. Get from DB and set.
            opportunity = await this._dataOptimiseService.beforeSaveOpportunity(opportunity, modes);
        }
        

        opportunity = await this._encryptionService.doGetLocalEncrypt(opportunity);

        var id = await this.localDbStore.putIdentityInlineKey("opportunities", opportunity);

        if (!opportunity.id) {
            opportunity.id = id;
        }

        opportunity = this._dataOptimiseService.beforeGetOpportunity(opportunity);

        return opportunity;
    }


    public async getPriorities(product) {
        var priorities = await this.localDbStore.getAll("customerPriorities");
        var productPriorities = priorities.filter(p => p.Products.includes(product));
        return productPriorities;
    }


    public getCustomerFile(oppId, fileName): Promise<any> {
        return this.localDbStore.getCustomerFile(oppId, fileName);
    }

    public async saveCustomerFile(file) {
        return this.localDbStore.saveCustomerFile(file);
    }

    public async getTimescales(product) {
        var timescaleOpts = await this.localDbStore.getAll("timescaleOptions");
        var tsoForProd = timescaleOpts
            .filter(p => p.Products.includes(product));

        return tsoForProd;
    }

    public doOpportunityTypeConversions(opp) {
        if (opp.order.items) {
            opp.order.items.forEach(item => {
                if (item.selectedTemplate) {
                    item.selectedTemplate.Id = parseInt(item.selectedTemplate.Id);
                }



                for (var key in item) {
                    //if prototype propertry
                    if (!item.hasOwnProperty(key)) {
                        continue;       // skip this property
                    }
                    this.doDesignNaNConversions(item);
                }
            })
        }
        if (opp.order.appliedDiscounts) {
            opp.order.appliedDiscounts.forEach(ad => {
                ad.appliedOptions.forEach(ao => {
                    if (!ao.ReferenceData) {
                        return; //skip
                    }

                    ao.ReferenceData.forEach(rd => {
                        //it's spicy down here in callback hell
                        if (rd.DiscountPercentage) {
                            rd.DiscountPercentage = rd.DiscountPercentage.toString();
                        }
                    });
                });
            });
        }
    }

    private doDesignNaNConversions(obj) {
        for (var k in obj) {
            //if property is an object
            if (typeof obj[k] == "object" && obj[k] !== null) {
                this.doDesignNaNConversions(obj[k]);
            }
            else {
                if (Number.isNaN(obj[k])) {
                    obj[k] = 0;
                }
            }
        }
    }

    public async getCurrentUser(): Promise<any> {
        return this.getLocal('UserAccount', null, null, true);
    }

    public async delete(storeName, key) {
        return this.localDbStore.delete(storeName, key);
    }
}



/** Token. AuthID and repNo */
export declare class AnglianData {
    constructor(token: string,
        repNo: string,
        authID: string, payloadToken: string);

    token: string;
    repNo: string;
    authID: string;
    payloadToken: string;
}


function isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}

