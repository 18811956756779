import { LocalDbStore } from "./localDbStore";

export class FinanceService {

    private readonly NO_DATE = 'To be arranged';
    private LocalDb = new LocalDbStore();

    public returnEligibleFinanceProviders(providers, opportunity) {
        var iRegionQty = 0;
        var iProdQty = 0;
        var iProvQty = providers.length;
        var elProviders = [];
        var oProv:any = {};
        var oAPRsAdded = [];

        for (var iProv = 0; iProv < iProvQty; iProv++) {

            if (providers[iProv].IsAFinanceProvider === true) {

                if (providers[iProv].Regions) {

                    iRegionQty = providers[iProv].Regions.length;
                    for (var iRegion = 0; iRegion < iRegionQty; iRegion++) {

                        if (providers[iProv].Regions[iRegion].RegionNo == opportunity.lead.RegionNumber) {

                            iProdQty = providers[iProv].Products.length;
                            for (var iProd = 0; iProd < iProdQty; iProd++) {

                                if (providers[iProv].Products[iProd].ProductCode == opportunity.lead.Product) {

                                    oProv = {};
                                    oProv.provider = providers[iProv];
                                    oProv.APRRate = this.returnCurrentAPRForProvider(oProv.provider);
                                    if (oProv.APRRate != null) {

                                        //We do not want the same APR twice, causes issues.
                                        if (oAPRsAdded.indexOf(oProv.APRRate.APRRate) == -1) {
                                            oProv.Repayments = this.returnCurrentRepaymentsForProvider(oProv.provider);
                                            if (oProv.Repayments.length > 0) {
                                                elProviders.push(oProv);
                                                oAPRsAdded.push(oProv.APRRate.APRRate);

                                            }
                                        }

                                    }

                                }

                            }
                        }
                    }
                }
            }
        }
        console.log(elProviders);
        return elProviders;
    }


     public returnCurrentAPRForProvider(provider) {
        var dNowDate = new Date();
        var sNowYYYYMMDD = this.convertDateToYYYYMMDD(dNowDate);
        var iNowYYYYMMDD = parseFloat(sNowYYYYMMDD);
        var sNowHHMM = this.convertTimeToHHMM(dNowDate);
        var iNowHHMM = parseFloat(sNowHHMM);

        if (provider.APRRates) {

            var iRateQty = provider.APRRates.length;
            var bStartOK = false;

            for (var iRate = 0; iRate < iRateQty; iRate++) {
                if (this.isDateTimeInRange(provider.APRRates[iRate], iNowYYYYMMDD, iNowHHMM) === true) {
                    return provider.APRRates[iRate];
                }
            }
        }

        return null;

    }

    private readonly EligibleProvidersKey = "EligibleProviders";
    private readonly SelectedProviderKey = "SelectedProvider";

    public async setEligibleProviders(opportunity) {
        var masterProviders = await this.LocalDb.getAll("financeSetup");
        var eligibleProviders = this.returnEligibleFinanceProviders(masterProviders, opportunity);
        localStorage.setItem(this.EligibleProvidersKey, JSON.stringify(eligibleProviders)); 
    }

    public async setSelectedProvider(opportunity) {
        let selectedProvider;
        var eligibleProviders = JSON.parse(localStorage.getItem(this.EligibleProvidersKey) || "[]");

        if (!eligibleProviders.length) {
            console.error("Error in financeService.setSelectedProvider(opp): no eligible providers available. ");
            return;
        }

        if (opportunity.order.illustration) {
            for (var iProv = 0; iProv < eligibleProviders.length; iProv++) {
                if (eligibleProviders[iProv].provider.SourceOfFinanceNumber == opportunity.order.illustration.SourceOfFinanceProviderId) {
                    selectedProvider = eligibleProviders[iProv];
                    break;
                }
            }
        }

        //Set as the default provider.
        if (!selectedProvider) {
            for (iProv = 0; iProv < eligibleProviders.length; iProv++) {
                if (eligibleProviders[iProv].APRRate.IsDefault === true) {
                    selectedProvider = eligibleProviders[iProv];
                    break;
                }
            }
        }

        //If default not set then use first one.
        if (!selectedProvider) {
            selectedProvider = eligibleProviders[0];
        }

        localStorage.setItem(this.SelectedProviderKey, JSON.stringify(selectedProvider));

        return selectedProvider;
    }


    //---------------- PRIVATE METHODS -----------------------//


    private isDateTimeInRange(itemrow, iNowYYYYMMDD, iNowHHMM) {

        var bStartOK = false;
        if (itemrow.EffectiveDate < iNowYYYYMMDD) {
            bStartOK = true;
        }
        else if (itemrow.EffectiveDate = iNowYYYYMMDD) {

            if (itemrow.EffectiveTime < iNowHHMM) {
                bStartOK = true;

            }
        }

        if (bStartOK === true) {

            if (itemrow.EffectiveEndDate > iNowYYYYMMDD || itemrow.EffectiveEndDate === 0) {
                return true;
            }
            else if (itemrow.EffectiveEndDate = iNowYYYYMMDD) {

                if (itemrow.EffectiveEndTime > iNowHHMM) {

                    return true;

                }
            }
        }

        return false;
    }

    private returnCurrentRepaymentsForProvider(provider) {
        var dNowDate = new Date();
        var sNowYYYYMMDD = this.convertDateToYYYYMMDD(dNowDate);
        var iNowYYYYMMDD = parseFloat(sNowYYYYMMDD);
        var sNowHHMM = this.convertTimeToHHMM(dNowDate);
        var iNowHHMM = parseFloat(sNowHHMM);

        var Repayments = [];
        var RepaymentPeriods = [];

        if (provider.Repayments) {

            var iRepayQty = provider.Repayments.length;
            var bStartOK = false;

            for (var iRepay = 0; iRepay < iRepayQty; iRepay++) {
                if (this.isDateTimeInRange(provider.Repayments[iRepay], iNowYYYYMMDD, iNowHHMM) === true) {

                    if (RepaymentPeriods.indexOf(provider.Repayments[iRepay].RepaymentPeriod) == -1) {
                        Repayments.push(provider.Repayments[iRepay]);
                        RepaymentPeriods.push(provider.Repayments[iRepay].RepaymentPeriod);
                    }

                }
            }
        }

        return Repayments;
    }



    private convertDateToYYYYMMDD(date) {
        if (!date || date === this.NO_DATE) {
            return null;
        }
        return date.getFullYear() + ("0" + (date.getMonth() + 1)).slice(-2) + ("0" + date.getDate()).slice(-2);
    }

     private convertTimeToHHMM(date) {
        if (!date || date === this.NO_DATE) {
            return null;
        }
        return ("0" + (date.getHours())).slice(-2) + ("0" + (date.getMinutes())).slice(-2);
    }
}