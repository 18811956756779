import { randomBytes, createCipheriv, createDecipheriv } from 'crypto-browserify';
import { Buffer } from 'buffer';
import { defer } from './dataAccessService';

export class encryptionService {

    public key: string = "qawsedcvgrtfhskvhftesjfgerghbjuf";
    public iv: string = "wsujdgvbgtrufhgl";
    public algorithm: string = 'aes-256-cbc';

    public async saveLocalEncrypt(data, entity): Promise<any> {
        var d = this.defer();
        var _this = this;
        if (entity == 'opportunities') {
            //TO-DO: ENCRYPT OPPORTUNITIES
            await _this.doGetLocalEncrypt(data).then(function (lead) {
                d.resolve(lead);
            });
        }
        else {
            d.resolve(data);
        }
        return d.promise;
    }

    public async doGetLocalEncrypt(oppertunity): Promise<any> {
        var _this = this;
        var querying = this.defer();

        var sLead = JSON.stringify(oppertunity.lead);

        var CanBeViewedById = oppertunity.lead.CanBeViewedById;
        var AppointmentDate = oppertunity.lead.AppointmentDate;
        var OpportunityNumber = oppertunity.lead.OpportunityNumber;

        if (oppertunity.contract.installationAddress) {
            oppertunity.contract.installationAddressE = await this.encrypt(JSON.stringify(oppertunity.contract.installationAddress));
            oppertunity.contract.installationAddress = null;
        }

        if (oppertunity.contract.invoiceAddress) {
            oppertunity.contract.invoiceAddressE = await this.encrypt(JSON.stringify(oppertunity.contract.invoiceAddress));
            oppertunity.contract.invoiceAddress = null;
        }

        this.encrypt(sLead).then(function (encryptedString) {

            oppertunity.leadE = encryptedString;

            oppertunity.lead = {};
            oppertunity.lead.CanBeViewedById = CanBeViewedById;
            oppertunity.lead.AppointmentDate = AppointmentDate;
            oppertunity.lead.OpportunityNumber = OpportunityNumber;

            if (oppertunity.unsoldVersions) {

                var pPromises = [];
                var pPromise = null;
                for (var iUnsold = 0; iUnsold < oppertunity.unsoldVersions.length; iUnsold++) {
                    pPromise = (_this.saveLocalUnsoldEncrypt(oppertunity.unsoldVersions[iUnsold]));
                    pPromises.push(pPromise);
                }


                Promise.all(pPromises).then(function (unsolds) {
                    oppertunity.unsoldVersions = unsolds;
                    querying.resolve(oppertunity);
                });
            }
            else {
                querying.resolve(oppertunity);
            }

        });

        return querying.promise;
    }

    public async saveLocalUnsoldEncrypt(unsold): Promise<any> {
        var d = this.defer();

        var proms = [];
        var prom1 = null;
        var prom2 = null;
        var prom3 = null;

        if (!unsold.OrderDetails.Opportunity.CustomerDetailsE) {

            var sCustDetails = JSON.stringify(unsold.OrderDetails.Opportunity.CustomerDetails);
            prom1 = await this.encrypt(sCustDetails).then(function (encryptedString) {

                unsold.OrderDetails.Opportunity.CustomerDetailsE = encryptedString;
                unsold.OrderDetails.Opportunity.CustomerDetails = {};

                //defer.resolve(unsold);

            });
            proms.push(prom1);

        }
        //else {
        //    defer.resolve(unsold);
        //}   

        if (!unsold.OrderDetails.Contract.InstallationAddressE && unsold.OrderDetails.Contract.InstallationAddress) {

            var sInst = JSON.stringify(unsold.OrderDetails.Contract.InstallationAddress);
            prom2 = this.encrypt(sInst).then(function (encryptedString) {

                unsold.OrderDetails.Contract.InstallationAddressE = encryptedString;
                unsold.OrderDetails.Contract.InstallationAddress = {};


            });
            proms.push(prom2);
        }

        if (!unsold.OrderDetails.Contract.SellingAddressE && unsold.OrderDetails.Contract.SellingAddress) {

            var sSell = JSON.stringify(unsold.OrderDetails.Contract.SellingAddress);
            prom3 = this.encrypt(sSell).then(function (encryptedString) {

                unsold.OrderDetails.Contract.SellingAddressE = encryptedString;
                unsold.OrderDetails.Contract.SellingAddress = {};



            });
            proms.push(prom3);
        }


        Promise.all(proms).then(function () {
            d.resolve(unsold);
        });


        return d.promise;
    }

    public async decryptOppsMain(opps, iStartIndex, results, promise) {
        var _this = await this;
        var promises = [];
        var prom = null;
        var iEndIndex = iStartIndex + 100; //Decrypt in batches
        if (iEndIndex > opps.length) {
            iEndIndex = opps.length;
        }

        for (var iIndex = iStartIndex; iIndex < iEndIndex; iIndex++) {
            prom = _this.doGetLocalDecrypt(opps[iIndex]);
            promises.push(prom);
        }

        Promise.all(promises).then(function (leads) {

            //Update main array
            for (var iLeadIdx = 0; iLeadIdx < leads.length; iLeadIdx++) {
                results.push(leads[iLeadIdx]);
            }

            if (opps.length === results.length) {
                promise.resolve(results);
            }
            else {
                _this.decryptOppsMain(opps, results.length, results, promise);
            }

        });
    }



    public deCryptGetLead(result) {
        var querying = this.defer();

        if (result) {

            if (result.leadE) {

                this.decrypt(result.leadE).then(function (decrypted) {

                    result.lead = JSON.parse(decrypted);
                    result.leadE = null;

                    querying.resolve(result);

                });

            }
            else {
                querying.resolve(result);
            }
        }
        else {
            querying.resolve(result);
        }

        return querying.promise;
    }

    public async doGetLocalDecrypt(opportunity): Promise<any> {
        var _this = this;

        var querying = this.defer();

        if (opportunity) {
            if (opportunity.contract.installationAddressE) {
                opportunity.contract.installationAddress = JSON.parse(
                    await this.decrypt(opportunity.contract.installationAddressE))
                    ;
                opportunity.contract.installationAddressE = null;
            }

            if (opportunity.contract.invoiceAddressE) {
                opportunity.contract.invoiceAddress = JSON.parse(
                    await this.decrypt(opportunity.contract.invoiceAddressE)
                );
                opportunity.contract.invoiceAddressE = null;
            }


            if (opportunity.leadE) {
                this.decrypt(opportunity.leadE).then(function (decryptedString) {

                    opportunity.lead = JSON.parse(decryptedString);
                    opportunity.leadE = null;

                    opportunity.lead.SurveyDate = _this.checkDate(opportunity.lead.SurveyDate);

                    opportunity.lead.SurveyTime = _this.checkDate(opportunity.lead.SurveyTime);

                    opportunity.lead.ProposedInstall = _this.checkDate(opportunity.lead.ProposedInstall);

                    opportunity.lead.AppointmentDate = _this.checkDate(opportunity.lead.AppointmentDate, false);

                    opportunity.lead.AppointmentTime = _this.checkDate(opportunity.lead.AppointmentTime, false);

                    opportunity.lead.InstallationDate = _this.checkDate(opportunity.lead.InstallationDate);

                    querying.resolve(opportunity);
                });
            }
            else {
                querying.resolve(opportunity);
            }
        }
        else {
            querying.resolve(opportunity);
        }

        return querying.promise;
    }

    private defer() {
        var querying: any = {};
        var promise = new Promise(function (resolve, reject) {
            querying.resolve = resolve;
            querying.reject = reject;
        });
        querying.promise = promise;
        return querying;
    }

    private checkDate(sValue, parseToDate = true) {

        var NO_DATE = 'To be arranged';

        if (sValue == NO_DATE) {
            return sValue;
        }

        if (sValue) {
            if (sValue.toString().length > 1 && parseToDate) {
                return new Date(sValue);
            }
        }

        return sValue;

    }

    public decryptUnsoldVersion(unsoldVersion): Promise<any> {

        var querying = this.defer();

        var proms = [];

        var rawEncrypted = unsoldVersion.OrderDetails.Opportunity.CustomerDetailsE;
        if (rawEncrypted) {

            var prom1 = this.decrypt(rawEncrypted).then(function (decrypted) {

                unsoldVersion.OrderDetails.Opportunity.CustomerDetails = JSON.parse(decrypted);
                unsoldVersion.OrderDetails.Opportunity.CustomerDetailsE = null;

                //querying.resolve(unsoldVersion);
            });

            proms.push(prom1);

            rawEncrypted = unsoldVersion.OrderDetails.Contract.InstallationAddressE;
            if (rawEncrypted) {

                var prom2 = this.decrypt(rawEncrypted).then(function (decrypted) {

                    unsoldVersion.OrderDetails.Contract.InstallationAddress = JSON.parse(decrypted);
                    unsoldVersion.OrderDetails.Contract.InstallationAddressE = null;

                });
                proms.push(prom2);
            }

            rawEncrypted = unsoldVersion.OrderDetails.Contract.SellingAddressE;
            if (rawEncrypted) {

                var prom3 = this.decrypt(rawEncrypted).then(function (decrypted) {

                    unsoldVersion.OrderDetails.Contract.SellingAddress = JSON.parse(decrypted);
                    unsoldVersion.OrderDetails.Contract.SellingAddressE = null;

                });
                proms.push(prom3);
            }

            Promise.all(proms).then(function () {
                querying.resolve(unsoldVersion);
            });

        }
        else {

            querying.resolve(unsoldVersion);

        }

        return querying.promise;
    }

    public async encrypt(text) {
       
        var cipher = createCipheriv(this.algorithm, Buffer.from(this.key), Buffer.from(this.iv));

        // Updating text
        let encrypted = cipher.update(text);

        // Using concatenation
        encrypted = Buffer.concat([encrypted, cipher.final()]);

        // Returning iv and encrypted data
        var data = {
            //iv: iv.toString('hex'),
            encryptedData: encrypted.toString('hex')
        };

        return data.encryptedData;
    }

    public async decrypt(text) {

        //const iv = Buffer.from(text.iv, 'hex');
        const encryptedText = Buffer.from(text, 'hex');

        // Creating Decipher
        let decipher = createDecipheriv(this.algorithm, Buffer.from(this.key), Buffer.from(this.iv));

        // Updating encrypted text
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);

        // returns data after decryption
        return decrypted.toString();
    }

    public decryptQueueItem(qItem) {

        var deferred = defer();

        var proms = [];
        var prom1 = null;
        var prom2 = null;
        var prom3 = null;

        if (qItem.data.OrderDetails) {

            var rawEncrypted = qItem.data.OrderDetails.Opportunity.CustomerDetailsE;
            if (rawEncrypted) {

                prom1 = this.decrypt(rawEncrypted).then(function (decrypted) {

                    qItem.data.OrderDetails.Opportunity.CustomerDetails = JSON.parse(decrypted);
                    qItem.data.OrderDetails.Opportunity.CustomerDetailsE = null;

                    //deferred.resolve(qItem);
                });

                proms.push(prom1);
            }

            rawEncrypted = qItem.data.OrderDetails.Contract.InstallationAddressE;
            if (rawEncrypted) {

                prom2 = this.decrypt(rawEncrypted).then(function (decrypted) {

                    qItem.data.OrderDetails.Contract.InstallationAddress = JSON.parse(decrypted);
                    qItem.data.OrderDetails.Contract.InstallationAddressE = null;


                });

                proms.push(prom2);
            }

            rawEncrypted = qItem.data.OrderDetails.Contract.SellingAddressE;
            if (rawEncrypted) {

                prom3 = this.decrypt(rawEncrypted).then(function (decrypted) {

                    qItem.data.OrderDetails.Contract.SellingAddress = JSON.parse(decrypted);
                    qItem.data.OrderDetails.Contract.SellingAddressE = null;


                });

                proms.push(prom3);
            }

            Promise.all(proms)
                .then(() => {
                deferred.resolve(qItem);
            });


            //else {

            //    deferred.resolve(qItem);

            //}
        }
        else {

            deferred.resolve(qItem);

        }

        return deferred.promise;

    }
    
}