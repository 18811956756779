import { AnglianData, dataAccessService, defer } from "./dataAccessService";
import { mappingHelperService } from './mappingHelperService';
import _, { map } from 'underscore';
//import { loggerService } from './loggerService';
import { DesignService } from './designService';
import { referenceDataService } from "./refferenceDataService";
import { PriceListService } from "./priceListService";
import { winStorage } from "./winStorage";
import { DataPruningService } from "./dataPruningService";
import { DiscountService } from "./discountService";
import { MediaService } from "./mediaService";
import { encryptionService } from "./encryptionService";

export class mapToAdaptService {
    private _mappingHelperService: mappingHelperService = new mappingHelperService;
    private _DesignService: DesignService = new DesignService;
    private _dataPruningService: DataPruningService = new DataPruningService;;
    private _priceListService: PriceListService = new PriceListService;
    private _winStorage: winStorage = new winStorage;
    private _referenceDataService: referenceDataService = new referenceDataService;
    private _discountService: DiscountService = new DiscountService;
    private _mediaService = new MediaService();
    private _encryptionService = new encryptionService();
    private readonly ListOfRequiredProperties = ["Glazing Type", "Energy Saving Glass", "Energy Saving Glass (UA)"];


    public async mapFromMessageBackToOpportunity(opportunity, message): Promise<any> {
        try {
            var mapping = defer();
            var result: any = {};

            var mappingOrder = defer();

            //Update the opt in.
            if (message.Contract) {
                result.AffiliateOptIns = message.Contract.AffiliateOptIns;
                result.AnglianOptIns = message.Contract.AnglianOptIns;
                result.SuppressedAnglianOptIns = message.Contract.SuppressedAnglianOptIns;

                message.Contract.WaitingESign = opportunity.contract.WaitingESign;
                message.Contract.ESignStatus = opportunity.contract.ESignStatus;

                message.Contract.allowVOC = opportunity.contract.allowVOC;

                message.Contract.customerDocs = opportunity.contract.customerDocs;

            }

            await this.mapLead(opportunity.lead, message.Opportunity);
            result.lead = opportunity.lead;
            result.lead.ConfirmedToReceiveEmails = this._mappingHelperService.mapYNToBool(opportunity.lead.ConfirmedToReceiveEmails);
            result.lead.ConfirmedToReceiveSpecialOffers = this._mappingHelperService.mapYNToBool(opportunity.lead.ConfirmedToReceiveSpecialOffers);
            if (!result.lead.Email || result.lead.Email.length === 0) {
                result.lead.noEmailAddress = true;
            }

            this.mapOrder(message.Order, result.lead.Product, result.lead.InstallingRegion, opportunity).then((order) => {
                result.order = order;
                mappingOrder.resolve();
            }); // Promise

            var mappingContract = defer();
            this.mapContract(message.Contract).then((contract) => {
                result.contract = contract;
                mappingContract.resolve();
            });

            await Promise.all([mappingOrder.promise, mappingContract.promise]).then(() => {
                mapping.resolve(result);
            });

            return mapping.promise;
        } catch (e) {
            throw e;
        }
    }

    public async mapToUnsoldVersion(opportunity, remoteOpportunity) {
        try {
            var isUnsoldVersionMatch = (v1, v2) => {
                var isSame =
                    v1.OrderDetails.Opportunity.RepNumber === v2.OrderDetails.Opportunity.RepNumber &&
                    v1.OrderDetails.MessageDate.toString() === v2.OrderDetails.MessageDate.toString();

                return isSame;
            };

            var localUnsoldVersionsCopy = JSON.parse(JSON.stringify(opportunity.unsoldVersions || []));

            opportunity.unsoldVersions = _.chain(opportunity.unsoldVersions)
                .reject((unsoldVersion) => {
                    return !_.find(remoteOpportunity.FailedSales, _.partial(isUnsoldVersionMatch, unsoldVersion));
                })
                .union(_.filter(remoteOpportunity.FailedSales, (failedSale) => {
                    return !_.find(opportunity.unsoldVersions, _.partial(isUnsoldVersionMatch, failedSale));
                }))
                .sortBy((unsoldVersion) => {
                    return unsoldVersion.OrderDetails.MessageDate;
                })
                .reverse()
                .value();

            var order;
            var contract;
            var lead;
            for (var unsoldVIdx in opportunity.unsoldVersions) {
                var entry = opportunity.unsoldVersions[unsoldVIdx];

                for (var idx in localUnsoldVersionsCopy) {
                    var localUnsoldVersionCopy = localUnsoldVersionsCopy[idx];
                    localUnsoldVersionsCopy[idx] = await this._encryptionService.decryptUnsoldVersion(localUnsoldVersionCopy);
                }
                var isNewUnsoldVersion = !localUnsoldVersionsCopy.some(uv => isUnsoldVersionMatch(uv, entry));

                if (isNewUnsoldVersion) {
                    //Sometimes it appears as if some properties are set as the unencrypted value (eg InstallationAddressE instead of InstallationAddress).
                    //It comes from the server like this, no idea why.
                    var unsoldVOpportunity = entry.OrderDetails.Opportunity;
                    var customerDetailsIsEmpty = !unsoldVOpportunity.CustomerDetails || Object.keys(unsoldVOpportunity.CustomerDetails).length === 0;
                    if (unsoldVOpportunity.CustomerDetailsE && customerDetailsIsEmpty) {
                        unsoldVOpportunity.CustomerDetails = JSON.parse(unsoldVOpportunity.CustomerDetailsE);
                        unsoldVOpportunity.CustomerDetailsE = null;
                    }
                    if (entry.OrderDetails.Contract.SellingAddressE && !entry.OrderDetails.Contract.SellingAddress) {
                        entry.OrderDetails.Contract.SellingAddress = JSON.parse(entry.OrderDetails.Contract.SellingAddressE);
                        entry.OrderDetails.Contract.SellingAddressE = null;
                    }
                    if (entry.OrderDetails.Contract.InstallationAddressE && !entry.OrderDetails.Contract.InstallationAddress) {
                        entry.OrderDetails.Contract.InstallationAddress = JSON.parse(entry.OrderDetails.Contract.InstallationAddressE);
                        entry.OrderDetails.Contract.InstallationAddressE = null;
                    }

                }



                order = entry.OrderDetails.Order;
                lead = entry.OrderDetails.Opportunity;
                lead.SalesTeamNumber = parseInt(lead.SalesTeamNumber);
                contract = entry.OrderDetails.Contract;
                this.doOpportunityTypeConversions(lead, order, contract);

            }

            return opportunity;
        }
        catch (e) {
            console.error(e);
        }
    }

    public async prepareExtraGroups(item) {
        var design = (await this._priceListService.getDesign(item.design.Id))[0];

        //extras groups
        var extrasGroups = this._priceListService.getExtrasGroupsForDesign(null, null, design.Properties);

        _.forEach(item.properties, (p) => {
            this.findExtra_designScreen(extrasGroups, p);
        });

        item.extrasGroups = extrasGroups;

        //defaultExtra
        this.setRequiredPropertiesToRedCircle(item.extrasGroups, item);
    }


    private setRequiredPropertiesToRedCircle(extrasGroups, item) {
        var extrasselected;
        _.each(extrasGroups, function (property) {
            if (property.IsAttributeRequired) {
                var multiSelect = false;

                extrasselected = _.some(property.data, function (d) {
                    if (d.isSelected === undefined) {
                        if (item.defaultExtra !== undefined && item.defaultExtra !== null) {
                            //if (item.defaultExtra.length > 0) {
                            if (property.AreAttributesMutex === false) {
                                if (item.defaultExtra[d.Name] === d.Code) {
                                    d.isSelected = true;
                                }
                            } else {
                                if (item.defaultExtra[property.title] === d.Code) {
                                    d.isSelected = true;
                                }

                            }
                            //}
                        }
                    }
                    if (d.isSelected === true) {
                        if (item.defaultExtra == null || item.defaultExtra instanceof Array) {
                            item.defaultExtra = {};
                        }
                        //if ($rootScope.ListOfMultipleSelectWindow.indexOf(property.title) !== -1) {
                        // 16-Sep-2016 as discussed with greg & Sukdev  if areAttributesMutex Exculsive = false means Multi selection else single selection correct 
                        if (property.AreAttributesMutex === false) {
                            item.defaultExtra[d.Name] = d.Code;
                        } else {
                            d.isViewing = true;
                            item.defaultExtra[property.title] = d.Code;
                        }

                        multiSelect = true;
                    }

                });
                if (property.specialSelections !== undefined && property.specialSelections !== null) {
                    if (property.specialSelections.length > 0) {
                        multiSelect = true;
                        property.data[0].isSelected = true;
                    }
                }
                if (!multiSelect) {
                    property.data[0].Notselected = true;
                }

            }
            else {
                extrasselected = _.some(property.data, function (d) {
                    if (d.isSelected === true) {
                        //d.isViewing = true;
                        if (item.defaultExtra == null || item.defaultExtra instanceof Array) {
                            item.defaultExtra = {};
                        }
                        //if ($rootScope.ListOfMultipleSelectWindow.indexOf(property.title) !== -1) {
                        // 16-Sep-2016 as discussed with greg & Sukdev  if areAttributesMutex Exculsive = false means Multi selection else single selection correct 
                        if (property.AreAttributesMutex === false) {
                            item.defaultExtra[d.Name] = d.Code;
                        } else {
                            d.isViewing = true;
                            item.defaultExtra[property.title] = d.Code;
                        }
                    }

                });
            }

        });
    }

    private mapLead(lead, message) {
        var details = message.CustomerDetails;
        lead.Title = details.Title;
        lead.Forename = details.Forename;
        lead.Surname = details.Surname;
        lead.Relationship = details.Relationship;
        lead.Title2 = details.Title2;
        lead.Forename2 = details.Forename2;
        lead.Surname2 = details.Surname2;
        lead.Relationship2 = details.Relationship2;
        lead.Address1 = details.AddressLine1;
        lead.Address2 = details.AddressLine2;
        lead.Address3 = details.AddressLine3;
        lead.Address4 = details.AddressLine4;
        lead.PostCode = details.Postcode;

        lead.Email = details.EmailAddress ? [{ EmailAddress: details.EmailAddress }] : [];

        lead.PhoneNumber = details.PhoneNumber;
        lead.ContactDetails = details.ContactDetails;
        lead.ContactMethod = details.PreferredContactMethod;
        lead.Documents = details.Documents;

        if (message.PromotionUnderstanding) {
            lead.promotionUnderstanding = message.PromotionUnderstanding;
        }

        if (message.funding) {
            lead.funding = message.funding;
        }

        if (message.budget) {
            lead.budget = message.budget;
        }

        if (message.cashLumpSum) {
            lead.cashLumpSum = message.cashLumpSum;
        }

        if (message.timescale) {
            lead.timescale = message.timescale;
        }

        if (message.timescaleOther) {
            lead.timescaleOther = message.timescaleOther;
        }

        if (message.uniqueTrackingId) {
            lead.uniqueTrackingId = message.uniqueTrackingId;
        }

        this.doOpportunityTypeConversions(lead, null, null);
    }

    private mapFromPriorities(priorities, product) {
        var mapping = defer();
        var selectedPriorities = [];

        if (priorities != null && !_.isArray(priorities)) {
            // Log error.
            //loggerService.logError('Unable to map [PRIORITIES] as it is not an array');
            mapping.resolve([]);
            return mapping.promise;
        }

        this._referenceDataService.getReferenceData('customerPriorities', product).then((customerPriorities) => {
            _.forEach(priorities, (selected) => {
                var found = _.find(customerPriorities, (p) => { return p.Description == selected.Description; });


                if (found) {
                    found.isMet = selected.IsMet;
                    selectedPriorities.push(found);
                }
                else {
                    if (selected.IsCustom === true) {
                        selected.IsActive = true;
                        selected.isMet = selected.IsMet;
                        selectedPriorities.push(selected);
                    }
                }
            });

            var availableToSelectPriorities = customerPriorities.filter(cp => !selectedPriorities.some(sp => sp.Description === cp.Description));

            mapping.resolve(
                {
                    selectedPriorities: selectedPriorities,
                    customerPriorities: availableToSelectPriorities
                });
        });

        return mapping.promise;
    }

    private mapFromMarketingOptions(options) {
        var mapping = defer();

        this._referenceDataService.getReferenceData('marketing', null).then((marketingOptions) => {

            if (options != null && !_.isArray(options)) {
                // Log error.
                //loggerService.logError('Unable to map marketing options as it is not an array');
            }

            _.forEach(options, function (selected) {
                try {
                    var option = _.find(marketingOptions, (opt) => { return opt.Id == selected.Id; });
                    option.selected = true;
                    option.details = selected.Details;
                    //Add condition because of 7025
                    if (selected.AdditionalSelection) {
                        option.selected = false;
                    }
                    if (selected.CollectContactInfo !== undefined && selected.CollectContactInfo !== null) {
                        option.collectContactInfo = selected.CollectContactInfo;
                    }
                    option.ContactMethods = selected.ContactMethods;
                } catch (err) {
                    // TODO Ignore for now - this is throwing an exception because options are coming back in an object not an array
                }
            });

            mapping.resolve(marketingOptions);
        });

        return mapping.promise;
    }

    private async mapRooftrim(items) {
        var mapping = [];

        var designGroups = _.groupBy(items, (i) => { return i.designGroup; });

        _.forEach(designGroups, (group) => {
            mapping.push(this.mapRooftrimGroup(group));
        });

        var result = await Promise.all(mapping);
        return result;
    }

    private async mapFromItems(items) {
        var mapping = [];

        items.forEach(entry => {
            mapping.push(this.mapItem(entry));
        });

        var mappedItems = await Promise.all(mapping);
        return mappedItems;
    }

    private mapRooftrimItem(i) {
        var mapping = defer();
        var item: any = {};

        this.getDesign(i.ProductCode).then((design) => {
            if (design) {
                item.selected = design;
                item.isDesignComplete = true;
                item.selected.extrasGroups = this._priceListService.getExtrasGroupsForDesign(design,design.ProductGroup, design.Properties);
                item.extrasGroups = this._priceListService.getExtrasGroupsForDesign(design, design.ProductGroup, design.Properties);
                item.colour = this.mapColour(i.Properties, design);
                item.material = this.mapMaterial(i.Properties, design);
                item.tempProps = i.Properties;
                mapping.resolve(item);
            } else {
                //loggerService.logError('Unable to map [DESIGN] ' + i.ProductCode + ' - Design could not be found');
                mapping.resolve(null);
            }


        });

        return mapping.promise;
    }

    private getDesign(code) {
        return this._priceListService.getDesignByCode(code);
    }

    private getSelectedTemplate(design, code) {
        var selectedTemplate = _.find(design.Templates, (t) => { return t.Code == code; });
        if (!selectedTemplate && design.Templates && design.Templates.length) {
            selectedTemplate = design.Templates[0];
        }

        return selectedTemplate;
    }

    private mapCustomTemplate(template, design) {
        if (!template) {
            return null;
        }

        try {
            var templateOptions = this._DesignService.getTemplateOptions(design.Properties);
            var customTemplate = this._DesignService.setupCustomWindow(Number(template.PanelsHigh), Number(template.PanelsWide), [], templateOptions);

            _.forEach(template.Slots, (s) => {
                // Get the corners
                var topLeftCol = Number(s.TopLeft.Column);
                var topLeftRow = Number(s.TopLeft.Row);
                var bottomRightCol = Number(s.BottomRight.Column);
                var bottomRightRow = Number(s.BottomRight.Row);

                // If corners are different we've got a merge somewhere
                var isMerged = topLeftCol != bottomRightCol || topLeftRow != bottomRightRow;

                if (isMerged) {
                    for (var row = topLeftRow - 1; row < bottomRightRow; row++) {
                        for (var col = topLeftCol - 1; col < bottomRightCol; col++) {
                            customTemplate[row][col].hide = true;
                        }
                    }
                }

                customTemplate[topLeftRow - 1][topLeftCol - 1] = {
                    TopLeft: s.TopLeft,
                    BottomRight: s.BottomRight,
                    PropertyTemplate: _.find(templateOptions.Attributes, (t) => { return t.Code == s.PanelType; }),
                    isMerged: isMerged,
                    rowspan: bottomRightRow - topLeftRow + 1,
                    colspan: bottomRightCol - topLeftCol + 1,
                    hide: false
                };
            });

            return customTemplate;
        } catch (err) {
            //loggerService.logError('Unable to map template for design: ' + design.Code);
            return null;
        }
    }

    private async mapRooftrimGroup(group) {
        var mapping = defer();

        var rooftrimItem: any = {
            included: true,
            isDesignComplete: true,
            itemPrice: Number(group[0].ListPrice),
            nonDiscountables: Number(group[0].NonDiscountables),
            notes: group[0].Notes,
            properties: [],
            name: group[0].Description,
            size: Number(group[0].Size)
        };

        var mappingItems = [];
        _.forEach(group, (item) => {
            mappingItems.push(this.mapRooftrimItem(item));
        });

        var items = await Promise.all(mappingItems)

        if (_.any(items, (i) => { return i === null; })) {
            // Something didn't map right so mark it as incomplete.
            rooftrimItem.isDesignComplete = false;

            // Filter out any that weren't mapped
            items = _.filter(items, (i) => { return i != null; });
        }

        if (items.length) {

            rooftrimItem.product = items[0].selected.Product;
            rooftrimItem.superProduct = items[0].selected.Product.ProductGroup.Name;

            rooftrimItem.designs = items;

            if (items[0].tempProps != null && !_.isArray(items[0].tempProps)) {
                // Log error.
                //loggerService.logError('Unable to map [ROOFTRIM PROPERTIES] as it is not an array');
            } else {

                _.forEach(items[0].tempProps, (p) => {
                    var found = null;

                    if (p.Class != 'MATERIAL' && p.Class != 'COLOUR') {

                        // for each property, look through each design to find which one it applies to. If we go through everything and don't find it then it's POA.
                        // Once found, configure it.
                        _.forEach(items, (mappedDesign) => {
                            if (!found) {
                                var designProperty = this.findExtraForRooftTrim(mappedDesign.extrasGroups, p);

                                if (designProperty) {
                                    designProperty.quantity = Number(p.Quantity);
                                    designProperty.price = Number(p.Price);
                                    designProperty.Name = p.Description;
                                    designProperty.Amount = Number(p.Price);
                                    this._DesignService.configureProperties(designProperty, mappedDesign.selected.PropertyPrices, null, null, rooftrimItem.size);
                                }

                                found = designProperty;
                            }

                        });

                        if (!found) {
                            // price on application instead
                            found = this._priceListService.getPriceOnApplicationProperty(true).data[0];
                            found.Name = p.Description;
                            found.Amount = Number(p.Price);
                            found.price = Number(p.Price);
                        }

                        if ((found) && (p.designId)) {
                            found.designId = p.designId;
                        }

                        rooftrimItem.properties.push(found);
                    }
                });
            }

            items[0].tempProps = null;
        }

        mapping.resolve(rooftrimItem);

        return mapping.promise;
    }

    private async mapFromPhotos(photos, opportunityId): Promise<any> {

        var dataSplit = [];
        for (var pIdx in photos) {
            var p = photos[pIdx];
            var mappingImage = defer();
            try {
                dataSplit = p.Photograph.split(',');
                var dataUrl = dataSplit[1];
                var fileName = 'opportunity_' + opportunityId + '_' + pIdx;

                var url = await this._mediaService.cacheFileAsync(dataUrl, fileName, 'camera-photos');
                var result = { note: p.Title, url: url, includeInSOW: p.includeInSOW };
                mappingImage.resolve(result);
            } catch (err) {
                //loggerService.logError('Unable to map photo: ' + p.Title);
                console.error(err);
            }
        }

    }

    private async mapFromDiscPhotos(DiscPhotos, opportunityId): Promise<any> {
        var mapping: Promise<any>[] = [];
        var dataUrl;
        var p;
        for (var photoIdx in DiscPhotos) {
            p = DiscPhotos[photoIdx];
            var mappingImage = defer();
            try {

                dataUrl = p.Photobase64.split(',')[1];
                var fileName = "opportunity_" + opportunityId;
                var savedUrl = await this._mediaService.cacheImageByDataUrl(dataUrl, fileName);

                var result: any = {};
                result.DiscountId = p.DiscountId;
                result.Photobase64 = p.Photobase64;
                result.PhotoURL = savedUrl;

                mappingImage.resolve(result);
            } catch (err) {
                //loggerService.logError('Unable to map mandatory discount photos: ' + p.Title);
                mappingImage.resolve({});
            }
            mapping.push(mappingImage.promise);
        }

        return Promise.all(mapping);
    }

    private mapFromDepositPayments(deposits) {
        var mapped = [];

        _.forEach(deposits, (d) => {
            mapped.push({
                method: d.PaymentMethod,
                amount: Number(d.Value),
                paytelRef: d.CardTransactionId
                // IMAGE ?
            });
        });

        return mapped;
    }

    private mapFromSignatures(signatures) {
        var mapped = [];
        _.forEach(signatures, (sig) => {
            if (sig) {
                mapped.push({
                    image: sig.Signature,
                    name: sig.PrintedName
                });
            }
        });

        return mapped;
    }

    private mapFromFinanceSource(financeSource) {
        var search = defer();

        if (!financeSource) {
            search.resolve(null);
        }

        this._referenceDataService.getReferenceData('financeSource', null).then((sources) => {
            search.resolve(_.find(sources, (s) => { return s.Code == financeSource; }));
        });

        return search.promise;
    }

    private mapContract(contract): Promise<any> {
        var mapping = defer();

        //If not defined then set value.
        if (contract.LivingSpacePhysicalBarrierRemain === undefined) {
            contract.LivingSpacePhysicalBarrierRemain = 'N';
        }

        //If not defined then set value.
        if (contract.LivingSpaceHotWaterHeatingSystemRequired === undefined) {
            contract.LivingSpaceHotWaterHeatingSystemRequired = 'N';
        }

        var result: any = {
            installationAddress: contract.InstallationAddress,
            invoiceAddress: contract.SellingAddress,
            isSold: this._mappingHelperService.mapYNToNumber(contract.IsSold),
            CashPrice: Number(contract.CashPrice),
            ValueOfFinance: Number(contract.ValueOfFinance),
            financeReference: contract.FinanceReference,
            depositAmount: Number(contract.DepositValue),
            deposits: this.mapFromDepositPayments(contract.DepositPayments),
            slidingScalePercent: Number(contract.SlidingScalePercentage),
            signatures: this.mapFromSignatures(contract.Signatures),
            Notes: contract.Notes,

            cpPhysicalBarrier: this._mappingHelperService.mapYNToBool(contract.LivingSpacePhysicalBarrierRemain),
            cpHotWaterSystem: this._mappingHelperService.mapYNToBool(contract.LivingSpaceHotWaterHeatingSystemRequired),

            permissionsWidthdrawn: this._mappingHelperService.mapYNToBool(contract.PlanningPermissionWidthdrawn),
            consentRequired: this._mappingHelperService.mapYNToBool(contract.ListedBuildingConsentRequired),
            conversionArea: this._mappingHelperService.mapYNToBool(contract.InConservationArea),
            article4: this._mappingHelperService.mapYNToBool(contract.Article4RightsApply),
            housingAssociation: this._mappingHelperService.mapYNToBool(contract.IsPropertyLeasehold),
            localAuthority: contract.LocalAuthorityName,
            InstalledAbutsSoffit: this._mappingHelperService.mapYNToBool(contract.abutsTheSoffit),
            notBeforeDate: this._mappingHelperService.convertYYYYMMDDToDate(contract.InstallationDate, true),
            declaration: contract.Declaration,
            OnMyBehalfAgentName: contract.OnMyBehalfAgentName,
            OnMyBehalfAgentConfirmed: contract.OnMyBehalfAgentConfirmed,
            OnMyBehalfTitleText: contract.OnMyBehalfTitleText,
            OnMyBehalfLabelTextBefore: contract.OnMyBehalfLabelTextBefore,
            OnMyBehalfLabelTextAfter: contract.OnMyBehalfLabelTextAfter,
            balancePayable: parseFloat(contract.BalancePayableOnInstallation || "0"),
            FinanceAmount: parseFloat(contract.AmountPayableOnFinance || "0"),
            WaitingESign: contract.WaitingESign,
            ESignStatus: contract.ESignStatus,
            allowVOC: contract.allowVOC,
            customerDocs: contract.customerDocs
        };



        this.mapFromFinanceSource(contract.FinanceSource).then((source) => {
            result.sourceOfFinance = source;
            this.doOpportunityTypeConversions(null, null, result);

            mapping.resolve(result);
        });

        return mapping.promise;
    }

    private async mapOrder(order, product, region, opportunity) {
        var opportunityId = opportunity.Id;
        var mapping = defer();

        var result: any = {
            OrderNumber: order.OrderNumber,
            slidingScaleEntry: Number(order.CalculatedSlidingScaleStartPoint),
            notes: order.Notes,
            illustration: order.Illustration,
            priceIllustration: order.PriceIllustration,
            ecohome: order.Ecohome,
            proposedProduct: order.CustomerInterest,
            charges: order.OrderCharges,
            sowPrices: order.sowPrices,
            sowQuestions: order.sowQuestions,
            documents: order.documents,
            doorbell: order.doorbell,
            HelpUsHelpYouAgreed: order.HelpUsHelpYouAgreed,
            RemoteSelling: order.RemoteSelling,
            RemoteSellingCustomerDetails: order.RemoteSellingCustomerDetails
        };

        //discount trail ids in the system are a mixture of ints/strings, needs to be converted back when we send to application
        if (result.priceIllustration && result.priceIllustration.DiscountTrail) {
            result.priceIllustration.DiscountTrail.forEach(entry => entry.id = entry.id.toString());
        }

        //some of the prices we get from the server are strings
        if (result.sowPrices) {
            result.sowPrices.FinanceAmount = parseFloat(result.sowPrices.FinanceAmount || "0");
            result.sowPrices.cashPrice = parseFloat(result.sowPrices.cashPrice || "0");
            result.sowPrices.priceAfterFinanceDiscount = parseFloat(result.sowPrices.priceAfterFinanceDiscount || "0");
            result.sowPrices.balancePayable = parseFloat(result.sowPrices.balancePayable || "0");
        }

        var mappingPriorities = defer();
        var mappingOptions = defer();
        var mappingItems = defer();
        var mappingDiscounts = defer();
        var mappingPhotos = defer();
        var mappingDiscPhotos = defer();

        if (product == 'CP') {

            result.cpDesighReq = {};
            result.cpShowHome = {};

            if (order.LivingSpace.CustomerDesignRequirements !== null) {
                result.cpDesighReq = order.LivingSpace.CustomerDesignRequirements;
            }

            if (order.LivingSpace.ShowhomeSurvey !== null) {
                result.cpShowHome = order.LivingSpace.ShowhomeSurvey;
            }

            result.cpFiles = [];

            if (order.LivingSpace.UnSoldFiles !== undefined) {

                order.LivingSpace.UnSoldFiles.forEach(function forEachFile(file) {

                    var newfile: any = {};
                    newfile.files = {};

                    newfile.TotalPrice = Number(file.TotalPrice);

                    newfile.jobname = file.jobname;
                    newfile.filename = file.filename;
                    newfile.feasibilityRef = file.feasibilityRef;
                    newfile.buildingWorkCost = Number(file.buildingWorkCost);
                    newfile.ADGCost = Number(file.ADGCost);
                    newfile.ProductCost = Number(file.ProductCost);
                    newfile.OtherCost = Number(file.OtherCost);
                    newfile.datemodified = new Date(file.datemodified);
                    newfile.files.frontpng = file.files_frontpng;
                    newfile.files.leftpng = file.files_leftpng;
                    newfile.files.rightpng = file.files_rightpng;
                    newfile.files.toppng = file.files_toppng;

                    newfile.files.right3dpng = null;
                    if (file.files_right3dpng) {
                        newfile.files.right3dpng = file.files_right3dpng;
                    }

                    newfile.files.left3dpng = null;
                    if (file.files_left3dpng) {
                        newfile.files.left3dpng = file.files_left3dpng;
                    }

                    newfile.files.WallFiles = file.files_WallFiles;

                    newfile.files.front_elevation_path = file.files_front_elevation_path;
                    newfile.files.left_elevation_path = file.files_left_elevation_path;
                    newfile.files.right_elevation_path = file.files_right_elevation_path;
                    newfile.files.top_elevation_path = file.files_top_elevation_path;
                    newfile.files.left3d_elevation_path = file.files_left3d_elevation_path;
                    newfile.files.right3d_elevation_path = file.files_right3d_elevation_path;

                    newfile.files.rw6base64 = file.files_rw6base64;
                    newfile.files.xmlbase64 = file.files_xmlbase64;
                    newfile.files.rwbbase64 = file.files_rwbbase64;
                    //newfile.files.exitbase64 = file.files_exitbase64;

                    newfile.launchcode = file.launchcode;
                    newfile.controlCode = file.controlCode;
                    newfile.controlExit = file.controlExit;

                    newfile.files.envisageversion = file.files_envisageversion;
                    newfile.files.regionalmarkupXML = file.files_regionalmarkupXML;

                    newfile.xmlTotalPrice = Number(file.xmlTotalPrice);
                    newfile.xmlbuildingWorkCost = Number(file.xmlbuildingWorkCost);
                    newfile.xmlADGCost = Number(file.xmlADGCost);
                    newfile.xmlProductCost = Number(file.xmlProductCost);
                    newfile.xmlOtherCost = Number(file.xmlOtherCost);

                    newfile.SolaroofUnitQuantity = Number(file.SolaroofUnitQuantity);
                    newfile.Ultra2UnitQuantity = Number(file.Ultra2UnitQuantity);

                    if (file.vatRate) {

                        newfile.vatInfo = {};

                        newfile.vatInfo.vatRate = file.vatRate;
                        newfile.vatInfo.vatRateCalc = file.vatRateCalc;

                        newfile.vatInfo.xmlProductCostPreVAT = file.xmlProductCostPreVAT;
                        newfile.vatInfo.xmlbuildingWorkCostPreVAT = file.xmlbuildingWorkCostPreVAT;
                        newfile.vatInfo.xmlADGCostPreVAT = file.xmlADGCostPreVAT;
                        newfile.vatInfo.xmlOtherCostPreVAT = file.xmlOtherCostPreVAT;
                        newfile.vatInfo.xmlTotalPricePreVAT = file.xmlTotalPricePreVAT;

                        newfile.vatInfo.xmlProductCostVATAmount = file.xmlProductCostVATAmount;
                        newfile.vatInfo.xmlbuildingWorkCostVATAmount = file.xmlbuildingWorkCostVATAmount;
                        newfile.vatInfo.xmlADGCostVATAmount = file.xmlADGCostVATAmount;
                        newfile.vatInfo.xmlOtherCostVATAmount = file.xmlOtherCostVATAmount;
                        newfile.vatInfo.xmlTotalPriceVATAmount = file.xmlTotalPriceVATAmount;

                    }


                    if (file.vatInfo) {
                        newfile.vatInfo = file.vatInfo;
                    }

                    newfile.systemname = file.systemname;
                    newfile.jobname = file.jobname;

                    result.cpFiles.push(newfile);

                });

            }
        }

        this.mapFromPriorities(order.Priorities, product).then((priorities) => {
            result.selectedPriorities = priorities.selectedPriorities;
            result.customerPriorities = priorities.customerPriorities;

            mappingPriorities.resolve();
        });

        this.mapFromMarketingOptions(order.MarketingOptions).then((opts) => {
            result.marketingOptions = opts;
            mappingOptions.resolve();
        });

        if (order.Items != null && !_.isArray(order.Items)) {
            // Log error.
            //loggerService.logError('Unable to map [ITEMS] as it is not an array');
            result.items = [];
            mappingItems.resolve();
        } else {
            //if (order.Items && order.Items.length && order.Items[0].designGroup) {
            var isRT = product === 'RT';
            var mappingItems2;
            if (isRT) {
                // Rooftrim
                mappingItems2 = this.mapRooftrim(order.Items);
            } else {
                mappingItems2 = this.mapFromItems(order.Items);
            }

            mappingItems2.then(async items => {
                for (var idx in items) {
                    var i = items[idx];
                    if (i.selectedTemplate) {
                        i.selectedTemplate.Id = i.selectedTemplate.Id.toString();
                    }

                    //set the local id for the items
                    //we dont need to set it if theres already one
                    if (i.uniqueId) {
                        return;
                    }

                    var allIds = items
                        .map(i => i.uniqueId)
                        .filter(i => i);

                    var nextAvailableId;

                    if (!allIds.count) {
                        nextAvailableId = 1;
                    }
                    else {
                        nextAvailableId = Math.max(allIds) + 1;
                    }

                    i.uniqueId = nextAvailableId;
                    if (!isRT) {
                        //set the extrasgroups
                        await this.prepareExtraGroups(i);
                    }

                }

                result.items = items;
                mappingItems.resolve();
            })



        }

        mappingItems.promise.then(() => {

            //If product does not match, then this routine errors and causes sync to hang.
            //var materialCodes = result.items.filter(function (item) { return item.material !== undefined; }).map(function (item) { return item.material.Code; });

            var materialCodes = result.items.filter((item) => {
                return item.material !== undefined;
            }).map((item) => {
                if (item.material !== null) {
                    return item.material.Code;
                }
                else {
                    return '';
                }
            });

            this.mapFromDiscounts(order.Discounts, product, materialCodes, region, Number(order.ListPrice), opportunity.order.appliedDiscounts, opportunity.lead.LeadSource, opportunity.lead.MediaCode, order.PriceIllustration).then((discounts) => {
                result.appliedDiscounts = discounts;
                mappingDiscounts.resolve();
            });
        });

        this.mapFromPhotos(order.Photos, opportunityId).then((photos) => {
            result.photos = photos;
            mappingPhotos.resolve();
        });

        this.mapFromDiscPhotos(opportunity.order.MandatoryDiscPhotos, opportunityId).then((discphotos) => {
            result.MandatoryDiscPhotos = discphotos;
            mappingDiscPhotos.resolve();
        });

        await Promise.all([mappingPriorities.promise, mappingOptions.promise, mappingItems.promise, mappingDiscounts.promise, mappingPhotos.promise, mappingDiscPhotos.promise]).then(() => {
            this.doOpportunityTypeConversions(null, result, null);

            mapping.resolve(result);
        });

        return mapping.promise;
    }

    private doOpportunityTypeConversions(lead, order, contract) {
        if (lead) {
            lead.SalesTeamNumber = this.convertToNumberZeroIfNaN(lead.SalesTeamNumber);
        }

        if (order) {
            if (order.priceIllustration && order.priceIllustration.DiscountTrail) {
                order.priceIllustration.DiscountTrail.forEach(entry => {
                    entry.id = entry.id.toString();
                    entry.discountApplied = this.convertToNumberZeroIfNaN(entry.discountApplied);
                });
            }
            else if (order.PriceIllustration && order.PriceIllustration.DiscountTrail) {
                order.PriceIllustration.DiscountTrail.forEach(entry => {
                    entry.id = entry.id.toString();
                    entry.discountApplied = this.convertToNumberZeroIfNaN(entry.discountApplied);
                });
            }

            //some of the prices we get from the server are strings
            if (order.sowPrices) {
                order.sowPrices.FinanceAmount = this.convertToNumberZeroIfNaN(order.sowPrices.FinanceAmount);
                order.sowPrices.cashPrice = this.convertToNumberZeroIfNaN(order.sowPrices.cashPrice);
                order.sowPrices.priceAfterFinanceDiscount = this.convertToNumberZeroIfNaN(order.sowPrices.priceAfterFinanceDiscount);
                order.sowPrices.balancePayable = this.convertToNumberZeroIfNaN(order.sowPrices.balancePayable);
            }

            //for unsold version mapping
            if (order.Items && order.Items.length) {
                order.Items.forEach(i => {
                    if (i.SelectedTemplate) {
                        i.SelectedTemplate.Id = parseInt(i.SelectedTemplate.Id.toString());
                    }

                    i.Properties.forEach(p => {
                        p.Price = this.convertToNumberZeroIfNaN(p.Price);
                    })

                })


            } //for normal opp mapping
            else if (order.items) {
                order.items.forEach(i => {
                    if (i.selectedTemplate) {
                        i.selectedTemplate.Id = parseInt(i.selectedTemplate.Id);
                    }

                    i.properties.forEach(p => {
                        p.price = this.convertToNumberZeroIfNaN(p.price);
                    })
                })


            }

            order.CalculatedSlidingScaleStartPoint = this.convertToNumberZeroIfNaN(order.CalculatedSlidingScaleStartPoint)
            order.ListPrice = this.convertToNumberZeroIfNaN(order.ListPrice);

        }

        if (contract) {
            contract.AdminFee = this.convertToNumberZeroIfNaN(contract.AdminFee);
            contract.AmountPayableOnFinance = this.convertToNumberZeroIfNaN(contract.AmountPayableOnFinance);
            contract.BalancePayableOnInstallation = this.convertToNumberZeroIfNaN(contract.BalancePayableOnInstallation);
            contract.GrossAdditionalWork = this.convertToNumberZeroIfNaN(contract.GrossAdditionalWork);
            contract.AdminFee = this.convertToNumberZeroIfNaN(contract.AdminFee);
            contract.GrossExtras = this.convertToNumberZeroIfNaN(contract.GrossExtras);
            contract.GrossProductPrice = this.convertToNumberZeroIfNaN(contract.GrossProductPrice);
            contract.GrossSGO = this.convertToNumberZeroIfNaN(contract.GrossSGO);
            contract.GrossOrderValue = this.convertToNumberZeroIfNaN(contract.GrossOrderValue);
            contract.GrossExtras = this.convertToNumberZeroIfNaN(contract.GrossExtras);
            contract.ValueOfFinance = this.convertToNumberZeroIfNaN(contract.ValueOfFinance);
            contract.DepositValue = this.convertToNumberZeroIfNaN(contract.DepositValue);
        }
    }

    private convertToNumberZeroIfNaN(toTest) {
        if (!toTest) {
            return 0;
        }
        return isNaN(toTest) ? 0 : parseFloat(toTest);
    }
    private mapFromDiscounts(discounts, product, materialCodes, regionNumber, totalPrice, existingAppliedDiscounts, leadSource, mediaCode, PriceIllustration) {
        try {
            var mapping = defer();

            if (discounts != null && !_.isArray(discounts)) {
                // Log error.
                //loggerService.logError('Unable to map [DISCOUNTS] as it is not an array');
                mapping.resolve([]);
                return mapping.promise;
            }

            this._referenceDataService.getReferenceData('discounts', null).then((dbDiscounts) => {
                var discountOptions = this._discountService.setupDiscountOptions(dbDiscounts, product, materialCodes, regionNumber, totalPrice, undefined, undefined, undefined, leadSource, mediaCode);

                var appliedDiscounts = [];
                _.forEach(discounts, (d) => {
                    var match = _.find(discountOptions, (opt) => { return d.DiscountId == opt.Id; });

                    d.DiscountValue = Number(d.DiscountValue);

                    if (!match && d.DiscountId == 'slidingScale') {


                        var iDiscountType = 1;
                        var eovInfo = null;
                        var oAddEov = null;
                        if (PriceIllustration && PriceIllustration.DiscountTrail && (!d.discountType && isNaN(d.discountType))) {
                            var oPIDisc = null;
                            for (var iPIDiscIdx = 0; iPIDiscIdx < PriceIllustration.DiscountTrail.length; iPIDiscIdx++) {
                                oPIDisc = PriceIllustration.DiscountTrail[iPIDiscIdx];
                                if (oPIDisc.id === d.DiscountId) {
                                    oAddEov = oPIDisc.AdditionalEovDiscount;
                                    eovInfo = oPIDisc.eovInfo;
                                    iDiscountType = oPIDisc.discountType;
                                    break;
                                }
                            }
                        }

                        match = this._discountService.getSlidingScale(d.DiscountName, d.DiscountValue, undefined, iDiscountType);
                        match.eovInfo = eovInfo;
                        match.AdditionalEovDiscount = oAddEov;
                        match.ReferenceData = [];
                    }

                    else if (!match && d.DiscountId == 'finance' && d.DiscountValue > 0) {
                        match = this._discountService.getFinanceDiscount('finance', product, d.DiscountValue);
                    }

                    else if (!match && d.DiscountId == 'finance' && d.DiscountValue < 0) {
                        match = this._discountService.getRemoveFinanceDiscount('finance', 'removeFinance');
                    }
                    if (match && match.ReferenceData) {
                        _.forEach(existingAppliedDiscounts, (ad) => {
                            _.forEach(ad.appliedOptions, (ao) => {
                                if (ao.Id.toString() === match.Id.toString()) {
                                    if (d.DiscountId == 'slidingScale') {
                                        match.AdditionalEovDiscount = ao.AdditionalEovDiscount;
                                        match.selectedHUHYOptions = d.selectedHUHYOptions;
                                        match.eovInfo = d.eovInfo;
                                    }
                                    else {
                                        match.ReferenceData = ao.ReferenceData;
                                        match.amountAdded = ao.amountAdded;
                                        match.selectedHUHYOptions = d.selectedHUHYOptions;
                                    }
                                }
                            });
                        });
                    }
                    if (d.selectedHUHYOptions) {
                        if (match && !match.selectedHUHYOptions) {
                            match.selectedHUHYOptions = d.selectedHUHYOptions;
                        }
                    }

                    if (match) { // Only map ones we have a match for. If no match, we don't have a valid discount.
                        appliedDiscounts = this._discountService.applyDiscount(appliedDiscounts, match);
                    }

                });


                mapping.resolve(appliedDiscounts);
            });

            return mapping.promise;
        } catch (e) {
            var error = e.Message;
        }
    }
    private async mapItem(i) {

        // Get design based on code
        var item: any = {
            area: i.area ? Number(i.area) : null,
            bay: i.Bay ? this.mapFromBay(i.Bay) : null,
            colourSelector: 0, // Not important.
            cols: i.CustomTemplate ? Number(i.CustomTemplate.PanelsWide) : null,
            height: i.Height ? Number(i.Height) : null,
            included: true,
            isDesignComplete: true,
            itemPrice: i.ListPrice ? Number(i.ListPrice) : 0,
            name: i.Description,
            nonDiscountables: i.NonDiscountables ? Number(i.NonDiscountables) : null,
            notes: i.Notes,
            rows: i.CustomTemplate ? Number(i.CustomTemplate.PanelsHigh) : null,
            size: i.Size ? Number(i.Size) : null,
            useWizardProcess: i.CustomTemplate != null,
            width: i.Width ? Number(i.Width) : null,
            confirmedReadCustomSizeGuide: i.confirmedReadCustomSizeGuide
        };


        // Deliberately not mapped - these are used on the design page and set on view item.
        item.priceOnApplication = null;
        item.priceOnApplicationNonDisc = null;

        if (i.ProductCode) {
            var design = await this.getDesign(i.ProductCode);
            if (design) {
                item.design = this._dataPruningService.stripDesign(design);
                item.product = design.Product;
                item.superProduct = design.Product.ProductGroup.Name;
                item.selectedTemplate = this.getSelectedTemplate(design, i.ProductCode);
                item.properties = this.mapFromProperties(i.Properties, design, item.width, item.height, item.size);
                item.colour = this.mapColour(i.Properties, design);
                item.colours = this.mapColours(i.Properties, design);
                item.material = this.mapMaterial(i.Properties, design);
                //if (item.colour != null) {
                //    for (let colour of item.colour) {
                //        var val = colour.price;
                //        colour.price = Number.isNaN(val) ? 0 : val;
                //        console.log("-----item.colour----VALUE--" + colour.price);
                //    }
                //}
                //if (item.material != null) {
                //    for (let _material of item.material) {
                //        var val = _material.price;
                //        _material.price = Number.isNaN(val) ? 0 : val;
                //        console.log("-----_material.price----VALUE--" + _material.price);
                //    }
                //}


                if (!item.material) {
                    item.isDesignComplete = false;
                }

                item.template = this.mapCustomTemplate(i.CustomTemplate, design);
                item.explosionConfirmations = this.mapExplosionConfirmations(i.Properties);
                item.nonStdColour = i.nonStdColour;

            } else {
                //loggerService.logError('Unable to map [DESIGN] ' + i.ProductCode + ' - Design could not be found');
                item.isDesignComplete = false;
            }

            return item;
        } else {
            item.isDesignComplete = false;
            return item;
        }

    }

    private mapExplosionConfirmations(properties) {

        var confirmations = [];
        var confirm: any = {};

        _.forEach(properties, (p) => {

            if (p.customerConfirmed) {

                confirm = {};
                confirm.propertyId = p.Id;
                confirm.confirmed = p.customerConfirmed;
                confirmations.push(confirm);
            }

        });


        return confirmations;

    }
    private findExtra(extrasGroups, property) {
        var found = null;
        _.forEach(extrasGroups, (group) => {
            if (!found) {
                _.forEach(group.data, (extra) => {
                    if (!found) {
                        if (extra.Code == property.PropertyId) {
                            found = extra;
                        } else if (extra.Attributes && extra.Attributes.length) {
                            var attr = _.find(extra.Attributes, (a) => { return a.Code == property.PropertyId; });
                            if (attr) {
                                found = attr;
                            }
                        }
                    }
                });
            }
        });

        return found;
    }

    //this was orig in design.js
    private findExtra_designScreen(extrasGroups, property) {
        var found = false;
        _.forEach(extrasGroups, function (group, i) {
            if (!found) {
                _.forEach(group.data, function (extra, j) {
                    if (!found) {
                        if (extra.Id == property.Id) {
                            found = true;
                            extrasGroups[i].data[j] = property;
                        } else if (extra.Attributes && extra.Attributes.length) {
                            var attr = _.find(extra.Attributes, function (a) { return a.Id == property.Id; });
                            if (attr) {
                                found = true;
                                var k = _.indexOf(extra.Attributes, attr);
                                extrasGroups[i].data[j].Attributes[k] = property;

                                if (group.specialSelections == null) {
                                    group.specialSelections = [];
                                }

                                group.specialSelections.push(property);
                            }
                        }
                    }
                });
            }
        });
    }

    private findExtraForRooftTrim(extrasGroups, property) {
        var found = null;
        _.forEach(extrasGroups, (group) => {
            if (!found) {
                _.forEach(group.data, (extra) => {
                    if (!found) {
                        if (extra.Code == property.PropertyId && extra.Id == property.Id) {
                            found = extra;
                        } else if (extra.Attributes && extra.Attributes.length) {
                            var attr = _.find(extra.Attributes, (a) => { return a.Code == property.PropertyId; });
                            if (attr) {
                                found = attr;
                            }
                        }
                    }
                });
            }
        });

        return found;
    }

    private mapColourObject(selectedColour, design) {
        var found = null;
        _.forEach(design.Colours, (c) => {
            if (!found) {
                found = _.find(c.Colours.Attributes, (colour) => { return colour.Code == selectedColour.PropertyId; });
            }
        });

        if (found) {
            found.quantity = Number(selectedColour.Quantity);
            found.price = Number(selectedColour.Price);
        }
        else {
            if (selectedColour.PropertyId == 'NONSTDCOLOUR') {
                found = "NONSTANDARD";
            }
        }

        return found;
    }

    private mapColour(properties, design) {
        // map colour
        if (properties != null && !_.isArray(properties)) {
            // Log error.
            //loggerService.logError('Unable to map [COLOUR] as PROPERTIES is not an array');
            return null;
        }

        //26-Sep-2016 PROT-6869 : this property has been added because if we select "GRP" and colour there are colour class in extra too so to differentiate , top "Choose Colour" Section and Extra Colour "Outside Leaf Colour" or "Inside Leaf Colour". 
        var colours = _.filter(properties, (p) => { return p.Class === 'COLOUR' && p.IsExtraData === undefined; });

        if (!colours.length) {
            // No colour.
            return null;
        }

        if (colours.length != 1) {
            // we're mapping colours instead,
            return null;
        }

        var selectedColour = colours[0];

        return this.mapColourObject(selectedColour, design);
    }

    private mapColours(properties, design) {
        // map colour
        if (properties != null && !_.isArray(properties)) {
            // Log error.
            //loggerService.logError('Unable to map [COLOURS] as PROPERTIES is not an array');
            return null;
        }

        var colours = _.filter(properties, (p) => { return p.Class === 'COLOUR'; });

        if (!colours.length) {
            // No colour.
            return null;
        }

        if (colours.length === 1) {
            // we're mapping colour instead,
            return null;
        }

        var mapped = [];

        _.forEach(colours, (colour) => {

            var mappedColour = this.mapColourObject(colour, design);

            if (mappedColour) {
                switch (colour.Description) {
                    case 'INSIDEFRAME':
                        mapped[1] = mappedColour;
                        break;
                    case 'INSIDESASH':
                        mapped[2] = mappedColour;
                        break;
                    case 'OUTSIDEFRAME':
                        mapped[3] = mappedColour;
                        break;
                    case 'OUTSIDESASH':
                        mapped[4] = mappedColour;
                        break;
                }
            }
        });

        return mapped;
    }

    private mapMaterial(properties, design) {
        // map material
        if (properties != null && !_.isArray(properties)) {
            // Log error.
            //loggerService.logError('Unable to map [MATERIAL] as PROPERTIES is not an array');
            return [];
        }

        var material = _.find(properties, (p) => { return p.Class === 'MATERIAL'; });

        if (!material) {
            return null;
        }

        var found = null;
        _.forEach(design.Colours, function (c) {
            if (!found) {
                if (c.Material.Code == material.PropertyId) {
                    found = c.Material;
                }
            }
        });

        if (!found) {
            return null;
        }

        found.quantity = Number(material.Quantity);
        found.price = Number(material.Price);
        found.price = Number.isNaN(found.price) ? 0 : Number(material.Price);
        return this._dataPruningService.stripMaterial(found);
    }

    private mapFromProperties(properties, design, width, height, size) {
        var mapped = [];
        var extrasGroups = this._priceListService.getExtrasGroupsForDesign(design, design.ProductGroup, design.Properties);
        var bayProps = _.find(design.Properties, (p) => { return p.Code === 'BAY'; });

        if (properties != null && !_.isArray(properties)) {
            // Log error.
            //loggerService.logError('Unable to map [PROPERTIES] as it is not an array');
            return [];
        }

        _.forEach(properties, (p) => {
            // dont map material or colour
            //26-Sep-2016 PROT-6869 : this property has been added because if we select "GRP" and colour there are colour class in extra too so to differentiate , top "Choose Colour" Section and Extra Colour "Outside Leaf Colour" or "Inside Leaf Colour". 
            var isExtraData = false;
            if (p.Class === 'MATERIAL' || p.Class === 'COLOUR') {
                if (p.IsExtraData === true) {
                    isExtraData = true;
                }

            }
            else { isExtraData = true; }

            if (isExtraData) {
                var designProperty: any = {};
                if (p.PropertyId == 'PRICEONAPPLICATION') {

                    designProperty = this._priceListService.getPriceOnApplicationProperty(p.IsDiscountable).data[0];
                    designProperty.Name = p.Description;
                    designProperty.Amount = Number(p.Price);
                    designProperty.price = Number(p.Price);

                }
                else {

                    designProperty = this.findExtra(extrasGroups, p);
                    if (designProperty) {
                        designProperty.quantity = Number(p.Quantity);
                        designProperty.price = Number(p.Price);
                        designProperty.isDiscountable = p.IsDiscountable;
                    }
                    else {

                        if (bayProps && bayProps.Attributes) {

                            designProperty = _.find(bayProps.Attributes, (t) => { return t.Code == p.PropertyId; });

                            if (designProperty) {
                                designProperty.quantity = Number(p.Quantity);
                                designProperty.price = Number(p.Price);
                                designProperty.isDiscountable = p.IsDiscountable;

                            }
                        }

                    }
                }

                if (designProperty) {

                    this._DesignService.configureProperties(designProperty, design.PropertyPrices, width, height, size);
                    mapped.push(designProperty);
                }

            }
        });

        return mapped;
    }

    private mapFromBay(bay) {
        return {
            bayId: bay.BayId,
            bayPosition: Number(bay.Position)
        };
    }



}





