import { config, tokendata} from '.';
import { AnglianData, defer, dataAccessService } from './dataAccessService';

export class InstallRegionService {

    dataAccess = new dataAccessService();

    public async fetchInstallRegion (authId,product, postcode) {
        var deferred = defer();

        var url = config.GetInstallRegion;

        var data: any = {};

        var user = await this.dataAccess.getCurrentUser();


        data.AuthID = authId;
        data.Token = user.AnglianToken;
        data.RepNo = user.RepNumber;
        data.Postcode = postcode;
        data.Product = product;

        this.dataAccess.httpPostAsync(url, data, retData => deferred.resolve(retData), false);

        return deferred.promise;

    };
}
