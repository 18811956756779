import { tokendata } from ".";
import { defer, AnglianData, dataAccessService } from "./dataAccessService";
import { entityInfoService } from "./entityInfoService";
import { LocalDbStore } from "./localDbStore";
import { opportunityService } from "./opportunityService";
import { progressReportingService } from "./progressReportingService";

export class syncService {
    private _entityInfoService: entityInfoService = new entityInfoService();
    //private _referenceDataService: referenceDataService = new referenceDataService();
    private _dataAccessService: dataAccessService = new dataAccessService;
    private _opportunityService: opportunityService = new opportunityService();
    private _localDbStore = new LocalDbStore();
    public get progressReporting(): progressReportingService {
        return window["progressReportingService"];
    }

    /**
     * Main entry point for Blazor to call teh sync process
     * starts a promise chain to run outside of Blazor and avoid thread locked state
     * @param data
     */
    public async getAlldata(data: AnglianData, fullSync: boolean) {


        try {
            await this.progressReporting.report('LeadsProgress', 'Synchronising leads:', 'pending')
            tokendata.authid = data.authID;
            tokendata.repno = data.repNo;
            tokendata.bearer = data.token;
            tokendata.payloadToken = data.payloadToken;

            //1) get ref data
            await this._entityInfoService.Sync(fullSync);
            //2) get opps
            await this._opportunityService.getLatestOpportunities(data.repNo, fullSync);


            var onSyncFinish = () => {
                //reload page just in case the user is on their first application load
                //service worker does not work on first ever page load for some reason
                const channel = new BroadcastChannel('reload-page');
                channel.postMessage({});
            };

            //3) send opps up
            await this._opportunityService.uploadQueue(onSyncFinish);


            

        } catch (e) {

            console.error("Error in SyncService.getAlldata:");
            console.error(e);
        }

        return 'called';
    }



    private async dummyApiCall(): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, 20000));
    }

    public async downloadUrlfromCache(url, cacheName) {
    const cache = await caches.open(cacheName);
        cache.match(url).then((r) => {
            r.blob().then((b) => {
                const blobUrl = URL.createObjectURL(b);
                const anchor = document.createElement("a");
                anchor.href = blobUrl;
                var filename = url.replace(/^.*[\\\/]/, '')
                anchor.download = filename
                document.body.appendChild(anchor);
                anchor.click();
                document.body.removeChild(anchor);
                URL.revokeObjectURL(blobUrl);

            });
           
    })

}


    public async storeFile() {

        var url = 'https://salestestext.anglian-windows.com/adapt-oneoff/tempimages/windows/WindowMaterials_PVCU.png';
        await this._dataAccessService.httpGetStoreInCache(url, 'testcache');

    }


    public async clearVideos() {

        await this._dataAccessService.ClearVideoCache();

    }
  


}

