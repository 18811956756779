import { openDB, deleteDB, wrap, unwrap, IDBPDatabase } from 'idb';
import { Constants } from './constants';

export const db = openDB('Anglian', 18, {
    upgrade(db) {
        try {
            console.log("Creating/Updating schema");
            if (!db.objectStoreNames.contains("Metadata")) {
                db.createObjectStore('Metadata');
            }
            // #region Setup Tables and Indexs

            // opportunity
            var opportunityStore = null;
            if (!db.objectStoreNames.contains("opportunities")) {
                opportunityStore = db.createObjectStore('opportunities', { autoIncrement: true, keyPath: "id" });
                opportunityStore.createIndex("id", "id", { unique: true });
                opportunityStore.createIndex("appointmentDate", "lead.AppointmentDateTime", { unique: false });
                opportunityStore.createIndex("OpportunityNumber", "lead.OpportunityNumber", { unique: false });
                opportunityStore.createIndex("IsSold", "contract.IsSold", { unique: false });
                opportunityStore.createIndex("WaitingESign", "contract.WaitingESign", { unique: false });

            } 

            if (!db.objectStoreNames.contains("preloadedData")) {
                opportunityStore = db.createObjectStore('preloadedData', { autoIncrement: true, keyPath: "id" });
                opportunityStore.createIndex("id", "id", { unique: true });

            }

            // not sure this is used
            if (!db.objectStoreNames.contains("reps")) {
                var repStore = db.createObjectStore("reps", { autoIncrement: true, keyPath: "id" });
                repStore.createIndex("repNumber", "repNumber", { unique: true });
            }

            if (!db.objectStoreNames.contains("salesGroups")) {
                var salesGroups = db.createObjectStore("salesGroups", { autoIncrement: true, keyPath: "Id" });
                salesGroups.createIndex("Id", "Id", { unique: true });
                salesGroups.createIndex("Code", "Code", { unique: true });
            }

            if (!db.objectStoreNames.contains("prices")) {
                var priceList = db.createObjectStore("prices", { autoIncrement: false, keyPath: "DesignId" });
                priceList.createIndex("DesignId", "DesignId");
            }

            if (!db.objectStoreNames.contains("designs")) {
                var designs = db.createObjectStore("designs", { autoIncrement: false, keyPath: "Id" });
                designs.createIndex("Id", "Id", { unique: true });
                designs.createIndex("Code", "Code", { unique: true });
                designs.createIndex("ProductGroup", "Product.ProductGroup.Id", { unique: false });
            }

            if (!db.objectStoreNames.contains("properties")) {
                var properties = db.createObjectStore("properties", { autoIncrement: true, keyPath: "Id" });
                properties.createIndex("Id", "Id", { unique: true });
                properties.createIndex("Code", "Code", { unique: false });
            }

            if (!db.objectStoreNames.contains("session")) {

                var session = db.createObjectStore("session", { autoIncrement: true, keyPath: "id" });
                session.createIndex("id", "id", { unique: true });
                session.createIndex("userId", "userId", { unique: true });
                session.createIndex("userName", "userName", { unique: false });
                session.createIndex("token", "token", { unique: false });
            }

            if (!db.objectStoreNames.contains("customerPriorities")) {
                var customerPriorities = db.createObjectStore("customerPriorities", { autoIncrement: true, keyPath: "Id" });
                customerPriorities.createIndex("Id", "Id", { unique: true });
            }

            if (!db.objectStoreNames.contains("rooms")) {
                var marketing = db.createObjectStore("rooms", { autoIncrement: true, keyPath: "Id" });
                marketing.createIndex("Id", "Id", { unique: true });
            }

            if (!db.objectStoreNames.contains("marketing")) {
                var rooms = db.createObjectStore("marketing", { autoIncrement: true, keyPath: "Id" });
                rooms.createIndex("Id", "Id", { unique: true });
            }

            if (!db.objectStoreNames.contains("entityInfo")) {
                var entityInfo = db.createObjectStore("entityInfo", { autoIncrement: true, keyPath: "id" });
                entityInfo.createIndex("id", "id", { unique: false });
                entityInfo.createIndex("EntityName", "EntityName", { unique: false });
            }

            if (!db.objectStoreNames.contains("discounts")) {
                var discounts = db.createObjectStore("discounts", { autoIncrement: false, keyPath: "Id" });
                discounts.createIndex("Id", "Id", { unique: true });
                discounts.createIndex("SalesGroup", "SalesGroup", { unique: false });
            }

            if (!db.objectStoreNames.contains("errorLog")) {
                var errorLog = db.createObjectStore("errorLog", { autoIncrement: true, keyPath: "id" });
                errorLog.createIndex("id", "id", { unique: true });
            }

            if (!db.objectStoreNames.contains("financeProviders")) {
                var financeProvider = db.createObjectStore("financeProviders", { autoIncrement: false, keyPath: "id" });
                financeProvider.createIndex("id", "id", { unique: true });
            }

            if (!db.objectStoreNames.contains("financeSource")) {

                var financeSource = db.createObjectStore("financeSource", { autoIncrement: true, keyPath: "id" });
                financeSource.createIndex("id", "id", { unique: true });
                financeSource.createIndex("code", "code", { unique: true });
            }

            if (!db.objectStoreNames.contains("financeSetup")) {

                var financeSetup = db.createObjectStore("financeSetup", { autoIncrement: true, keyPath: "id" });
                financeSetup.createIndex("id", "id", { unique: true });
                financeSetup.createIndex("sourceOfFinanceNumber", "SourceOfFinanceNumber", { unique: true });
            }

            if (!db.objectStoreNames.contains("adminCharge")) {

                var adminCharge = db.createObjectStore("adminCharge", { autoIncrement: true, keyPath: "id" });
                adminCharge.createIndex("id", "id", { unique: true });
                adminCharge.createIndex("Product", "Product", { unique: false });
            }

            if (!db.objectStoreNames.contains("pageVisits")) {

                var pageVisits = db.createObjectStore("pageVisits", { autoIncrement: true, keyPath: "id" });
                pageVisits.createIndex("id", "id", { unique: true });
                pageVisits.createIndex("repId", "repId", { unique: false });
            }

            if (!db.objectStoreNames.contains("settings")) {
                var settings = db.createObjectStore("settings", { autoIncrement: true, keyPath: "id" });
                settings.createIndex("id", "id", { unique: true });
                settings.createIndex("repNumber", "repNumber", { unique: true });
            }

            if (!db.objectStoreNames.contains("budgetOptions")) {
                var budgetOptions = db.createObjectStore("budgetOptions", { autoIncrement: true, keyPath: "Id" });
                budgetOptions.createIndex("Id", "Id", { unique: true });
            }

            if (!db.objectStoreNames.contains("fundingOptions")) {
                var fundingOptions = db.createObjectStore("fundingOptions", { autoIncrement: true, keyPath: "Id" });
                fundingOptions.createIndex("Id", "Id", { unique: true });
            }

            if (!db.objectStoreNames.contains("timescaleOptions")) {
                var timescaleOptions = db.createObjectStore("timescaleOptions", { autoIncrement: true, keyPath: "Id" });
                timescaleOptions.createIndex("Id", "Id", { unique: true });
            }
            if (!db.objectStoreNames.contains("financeNotEligibleOptions")) {
                var dataOtions = db.createObjectStore("financeNotEligibleOptions", { autoIncrement: true, keyPath: "Id" });
                dataOtions.createIndex("Id", "Id", { unique: true });
            }
            if (!db.objectStoreNames.contains("slidingscaleregionalpricings")) {
                var slidingscalereginalpricing = db.createObjectStore("slidingscaleregionalpricings", { autoIncrement: true, keyPath: "id" });
                slidingscalereginalpricing.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("magiceyevariables")) {
                var magiceyevariables = db.createObjectStore("magiceyevariables", { autoIncrement: true, keyPath: "id" });
                magiceyevariables.createIndex("id", "id", { unique: true });
                magiceyevariables.createIndex("SalesGroup", "SalesGroup", { unique: false });
            }
            if (!db.objectStoreNames.contains("relevantdays")) {
                var relevantdays = db.createObjectStore("relevantdays", { autoIncrement: true, keyPath: "id" });
                relevantdays.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("commissionsReferrals")) {
                var commissionsReferrals = db.createObjectStore("commissionsReferrals", { autoIncrement: true, keyPath: "id" });
                commissionsReferrals.createIndex("id", "id", { unique: true });
                commissionsReferrals.createIndex("Product", "Product", { unique: false });
            }

            if (!db.objectStoreNames.contains("commissionsSlidingScales")) {
                var commissionsSlidingScales = db.createObjectStore("commissionsSlidingScales", { autoIncrement: true, keyPath: "id" });
                commissionsSlidingScales.createIndex("id", "id", { unique: true });
                commissionsSlidingScales.createIndex("Product", "Product", { unique: false });
            }

            if (!db.objectStoreNames.contains("commissionsMinimums")) {
                var commissionsMinimums = db.createObjectStore("commissionsMinimums", { autoIncrement: true, keyPath: "id" });
                commissionsMinimums.createIndex("id", "id", { unique: true });
                commissionsMinimums.createIndex("Product", "Product", { unique: false });
            }

            if (!db.objectStoreNames.contains("commissionsValuePercentages")) {
                var commissionsValuePercentages = db.createObjectStore("commissionsValuePercentages", { autoIncrement: true, keyPath: "id" });
                commissionsValuePercentages.createIndex("id", "id", { unique: true });
                commissionsValuePercentages.createIndex("Product", "Product", { unique: false });
            }


            if (!db.objectStoreNames.contains("envisagesetup")) {
                var envisagesetup = db.createObjectStore("envisagesetup", { autoIncrement: true, keyPath: "id" });
                envisagesetup.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("scrappagescheme")) {
                var scrappagescheme = db.createObjectStore("scrappagescheme", { autoIncrement: true, keyPath: "id" });
                scrappagescheme.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("optinsettings")) {
                var optinsettings = db.createObjectStore("optinsettings", { autoIncrement: true, keyPath: "Id" });
                optinsettings.createIndex("Id", "Id", { unique: true });
            }
            if (!db.objectStoreNames.contains("leadtimes")) {
                var leadtimes = db.createObjectStore("leadtimes", { autoIncrement: true, keyPath: "id" });
                leadtimes.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("noemailcharge")) {
                var noemailcharge = db.createObjectStore("noemailcharge", { autoIncrement: true, keyPath: "id" });
                noemailcharge.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("regioncost")) {
                var regioncost = db.createObjectStore("regioncost", { autoIncrement: true, keyPath: "id" });
                regioncost.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("defaulterror")) {
                var defaulterror = db.createObjectStore("defaulterror", { autoIncrement: true, keyPath: "id" });
                defaulterror.createIndex("id", "id", { unique: true });
            }
            if (!db.objectStoreNames.contains("customerFiles")) {
                var customerFile = db.createObjectStore("customerFiles", { autoIncrement: true, keyPath: "id" });
                customerFile.createIndex("id", "id", { unique: true });
                customerFile.createIndex("fileType", "fileType", { unique: false });
                customerFile.createIndex("opportunityId", "opportunityId", { unique: false });
            }

            if (!db.objectStoreNames.contains("QueueItem")) {
                var store = db.createObjectStore("QueueItem", {
                    autoIncrement: true,
                    keyPath: "Id"
                });

                store.createIndex("Id", "Id", { unique: true });
                store.createIndex("Type", "Type", { unique: false });
            }


            if (!db.objectStoreNames.contains("UserAccount")) {
                db.createObjectStore('UserAccount');
            }
            
            if (!db.objectStoreNames.contains("Layout")) {
                db.createObjectStore('Layout');
            }

            if (!db.objectStoreNames.contains("audits")) {
                var auditScheme = db.createObjectStore("audits", { autoIncrement: true, keyPath: "id" });
                auditScheme.createIndex("id", "id", { unique: true });
                auditScheme.createIndex("opportunityId", "opportunityId", { unique: false });
                auditScheme.createIndex("versionId", "opportunityId", { unique: false });
            }
            if (!db.objectStoreNames.contains("userActions")) {
                var userActionsScheme = db.createObjectStore("userActions", { autoIncrement: true, keyPath: "id" });
                userActionsScheme.createIndex("id", "id", { unique: true });
                userActionsScheme.createIndex("opportunityId", "opportunityId", { unique: false });
            }


        } catch (e) {
            console.log('error upgradeing db:' + e);
        }

    },
});

export class LocalDbStore {
    public async init() {
        return (await db);
    }

    public async get(storeName, key) {
        try {
            return (await db).transaction(storeName).store.get(key);
        } catch (e) {
            console.log("get() error getting key: " + key + " from store: " + storeName + " exception:" + e);
            return null;
        }
    };

    public async getAllByKey(storeName, key) {
        try {
          //  console.log("getAllbyKey" + key);
            var result = (await db).transaction(storeName).store.getAll(key);
            return result;
        } catch (e) {
            console.log("getAllbyKey() error getting key: " + key + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }

    public async getAll(storeName, returnFirstEntryOnly = false) {
        try {
            var dbResult = await db;
            var result = await dbResult.transaction(storeName).store.getAll();
            if (!result) {
                return [];
            }

            if (storeName === "opportunities") {
                result.forEach(val => {
                    val.UnsoldVersionCount = val.unsoldVersions ? val.unsoldVersions.length : 0;
                })
            }

            //This handles the case such as OptInSettings, where the EntitySyncState properly is a single object and this dbcall results in an array
            if (returnFirstEntryOnly) {
                return result[0];
            }

            return result;
        } catch (e) {
            console.log("getAll() error getting from store: " + storeName + " exception:" + e);
            return null;
        }
    }

    public async getFirstFromIndex(storeName, indexName, direction) {
        try {
            const cursor = await (await db).transaction(storeName).store.index(indexName).openCursor(null, direction);
            return (cursor && cursor.value) || null;
        } catch (e) {
            console.log("getFirstFromIndex() error getting Index: " + indexName + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }

    public async getDesignsForProduct(productGroupId) {
        try {
            var result = await (await db).transaction("designs").store.index("ProductGroup").getAll(productGroupId);
            return result;
        }
        catch (e) {
            console.error(e);
        }
    }
    public async getPricesForDesign(designId) {
        try {
            var result = await (await db).transaction("prices").store.getAll(designId);
            return result;
        }
        catch (e) {
            console.error(e);
        }
    }

    preloadedDataStore = Constants.PreloadedDataStore;
    pricePresentationDataKey = Constants.PricePresentationDataKey;

    public async GetPreloadedPricePresentationData(oppid) {
        var results = (await this.getAll(this.preloadedDataStore)) || [];
        results = results.filter(r => r.OpportunityId === oppid && r.type === this.pricePresentationDataKey);

        if (!results.length) {
            return null;
        }

        var result = results[0];
        var lisData = result.LoadIntoStateData;;

        var slidingScales = lisData.commissions.SlidingScales;
        sessionStorage.setItem(Constants.SlidingScalesDataKey, JSON.stringify(slidingScales));

        var referrals = lisData.commissions.Referrals;
        sessionStorage.setItem(Constants.ReferralsDataKey, JSON.stringify(referrals));

        var minimums = lisData.commissions.Minimums;
        sessionStorage.setItem(Constants.MinimumsDataKey, JSON.stringify(minimums));

        var valuePercentages = lisData.commissions.ValuePercentages;
        sessionStorage.setItem(Constants.ValuePercentagesDataKey, JSON.stringify(valuePercentages));

        delete result.LoadIntoStateData.commissions;

        return result;
    }

    public async GetSlidingScales(inttodayDate, salesGroup, discount, code) {
        var ss = JSON.parse(sessionStorage.getItem(Constants.SlidingScalesDataKey));

        var metSlidingData = ss.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.Discount >= discount && band.ProductDetail == code);


        if (salesGroup == "RT" || salesGroup == "CP") {
            if (code != null) {
                metSlidingData = ss.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.Discount >= discount && band.ProductDetail == code);
            }
            else {
                metSlidingData = ss.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.Discount >= discount); // if Product is RT no need to check the Material code
            }
        }

        return metSlidingData;
    }

    public async GetReferrals(inttodayDate, salesGroup, discount, code) {
        var r = JSON.parse(sessionStorage.getItem(Constants.ReferralsDataKey));

        var metrefSPF = r
            .filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.ProductDetail == code && band.SlideFrom <= discount && band.SlideTo >= discount);

        return metrefSPF;
    }

    public async GetMinimums(inttodayDate, salesGroup, cashPrice, code) {     
        var m = JSON.parse(sessionStorage.getItem(Constants.MinimumsDataKey));

        var metminimums = m.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.BasedOnValue <= cashPrice && band.ProductDetail == code);
        if (salesGroup == "RT" || salesGroup == "CP") {
            if (!code) {
                metminimums = m.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.BasedOnValue <= cashPrice && band.ProductDetail == code);

            }
            else {
                metminimums = m.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.BasedOnValue <= cashPrice);
            }
        }

        return metminimums;
    }

    public async GetValuePercentages(inttodayDate, salesGroup, code) {
        var vp = JSON.parse(sessionStorage.getItem(Constants.ValuePercentagesDataKey));

        return vp.filter(band => band.EffectiveDate <= inttodayDate && band.Product == salesGroup && band.ProductDetail == code);
    }


    public async WipePreloadInfoForOpp(oppId) {
        var results = (await this.getAll(this.preloadedDataStore)) || [];
        results = results.filter(r => r.OpportunityId === oppId && r.type === this.pricePresentationDataKey);

        for (var resultIdx in results) {
            var result = results[resultIdx];
            await this.delete(this.preloadedDataStore, result.id);
        }
    }

    public async GetProductDependentPricePresentationData(productGroupCode: string){
        var prodGroupPromise = (await db).transaction("salesGroups").store.index("Code").getAll(productGroupCode);
        var discountsPromise = (await db).transaction("discounts").store.index("SalesGroup").getAll(productGroupCode);
        var magEyePromise = (await db).transaction("magiceyevariables").store.index("SalesGroup").getAll(productGroupCode);

        var finTimesPromise = (await db).transaction("financeSetup").store.getAll();
        var leadTimesPromise = (await db).transaction("leadtimes").store.getAll();
        var optinsPromise = (await db).transaction("optinsettings").store.getAll();
        var relevantDaysPromise = (await db).transaction("relevantdays").store.getAll();
        var commissionsPromise1 = (await db).transaction("commissionsMinimums").store.index("Product").getAll(productGroupCode);
        var commissionsPromise2 = (await db).transaction("commissionsReferrals").store.index("Product").getAll(productGroupCode);
        var commissionsPromise3 = (await db).transaction("commissionsSlidingScales").store.index("Product").getAll(productGroupCode);
        var commissionsPromise4 = (await db).transaction("commissionsValuePercentages").store.index("Product").getAll(productGroupCode);

        var results = await Promise.all([
            prodGroupPromise,
            discountsPromise,
            magEyePromise,
            commissionsPromise1,
            commissionsPromise2,
            commissionsPromise3,
            commissionsPromise4,
            finTimesPromise,
            leadTimesPromise,
            optinsPromise,
            relevantDaysPromise
        ]);

        //-----process------//
        //comm
        var commissions = {
            Minimums: results[3],
            Referrals: results[4],
            SlidingScales: results[5],
            ValuePercentages: results[6]
        };

        //fin
        var finance = results[7]
            .filter(r =>
                r.Products
                && r.Products.some(p => p.ProductCode == productGroupCode)
        );


        var leadTimes = results[8][0];
        leadTimes.Regions = leadTimes.Regions.filter(r => r.Products.some(p => p.ProductCategory === productGroupCode));

        //discounts: only use discounts which have no expired
        var now = new Date();
        results[1].forEach(entry => {
            entry.DiscountOptions = entry.DiscountOptions.filter(r => new Date(r.ValidFrom) < now && new Date(r.ValidTo) > now);
        })

        return {
            ProductGroups: results[0],
            Discounts: results[1],
            MagicEyeVariables: results[2],
            commissions: commissions,
            Finance: finance,
            LeadTimes: leadTimes,
            OptInSettings: results[9][0], //we only want the single entry
            RelevantDays: results[10],
        };
    }

    readonly customerDocsStore = "customerFiles";
    readonly opportunityIdKey = "opportunityId";

    public async getCustomerFile(oppId, fileName): Promise<any> {
        //get all files for opportuntity
        var query = IDBKeyRange.only(oppId);
        var results = await (await db).transaction(this.customerDocsStore).store.index(this.opportunityIdKey).getAll(query);

        //get just the file we are looking for
        results = results.filter(r => r.fileName === fileName);
        return results[0];
    }

    public async saveCustomerFile(file): Promise<IDBValidKey> {
        return this.putIdentityInlineKey(this.customerDocsStore, file);
    }

    public async getLazyLoadData(referenceData: string[]) {
        var entitySyncState: any = {};
        var tableName: string;
        var entitySyncStatePropertyName: string;

        for (var entry in referenceData) {
            var getFromLocalStorage = false;
            var getSingle = false;
            switch (referenceData[entry]) {
                case "SALESGROUPS":
                    tableName = "salesGroups";
                    entitySyncStatePropertyName = "ProductGroups";
                        break;

                case "FINANCE":
                    tableName = "financeSetup";
                    entitySyncStatePropertyName = "Finance";
                        break;

                case "OPTINS":
                    tableName = "optinsettings";
                    entitySyncStatePropertyName = "OptInSettings";
                    getSingle = true;
                        break;
                case "RELEVANTDAYS":
                    tableName = "relevantdays";
                    entitySyncStatePropertyName = "RelevantDays";
                        break;
                case "MARKETING":
                    tableName = "marketing";
                    entitySyncStatePropertyName = "Marketing";
                        break;
                case "BUDGETOPTIONS":
                    tableName = "budgetOptions";
                    entitySyncStatePropertyName = "BudgetOptions";
                        break;
                case "FUNDINGOPTIONS":
                    tableName = "fundingOptions";
                    entitySyncStatePropertyName = "FundingOptions";
                        break;
                case "FINANCENOTELIGIBLEOPTIONS":
                    tableName = "financeNotEligibleOptions";
                    entitySyncStatePropertyName = "FinanceNotEligibleOptions";
                        break;
                case "VIDEOMENUITEMS":
                    getFromLocalStorage = true;
                    tableName = "VideoMenu";
                    entitySyncStatePropertyName = "VideoMenuItems";
                        break;
                case "CUSTOMERPRIORITIES":
                     tableName = "customerPriorities";
                    entitySyncStatePropertyName = "CustomerPriorities";
                        break;
                case "TIMESCALEOPTIONS":
                    tableName = "timescaleOptions";
                    entitySyncStatePropertyName = "TimescaleOptions";
                        break;
                case "ROOMLIST":
                    tableName = "rooms";
                    entitySyncStatePropertyName = "RoomList";
                        break;
                case "IMAGEMENU":
                    getFromLocalStorage = true;
                    tableName = "ImageMenu";
                    entitySyncStatePropertyName = "ImageMenu";
                        break;

            }

            var data;
            if (!getFromLocalStorage) {
                data = await (await db).transaction(tableName).store.getAll()
            }
            else {
                data = localStorage.getItem(tableName);
                data = JSON.parse(data);
            }

            if (getSingle) {
                data = data[0];
            }

            entitySyncState[entitySyncStatePropertyName] = data;
        }

        return entitySyncState;
    }




    public async putIdentityInlineKey(storeName, value): Promise<IDBValidKey> {
        try {
            var tran = (await db).transaction(storeName, 'readwrite');

            //  THIS IS THE ONLY WAY TO MAKE THE AUTOGENERATE KEY WORK, PASSING NULL AS THE ID VAL MAKES IT THROW DataError
            if (value.id === null) {
                delete value.id;
            }
            if (value.Id === null) {
                delete value.Id;
            }
            if (value.ID === null) {
                delete value.ID;
            }


            var req = await tran.store.put(value)
            return req;
        } catch (e) {
            console.log("putIdentity() error putting value " + value + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }

    public async addIdentityInlineKey(storeName, value): Promise<IDBValidKey> {
        try {
            var tran = (await db).transaction(storeName, 'readwrite');

            //  THIS IS THE ONLY WAY TO MAKE THE AUTOGENERATE KEY WORK, PASSING NULL AS THE ID VAL MAKES IT THROW DataError
            if (value.id === null) {
                delete value.id;
            }
            if (value.Id === null) {
                delete value.Id;
            }
            if (value.ID === null) {
                delete value.ID;
            }


            var req = await tran.store.add(value)
            return req;
        } catch (e) {
            console.log("addIdentiyu() error adding value " + value + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }

    private readonly MetaDataStore = "Metadata";
    private readonly IsSyncingStore = "IsSyncing";

    public async getThenResetIsSyncing(): Promise<boolean>
    {
        var stValue: string = await this.get(this.MetaDataStore, this.IsSyncingStore);
        var value = stValue
            ? (stValue.toLowerCase() === "true" ? true : false)
            : false;

        //reset it
        if (value)
        {
            await this.setIsSyncing(false);
        }

        return value;
    }

    public async setIsSyncing(val: boolean): Promise<void> {
        await this.putOutOfLineKey(this.MetaDataStore, this.IsSyncingStore, val.toString());
    }


    //returns: the ID of the successfully saved entry
    public async putOutOfLineKey(storeName, key, value): Promise<IDBValidKey> {
        try {
            var tran = (await db).transaction(storeName, 'readwrite');
            var req = await tran.store.put(value, key);

            return req;
        } catch (e) {
            console.log("put() error putting value " + value + " key: " + key + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }


    public async putAllFromJson(storeName, json): Promise<void> {
        try {
            const store = (await db).transaction(storeName, 'readwrite').store;
            JSON.parse(json).forEach(item => store.put(item));
        } catch (e) {
            console.log("getAllfromJson() error putting json: " + json + "  to store: " + storeName + " exception:" + e);
            return null;
        }
    }

    public async delete(storeName, key) {
        try {
            (await db).transaction(storeName, 'readwrite').store.delete(key);
        } catch (e) {
            console.log("delete() error dlete key: " + key + " from store: " + storeName + " exception:" + e);
            return null;
        }
    }
    public async autocompleteKeys(storeName, text, maxResults) {
        try {
            const results = [];
            let cursor = await (await db).transaction(storeName).store.openCursor(IDBKeyRange.bound(text, text + '\uffff'));
            while (cursor && results.length < maxResults) {
                results.push(cursor.key);
                cursor = await cursor.continue();
            }
            return results;

        } catch (e) {
            console.log("getAllbyKey() error storeing text: " + text + " in store: " + storeName + " exception:" + e);
            return [];
        }
    }

}