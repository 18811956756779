import { sessionService } from "./sessionService";
import { dataAccessService } from "./dataAccessService";
import { PriceListService } from "./priceListService";
import * as moment from "moment";
import * as _ from 'underscore'

export class EnvisageService {
    das = new dataAccessService();
    user;

    selectedCPDetail: any = {
        showScreen: false,
        selectedFile: {},
        fileIndex: -1,
        prodPriceOK: false
    };

    priceListService = new PriceListService();
    orderPrice = 0;
    orderIncomplete = true;

    public async processCPFiles(cpFiles, opportunity, ultra2DesignData, solarDesignData) {
        this.user = (await this.das.getLocal('UserAccount'))[0];

        //process files
        var cpFile, processedFile;

        for (var cpFileIdx in cpFiles) {
            cpFile = cpFiles[cpFileIdx];
            processedFile = await this.processCPFile(cpFile);
            //processedFile.TotalPrice = this.calcTotalPrice(processedFile); THIS IS REDUNDANT
            this.updateCPFilesArray(processedFile, opportunity, ultra2DesignData, solarDesignData);
        }


        return opportunity
    }

    public async proceedCP(file, opportunity, SOLAROOF, ULTRA2) {
        try
        {
            var iVersion = this.extractVerNo(file.filename);

            //Extract files to file system.
            //var fileInfo = await this.extractEnvisageFiles(file, opportunity.lead.cpFileNameRoot, iVersion);

            //    //Update file dates with new dates.
            //this.updateFileDates(file.filename, fileinfo.datemodifiedrw6, fileinfo.datemodifiedrwb);


            var bResult = this.codeCheck(file);
            if (bResult === false) {
                return;
            }

            file.fileschanged = false;

            bResult = this.priceCheck(file);
            if (bResult === false) {
                return;
            }

            opportunity.order.items = [];

            var itemPrice = 0;
            var itemPrice1 = 0;
            if (SOLAROOF === undefined) {
                itemPrice = 0;
            }
            else {
                itemPrice = SOLAROOF.Amount * file.SolaroofUnitQuantity;
            }


            if (ULTRA2 === undefined) {
                itemPrice1 = 0;
            }
            else {
                itemPrice1 = ULTRA2.Amount * file.Ultra2UnitQuantity;
            }


            file.TotalPrice = file.ProductCost + file.buildingWorkCost + file.ADGCost + file.OtherCost - itemPrice - itemPrice1;
            this.orderPrice = file.TotalPrice;

            var properties = [];

            var oProperty: any = {};
            oProperty.TypeOfPrice = 0;
            oProperty.Style = {};
            oProperty.Style.Code = 'DECORATIVE';
            oProperty.name = "ADG Cost";
            oProperty.price = file.ADGCost;
            oProperty.isDiscountable = false;
            oProperty.quantity = 1;
            oProperty.typeOfPrice = 7;
            oProperty.included = true;

            properties.push(oProperty);

            oProperty = {};
            oProperty.TypeOfPrice = 0;
            oProperty.Style = {};
            oProperty.Style.Code = 'NONDISCOUNTABLE';
            oProperty.name = "Building Work Cost";
            oProperty.price = file.buildingWorkCost + file.OtherCost;
            oProperty.isDiscountable = false;
            oProperty.quantity = 1;
            oProperty.included = true;

            properties.push(oProperty);

            var primaryDesignPromise = this.buildCPDesign('NO0101', file.totalPrice, file.systemname, file.systemname, properties, file.TotalPrice, false, file.buildingWorkCost + file.OtherCost + file.ADGCost, null);

            var promises = [primaryDesignPromise];

            if (file.SolaroofUnitQuantity > 0) {
                var solaroofDesignPromise = this.buildCPDesign('SOLAROOF', 0, 'SOLAROOF', 'SOLAROOF', [], null, true, 0, file.SolaroofUnitQuantity);
                promises.push(solaroofDesignPromise);
            }

            if (file.Ultra2UnitQuantity > 0) {
                var ultra2DesignPromise = this.buildCPDesign('ULTRA2', 0, 'ULTRA2', 'ULTRA 2 UPGRADE', [], null, true, 0, file.Ultra2UnitQuantity);
                promises.push(ultra2DesignPromise);
            }

            var items = await Promise.all(promises);

            opportunity.order.items = _.compact(items);
            items.forEach(i => {
                if (!i.size) {
                    i.size = 0;
                }
                if (typeof i.size === 'string' || i.size instanceof String) {
                    i.size = Number(i.size);
                }
            })


            opportunity.order.cpSelected = file;
            opportunity.order.cpSelected.proceedDateTime = new Date();

            //Make sure the proceed date time is set on the file object, this is sometimes not coming up.
            this.updateProceedDate(file.filename, opportunity.order.cpSelected.proceedDateTime, opportunity);

            this.orderIncomplete = false;
            return opportunity;
        } catch (e) {
            var error = e.Message;
            console.log(error);
        }
       
    }


    //------------ PRIVATE ----------------//

    private async processCPFile(cpFile) {
        var oCons: any = {};

        if (!cpFile.xmlMissing) {
            var oParser = new DOMParser();
            var oDOM = oParser.parseFromString(cpFile.xml, "text/xml");

            oCons = await this.extractXMLInfo(oDOM);
            oCons.filename = cpFile.rw6_filename;
            oCons.files = cpFile;

            var exitData = await this.readExitFile(cpFile);

            oCons.controlCode = exitData.controlCode;
            oCons.controlCode = exitData.controlExit;
            oCons.datemodified = new Date(cpFile.rw6_datemodified);
        }
        else {
            oCons.filename = cpFile.rw6_filename;
            oCons.files = cpFile;
            oCons.xmlMissing = true;

        }

        return oCons;
    }

    //Extract XML information
    private async extractXMLInfo(oXML) {

        var oCons: any = {};
        oCons.TotalPrice = 0;
        oCons.xmlTotalPrice = 0;
        oCons.jobname = '';
        oCons.feasibilityRef = '';
        oCons.buildingWorkCost = 0;
        oCons.xmlbuildingWorkCost = 0;
        oCons.ADGCost = 0;
        oCons.xmlADGCost = 0;
        oCons.ProductCost = 0;
        oCons.xmlProductCost = 0;
        oCons.OtherCost = 0;
        oCons.xmlOtherCost = 0;
        oCons.systemname = '';

        oCons.systemname = oXML.querySelector("window_system").textContent;

        oCons.jobname = oXML.querySelector("reference").textContent;

        oCons.controlExit = '';
        oCons.controlCode = '';

        var iCount = oXML.querySelector("job_total").attributes.length;
        var oAtt = null;

        for (var iAtt = 0; iAtt < iCount; iAtt++) {

            oAtt = oXML.querySelector("job_total").attributes[iAtt];
            if (oAtt.name == 'value') {
                //oCons.TotalPrice = parseFloat(parseFloat(oAtt.value).toFixed(2));
                oCons.xmlTotalPrice = parseFloat(parseFloat(oAtt.value).toFixed(2));
            }

        }

        var oTotals = oXML.querySelectorAll("group_total");
        var oTotal = null;
        var sName = '';
        var dValue = 0;

        for (var iTotal = 0; iTotal < oTotals.length; iTotal++) {

            oTotal = oTotals[iTotal];
            iCount = oTotal.attributes.length;

            for (iAtt = 0; iAtt < iCount; iAtt++) {

                oAtt = oTotal.attributes[iAtt];
                if (oAtt.name == 'value') {
                    dValue = parseFloat(parseFloat(oAtt.value).toFixed(2));
                }
                else if (oAtt.name == 'name') {
                    sName = oAtt.value.toUpperCase();
                }

            }

            switch (sName) {
                case 'ADG':
                    //oCons.ADGCost = dValue;
                    oCons.xmlADGCost = dValue;
                    break;
                case 'BASEWORK':
                    //oCons.buildingWorkCost = dValue;
                    oCons.xmlbuildingWorkCost = dValue;
                    break;
                case 'PRODUCT':
                    //oCons.ProductCost = dValue;
                    oCons.xmlProductCost = dValue;
                    break;
                case 'OTHER':
                    //oCons.OtherCost = dValue;
                    oCons.xmlOtherCost = dValue;
                    break;
                default:
            }

        }

        //We now need to check if we should apply VAT
        if (this.useAdaptTLPrices()) {

            var vatRates = this.user.VATRates;

            var dateNow = new Date();
            var iDateNow = Number(moment(dateNow).format('YYYYMMDD'));

            var dVatRate = 0;

            //AW - Find the current VAT rate..
            for (var iRate = 0; iRate < vatRates.length; iRate++) {
                if (vatRates[iRate].effectiveDate <= iDateNow) {
                    if (vatRates[iRate].expirationDate >= iDateNow) {

                        dVatRate = vatRates[iRate].vatRate;

                    }
                }
            }

            oCons.vatInfo = {};
            oCons.vatInfo.vatRate = dVatRate;
            oCons.vatInfo.vatRateCalc = (dVatRate / 100);

            oCons.vatInfo.xmlProductCostPreVAT = parseFloat(oCons.xmlProductCost);
            oCons.vatInfo.xmlbuildingWorkCostPreVAT = parseFloat(oCons.xmlbuildingWorkCost);
            oCons.vatInfo.xmlADGCostPreVAT = parseFloat(oCons.xmlADGCost);
            oCons.vatInfo.xmlOtherCostPreVAT = parseFloat(oCons.xmlOtherCost);
            oCons.vatInfo.xmlTotalPricePreVAT = parseFloat(oCons.xmlTotalPrice);

            oCons.vatInfo.xmlProductCostVATAmount = parseFloat(parseFloat((oCons.xmlProductCost * oCons.vatInfo.vatRateCalc).toFixed(2)).toFixed(2));
            oCons.vatInfo.xmlbuildingWorkCostVATAmount = parseFloat(parseFloat((oCons.xmlbuildingWorkCost * oCons.vatInfo.vatRateCalc).toFixed(2)).toFixed(2));
            oCons.vatInfo.xmlADGCostVATAmount = parseFloat(parseFloat((oCons.xmlADGCost * oCons.vatInfo.vatRateCalc).toFixed(2)).toFixed(2));
            oCons.vatInfo.xmlOtherCostVATAmount = parseFloat(parseFloat((oCons.xmlOtherCost * oCons.vatInfo.vatRateCalc).toFixed(2)).toFixed(2));
            oCons.vatInfo.xmlTotalPriceVATAmount = parseFloat(parseFloat((oCons.xmlTotalPrice * oCons.vatInfo.vatRateCalc).toFixed(2)).toFixed(2));

            oCons.xmlProductCost = parseFloat(parseFloat((oCons.vatInfo.xmlProductCostPreVAT + oCons.vatInfo.xmlProductCostVATAmount)).toFixed(2));
            oCons.xmlbuildingWorkCost = parseFloat(parseFloat((oCons.vatInfo.xmlbuildingWorkCostPreVAT + oCons.vatInfo.xmlbuildingWorkCostVATAmount)).toFixed(2));
            oCons.xmlADGCost = parseFloat(parseFloat((oCons.vatInfo.xmlADGCostPreVAT + oCons.vatInfo.xmlADGCostVATAmount)).toFixed(2));
            oCons.xmlOtherCost = parseFloat(parseFloat((oCons.vatInfo.xmlOtherCostPreVAT + oCons.vatInfo.xmlOtherCostVATAmount)).toFixed(2));
            oCons.xmlTotalPrice = parseFloat(parseFloat((oCons.vatInfo.xmlTotalPricePreVAT + oCons.vatInfo.xmlTotalPriceVATAmount)).toFixed(2));

        }



        var oControls = oXML.querySelectorAll("control");
        for (var iControl = 0; iControl < oControls.length; iControl++) {

            var oControl = oControls[iControl];
            for (var iNode = 0; iNode < oControl.childNodes.length; iNode++) {

                var oNode = oControl.childNodes[iNode];

                switch (oNode.nodeName.toLowerCase()) {
                    case 'code':
                        oCons.controlCode = oNode.textContent;
                        break;
                    case 'exit':
                        oCons.controlExit = oNode.textContent;
                        break;

                }

            }

        }

        var solaroofSectionSelector = 'livingspace > pricing_detail > discount_group[name="Product"] > Section[Area="Pricing roof glazing"] > Section[Area*="Solaroof"]';
        oCons.xmlSolaroofUnitQuantity = oXML.querySelectorAll(solaroofSectionSelector).length;

        var ultra2ComponentSelector = 'livingspace > pricing_detail > discount_group[name="Product"] > Section[Area*="Pricing Frames for Wall"] Section[Area="Frame glass price"] > Component[PartCode="Ultra 2 Tgh"]';
        oCons.xmlUltra2UnitQuantity = oXML.querySelectorAll(ultra2ComponentSelector).length;

        return oCons;

    }


    private async readExitFile(cpFile) {
        var exitFile = cpFile.exit;

        var info: any = {};
        info.controlCode = '';
        info.controlExit = '';

        var oParser = new DOMParser();
        var oDOM = oParser.parseFromString(exitFile, "text/xml");

        var oControls = oDOM.querySelectorAll("control");
        for (var iControl = 0; iControl < oControls.length; iControl++) {

            var oControl = oControls[iControl];
            for (var iNode = 0; iNode < oControl.childNodes.length; iNode++) {

                var oNode = oControl.childNodes[iNode];

                switch (oNode.nodeName.toLowerCase()) {
                    case 'code':
                        info.controlCode = oNode.textContent;
                        break;
                    case 'exit':
                        info.controlExit = oNode.textContent;
                        break;

                }

            }
        }

        return info;
    };

    private calcTotalPrice(file) {
        return file.ProductCost + file.buildingWorkCost + file.ADGCost + file.OtherCost;
    }

            //AW-CODE - See if we need to add to array or update existing.
    private updateCPFilesArray(oFile, opportunity, ultra2Data, solarData) {

        var index;
        var match = false;

        for (index = 0; index < opportunity.order.cpFiles.length; ++index) {
            var cpFile = opportunity.order.cpFiles[index];
            //Check names match and also that it's the one that was launched for editing.
            if (cpFile.filename == oFile.filename) {

                if (typeof cpFile.datemodified === 'string' || cpFile.datemodified instanceof String) {
                    cpFile.datemodified = new Date(cpFile.datemodified);
                }
                var bDatesMatch = this.isMatchingDate(cpFile.datemodified, oFile.datemodified);
                var sFesabilityRef = cpFile.feasibilityRef;
                var sLaunchCode = cpFile.launchcode;

                //if (bDatesMatch === true) {

                //    bDatesMatch = isMatchingDate(opportunity.order.cpFiles[index].files.rwbDateModified, oFile.files.rwbDateModified);
                //    if (bDatesMatch === true) {

                //        bDatesMatch = isMatchingDate(opportunity.order.cpFiles[index].files.ecxitDateModified, oFile.files.exitDateModified);

                //    }

                //}

                if (bDatesMatch === false) {

                    //If we are to use ADAPT LS prices then assign xml values.
                    if (this.useAdaptTLPrices()) {

                        oFile.ProductCost = oFile.xmlProductCost;
                        oFile.buildingWorkCost = oFile.xmlbuildingWorkCost;
                        oFile.ADGCost = oFile.xmlADGCost;
                        oFile.OtherCost = oFile.xmlOtherCost;
                        oFile.TotalPrice = oFile.xmlTotalPrice;
                        oFile.SolaroofUnitQuantity = oFile.xmlSolaroofUnitQuantity;
                        oFile.Ultra2UnitQuantity = oFile.xmlUltra2UnitQuantity;
                        var itemPrice = 0;
                        if (solarData === undefined) {
                            itemPrice = 0;
                        }
                        else {
                            itemPrice = solarData.Amount * oFile.SolaroofUnitQuantity;
                        }

                        oFile.ProductCost += itemPrice;

                        if (ultra2Data === undefined) {
                            itemPrice = 0;
                        }
                        else {
                            itemPrice = ultra2Data.Amount * oFile.Ultra2UnitQuantity;
                        }
                        oFile.ProductCost += itemPrice;
                    }
                    else {

                        oFile.xmlProductCost = 0;
                        oFile.xmlbuildingWorkCost = 0;
                        oFile.xmlADGCost = 0;
                        oFile.xmlOtherCost = 0;
                        oFile.xmlTotalPrice = 0;
                        oFile.xmlSolaroofUnitQuantity = 0;
                        oFile.xmlUltra2UnitQuantity = 0;

                        oFile.ProductCost = opportunity.order.cpFiles[index].ProductCost;
                        oFile.buildingWorkCost = opportunity.order.cpFiles[index].buildingWorkCost;
                        oFile.ADGCost = opportunity.order.cpFiles[index].ADGCost;
                        oFile.OtherCost = opportunity.order.cpFiles[index].OtherCost;
                        oFile.TotalPrice = opportunity.order.cpFiles[index].TotalPrice;
                        oFile.SolaroofUnitQuantity = opportunity.order.cpFiles[index].SolaroofUnitQuantity;
                        oFile.Ultra2UnitQuantity = opportunity.order.cpFiles[index].Ultra2UnitQuantity;

                    }

                    opportunity.order.cpFiles[index] = oFile;
                    opportunity.order.cpFiles[index].feasibilityRef = sFesabilityRef;
                    opportunity.order.cpFiles[index].launchcode = sLaunchCode;
                    opportunity.order.cpFiles[index].fileschanged = true;

                }
                else {

                    //opportunity.order.cpFiles[index].xmlMissing = false;
                    //opportunity.order.cpFiles[index].fileschanged = false;
                    //opportunity.order.cpFiles[index].controlCode = opportunity.order.cpFiles[index].launchcode;                       
                }


                //cpeditfilename = ''; //reset.
                match = true;
                break;

            }
            cpFile.ProductCost = oFile.xmlProductCost;
            cpFile.buildingWorkCost = oFile.xmlbuildingWorkCost;
            cpFile.ADGCost = oFile.xmlADGCost;
            cpFile.OtherCost = oFile.xmlOtherCost;
            cpFile.TotalPrice = oFile.xmlTotalPrice;
            cpFile.SolaroofUnitQuantity = oFile.xmlSolaroofUnitQuantity;
            cpFile.Ultra2UnitQuantity = oFile.xmlUltra2UnitQuantity;
        }

        if (match === false) {


            //If we are to use ADAPT LS prices then assign xml values.
            if (this.useAdaptTLPrices()) {

                oFile.ProductCost = oFile.xmlProductCost;
                oFile.buildingWorkCost = oFile.xmlbuildingWorkCost;
                oFile.ADGCost = oFile.xmlADGCost;
                oFile.OtherCost = oFile.xmlOtherCost;
                oFile.TotalPrice = oFile.xmlTotalPrice;
                oFile.SolaroofUnitQuantity = oFile.xmlSolaroofUnitQuantity;
                oFile.Ultra2UnitQuantity = oFile.xmlUltra2UnitQuantity;
                var itemPrice1 = 0;
                if (solarData === undefined) {
                    itemPrice1 = 0;
                }
                else {
                    itemPrice1 = solarData.Amount * oFile.SolaroofUnitQuantity;
                }

                oFile.ProductCost += itemPrice1;

                if (ultra2Data === undefined) {
                    itemPrice1 = 0;
                }
                else {
                    itemPrice1 = ultra2Data.Amount * oFile.Ultra2UnitQuantity;
                }
                oFile.ProductCost += itemPrice1;
            }
            else {

                oFile.xmlProductCost = 0;
                oFile.xmlbuildingWorkCost = 0;
                oFile.xmlADGCost = 0;
                oFile.xmlOtherCost = 0;
                oFile.xmlTotalPrice = 0;
                oFile.xmlSolaroofUnitQuantity = 0;
                oFile.xmlUltra2UnitQuantity = 0;

            }

            oFile.launchcode = opportunity.order.newLaunchCode;
            opportunity.order.cpFiles.push(oFile);

        }
    }

    //AW-CODE - Check if dates match
    private isMatchingDate(date1, date2) {

        var date1OK = this.isDateVarOK(date1);
        var date2OK = this.isDateVarOK(date2);

        if (date1OK === false && date2OK === false) { return true; }
        if (date1OK === false && date2OK === true) { return false; }
        if (date1OK === true && date2OK === false) { return false; }


        //Extract date 1 values
        var year1 = date1.getFullYear();
        var month1 = date1.getMonth();
        var day1 = date1.getDate();
        var hour1 = date1.getHours();
        var min1 = date1.getMinutes();
        var sec1 = date1.getSeconds();

        //Extract date 2 values.
        var year2 = date2.getFullYear();
        var month2 = date2.getMonth();
        var day2 = date2.getDate();
        var hour2 = date2.getHours();
        var min2 = date2.getMinutes();
        var sec2 = date2.getSeconds();

        //Compare each set of values
        if (year1 == year2) {
            if (month1 == month2) {
                if (day1 == day2) {
                    if (hour1 == hour2) {
                        if (min1 == min2) {
                            if (sec1 == sec2) {

                                return true;

                            }
                        }
                    }
                }
            }
        }

        return false;

    }

    //AW-Code
    private isDateVarOK(datevar) {

        if (datevar === undefined) { return false; }

        if (typeof (datevar) === 'object') {

            if (datevar.toString() === 'Invalid Date') {
                return false;
            }

        }

        if (datevar !== '') {
            return true;
        }

    }

    private useAdaptTLPrices() {
        if (this.user.ADAPTLSApplyVATRate === 'Y') {
            return true;
        }
    }

    private extractVerNo(filename) {      //Extract version number from rw6 file name.
        var parts = filename.split('_');
        var iVerPart = (parts.length - 1);

        var sVerPart = parts[iVerPart];
        sVerPart = sVerPart.replace('.rw6', '');
        sVerPart = sVerPart.replace('v', '');

        return Number(sVerPart);

    }

    private codeCheck(file) {
        return true; //DEV CODE ----------------------- REMOVE
        var bMatch = false;

        if (file.launchcode && file.launchcode.length > 0) {

            if (file.fileschanged === true) {

                if (file.launchcode === file.controlCode && file.controlExit == "YES") {

                    return true;

                }

            }
            else {

                if (file.controlCode !== undefined) {

                    if (file.controlExit !== undefined) {

                        if (file.controlCode.length > 0) {

                            if (file.launchcode === file.controlCode && file.controlExit == "YES") {

                                return true;

                            }

                        }
                        else {
                            return true;
                        }
                    }
                }

            }

        }


        console.error('Internal code difference, you must save and close down ADAPT Living Spaces before you can continue.');
        return false;
    }

    //Price check
    private priceCheck(file) {

        if (this.useAdaptTLPrices()) {
            return true;
        }

        var sVal = file.ProductCost === undefined ? "0" : file.ProductCost;
        var dVal = parseFloat(sVal)

        if (dVal === 0) {

            this.loadMaintainCPScreen(file);

            return false;

        }
        else {

            return true;

        }

    }

    private loadMaintainCPScreen(file) {

            this.selectedCPDetail.showScreen = true;
            this.selectedCPDetail.selectedFile.filename = file.filename;
            this.selectedCPDetail.selectedFile.feasibilityRef = file.feasibilityRef;
            this.selectedCPDetail.selectedFile.ProductCost = file.ProductCost;
            this.selectedCPDetail.selectedFile.buildingWorkCost = parseFloat(file.buildingWorkCost.toFixed(2));
            this.selectedCPDetail.selectedFile.ADGCost = file.ADGCost;
            this.selectedCPDetail.selectedFile.OtherCost = file.OtherCost;
            this.selectedCPDetail.selectedFile.jobname = file.jobname;
            this.selectedCPDetail.selectedFile.datemodified = file.datemodified;


            this.checkProductPrice();
            this.checkBWorkPrice();

            this.selectedCPDetail.fileIndex = -1;



            //var itemPrice = 0;
            //if ($scope.SOLAROOF === undefined) {
            //    itemPrice = 0;
            //}
            //else {
            //    itemPrice = $scope.SOLAROOF.Amount * file.SolaroofUnitQuantity;
            //}

            //$scope.selectedCPDetail.selectedFile.ProductCost += itemPrice;

            //if ($scope.ULTRA2 === undefined) {
            //    itemPrice = 0;
            //}
            //else {
            //    itemPrice = $scope.ULTRA2.Amount * file.Ultra2UnitQuantity;
            //}

            //$scope.selectedCPDetail.selectedFile.ProductCost += itemPrice;
        }
        private checkProductPrice() {
            var sVal = this.checkUndefined(this.selectedCPDetail.selectedFile.ProductCost, "0");
            var dVal = parseFloat(sVal);

            if (dVal > 0) {
                this.selectedCPDetail.selectedFile.prodPriceOK = true;

            }
            else {
                this.selectedCPDetail.selectedFile.prodPriceOK = false;

            }

    }

     private checkBWorkPrice() {
        if (this.selectedCPDetail.selectedFile.buildingWorkCost !== undefined) {
            var sVal = this.checkUndefined(this.selectedCPDetail.selectedFile.buildingWorkCost, "0");
            var dVal = parseFloat(sVal);
            this.selectedCPDetail.selectedFile.buildingWorkCost = dVal;
            this.selectedCPDetail.selectedFile.BWorkPriceOK = true;
        }
        else {
            this.selectedCPDetail.selectedFile.BWorkPriceOK = false;
        }

    }

    private checkUndefined(value, defaultvalue) {

        if (value === undefined) {
            return defaultvalue;
        }

        return value;

    }

    private async buildCPDesign(designCode, totalPrice, materialCode, materialName, properties, itemPrice, retrieveItemPriceFromDesign, nonDiscountables, unitQuantity) {
        var promise = this.priceListService.getDesignByCode(designCode).then(function (data) {
            if (data === undefined) {
                return null;
            }

            if (retrieveItemPriceFromDesign) {
                itemPrice = data.Amount || 0;
            }

            //added below code for AHIADAPT-85
            if (unitQuantity > 0) {
                itemPrice = data.Amount * unitQuantity;
            }
            var item: any = {
                included: true,
                priceOnApplication: {
                    amount: totalPrice
                },
                priceOnApplicationNonDisc: {
                    amount: totalPrice
                },
                material: { //Added Material as pricing would not work without it.
                    Code: materialCode,
                    Name: materialName
                },
                name: 'Living Space',
                superProduct: 'Living Space',
                properties: properties,
                itemPrice: itemPrice,
                nonDiscountables: nonDiscountables,
                design: {
                    Code: designCode
                },
                product: data.Product
            };

            if (unitQuantity !== null) {
                item.size = unitQuantity.toString();
            }

            return item;
        });

        return promise;
    };

    //AW-CODE - update proceed date
    private updateProceedDate(sFilename, proceedDateTime, opportunity) {

        for (var index = 0; index <  opportunity.order.cpFiles.length; ++index) {

            //Check names match and also that it's the one that was launched for editing.
            if (opportunity.order.cpFiles[index].filename == sFilename) {
                    
                opportunity.order.cpFiles[index].proceedDateTime = proceedDateTime;                                       
            }

        }

    }
}