import { entityInfoService } from './entityInfoService';
import { sessionService } from './sessionService';
import { config, tokendata } from '.';
import _, { map } from 'underscore';
import { AnglianData, dataAccessService, defer } from "./dataAccessService";
import { mappingHelperService } from './mappingHelperService';
import { PriceListService } from './priceListService';
import { BrowserApi } from './browserapi';
import { orderValueService } from './orderValueService';
import { MediaService } from './mediaService';

export class mapFromAdaptService {
    private _dataAccess = new dataAccessService();
    private _browserAPI = new BrowserApi();
    private _orderValueService = new orderValueService();
    private _priceListService = new PriceListService();
    private _mediaService = new MediaService();

    public async mapOpportunityToMessage(opp): Promise<any> {

        var deferredBuilder = defer();
        var mappingDeposits = defer();
        var mappingPhotos = defer();

        if (opp.timer === undefined) {
            opp.timer = {};
        }
        var user = await this._dataAccess.getCurrentUser();
        var _regionmarkups;
        if (user.regionmarkups !== undefined && user.regionmarkups !== null) {
            if (user.regionmarkups.length > 0) {
                _regionmarkups = JSON.parse(user.regionmarkups);
            }
        }

        //var grossOrderValue = opp.contract.GrossOrderValue;
        var grossOrderValue = opp.contract.GrossOrderValueWithEmail;
        if (isNaN(opp.contract.GrossOrderValueWithEmail) === true) {
            grossOrderValue = opp.contract.GrossOrderValue;
        }

        var adminFee = Number(opp.contract.AdminFee).toFixed(2);
        var noEmailCharge = 0;
        if (opp.contract.noEmailCharge && opp.contract.noEmailCharge > 0) {
            noEmailCharge = opp.contract.noEmailCharge;
        }

        var logisticCharge = 0;
        if (isNaN(opp.contract.logisticCharge) === false) {
            logisticCharge = opp.contract.logisticCharge;
        }

        var cashPrice = null;
        if (opp.contract.CashPrice !== null) {
            cashPrice = opp.contract.CashPriceWithEmail;
        }

        var includedItems = [];

        //For sold orders, only add included items, else add all.
        if (opp.contract.isSold) {
            includedItems = _.filter(opp.order.items, function (i) { return i.included; });
        }
        else {
            includedItems = opp.order.items;
        }


        var oReport:any = {};
        oReport.lastupdated = new Date(opp.LastUpdatedDate);
        oReport.product = opp.lead.Product;
        oReport.title = opp.lead.Title;
        oReport.forename = opp.lead.Forename;
        oReport.surname = opp.lead.Surname;
        oReport.unitqty = opp.order.items.length;
        oReport.sold = opp.contract.isSold;
        oReport.dataid = opp.Id;


        //loggerService.logInfo(JSON.stringify(oReport), "MAPPING ORDER FOR UPLOAD");

        var grossTotals = this._orderValueService.getGrossTotals(grossOrderValue, includedItems, opp.order.charges, adminFee, opp.lead.Product, opp.order.cpSelected, opp.order.appliedDiscounts, noEmailCharge, logisticCharge);
        var grossProductPrice = Number(grossTotals.totalProductPrice).toFixed(2);
        var grossExtras = Number(grossTotals.totalExtrasPrice).toFixed(2);
        var grossAdditionalWork = Number(grossTotals.totalAdditionalWork).toFixed(2);
        var grossSGO = Number(grossTotals.totalSGO).toFixed(2);


        var version = this._browserAPI.getVersion();

        var repID = user.RepNumber;

        var stream2DiscountTrail = [];
        if (opp.contract.cashDefault) {
            stream2DiscountTrail = opp.contract.stream2DiscountTrailCash;

        }
        else {
            stream2DiscountTrail = opp.contract.stream2DiscountTrailFinance;

        }


        var sCustomerTypeName = '';
        switch (opp.lead.customertype) {
            case '1':
                sCustomerTypeName = "Retail";
                break;
            case "2":
                sCustomerTypeName = "Landlord";
                break;
            case "3":
                sCustomerTypeName = "Commercial";
                break;
        }

        var message:any = {
            OrderDetails: {
                Opportunity: {
                    RepNumber: repID,
                    OpportunityNumber: opp.lead.OpportunityNumber,
                    Product: opp.lead.Product,
                    RegionNumber: opp.lead.RegionNumber,
                    InstallingRegionNumber: opp.lead.InstallingRegion,
                    InstallingRegionPostcode: opp.lead.InstallingRegionPostcode,
                    uniqueTrackingId: opp.lead.uniqueTrackingId,
                    SalesTeamNumber: opp.lead.SalesTeamNumber,
                    AppointmentDate: opp.lead.AppointmentDate,
                    AppointmentTime: opp.lead.AppointmentTime,
                    LeadSource: opp.lead.LeadSource,
                    MediaCode: opp.lead.MediaCode,
                    customertype: opp.lead.customertype,
                    customertypename: sCustomerTypeName,
                    CustomerDetails: {
                        Title: opp.lead.Title,
                        Forename: opp.lead.Forename,
                        Surname: opp.lead.Surname,
                        Relationship: opp.lead.Relationship,
                        Title2: opp.lead.Title2,
                        Forename2: opp.lead.Forename2,
                        Surname2: opp.lead.Surname2,
                        Relationship2: opp.lead.Relationship2,
                        AddressLine1: opp.lead.Address1,
                        AddressLine2: opp.lead.Address2,
                        AddressLine3: opp.lead.Address3,
                        AddressLine4: opp.lead.Address4,
                        Postcode: opp.lead.PostCode,
                        EmailAddress: opp.lead.Email != null && opp.lead.Email.length ? opp.lead.Email[0].EmailAddress : null,
                        ContactDetails: this.mapContactDetails(opp.lead.ContactDetails),
                        PhoneNumber: opp.lead.PhoneNumber,
                        PreferredContactMethod: opp.lead.ContactMethod
                    },
                    timer: {
                        Name: opp.timer ? opp.timer.Name : null,
                        Reason: opp.timer ?  opp.timer.Reason : null,
                        Whatnext: opp.timer ? opp.timer.Whatnext : null,
                        SignatureName: opp.timer ?  opp.timer.signatureName : null,
                        Image: opp.timer ?  opp.timer.image : null,
                        DurationDate: opp.timer ?  opp.timer.DurationDate : null,
                        Duration: opp.timer ?  opp.timer.Duration : null,
                        AnswerStatus: opp.timer ?  opp.timer.AnswerStatus : null

                    },
                    PromotionUnderstanding: opp.lead.promotionUnderstanding ? opp.lead.promotionUnderstanding : null,
                    funding: opp.lead.funding ? opp.lead.funding : null,
                    budget: opp.lead.budget ? opp.lead.budget : null,
                    cashLumpSum: opp.lead.cashLumpSum ? opp.lead.cashLumpSum : null,
                    timescale: opp.lead.timescale ? opp.lead.timescale : null,
                    timescaleOther: opp.lead.timescaleOther ? opp.lead.timescaleOther : null,
                    ConfirmedToReceiveEmails: this._mappingHelperService.mapBoolToYN(opp.lead.ConfirmedToReceiveEmails),
                    ConfirmedToReceiveSpecialOffers: this._mappingHelperService.mapBoolToYN(opp.lead.ConfirmedToReceiveSpecialOffers),
                    quote: this.mapQuote(opp.quote),
                    visitedHUHYScreen: opp.visitedHUHYScreen

                },
                Order: {
                    OrderNumber: opp.order.OrderNumber,
                    Items: this.mapItems(includedItems),
                    ListPrice: Number(this._priceListService.getTotalPrice(includedItems, null)).toFixed(2),
                    CalculatedSlidingScaleStartPoint: Number(opp.order.slidingScaleEntry).toFixed(2),
                    Discounts: this.mapDiscounts(opp.order.discountTrail, opp),
                    Priorities: this.mapPriorities(opp.order.selectedPriorities),
                    Notes: opp.order.notes, // todo confirm
                    PriceIllustration: opp.order.priceIllustration,
                    Illustration: opp.order.customerIsEligibleForFinance ? opp.order.illustration : null, //  only if Eligible for finance
                    Ecohome: opp.order.ecohome,
                    MarketingOptions: this.mapMarketingOptions(opp.order.marketingOptions, opp.lead.Product),
                    CustomerInterest: opp.order.proposedProduct,
                    CustomerIsEligibleForFinance: opp.order.customerIsEligibleForFinance,
                    customerNotEligibleForFinanceReason: opp.order.customerNotEligibleForFinanceReason,
                    MandatoryDiscPhotos: opp.order.MandatoryDiscPhotos,
                    sowPrices: opp.order.sowPrices,
                    sowQuestions: opp.order.sowQuestions,
                    doorbell: opp.order.doorbell,
                    HelpUsHelpYouAgreed: opp.order.HelpUsHelpYouAgreed,
                    //AW-CODE Living Space
                    LivingSpace: {
                        CustomerDesignRequirements: opp.order.cpDesighReq,
                        ShowhomeSurvey: opp.order.cpShowHome
                    },
                    CustomerTreatment: opp.order.customerTreatment,
                    ProductPriceGroupMarkFactor: _regionmarkups,
                    IsResendData: opp.order.IsResendData,
                    SlidingScaleConfirm: opp.order.SlidingScaleConfirm, //1. No HUHY 2. Less than  Maxmimum HUHY 3. Maximum HUHY
                    OrderCharges: opp.order.charges,
                    RemoteSelling: opp.order.RemoteSelling,
                    RemoteSellingCustomerDetails: opp.order.RemoteSellingCustomerDetails,
                    eSignature: opp.order.eSignature,
                    RemoteSellingPriortiesScreen: opp.order.RemoteSellingPriortiesScreen,
                    LeadTimes: opp.order.leadtimes
                },
                Contract: {
                    Title: opp.lead.Title,
                    FirstName: opp.lead.Forename,
                    Surname: opp.lead.Surname,
                    Relationship: opp.lead.Relationship,
                    Title2: opp.lead.Title2,
                    FirstName2: opp.lead.Forename2,
                    Surname2: opp.lead.Surname2,
                    Relationship2: opp.lead.Relationship2,
                    InstallationAddress: opp.contract.installationAddress,
                    SellingAddress: opp.contract.invoiceAddress,
                    IsSold: this._mappingHelperService.mapNumberToYN(opp.contract.isSold),
                    FinanceSource: opp.contract.sourceOfFinance != null ? opp.contract.sourceOfFinance.SourceOfFinanceNumber : null,
                    SupplyAndFit: 'Y', // TODO                    
                    stream2DiscountTrail: stream2DiscountTrail,
                    BalancePayableOnInstallation: Number(opp.contract.balancePayable).toFixed(2),
                    AmountPayableOnFinance: Number(opp.contract.FinanceAmount).toFixed(2),
                    GrossProductPrice: grossProductPrice,
                    GrossAdditionalWork: grossAdditionalWork,
                    GrossSGO: grossSGO,
                    GrossExtras: grossExtras,
                    GrossOrderValue: grossOrderValue,
                    CashPrice: opp.contract.IsFianceClick ? cashPrice : null,
                    AdminFee: adminFee,
                    adminFeeOnePAudit: opp.contract.adminFeeOnePAudit,
                    noEmailCharge: noEmailCharge,
                    logisticCharge: logisticCharge,
                    regionCost: opp.contract.regionCost,
                    ValueOfFinance: Number(opp.contract.FinanceAmount).toFixed(2),
                    FinanceReference: opp.contract.financeReference,
                    DepositValue: Number(opp.contract.depositAmount).toFixed(2),
                    ContractDate: opp.contract.isSold ? this._mappingHelperService.convertDateToYYYYMMDD(new Date()) : null, // only if IsSold is Y
                    ContractTime: opp.contract.isSold ? this._mappingHelperService.convertDateToTime(new Date()) : null, //  ony if IsSold is Y
                    SlidingScalePercentage: opp.order.slidingScalePercent,
                    SlidingScaleAudit: opp.order.slidingScaleAudit,
                    AverageDiscount: parseFloat(opp.order.AverageDiscount) * 100,
                    EliteDiscount: parseFloat(opp.order.EliteDiscount) * 100,
                    cpFirstStagePayment: opp.contract.cpFirstStagePayment,
                    cpSecondStagePayment: opp.contract.cpSecondStagePayment,
                    RESIDENT: this.mappedQuestionValue(opp.lead.Questions, "RESIDENT"),
                    OWNER: this.mappedQuestionValue(opp.lead.Questions, "OWNER"),
                    QUOTES: this.mappedQuestionValue(opp.lead.Questions, "QUOTES"),
                    TRIPLE: this.mappedQuestionValue(opp.lead.Questions, "TRIPLE"),
                    CONSERVATION: opp.order.isConservationArea ? "Y" : "N",
                    MagicEyePer: opp.order.MagicEyePer,
                    CameoCode: opp.lead.CameoCode,
                    Questions: opp.lead.Questions,
                    Signatures: this.mapSignatures(opp.contract.signatures),
                    Notes: opp.contract.notes,
                    ContractQuestions: {
                        LivingSpacePhysicalBarrierRemain: this._mappingHelperService.mapBoolToYN(opp.contract.cpPhysicalBarrier),
                        LivingSpaceHotWaterHeatingSystemRequired: this._mappingHelperService.mapBoolToYN(opp.contract.cpHotWaterSystem),
                        PlanningPermissionWithdrawn: this._mappingHelperService.mapBoolToYN(opp.contract.permissionsWithdrawn),
                        ListedBuildingConsentRequired: this._mappingHelperService.mapBoolToYN(opp.contract.consentRequired),
                        InConservationArea: this._mappingHelperService.mapBoolToYN(opp.contract.conversionArea),
                        Article4RightsApply: this._mappingHelperService.mapBoolToYN(opp.contract.article4),
                        IsPropertyLeasehold: this._mappingHelperService.mapBoolToYN(opp.contract.housingAssociation),
                        LocalAuthorityName: opp.contract.housingAssociation ? opp.contract.localAuthority : '',
                        InstalledAbutsSoffit: this._mappingHelperService.mapBoolToYN(opp.contract.abutsTheSoffit),
                        IsNewBuildProperty: this._mappingHelperService.mapBoolToYN(opp.contract.newBuild),
                        IsPorch: this._mappingHelperService.mapBoolToYN(opp.contract.isPorch),
                        PorchNewBase: this._mappingHelperService.mapBoolToYN(opp.contract.PorchNewBase),
                        PlanningApplication: opp.contract.PlanningApplication,
                        GasAppliancePresent: this._mappingHelperService.mapBoolToYN(opp.contract.GasAppliancePresent),
                        RestrictiveCovenantsRecorded: this._mappingHelperService.mapBoolToYN(opp.contract.RestrictiveCovenantsRecorded),
                        PorchPurchaserOwnBase: this._mappingHelperService.mapBoolToYN(opp.contract.PorchPurchaserOwnBase),
                        SupplyOnlyPorch: this._mappingHelperService.mapBoolToYN(opp.contract.SupplyOnlyPorch),
                        PorchAnglianConstructedBase: this._mappingHelperService.mapBoolToYN(opp.contract.PorchAnglianConstructedBase)
                    },
                    InstallationDate: this._mappingHelperService.convertDateToYYYYMMDD(opp.contract.notBeforeDate),
                    Declaration: opp.contract.declaration,
                    DeclarationQuestionsDisplayedText: this.mapContractQuestionsText(opp.contract.declarationquestions),
                    IsFianceClick: opp.contract.IsFiance,
                    AnglianOptIns: opp.AnglianOptIns,
                    AffiliateOptIns: opp.AffiliateOptIns,
                    SuppressedAnglianOptIns: opp.SuppressedAnglianOptIns,
                    AuditTrail: opp.auditTrail,
                    promarOptIns: opp.promarOptIns,
                    OnMyBehalfAgentName: opp.contract.OnMyBehalfAgentName,
                    OnMyBehalfAgentConfirmed: opp.contract.OnMyBehalfAgentConfirmed,
                    OnMyBehalfTitleText: opp.contract.OnMyBehalfTitleText,
                    OnMyBehalfLabelTextBefore: opp.contract.OnMyBehalfLabelTextBefore,
                    OnMyBehalfLabelTextAfter: opp.contract.OnMyBehalfLabelTextAfter,
                    OnMyBehalfAgentName2: opp.contract.OnMyBehalfAgentName2,
                    OnMyBehalfAgentConfirmed2: opp.contract.OnMyBehalfAgentConfirmed2,
                    OnMyBehalfTitleTex2: opp.contract.OnMyBehalfTitleText2,
                    UserActions: opp.userActions,
                    DiscountActions: opp.discountActions,
                    ShareAndSaveOrder: true
                },
                Id: opp.Id,
                AdaptVersion: version,
                MessageDate: new Date()
            }
        };

        this.mapDeposits(opp.contract.deposits).then(function (deposits) {
            message.OrderDetails.Contract.DepositPayments = deposits;
            mappingDeposits.resolve();

        });

        this.mapPhotos(opp.order.photos).then(function (photos) {
            message.OrderDetails.Order.Photos = photos;
            mappingPhotos.resolve();
        });

        //Only add the files if it's sold.
        if (opp.contract.isSold == 1) {
            message.OrderDetails.Order.LivingSpace.Files = this.mapCPFiles(opp.order.cpSelected, opp.lead.Product);
        }
        else {
            //Add unsold files
            message.OrderDetails.Order.LivingSpace.UnSoldFiles = this.mapUnSoldFiles(opp.order.cpFiles, opp.lead.Product);
        }

        Promise.all([mappingDeposits.promise, mappingPhotos.promise]).then(function () {
            deferredBuilder.resolve(message);
        });

        return deferredBuilder.promise;
   }










    private _mappingHelperService: mappingHelperService = new mappingHelperService;
    private mapContactDetails(contactDetails) {
        var mapped = [];
        _.forEach(contactDetails, function (c) {
            mapped.push({ PhoneNumber: c.PhoneNumber, Type: c.Type });
        });
        return mapped;
    }

    private mapContractQuestionsText(contractQuestions) {
        return contractQuestions;
    }

    private mapProperty(prop) {
        try {
            //var result = {
            //    PropertyId: prop.Code,
            //    Class: prop.Style.Code ? prop.Style.Code : '',
            //    SOWClass: prop.SOWCode,
            //    Description: prop.Name,
            //    Price: prop.price,
            //    Quantity: prop.quantity ? prop.quantity : 1, // if it's null, then it's defaulted to 1.
            //    IsDiscountable: prop.isDiscountable,
            //    IsExtraData: prop.IsExtraData, //26-Sep-2016 PROT-6869 : this property has been added because if we select "GRP" and colour there are colour class in extra too so to differentiate , top "Choose Colour" Section and Extra Colour "Outside Leaf Colour" or "Inside Leaf Colour". 
            //    Id: prop.Id // Added on 10-Jan-2017 for Task 7092 (Because PriceofApplication and PriceofApplication no disclose ) code are same so they are only differentiate via Id.
            //};

            var result:any = {};

            result.PropertyId = prop.Code;
            result.Class = '';
            if (prop.Style) {
                if (prop.Style.Code) {
                    result.Class = prop.Style.Code;
                }
            }
            result.SOWClass = prop.SOWCode;
            result.Description = prop.Name;
            result.Price = prop.price;
            result.Quantity = prop.quantity ? prop.quantity : 1;
            result.IsDiscountable = prop.isDiscountable;
            result.IsExtraData = prop.IsExtraData;
            result.Id = prop.Id;
            result.ProductPriceGroup = prop.ProductPriceGroup;

            return result;
        } catch (e) { // We should never get here but it stops us throwing an exception when a sale is made.
            return null;
        }
    }

    private returnNonStdColour() {

        var colProp:any = {};
        colProp.Style = {};
        colProp.Style.Code = "COLOUR";
        colProp.SOWCode = "COLOUR";
        colProp.Description = name;
        colProp.Code = "NONSTDCOLOUR";

        return colProp;
    }

    private mapProperties(item) {
        var mapped = [];

        var oProp;

        // Get material
        if (item.material) {
            oProp = this.mapProperty(item.material);
            if (oProp !== null) {
                mapped.push(oProp);
            }
        }

        // Get Colour
        if (item.colours && item.colours.length) {

            if (item.colours[1]) {

                if (item.colours[1] === 'NONSTANDARD') {
                    item.colours[1] = this.returnNonStdColour();
                }
                item.colours[1].Name = 'INSIDEFRAME';

                if (item.colours.price) {
                    item.colours[1].price = item.colours.price;
                }

                oProp = this.mapProperty(item.colours[1]);
                if (oProp !== null) {
                    mapped.push(oProp);
                }

            }

            if (item.colours[2]) {

                if (item.colours[2] === 'NONSTANDARD') {
                    item.colours[2] = this.returnNonStdColour();
                }
                item.colours[2].Name = 'INSIDESASH';

                oProp = this.mapProperty(item.colours[2]);
                if (oProp !== null) {
                    mapped.push(oProp);
                }

            }

            if (item.colours[3]) {

                if (item.colours[3] === 'NONSTANDARD') {
                    item.colours[3] = this.returnNonStdColour();
                }
                item.colours[3].Name = 'OUTSIDEFRAME';

                oProp = this.mapProperty(item.colours[3]);
                if (oProp !== null) {
                    mapped.push(oProp);
                }
            }

            if (item.colours[4]) {

                if (item.colours[4] === 'NONSTANDARD') {
                    item.colours[4] = this.returnNonStdColour();
                }
                item.colours[4].Name = 'OUTSIDESASH';

                oProp = this.mapProperty(item.colours[4]);
                if (oProp !== null) {
                    mapped.push(oProp);
                }

            }
        } else if (item.colour) {

            oProp = this.mapProperty(item.colour);

            if (item.nonStdColour) {
                oProp.PropertyId = item.nonStdColour.PropertyId;
                oProp.Price = item.nonStdColour.price;
                oProp.IsDiscountable = item.nonStdColour.discountable;
                oProp.Class = item.nonStdColour.Class;
                oProp.SOWClass = item.nonStdColour.SOWClass;
                oProp.Description = item.nonStdColour.Description;
            }

            if (oProp !== null) {
                mapped.push(oProp);
            }

        }

        // Get others
        _.forEach(item.properties, (p) => {
            if (p && p.included) {
                //26-Sep-2016 PROT-6869 : this property has been added because if we select "GRP" and colour there are colour class in extra too so to differentiate 
                // top "Choose Colour" Section and Extra Colour "Outside Leaf Colour" or "Inside Leaf Colour". 
                p.IsExtraData = true;
                var _p = this.mapProperty(p);
                if (_p !== null) {
                    if (Object.keys(_p).length > 0) {
                        mapped.push(_p);
                    }
                }
            }
        });
        //We want to update the customer confirmed property
        if (item.explosionConfirmations && mapped) {

            var iConfirms = item.explosionConfirmations.length;
            var iProps = mapped.length;

            for (var iProp = 0; iProp < iProps; iProp++) {

                for (var iCon = 0; iCon < iConfirms; iCon++) {

                    if (mapped[iProp].Id) {

                        if (item.explosionConfirmations[iCon].propertyId === mapped[iProp].Id) {

                            mapped[iProp].customerConfirmed = item.explosionConfirmations[iCon].confirmed;
                            break;
                        }

                    }

                }

            }

        }

        return mapped;
    }

    private mapSlots(slots) {
        var mapped = [];

        _.forEach(slots, function (row) {
            _.forEach(row, function (col) {
                if (!col.hide) {
                    mapped.push({
                        TopLeft: col.TopLeft,
                        BottomRight: col.BottomRight,
                        PanelType: col.PropertyTemplate.Code
                    });
                }
            });
        });

        return mapped;
    }

    private mapCustomTemplate(rows, columns, template) {
        return {
            PanelsHigh: rows,
            PanelsWide: columns,
            Slots: this.mapSlots(template)
        };
    }

    private mapBay(bay) {
        return {
            BayId: bay.bayId,
            Position: bay.bayPosition
        };
    }

    private mapItem(item) {
        var mapped:any = {
            ProductCode: item.selectedTemplate ? item.selectedTemplate.Code : item.design ? item.design.Code : null,
            ProductName: item.product ? item.product.Name : null,
            ProductType: item.product ? item.product.Code : null,
            Description: item.name,
            Width: item.width,
            Height: item.height,
            ListPrice: item.itemPrice,
            Bay: item.bay != null ? this.mapBay(item.bay) : null,
            CustomTemplate: item.useWizardProcess ? this.mapCustomTemplate(item.rows, item.cols, item.template) : null,
            Properties: this.mapProperties(item),
            Notes: item.notes,
            NonDiscountables: item.nonDiscountables,
            Included: item.included,
            nonStdColour: item.nonStdColour,
            confirmedReadCustomSizeGuide: item.confirmedReadCustomSizeGuide
        };

        //This is not always set when saving.
        if (item.material) {
            mapped.IsCustomerConfirmation = item.material.IsCustomerConfirmation;
        }

        if (item.size) {
            // Note: added for Living Spaces Solaroof and Ultra2 designs - not used for other items.
            mapped.Size = item.size;
        }

        return mapped;
    }

    private mapDesigns(item, groupBy) {
        var mapped = [];

        var oProp;

        _.forEach(item.designs,  (d, idx) => {
            var design:any = {
                ProductCode: d.selected.Code,
                ProductName: d.selected.Product.Name,
                ProductType: d.selected.Product.Code,
                Description: item.name,
                Size: item.size,
                designGroup: groupBy,
                designId: d.selected.Id,
                Included: item.included

            };

            design.Properties = [];

            // Map colour
            if (d.colour) {

                oProp = this.mapProperty(d.colour);
                if (oProp != null) {
                    design.Properties.push(oProp);
                }

            }

            // Map material
            if (d.material) {

                oProp = this.mapProperty(d.material);
                if (oProp != null) {
                    design.Properties.push(oProp);
                }

            }

            if (idx === 0) { // We'll add certain things to the first one
                design.ListPrice = item.itemPrice;
                design.NonDiscountables = item.nonDiscountables;
                design.Notes = item.notes;

                _.forEach(item.properties, function (prop) {

                    oProp = this.mapProperty(prop);
                    if (oProp != null) {
                        oProp.designId = prop.designId;
                        design.Properties.push(oProp);
                    }

                });

            }


            mapped.push(design);
        });

        return mapped;
    }

    private mapItems(items) {
        var mapped = [];
        _.forEach(items, (i, idx) => {
            if (i.designs && i.designs.length) {
                // map slightly differently as it's rooftrim
                var designs = this.mapDesigns(i, idx);
                _.forEach(designs, function (d) {
                    mapped.push(d);
                });
            } else {
                mapped.push(this.mapItem(i));
            }
        });

        return mapped;
    }

    private mapDiscounts(discounts, opp) {
        var mapped = [];

        _.forEach(discounts, function (d) {

            var info:any = {};
            info.DiscountId = d.id;
            info.DiscountName = d.name;
            info.DiscountValue = Number(d.value).toFixed(2);
            info.DiscountType = d.discountType;
            info.IsApplied = true;
            info.AdditionalEovDiscount = d.AdditionalEovDiscount;
            info.selectedHUHYOptions = d.selectedOptions;
            info.eovInfo = d.eovInfo;
            info.MandatoryPhotoRequired = d.MandatoryPhotoRequired;
            info.PhotoBase64 = null;
            if (d.mandatoryPhoto) {
                info.PhotoBase64 = d.mandatoryPhoto.base64;
            }
            mapped.push(info);

            //mapped.push({
            //    DiscountId: d.id,
            //    DiscountName: d.name,
            //    DiscountValue: Number(d.value).toFixed(2),
            //    IsApplied: true, // TODO - MAP
            //    AdditionalEovDiscount: d.AdditionalEovDiscount,
            //    //  ExtendedEovDiscount: d.ExtendedEovDiscount,
            //    selectedHUHYOptions: d.selectedOptions
            //});
        });
        if (discounts === undefined) {
            try {
                if (opp.unsoldVersions !== undefined && opp.unsoldVersions !== null) {
                    if (opp.unsoldVersions.length > 0) {
                        if (opp.unsoldVersions[opp.unsoldVersions.length - 1].OrderDetails !== undefined && opp.unsoldVersions[opp.unsoldVersions.length - 1].OrderDetails !== null) {
                            if (opp.unsoldVersions[opp.unsoldVersions.length - 1].OrderDetails.Order !== undefined && opp.unsoldVersions[opp.unsoldVersions.length - 1].OrderDetails.Order !== null) {
                                mapped = opp.unsoldVersions[opp.unsoldVersions.length - 1].OrderDetails.Order.Discounts;
                            }
                        }

                    }
                }
            }
            catch (ex) {

            }


        }

        return mapped;
    }

    private mapPriorities(priorities) {
        var mapped = [];

        _.forEach(priorities, function (p, index) {
            if (p.IsCustom === undefined) {
                p.IsCustom = false;
            }
            mapped.push({
                Description: p.Description,
                PriorityNumber: index + 1,
                IsMet: p.isMet,
                IsCustom: p.IsCustom
            });
        });

        return mapped;
    }


    private async mapPhotos(photos) {
        var mapping = defer();
        var loading = [];

        var mapped = [];

        for (var photoIdx in photos) {
            var p = photos[photoIdx];
            var photo: any = { Title: p.note, Photograph : p.base64 };
            photo.includeInSOW = p.includeInSOW;
            photo.Photograph = await this._mediaService.getCachedImageBase64(p.url)
            mapped.push(photo);
        }

        Promise.all(loading).then(function () { mapping.resolve(mapped); });

        return mapping.promise;
    }

    private mapDiscManPhotos(discManPhotos) {
        var mapping = defer();
        var loading = [];

        var mapped = [];

        for (var pIdx in discManPhotos) {
            var p = discManPhotos[pIdx];
            var photo: any = {};
            photo.DiscountId = p.DiscountId;

            var converting = this._mediaService.getCachedImageBase64(p.url);
            loading.push(converting);
            converting.then(function (data) {
                photo.Photobase64 = data;
            });

            mapped.push(photo);
        }

        Promise.all(loading).then(function () { mapping.resolve(mapped); });

        return mapping.promise;
    }

    private mapDeposits(deposits) {
        var mapping = defer();
        var loading = [];

        var mapped = [];

        for (var dIdx in deposits) {
            var d = deposits[dIdx];
            var payment: any = {
                PaymentMethod: d.method,
                Value: Number(d.amount).toFixed(2),
                CardTransactionId: d.paytelRef
            };

            if (d.chequePhoto && d.chequePhoto.url) {
                var converting = this._mediaService.getCachedImageBase64(d.chequePhoto.url);
                loading.push(converting);
                converting.then(function (data) {
                    payment.DepositImage = data;
                });
            }

            mapped.push(payment);
        }


        Promise.all(loading).then(function () { mapping.resolve(mapped); });

        return mapping.promise;
    }

    //AW-CODE - Map CP files for return JSON
    private mapCPFiles(cpSelected, product) {

        var mapped:any = {};

        if (product == 'CP') {

            mapped.frontpng = cpSelected.files.frontpng;
            mapped.leftpng = cpSelected.files.leftpng;
            mapped.rightpng = cpSelected.files.rightpng;
            mapped.toppng = cpSelected.files.toppng;

            mapped.left3dpng = cpSelected.files.left3dpng;
            mapped.right3dpng = cpSelected.files.right3dpng;

            mapped.WallFiles = cpSelected.files.WallFiles;

            mapped.envisageversion = cpSelected.files.envisageversion;
            mapped.regionalmarkupXML = cpSelected.files.regionalmarkupXML;

            mapped.rw6base64 = cpSelected.files.rw6base64;
            mapped.xml = cpSelected.files.xmlbase64;
            mapped.rwb = cpSelected.files.rwbbase64;
            mapped.TotalPrice = cpSelected.TotalPrice;
            mapped.xmlTotalPrice = cpSelected.xmlTotalPrice;
            mapped.jobname = cpSelected.jobname;
            mapped.filename = cpSelected.filename;
            mapped.feasibilityRef = cpSelected.feasibilityRef;
            mapped.buildingWorkCost = cpSelected.buildingWorkCost;
            mapped.ADGCost = cpSelected.ADGCost;
            mapped.ProductCost = cpSelected.ProductCost;
            mapped.OtherCost = cpSelected.OtherCost;
            mapped.SolaroofUnitQuantity = cpSelected.SolaroofUnitQuantity;
            mapped.Ultra2UnitQuantity = cpSelected.Ultra2UnitQuantity;
            mapped.datemodified = cpSelected.datemodified;

            mapped.xmlbuildingWorkCost = cpSelected.xmlbuildingWorkCost;
            mapped.xmlADGCost = cpSelected.xmlADGCost;
            mapped.xmlProductCost = cpSelected.xmlProductCost;
            mapped.xmlOtherCost = cpSelected.xmlOtherCost;
            mapped.xmlSolaroofUnitQuantity = cpSelected.xmlSolaroofUnitQuantity;
            mapped.xmlUltra2UnitQuantity = cpSelected.xmlUltra2UnitQuantity;

            mapped.systemname = cpSelected.systemname;
            mapped.jobname = cpSelected.jobname;

            if (cpSelected.vatInfo) {

                mapped.vatRate = cpSelected.vatInfo.vatRate;
                mapped.vatRateCalc = cpSelected.vatInfo.vatRateCalc;

                mapped.xmlProductCostPreVAT = cpSelected.vatInfo.xmlProductCostPreVAT;
                mapped.xmlbuildingWorkCostPreVAT = cpSelected.vatInfo.xmlbuildingWorkCostPreVAT;
                mapped.xmlADGCostPreVAT = cpSelected.vatInfo.xmlADGCostPreVAT;
                mapped.xmlOtherCostPreVAT = cpSelected.vatInfo.xmlOtherCostPreVAT;
                mapped.xmlTotalPricePreVAT = cpSelected.vatInfo.xmlTotalPricePreVAT;

                mapped.xmlProductCostVATAmount = cpSelected.vatInfo.xmlProductCostVATAmount;
                mapped.xmlbuildingWorkCostVATAmount = cpSelected.vatInfo.xmlbuildingWorkCostVATAmount;
                mapped.xmlADGCostVATAmount = cpSelected.vatInfo.xmlADGCostVATAmount;
                mapped.xmlOtherCostVATAmount = cpSelected.vatInfo.xmlOtherCostVATAmount;
                mapped.xmlTotalPriceVATAmount = cpSelected.vatInfo.xmlTotalPriceVATAmount;

            }

            if (cpSelected.files.rwbbase64 != null) {
                mapped.rwbDateModified = cpSelected.files.rwbDateModified;

            }

            // >> THIS MAY NEED TO BE ADDED IN LATER
            //Clean up files, no longer needed.
            //envisageService.deleteOrderFiles(cpSelected.filename);

        }

        return mapped;

    }

    //AW - Map unsold files into return object.
    private mapUnSoldFiles(oUnSolds, product) {

        var oReturns = [];

        var sfilename = '';

        var bProcess = false;

        if (product == 'CP') {

            _.forEach(oUnSolds, function (oUn) {

                bProcess = true;
                if (oUn.files.xmlMissing === true) {
                    bProcess = false;
                }

                if (bProcess === true) {

                    var oFile:any = {};

                    sfilename = oUn.filename;

                    oFile.TotalPrice = oUn.TotalPrice;
                    oFile.xmlTotalPrice = oUn.xmlTotalPrice;
                    oFile.jobname = oUn.jobname;
                    oFile.filename = oUn.filename;
                    oFile.feasibilityRef = oUn.feasibilityRef;
                    oFile.buildingWorkCost = oUn.buildingWorkCost;
                    oFile.ADGCost = oUn.ADGCost;
                    oFile.ProductCost = oUn.ProductCost;
                    oFile.OtherCost = oUn.OtherCost;
                    oFile.datemodified = oUn.datemodified;
                    oFile.files_frontpng = oUn.files.frontpng;
                    oFile.files_leftpng = oUn.files.leftpng;
                    oFile.files_rightpng = oUn.files.rightpng;
                    oFile.files_toppng = oUn.files.toppng;
                    oFile.files_left3dpng = oUn.files.left3dpng;
                    oFile.files_right3dpng = oUn.files.right3dpng;
                    oFile.files_rw6base64 = oUn.files.rw6base64;
                    oFile.files_xmlbase64 = oUn.files.xmlbase64;
                    oFile.files_rwbbase64 = oUn.files.rwbbase64;
                    oFile.files_exitbase64 = oUn.files.exitbase64;
                    oFile.files_front_elevation_path = oUn.files.front_elevation_path;
                    oFile.files_left_elevation_path = oUn.files.left_elevation_path;
                    oFile.files_right_elevation_path = oUn.files.right_elevation_path;
                    oFile.files_top_elevation_path = oUn.files.top_elevation_path;
                    oFile.files_left3d_elevation_path = oUn.files.left3d_elevation_path;
                    oFile.files_right3d_elevation_path = oUn.files.right3d_elevation_path;

                    oFile.files_envisageversion = oUn.files.envisageversion;
                    oFile.files_regionalmarkupXML = oUn.files.regionalmarkupXML;

                    oFile.launchcode = oUn.launchcode;
                    oFile.controlCode = oUn.controlCode;
                    oFile.controlExit = oUn.controlExit;

                    oFile.files_WallFiles = oUn.files.WallFiles;

                    oFile.xmlbuildingWorkCost = oUn.xmlbuildingWorkCost;
                    oFile.xmlADGCost = oUn.xmlADGCost;
                    oFile.xmlProductCost = oUn.xmlProductCost;
                    oFile.xmlOtherCost = oUn.xmlOtherCost;

                    oFile.SolaroofUnitQuantity = oUn.SolaroofUnitQuantity;
                    oFile.Ultra2UnitQuantity = oUn.Ultra2UnitQuantity;

                    oFile.systemname = oUn.systemname;
                    oFile.jobname = oUn.jobname;

                    if (oUn.vatInfo) {

                        oFile.vatRate = oUn.vatInfo.vatRate;
                        oFile.vatRateCalc = oUn.vatInfo.vatRateCalc;

                        oFile.xmlProductCostPreVAT = oUn.vatInfo.xmlProductCostPreVAT;
                        oFile.xmlbuildingWorkCostPreVAT = oUn.vatInfo.xmlbuildingWorkCostPreVAT;
                        oFile.xmlADGCostPreVAT = oUn.vatInfo.xmlADGCostPreVAT;
                        oFile.xmlOtherCostPreVAT = oUn.vatInfo.xmlOtherCostPreVAT;
                        oFile.xmlTotalPricePreVAT = oUn.vatInfo.xmlTotalPricePreVAT;

                        oFile.xmlProductCostVATAmount = oUn.vatInfo.xmlProductCostVATAmount;
                        oFile.xmlbuildingWorkCostVATAmount = oUn.vatInfo.xmlbuildingWorkCostVATAmount;
                        oFile.xmlADGCostVATAmount = oUn.vatInfo.xmlADGCostVATAmount;
                        oFile.xmlOtherCostVATAmount = oUn.vatInfo.xmlOtherCostVATAmount;
                        oFile.xmlTotalPriceVATAmount = oUn.vatInfo.xmlTotalPriceVATAmount;

                    }

                    if (oUn.files.rwbbase64 != null) {
                        oFile.files_rwbDateModified = oUn.files.rwbDateModified;

                    }

                    if (oUn.proceedDateTime !== undefined) {
                        oFile.proceedDateTime = oUn.proceedDateTime;

                    }

                    oReturns.push(oFile);

                }

            });

            //Clean up files, no longer needed.
            if (sfilename.length > 0) {
                //envisageService.deleteOrderFiles(sfilename);

            }


        }

        return oReturns;

    }

    private mapSignatures(signatures) {
        var mapped = [];
        _.chain(signatures)
            .filter(function (sig) { return !_.isUndefined(sig.image); })
            .forEach(function (sig) {
                mapped.push({
                    Signature: sig.image,
                    PrintedName: sig.name
                });
            });

        return mapped;
    }

    private mapMarketingOptions(options, product) {
        var selected = _.filter(options, function (opt) { return opt.selected; });
        //added collect contact info condition because of 7025
        var SelectcollectContctInfo = _.filter(options, function (opt) { return opt.collectContactInfo !== undefined && opt.collectContactInfo !== null; });

        var iDisc = null;
        var iProdCount = 0;
        var mapped = [];
        _.forEach(selected, function (opt) {

            iDisc = null;
            if (opt.SalesGroupDiscounts) {
                for (iProdCount = 0; iProdCount < opt.SalesGroupDiscounts.length; iProdCount++) {
                    if (opt.SalesGroupDiscounts[iProdCount].Product == product) {
                        iDisc = opt.SalesGroupDiscounts[iProdCount].Discount;
                    }
                }
            }

            var cmMethods = [];
            if (opt.DiscountTiedToContactMethods === true && opt.contactMethods) {
                var iSelQty = _.grep(opt.contactMethods, function (elem) {
                    return elem.selected === true;
                }).length;

                var dDisc = 0;
                switch (iSelQty) {
                    case 1:
                        dDisc = opt.DiscountAfter1ContactMethod;
                        break;
                    case 2:
                        dDisc = opt.DiscountAfter2ContactMethod;
                        break;
                    case 3:
                        dDisc = opt.DiscountAfter3ContactMethod;
                        break;
                    case 4:
                        dDisc = opt.DiscountAfter4ContactMethod;
                        break;
                    case 5:
                        dDisc = opt.DiscountAfter5ContactMethod;
                        break;
                }

                iDisc = dDisc;
                cmMethods = opt.contactMethods;
            }


            if (opt.IsCollectContactInfo) {
            }
            else {
                opt.collectContactInfo = undefined;
            }
            mapped.push({
                Id: opt.Id,
                Option: opt.Description,
                Details: opt.details,
                Discount: iDisc,
                CollectContactInfo: opt.collectContactInfo,
                ContactMethods: cmMethods
            });
        });
        _.forEach(SelectcollectContctInfo, function (opt) {
            var mrkeingOption = _.find(mapped, function (band) { return band.Id === opt.Id; });
            if (mrkeingOption === undefined) {
                mapped.push({
                    Id: opt.Id,
                    Option: opt.Description,
                    Details: opt.details,
                    CollectContactInfo: opt.collectContactInfo,
                    AdditionalSelection: true
                });
            }
        });
        return mapped;
    }

    private mappedQuestionValue(Question, name) {
        var magicEyeQuestion = _.find(Question, function (d) { return d.Question === name; });
        if (magicEyeQuestion !== null && magicEyeQuestion !== undefined) {
            return magicEyeQuestion.Answer;
        }
        else {
            return "N";
        }
    }
    private mapQuote(quote) {

        if (!quote || quote.requested !== true) {
            return null;
        }

        var data: any = {};
        data.requested = quote.requested;
        data.QuoteNo = quote.QuoteNo;
        data.summary = quote.summary;
        data.CashPrice = quote.CashPrice;
        data.FinancePrice = quote.FinancePrice;
        data.NoOfUnits = quote.NoOfUnits;
        data.DaysUntilExpiry = quote.DaysUntilExpiry;
        data.ExpiryDate = quote.ExpiryDate;
        data.productDescription = quote.productDescription;
        data.SubjectLineText = quote.SubjectLineText;
        data.ExpiryDiscounts = [];

        if (quote.ExpiringDiscounts) {

            var iDiscLen = quote.ExpiringDiscounts.length;
            var oDisc;
            for (var iDisc = 0; iDisc < iDiscLen; iDisc++) {

                oDisc = {};
                oDisc.discId = quote.ExpiringDiscounts[iDisc].Id;
                oDisc.Name = quote.ExpiringDiscounts[iDisc].Name;
                oDisc.ValidFrom = quote.ExpiringDiscounts[iDisc].ValidFrom;
                oDisc.ValidTo = quote.ExpiringDiscounts[iDisc].ValidTo;

                data.ExpiryDiscounts.push(oDisc);

            }

        }

        return data;

    }




}

